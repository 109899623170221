export interface IAddManualPaymentDto {
  numberOfYears: number;
  paymentType: string;
  paymentReference: string;
}

export const DefaultAddManualPaymentDto: IAddManualPaymentDto = {
  numberOfYears: 1,
  paymentType: "cash",
  paymentReference: "",
};
