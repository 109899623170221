import * as React from "react";
import { Fab } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

interface IProps {
  onEdit: any;
  onDelete: any;
  mediaDetailsSet: boolean;
}

export const DisplayedImageActions: React.FC<IProps> = props => {
  const handleEdit = () => {
    props.onEdit();
  };

  const handleDelete = () => {
    props.onDelete();
  };

  return (
    <>
      <Fab size="small" color="primary" onClick={handleEdit} aria-label="edit">
        <EditIcon />
      </Fab>
      <Fab size="small" color="secondary" onClick={handleDelete} aria-label="delete">
        <DeleteIcon />
      </Fab>
    </>
  );
};
