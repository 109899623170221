import * as React from "react";
import { Typography, Container, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";
import * as Styles from "./styles/RulesAndFeesStyles";
import { SecureArea } from "../components/authentication/SecureArea";
import { MemberPermission } from "../services/MemberService";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../services/TelemetryService";

function RulesAndFees() {
  const classes = Styles.RulesAndFeesStyles();

  return (
    <Container maxWidth={"lg"} fixed>
      <SecureArea memberPermission={MemberPermission.None}>
        <Typography variant="h4" component="h1" color="primary" gutterBottom>
          Seller Rules and Fees
        </Typography>
        <Typography>The Item Listing Fee is applied when an item is listed and is payable whether the item is sold or not.</Typography>
        <TableContainer className={classes.table}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Fee Type</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>e2e Income</TableCell>
                <TableCell>Seller Income</TableCell>
                <TableCell>Bidder Owner Income</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Item Listing Fee</TableCell>
                <TableCell>A fee will be charged for every Item that is listed on the auction</TableCell>
                <TableCell>£25</TableCell>
                <TableCell>£0</TableCell>
                <TableCell>£0</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Admin Release Fee</TableCell>
                <TableCell>
                  The income received from a bidder via the auction Admin Fee (£35) will be split between the Seller of the vehicle (paid £22.50) and
                  the Seller who the bidder belongs to (paid £12.50).
                </TableCell>
                <TableCell>£0</TableCell>
                <TableCell>£22.50</TableCell>
                <TableCell>£12.50</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Typography className={classes.text}>N.B. All fees shown are exclusive of VAT</Typography>

        <Typography variant="h6" component="h2" color="primary" gutterBottom className={classes.heading}>
          Images and text policy
        </Typography>
        <Typography className={classes.text}>
          To ensure you are giving potential buyers an accurate representation of your items for sale, and are not infringing on any 3rd party
          copyright, you should write your own descriptions and use your own images.
        </Typography>
        <Typography className={classes.text}>
          <em>
            To optimise the display of your images on the auction they should be sized at 1440 x 1080 pixels, saved in png or jpeg format with a
            maximum file size of 2MB
          </em>
        </Typography>

        <Typography variant="h6" component="h2" color="primary" gutterBottom className={classes.heading}>
          Other terms and conditions
        </Typography>
        <Typography className={classes.text}>
          We want to be open and transparent with the fees we charge, so here are other details you should be aware of:
        </Typography>
        <Typography className={classes.text}>
          The Item Listing Fee is based on the terms in effect when the listing is made live and all other fees are based on the terms in effect when
          the listing ends.
        </Typography>
        <Typography className={classes.text}>The fees on this page apply to listings on salvagemarket.co.uk or any of its subdomains.</Typography>
        <Typography className={classes.text}>All fees are in UK Sterling</Typography>
        <Typography className={classes.text}>
          There may be instances when your listing does not immediately appear in search results. This can take up to 24 hours.
        </Typography>
        <Typography className={classes.text}>
          Seller fees do not purchase exclusive rights to web pages on salvagemarket.co.uk. We may, at our sole discretion and without consent from or
          payment to sellers, display third-party advertisements (including links and references thereto) and listings from other sellers on any
          salvagemarket.co.uk page.
        </Typography>
      </SecureArea>
    </Container>
  );
}

export default withAITracking(reactPlugin, RulesAndFees, "RulesAndFees");
