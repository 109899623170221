import { GetClientSettings } from "./SettingsService";
import { useReadonlyData, usePostedData } from "./useVersionedData";
import { ISalvageClientSettings } from "../interfaces/ISalvageClientSettings";
import { IAuctionInfo } from "./AuctionService";
import { AuctionStatus } from "../interfaces/auctions/IAuction";

const ClientSettings: ISalvageClientSettings = GetClientSettings();

export interface IBucketNameAndId {
  id: string;
  bucketName: string;
}

export interface IAuctionMgmtFilterOptions {
  lotTypes: string[];
  bodyTypes: string[];
  locations: string[];
  insuranceCompanies: string[];
  auctionStatusGroupings: string[];
  sortBy: string[];
  bucketNameAndIds: IBucketNameAndId[];
}

export interface IAuctionMgmtFilter {
  auctionStatusGrouping?: string;
  lotType?: string;
  bodyType?: string;
  endDateLessThanDays?: number;
  location?: string;
  insuranceCompany?: string;
  isPaidAndCollected?: boolean;
  bucketNameContains?: string;
  bucketId?: string;
  searchText?: string;
  sortBy?: string;
  sortDescending: boolean;
  pageNumber: number;
  numberPerPage: number;
  requestId: string;
}

export const requestId = () => {
  return Date.now().toString();
};

export const DefaultAuctionMgmtFilter = (): IAuctionMgmtFilter => {
  return {
    sortDescending: true,
    pageNumber: 0,
    numberPerPage: 20,
    requestId: requestId(),
    auctionStatusGrouping: "Holding",
    sortBy: "DateAdded",
  };
};

export interface IAuctionMgmtFilterResult {
  pageNumber: number;
  numberPerPage: number;
  totalPages: number;
  totalNumber: number;
  pageResults: IAuctionInfo[];
  requestId: string;
}

export interface IAuctionHistory {
  id: string;
  auctionEnd: string;
  startPrice: number;
  reservePrice: number;
  title: string;
  isFeatured: boolean;
  isRelistedAuction: boolean;
  previousAuctionId: string;
  currentHighestBid: IAuctionHistoryBid;
  bids: IAuctionHistoryBid[];
  status: AuctionStatus;
  vatIncluded: boolean;
  preAccidentValue: number;
  registrationNumber: string;
  referenceNumber: string;
  cost: number;
}

export interface IAuctionHistoryBid {
  bidId: string;
  bidderName: string;
  amount: number;
  isProxy: boolean;
  proxyAmount: number;
  bidDateTime: string;
  isBuyBack: boolean;
}

export interface AuctionAudit {
  id: string;
  createdByName: string;
  createdDate: Date;
  auditType: AuctionAuditTypes;
  changes: AuctionAuditChange[];
}

export interface AuctionAuditChange {
  propertyName: string;
  originalValue: string | undefined;
  updatedValue: string | undefined;
}

export enum AuctionAuditTypes {
  Unknown = 0,
  Create = 1,
  Update = 2,
  UpdateState = 3,
  AddToBucket = 4,
  RemoveFromBucket = 5,
  BucketLive = 6,
  Relist = 7,
  UpdateReserve = 8,
  RemoveBid = 9,
  BuyBack = 10,
  Ended = 11,
  Collected = 12,
  Completed = 13,
  UpdateLotCollectedDate = 14,
  UpdateLotPaymentDate = 15,
}

export const auctionStatusGroupingOptionString = (auctionStatus: string) => {
  switch (auctionStatus) {
    case "ClosedAndSold": {
      return "Closed - Sold";
    }
    case "ClosedButNotSold": {
      return "Closed - Not Sold";
    }
    default: {
      return auctionStatus;
    }
  }
};

export const auctionStatusGroupingRequestString = (auctionStatus: string) => {
  switch (auctionStatus) {
    case "Closed - Sold": {
      return "ClosedAndSold";
    }
    case "Closed - Not Sold": {
      return "ClosedButNotSold";
    }
    default: {
      return auctionStatus;
    }
  }
};

export const useAuctionMgmtFilterOptions = (onError: (httpStatusCode: number) => void) =>
  useReadonlyData<IAuctionMgmtFilterOptions>(`${ClientSettings.AuctionPrivateApiUrl}/auctionmanagement/searchoptions/`, onError);

export const useAuctionHistoryIncludingPreviousListing = (auctionId: string, onError: (httpStatusCode: number) => void) =>
  useReadonlyData<IAuctionHistory[]>(`${ClientSettings.AuctionPrivateApiUrl}/auctions/id/${auctionId}/history/includingpreviouslistings`, onError);

export const useAuctionMgmtFilter = (onError: (httpStatusCode: number) => void) =>
  usePostedData<IAuctionMgmtFilterResult, IAuctionMgmtFilter>(ClientSettings.AuctionPrivateApiUrl + `/auctionmanagement/searches`, onError);

export const useAuctionAuditHistory = (auctionId: string, onError: (httpStatusCode: number) => void) =>
  useReadonlyData<AuctionAudit[]>(`${ClientSettings.AuctionPrivateApiUrl}/auctions/id/${auctionId}/audit`, onError);
