import * as React from "react";

import { useSnackbar } from "notistack";
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import { MemberNameAndId, useMarginShareReportOptions, useLiveLotDetailsReport, DefaultMarginShareReportRequest, DefaultLiveLotDetailsReportRequest, useLiveLotDetailsReportOptions } from "../../services/ReportsService";
import { ContainerStyles } from "./styles/ContainerStyles";
import { SearchSkeleton } from "../auction-management/SearchSkeleton";
import { LoadingComponent } from "../loading/LoadingComponent";
import { downloadCSV } from "./DownloadCsv";
import { Filter } from "../../helpers/Filter";

export const LiveLotDetailsReportContainer: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = ContainerStyles();

  const [filterOptions, isOptionsLoading, ,] = useMarginShareReportOptions(() =>
    enqueueSnackbar("Could not retrieve list of filter options", { variant: "error" })
  );

  const [sortByFilterOptions, isSortByLoading, ,] = useLiveLotDetailsReportOptions(() =>
    enqueueSnackbar("Could not retrieve list of sort by options", { variant: "error" })
  );

  const [filterRequest, setFilterRequest, apiPostAsync, filterResponse, isLoadingReport, ,] = useLiveLotDetailsReport(() =>
    enqueueSnackbar("Could not retrieve list of auctions", { variant: "error" })
  );

  React.useEffect(() => {
    setFilterRequest(DefaultLiveLotDetailsReportRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (filterResponse) {
      downloadCSV(filterResponse.byteArray, "LIVE Lot Details Report.csv");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterResponse]);

  const changeHandler = (event: any, property: string) => {
    if (filterRequest) {
      if (event.target.value === "All") {
        setFilterRequest({ ...filterRequest, [property]: null, requestId: Date.now.toString() });
      } else {
        setFilterRequest({ ...filterRequest, [property]: event.target.value, requestId: Date.now.toString() });
      }
    }
  };

  const changeDirection = (sortDescending: boolean) => {
    if (filterRequest) {
      setFilterRequest({ ...filterRequest, sortDescending: sortDescending, requestId: Date.now.toString() });
    }
  };

  const search = () => {
    if (filterRequest !== null) {
      apiPostAsync();
    }
  };

  const reset = () => {
    if (filterRequest !== null) {
      setFilterRequest(DefaultLiveLotDetailsReportRequest());
    }
  };

  return (
    <>
      {isLoadingReport && <LoadingComponent label="Loading report" />}
      {isOptionsLoading && <SearchSkeleton />}
      {!isLoadingReport && (
        <Grid container spacing={1}>
          <Grid item xs={12} lg={2}>
            {!isOptionsLoading && !isSortByLoading && filterOptions?.memberNameAndIds !== null && (
              <FormControl className={classes.width100} variant="outlined" margin="dense">
                <InputLabel variant="outlined">Member</InputLabel>
                <Select
                  label={"Member"}
                  onChange={value => {
                    changeHandler(value, "memberId");
                  }}
                  value={filterRequest?.memberId ? filterRequest.memberId : ""}
                  variant="outlined"
                >
                  <MenuItem value={"All"}>All</MenuItem>
                  {filterOptions?.memberNameAndIds &&
                    filterOptions?.memberNameAndIds.map((type: MemberNameAndId, index: number) => (
                      <MenuItem value={type.id} key={index}>
                        {type.memberName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
          </Grid>

          {/* <Grid item xs={12} lg={2}>
            {!isOptionsLoading && !isSortByLoading && (
              <TextField
                variant="outlined"
                id="date"
                label="Date From"
                type="date"
                margin="dense"
                value={filterRequest?.dateFrom ? filterRequest?.dateFrom : ""}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={event => changeHandler(event, "dateFrom")}
                fullWidth
              />
            )}
          </Grid>

          <Grid item xs={12} lg={2}>
            {!isOptionsLoading && !isSortByLoading && (
              <TextField
                variant="outlined"
                id="date"
                label="Date To"
                type="date"
                margin="dense"
                value={filterRequest?.dateTo ? filterRequest?.dateTo : ""}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={event => changeHandler(event, "dateTo")}
                fullWidth
              />
            )}
          </Grid> */}

          <Grid item xs={12} lg={2}>
            {!isSortByLoading && (
              <Filter
                title="Sort by"
                filterOptions={sortByFilterOptions?.sortBy}
                filterPropertyName="sortBy"
                changeHandler={changeHandler}
                filterValue={filterRequest?.sortBy ? filterRequest.sortBy : ""}
                enumCase={true}
                all={false}
              />
            )}
          </Grid>

          <Grid item xs={12} lg={2}>
            {!isSortByLoading && (
              <Filter
                title="Direction"
                filterOptions={["Descending", "Ascending"]}
                filterPropertyName="sortDescending"
                changeHandler={value => changeDirection(value.target.value === "Descending")}
                filterValue={filterRequest?.sortDescending ? "Descending" : "Ascending"}
                all={false}
              />
            )}
          </Grid>

          <Grid item xs={12} lg={1}>
            {!isOptionsLoading && !isSortByLoading && (
              <Button color="primary" className={classes.searchButton} onClick={reset}>
                Reset
              </Button>
            )}
          </Grid>

          <Grid item xs={12}>
            {!isOptionsLoading && !isSortByLoading && (
              <Button className={classes.createReportButton} variant="contained" color="primary" onClick={search} fullWidth>
                Create Report
              </Button>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};
