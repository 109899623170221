import * as React from "react";
import { CssBaseline } from "@material-ui/core";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { FeaturedDragContainer } from "./FeaturedDragContainer";

export const FeaturedOrderingComponent: React.FC = () => {
  return (
    <>
      <CssBaseline />
      <DndProvider backend={HTML5Backend}>
        <FeaturedDragContainer />
      </DndProvider>
    </>
  );
};
