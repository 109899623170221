import * as React from "react";
import { Typography, Container, CssBaseline } from "@material-ui/core";
import { RouteComponentProps } from "react-router-dom";
import { EditBucketContainer } from "../components/buckets/EditBucketContainer";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { SecureArea } from "../components/authentication/SecureArea";
import { MemberPermission } from "../services/MemberService";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../services/TelemetryService";

type EditBucketParams = { id: string };

function EditBucket({ match }: RouteComponentProps<EditBucketParams>) {
  return (
    <Container maxWidth={"lg"} fixed>
      <SecureArea memberPermission={MemberPermission.BucketAdmin}>
        <Typography variant="h4" color="primary" gutterBottom>
          Bucket Setup
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          You can add the waiting auctions into this bucket by dragging them in. You can also set details such as the bucket schedule and how far
          apart the auctions should be spaced.
        </Typography>
        <CssBaseline />
        <DndProvider backend={HTML5Backend}>
          <EditBucketContainer bucketId={match.params.id} isActiveBucket={false} />
        </DndProvider>
      </SecureArea>
    </Container>
  );
}

export default withAITracking(reactPlugin, EditBucket, "EditBucket");
