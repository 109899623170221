import * as React from "react";
import { Typography, Container, Box } from "@material-ui/core";
import { StripeProductsListContainer } from "../components/stripe-products-list/StripeProductsListContainer";
import { SecureArea } from "../components/authentication/SecureArea";
import { MemberPermission } from "../services/MemberService";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../services/TelemetryService";

function StripeProducts() {
    return (
        <Container maxWidth={"lg"} fixed>
            <SecureArea memberPermission={MemberPermission.SuperAdmin}>
                <Typography variant="h4" color="primary" gutterBottom>
                    Bidder Subscription Plans
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    View and manage all bidder subscription  plans
                </Typography>
                <Box mt={4}>
                    {/* <IdVerificationContainer /> */}
                    <StripeProductsListContainer></StripeProductsListContainer>
                </Box>
            </SecureArea>
        </Container>
    );
}

export default withAITracking(reactPlugin, StripeProducts, "StripeProducts");
