import * as React from "react";

import { Typography, Grid, makeStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

export const LoadingComponent: React.FC<{ label: string; marginTop?: string; marginBottom?: string }> = ({ label, marginTop, marginBottom }) => {
  const classes = makeStyles(theme => ({
    loadingResultsComponent: {
      marginTop: marginTop ?? "150px;",
      marginBottom: marginBottom ?? "150px;",
    },
  }))();

  return (
    <Grid direction="column" container alignItems="center" className={classes.loadingResultsComponent}>
      <Grid item>
        <Typography variant="h5" gutterBottom>
          {label}...
        </Typography>
      </Grid>
      <Grid item>
        <CircularProgress color="primary" size={60} thickness={8} />
      </Grid>
    </Grid>
  );
};
