import * as React from "react";
import { CssBaseline } from "@material-ui/core";
import { TypeSelection } from "./steps/TypeSelection";
import { LotForm } from "./LotForm";

interface LotFormComponentProps {
  id: string | null;
  auctionId: string | null;
}

export const LotFormComponent: React.FC<LotFormComponentProps> = ({ id, auctionId }) => {
  return (
    <>
      <CssBaseline />
      {!id && <TypeSelection />}
      {id && <LotForm lotId={id!} auctionId={auctionId} />}
    </>
  );
};
