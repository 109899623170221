import { usePostedData } from "./useVersionedData";
import { GetClientSettings } from "./SettingsService";
import { ISalvageClientSettings } from "../interfaces/ISalvageClientSettings";
import moment from "moment";
import { ApiService, HttpResponse } from "./ApiService";
import { IssueRefundDto } from "../interfaces/bidders/IIssueRefundDto";

const ClientSettings: ISalvageClientSettings = GetClientSettings();

export interface IRefund {
  id: string;
  orderDateTime: string;
  memberName: string;
  bidderName: string;
  bidderId: string;
  total: number;
  paymentLength: number;
  originalPaymentReference: string;
  originalOrderDate: string;
  originalOrderType: orderTypes;
  orderStatus: orderStatus;
  paymentReference: string;
}

export interface IRefundDates {
  dateFrom: string;
  dateTo: string;
}

export const DefaultRefundDates = {
  dateFrom: moment()
    .subtract(2, "months")
    .format("YYYY-MM-DDTHH:mm"),
  dateTo: moment()
    .add(1, "hours")
    .format("YYYY-MM-DDTHH:mm"),
};

export enum orderStatus {
  Pending = 0,
  Unknown = 1,
  Error = 2,
  Failed = 3,
  Succeeded = 4,
}

export const orderStatusToString = (status: orderStatus) => {
  switch (status) {
    case orderStatus.Pending:
      return "Pending";
    case orderStatus.Error:
      return "Error";
    case orderStatus.Failed:
      return "Failed";
    case orderStatus.Succeeded:
      return "Succeeded";
    default:
      return "Unknown";
  }
};

export enum orderTypes {
  Subscription = 1,
  Renewal = 2,
  FreeTrial = 3,
  ManualCashPayment = 4,
  ManualCardPayment = 5,
  Refund = 6,
}

export const orderTypesToString = (status: orderTypes) => {
  switch (status) {
    case orderTypes.Subscription:
      return "Subscription";
    case orderTypes.Renewal:
      return "Renewal";
    case orderTypes.FreeTrial:
      return "Free trial";
    case orderTypes.ManualCashPayment:
      return "Manual cash payment";
    case orderTypes.ManualCardPayment:
      return "Manual card payment";
    case orderTypes.Refund:
      return "Refund";
    default:
      return "Unknown";
  }
};

export const useRefunds = (onError: (httpStatusCode: number) => void) =>
  usePostedData<IRefund[], IRefundDates>(ClientSettings.PaymentsPrivateApiUrl + `/refunds`, onError);

export const CompletePayzoneRefund = async (refundOrderId: string, transactionId: string, bidderId: string): Promise<HttpResponse<undefined>> => {
  return await ApiService().Put<undefined>(
    ClientSettings.PaymentsPrivateApiUrl + `/bidder/${bidderId}/refund/${refundOrderId}/payzone/complete/${transactionId}`,
    undefined
  );
};

export const issueDirectPaymentRefund = async (bidderId: string, refundDetails: IssueRefundDto): Promise<HttpResponse<Date | null>> => {
  return await ApiService().Post<Date | null>(ClientSettings.PaymentsPrivateApiUrl + `/bidder/${bidderId}/refund/direct`, refundDetails);
};

// export const requestPayzoneRefund = async (bidderId: string, refundDetails: IssueRefundDto): Promise<HttpResponse<Date | null>> => {
//   return await ApiService().Post<Date | null>(ClientSettings.PaymentsPrivateApiUrl + `/bidder/${bidderId}/refund/payzone/request`, refundDetails);
// };

export const requestStripeRefund = async (bidderId: string, refundDetails: IssueRefundDto): Promise<HttpResponse<Date | null>> => {
  return await ApiService().Post<Date | null>(ClientSettings.PaymentsPrivateApiUrl + `/bidder/${bidderId}/refund/payzone/request`, refundDetails);
};

export const rejectStripeRefund = async (bidderId: string, orderId: string): Promise<HttpResponse<Date | null>> => {
  return await ApiService().Get<Date | null>(ClientSettings.PaymentsPrivateApiUrl + `/stripe/refund/reject/${bidderId}/${orderId}`,);
};



export const postRequestRefund = async (refundDetails: IssueRefundDto): Promise<HttpResponse<void>> => {
  return await ApiService().Post<void>(ClientSettings.PaymentsPrivateApiUrl + `/stripe/refund/request`, refundDetails);
};


