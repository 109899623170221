import React from "react";
import { useSnackbar } from "notistack";
import { TableCell, Hidden, CircularProgress } from "@material-ui/core";
import { Bid, IAuctionInfoWithAdminOnlyData, useAuctionBidHistory } from "../../services/AuctionService";
import { bidPlacedFormat, currencyFormat } from "../../helpers/text-format/TextFormat";
import { IBidder } from "../../interfaces/bidders/IBidder";
import { GetClientSettings } from "../../services/SettingsService";
import { ISalvageClientSettings } from "../../interfaces/ISalvageClientSettings";
import moment from "moment";

interface WatchHistoryRowProps {
  auctionListing: IAuctionInfoWithAdminOnlyData;
  bidder: IBidder;
}

export const WatchHistoryRow: React.FC<WatchHistoryRowProps> = ({ auctionListing, bidder }) => {
  const { enqueueSnackbar } = useSnackbar();
  const clientSettings: ISalvageClientSettings = GetClientSettings();

  const [bidHistory, bidHistoryLoading] = useAuctionBidHistory(auctionListing.id, () => {
    enqueueSnackbar("There was a problem loading the latest bid information, please try again.", { variant: "error" });
  });
  const [latestBidForBidder, setLatestBidForBidder] = React.useState<Bid>();

  React.useEffect(() => {
    if (bidHistory) {
      const sorted = [...bidHistory].sort((a, b) => Number(b.bidDateTime) - Number(a.bidDateTime));
      setLatestBidForBidder(sorted.length > 0 ? sorted[0] : undefined);
    }
  }, [bidHistory]);

  return (
    <>
      {bidHistoryLoading && (
        <>
          <TableCell colSpan={7} align="center">
            <CircularProgress color="primary" size={16} thickness={8} style={{ marginRight: "12px" }} />
            Loading...
          </TableCell>
        </>
      )}
      {!bidHistoryLoading && (
        <>
          <TableCell padding="checkbox">
            <a href={`${clientSettings.AuctionSiteUrl}Lot/${auctionListing.id}/`} target="_blank" rel="noopener noreferrer">
              <img alt="logo" src={auctionListing.thumbnailImageUrl} width="95px" height="auto"></img>
            </a>
          </TableCell>
          <TableCell>{auctionListing.title}</TableCell>
          <Hidden smDown>
            <TableCell>{auctionListing.memberName}</TableCell>
            <TableCell style={{ whiteSpace: "nowrap" }}>{moment(auctionListing.auctionEnd).format('DD/MM/YYYY h:mm a')}</TableCell>
            <TableCell>{currencyFormat(latestBidForBidder?.amount ? latestBidForBidder?.amount : 0)}</TableCell>
            <TableCell>{auctionListing.metadata.referenceNumber}</TableCell>
            <TableCell>{auctionListing.adminOnly.registrationNumber}</TableCell>
          </Hidden>
        </>
      )}
    </>
  );
};
