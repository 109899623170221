import React from "react";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Link, Typography } from "@material-ui/core";
import * as Styles from "./styles/LiveBidItemDialogStyles";
import { useHistory } from "react-router-dom";

interface EditDialogProps {
  lotId: string;
  auctionId: string;
}

export const EditDialog: React.FC<EditDialogProps> = ({ lotId, auctionId }) => {
  const classes = Styles.LiveBidItemDialogStyles();
  const history = useHistory();

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Link
        style={{ cursor: "pointer" }}
        onClick={() => {
          setOpen(true);
        }}
      >
        Edit
      </Link>

      <Dialog maxWidth={"md"} open={open} onClose={handleClose}>
        <DialogTitle className={classes.dialogHeader} id="alert-dialog-title">
          Edit
        </DialogTitle>

        <DialogContent style={{ alignContent: "center" }}>
          <Typography>Are you sure you want to edit this lot</Typography>
        </DialogContent>

        <DialogActions>
          <Button variant="contained" color="primary" onClick={() => history.push(`/UpdateLot/${lotId}/${auctionId}`)}>
            OK
          </Button>
          <Button variant="contained" color="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
