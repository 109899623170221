import React from "react";
import TextField from "@material-ui/core/TextField";
import * as Styles from "./styles/BidderTextFieldStyles";

interface IProps {
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  setGetSearchResults: React.Dispatch<React.SetStateAction<boolean>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  getSearchResults: boolean;
}

export const BidderSearchTextField: React.FC<IProps> = Props => {
  const classes = Styles.BidderTextFieldStyles();

  const searchHandler = (value: any) => {
    Props.setSearchText(value);
    Props.setPage(0);
  };

  return (
    <TextField
      disabled={Props.getSearchResults}
      className={classes.textField}
      margin="dense"
      id="textSearch"
      placeholder={"Search Bidders"}
      onChange={e => {
        searchHandler(e.target.value);
      }}
      variant="outlined"
      value={Props.searchText}
      fullWidth
    />
  );
};
