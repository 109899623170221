import * as React from "react";
import { LoadingComponent } from "../loading/LoadingComponent";
import {
  Grid,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TextField,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import { endDateFormat } from "../../helpers/text-format/TextFormat";
import { RefundContainerStyles } from "./styles/RefundContainerStyles";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";

import {
  DefaultRefundDates,
  orderStatus,
  orderStatusToString,
  orderTypes,
  orderTypesToString,
  useRefunds,
  IRefund,
} from "../../services/RefundService";
import { CompleteRefundDialog } from "./CompleteRefundDialog";
import { Link } from "react-router-dom";

export const RefundsContainer: React.FC = () => {
  const classes = RefundContainerStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState<boolean>(false);
  const [refundToComplete, setRefundToComplete] = React.useState<IRefund>();
  const [refundDateRange, setRefundDateRange, apiPostAsync, refunds, isLoading, ,] = useRefunds(() =>
    enqueueSnackbar("Could not retrieve list of refunds", { variant: "error" })
  );

  const changeDate = (event: any, property: string) => {
    if (refundDateRange) {
      setRefundDateRange({ ...refundDateRange, [property]: event.target.value });
    }
  };

  React.useEffect(() => {
    apiPostAsync(DefaultRefundDates);
    setRefundDateRange(DefaultRefundDates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const search = () => {
    if (refundDateRange) {
      apiPostAsync();
    }
  };

  const reset = () => {
    if (refundDateRange) {
      setRefundDateRange(DefaultRefundDates);
    }
  };

  return (
    <>
      {refundToComplete && <CompleteRefundDialog open={open} setOpen={setOpen} refund={refundToComplete} refreshRefunds={apiPostAsync} />}
      {isLoading && <LoadingComponent label="Loading Refunds" />}
      {!isLoading && (
        <Grid container spacing={2}>
          <Grid item xs={12} lg={3}>
            <TextField
              variant="outlined"
              id="date"
              label="Date From"
              type="datetime-local"
              margin="dense"
              value={refundDateRange?.dateFrom ? refundDateRange?.dateFrom : ""}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={event => changeDate(event, "dateFrom")}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <TextField
              variant="outlined"
              id="date"
              label="Date To"
              type="datetime-local"
              margin="dense"
              value={refundDateRange?.dateTo ? refundDateRange?.dateTo : ""}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={event => changeDate(event, "dateTo")}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} lg={2} className={classes.buttonContainer}>
            <Button color="primary" onClick={reset}>
              Reset
            </Button>
            <Button variant="contained" color="primary" className={classes.searchButton} onClick={search}>
              Search
            </Button>
          </Grid>
          <Grid xs={12} item>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableTitle}>Refund date/time</TableCell>
                    <TableCell className={classes.tableTitle}>Member</TableCell>
                    <TableCell className={classes.tableTitle}>Bidder</TableCell>
                    <TableCell className={classes.tableTitle}>Amount</TableCell>
                    <TableCell className={classes.tableTitle}>Payment length</TableCell>
                    <TableCell className={classes.tableTitle}>Original transaction reference</TableCell>
                    <TableCell className={classes.tableTitle}>Original payment date</TableCell>
                    <TableCell className={classes.tableTitle}>Original payment type</TableCell>
                    <TableCell className={classes.tableTitle}>Status of refund</TableCell>
                    <TableCell className={classes.tableTitle}>Refund reference number</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {refunds && refunds.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={3}>No refunds available</TableCell>
                    </TableRow>
                  )}
                  {refunds &&
                    refunds?.map(refund => (
                      <TableRow key={refund.id}>
                        <TableCell className={classes.firstTableCell}>{endDateFormat(refund.orderDateTime, "")}</TableCell>
                        <TableCell className={classes.tableCell}>{refund.memberName}</TableCell>
                        <TableCell className={classes.tableCell}>
                          <Link to={`/BidderDetailsChange/${refund.bidderId}`}>{refund.bidderName}</Link>
                        </TableCell>
                        <TableCell className={classes.tableCell}> £{(refund.total / 100).toFixed(2)}</TableCell>
                        <TableCell className={classes.tableCell}>{refund.paymentLength} years</TableCell>
                        <TableCell className={classes.tableCell}>{refund.originalPaymentReference}</TableCell>
                        <TableCell className={classes.tableCell}>{endDateFormat(refund.originalOrderDate, "")}</TableCell>
                        <TableCell className={classes.tableCell}>{orderTypesToString(refund.originalOrderType)}</TableCell>
                        <TableCell className={classes.tableCell}>{orderStatusToString(refund.orderStatus)}</TableCell>
                        <TableCell className={classes.tableCell}>{refund.paymentReference}</TableCell>
                        <TableCell className={classes.lastTableCell}>
                          {(refund.originalOrderType === orderTypes.Subscription || refund.originalOrderType === orderTypes.Renewal) &&
                          refund.orderStatus === orderStatus.Pending ? (
                            <Tooltip title="Complete Refund">
                              <IconButton
                                aria-label="Complete Refund"
                                onClick={() => {
                                  setRefundToComplete(refund);
                                  setOpen(true);
                                }}
                              >
                                <AssignmentTurnedInIcon color="primary" />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <IconButton aria-label="Complete Refund" disabled>
                              <AssignmentTurnedInIcon />
                            </IconButton>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      )}
    </>
  );
};
