import * as React from "react";
import { Typography, Container } from "@material-ui/core";
import { SecureArea } from "../components/authentication/SecureArea";
import { MemberPermission } from "../services/MemberService";
import { reactPlugin } from "../services/TelemetryService";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { PermissionsContainer } from "../components/permissions/PermissionsContainer";

function Permissions() {
  return (
    <Container maxWidth={"lg"} fixed>
      <SecureArea memberPermission={MemberPermission.LiveAuctionAdmin}>
        <Typography variant="h4" color="primary" gutterBottom>
          Administrators & Permissions
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Manage member Administrators & Permissions.
        </Typography>
        <PermissionsContainer />
      </SecureArea>
    </Container>
  );
}

export default withAITracking(reactPlugin, Permissions, "Permissions");
