import * as React from "react";
import { BiddersDocumentsSubmitted } from "./BiddersDocumentsSubmitted";
import { BiddersAwaitingDeDupe } from "./BiddersAwaitingDeDupe";

export const IdVerificationContainer: React.FC = () => {
  return (
    <>
      <BiddersDocumentsSubmitted />
      <BiddersAwaitingDeDupe />
    </>
  );
};
