import React, { useCallback } from "react";
import { Button, Typography, Paper } from "@material-ui/core";
import * as Styles from "./styles/FeaturedDragContainerStyles";
import { useFeaturedAuctions } from "../../services/FeaturedAuctionService";
import FeaturedDragItem from "./FeaturedDragItem";
import update from "immutability-helper";
import { LoadingComponent } from "../loading/LoadingComponent";
import { useSnackbar, OptionsObject } from "notistack";

const variantError: OptionsObject = { variant: "error" };

export const FeaturedDragContainer: React.FC = () => {
  const classes = Styles.FeaturedDragContainerStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [featuredAuctions, setFeaturedAuctions, isLoading, , putFeaturedAuctionOrder, refreshFeaturedAuctionOrder] = useFeaturedAuctions(() => {
    enqueueSnackbar("There was a problem with your request, please try again.", variantError);
  });

  const moveFeaturedAuction = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      if (featuredAuctions) {
        const dragCard = featuredAuctions[dragIndex];
        setFeaturedAuctions(
          update(featuredAuctions, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragCard],
            ],
          })
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [featuredAuctions]
  );

  return (
    <>
      {isLoading && <LoadingComponent label="Loading featured auctions" />}
      {!isLoading &&
        featuredAuctions &&
        featuredAuctions.map((card, i) => (
          <FeaturedDragItem
            key={i}
            index={i}
            id={card.AuctionId}
            auctionTitle={card.auctionTitle}
            auctionImage={card.auctionImage}
            moveFeaturedAuction={moveFeaturedAuction}
          />
        ))}
      {!isLoading && !featuredAuctions && (
        <Paper square>
          <Typography variant="subtitle1" align="center" gutterBottom style={{ paddingBottom: "32px" }}>
            No records found
          </Typography>
        </Paper>
      )}
      {!isLoading && (
        <div className={classes.buttonWrapper}>
          <Button
            className={classes.OkButton}
            variant="contained"
            color="primary"
            onClick={() => {
              putFeaturedAuctionOrder();
            }}
          >
            OK
          </Button>
          <Button
            className={classes.CancelButton}
            variant="contained"
            color="secondary"
            onClick={() => {
              refreshFeaturedAuctionOrder();
            }}
          >
            Refresh
          </Button>
        </div>
      )}
    </>
  );
};
