import { base64ToBuffer } from "./base64ToBuffer";

export function downloadZip(byteArrayStr: string, fileName: string) {
  //var csvStr = atob(byteArrayStr);
  var csvStr = base64ToBuffer(byteArrayStr);
  var blob = new Blob([csvStr], { type: "application/zip" });

  var hiddenElement = document.createElement("a");
  hiddenElement.href = window.URL.createObjectURL(blob);
  hiddenElement.target = "_blank";
  hiddenElement.download = `${fileName}`;
  hiddenElement.click();
}
