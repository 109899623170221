import * as React from "react";
import { Typography } from "@material-ui/core";
import { LoadingComponent } from "../../loading/LoadingComponent";

export const BulkProcessLoading = () => (
  <div style={{ margin: "10px 40px" }}>
    <Typography variant="h6" align="center">
      Please do not navigate away until this process is finished
    </Typography>
    <LoadingComponent label="Processing auctions to bucket" />
  </div>
);
