import * as React from "react";
import { Box, Button } from "@material-ui/core";

import * as Styles from "./styles/NavbarAuthenticationStyles";
import { MsalConsumer } from "../../authentication/MsalContext";
import { ISalvageClientSettings } from "../../interfaces/ISalvageClientSettings";
import { GetClientSettings } from "../../services/SettingsService";

const clientSettings: ISalvageClientSettings = GetClientSettings();

export const NavbarAuthentication: React.FC = () => {
  const classes = Styles.NavbarAuthenticationStyles();

  return (
    <Box maxWidth="xs" className={classes.authentication}>
      <MsalConsumer>
        {msal => (
          <>
            {msal.loginInProgress && (
              <Box component="span" m={1}>
                <Button
                  disabled={true}
                  variant="contained"
                  color="primary"
                  style={{
                    margin: "0 16px",
                    minWidth: "75px",
                    fontWeight: "normal",
                  }}
                >
                  loading...
                </Button>
              </Box>
            )}
            {!msal.loginInProgress && msal.accounts.length > 0 && (
              <Box component="span" m={1}>
                <Button
                  onClick={() => msal.instance.logout()}
                  variant="contained"
                  color="primary"
                  style={{
                    margin: "0 16px",
                    minWidth: "75px",
                    fontWeight: "normal",
                  }}
                >
                  Logout
                </Button>
              </Box>
            )}

            {!msal.loginInProgress && msal.accounts.length === 0 && (
              <Box component="span" m={1}>
                <Button
                  onClick={async () =>
                    await msal.instance.loginRedirect({
                      scopes: [clientSettings.B2CSettings.TokenScope],
                    })
                  }
                  variant="contained"
                  color="primary"
                  style={{
                    margin: "0 16px",
                    minWidth: "75px",
                    fontWeight: "normal",
                  }}
                >
                  Login
                </Button>
              </Box>
            )}
          </>
        )}
      </MsalConsumer>
    </Box>
  );
};
