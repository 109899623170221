import { makeStyles } from "@material-ui/styles";

import { Theme, createStyles } from "@material-ui/core";

export const EditBucketCardStyles = (isEnded: boolean) =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        display: "flex",
        margin: "8px",
        backgroundColor: isEnded ? theme.palette.error.light : theme.palette.background.paper,
      },
      fontSize: {
        fontSize: "12px",
      },
      alreadyEndedFont: {
        fontSize: "12px",
        fontWeight: "bold",
        marginLeft: "1em",
      },
      title: {
        fontSize: "12px",
        fontWeight: "bold",
      },
      auctionImage: {
        height: "100%",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
      },
    })
  );
