import * as React from "react";
import { useHistory } from "react-router-dom";

import { CssBaseline } from "@material-ui/core";
import { useSnackbar, OptionsObject } from "notistack";

import { useConfiguration } from "../../services/VehicleConfigurationService";
import { useLotProperties, useLotDetails, publishLot, createAuctionFromLotId, deleteLot } from "../../services/LotService";

import { MediaDetails } from "./steps/MediaDetails";
import { LotPropertiesDetails } from "./steps/LotPropertiesDetails";
import { LotDetailsForm } from "./steps/LotDetailsForm";

import { LoadingComponent } from "../loading/LoadingComponent";
import { StepType } from "./types/StepTypes";
import { LotFormButtons } from "./components/LotFormButtons";
import { LotFormStepper } from "./components/LotFormStepper";
import { useInsuranceCompanies } from "../../services/MemberService";
import { publishAuction, quickAddAuctionToBucket, updateAuctionMetadata, useAuctionListing } from "../../services/AuctionService";
import { useActiveBuckets, useDraftBuckets } from "../../services/BucketService";
import { IBucket } from "../../interfaces/buckets/IBucket";
import { AuctionStatus } from "../../interfaces/auctions/IAuction";
import Completed from "./steps/Completed";

const steps = ["Details", "Media", "Options and Financials", "Completed"];

const variantError: OptionsObject = { variant: "error" };

interface LotFormProps {
  lotId: string;
  auctionId: string | null;
}

export const LotForm: React.FC<LotFormProps> = ({ lotId, auctionId }) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  var apiError: boolean = false;

  const [loading, setLoading] = React.useState<boolean>(true);
  const [publishing, setPublishing] = React.useState(false);
  const [uploadMedia, setUploadMedia] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(StepType.Details);
  const [mediaAllValid, setMediaAllValid] = React.useState<boolean>();

  const [lotDetails, setLotDetails, lotDetailsLoading, , putLotDetails, , lotDetailsValidation] = useLotDetails(lotId!, httpStatusCode => {
    apiError = true;
    if (httpStatusCode !== 400) {
      enqueueSnackbar("There was a problem with your lot details request, please try again.", variantError);
    }
  });

  const [auctionListing, setAuctionListing, auctionListingLoading] = useAuctionListing(auctionId!, httpStatusCode => {
    if (httpStatusCode !== 404) {
      enqueueSnackbar("There was a problem with your auction request, please try again.", variantError);
    }
  });

  const [lotProperties, setLotProperties, lotPropertiesLoading, , putLotProperties, , lotPropertiesValidation] = useLotProperties(
    lotId!,
    httpStatusCode => {
      apiError = true;
      setPublishing(false);
      if (httpStatusCode !== 400) {
        enqueueSnackbar("There was a problem with your lot properties request, please try again.", variantError);
      }
    }
  );

  const [configuration, configurationLoading] = useConfiguration(() => {
    enqueueSnackbar("There was a problem getting the lot configuration options, please try again.", variantError);
  });

  const [insuranceCompanies, insuranceCompaniesLoading] = useInsuranceCompanies(() => {
    enqueueSnackbar("There was a problem getting the list of insurance companies, please try again.", variantError);
  });

  const [draftBuckets, isDraftBucketsLoading] = useDraftBuckets(() => {
    enqueueSnackbar("There was a problem loading draft buckets, please try again.", variantError);
  });
  const [activeBuckets, isActiveBucketsLoading] = useActiveBuckets(() => {
    enqueueSnackbar("There was a problem loading active buckets, please try again.", variantError);
  });

  const [allBuckets, setAllBuckets] = React.useState<IBucket[]>([]);

  React.useEffect(() => {
    const buckets: IBucket[] = [];
    if (draftBuckets) buckets.push(...draftBuckets);
    if (activeBuckets) buckets.push(...activeBuckets);
    setAllBuckets(buckets);
  }, [draftBuckets, activeBuckets]);

  React.useEffect(() => {
    setLoading(
      configurationLoading ||
        isDraftBucketsLoading ||
        isActiveBucketsLoading ||
        lotDetailsLoading ||
        lotPropertiesLoading ||
        insuranceCompaniesLoading ||
        auctionListingLoading ||
        publishing
    );
  }, [
    configurationLoading,
    isDraftBucketsLoading,
    isActiveBucketsLoading,
    lotDetailsLoading,
    lotPropertiesLoading,
    insuranceCompaniesLoading,
    auctionListingLoading,
    publishing,
  ]);

  const handleNext = () => {
    apiError = false;
    switch (activeStep) {
      case StepType.Details: {
        putLotDetails().then(() => {
          if (!apiError) {
            setActiveStep(StepType.Media);
          }
        });
        break;
      }
      case StepType.Media: {
        setUploadMedia(true);
        break;
      }
      case StepType.Options: {
        setPublishing(true);
        putLotProperties()
          .then(() => {
            if (!apiError) {
              publish();
            }
          })
          .catch(() => {
            setPublishing(false);
            enqueueSnackbar("Could not save the lot properties, please try again.", variantError);
          });
        break;
      }
      default:
        enqueueSnackbar("Could not determine your step", variantError);
    }
  };

  const mediaUploaded = (success: boolean) => {
    if (success) {
      setUploadMedia(false);
      if (mediaAllValid !== undefined && mediaAllValid) {
        setActiveStep(StepType.Options);
      }
    }
  };

  const updateAuction = (auctionId: string) => {
    updateAuctionMetadata(auctionId)
      .then(() => {
        if (auctionListing?.status === AuctionStatus.CreatedPartially) {
          publishAuctionById(auctionListing.id);
        } else {
          setPublishing(false);
          setActiveStep(StepType.Completed);
        }
      })
      .catch(() => {
        setPublishing(false);
        enqueueSnackbar("Could not update auction, please try again.", variantError);
      });
  };

  const createAuction = (lotId: string) => {
    createAuctionFromLotId(lotId)
      .then(data => {
        publishAuctionById(data.parsedBody?.id);
      })
      .catch(() => {
        setPublishing(false);
        enqueueSnackbar("Could not create an auction, please try again.", variantError);
      });
  };

  const publishAuctionById = (auctionId?: string) => {
    if (auctionId) {
      publishAuction(auctionId)
        .then(() => {
          if (auctionListing && auctionListing.bucketId) {
            addToBucket(auctionListing.bucketId, auctionId);
          } else {
            setPublishing(false);
            setActiveStep(StepType.Completed);
          }
        })
        .catch(() => {
          setPublishing(false);
          enqueueSnackbar("Could not publish auction, please try again.", variantError);
        });
    }
  };

  const addToBucket = (bucketId: string, auctionId: string) => {
    quickAddAuctionToBucket(bucketId, auctionId)
      .then(() => {
        setPublishing(false);
        setActiveStep(StepType.Completed);
      })
      .catch(() => {
        setPublishing(false);
        enqueueSnackbar("Could not add auction to bucket, please try again.", variantError);
      });
  };

  const publish = () => {
    publishLot(lotId)
      .then(() => {
        if (auctionId) {
          updateAuction(auctionId);
        } else {
          createAuction(lotId);
        }
      })
      .catch(() => {
        setPublishing(false);
        enqueueSnackbar("Could not publish lot, please try again.", variantError);
      });
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const getStepContent = (step: StepType) => {
    switch (step) {
      case StepType.Details:
        return (
          <LotDetailsForm
            validationState={lotDetailsValidation}
            configuration={configuration}
            insuranceCompanies={insuranceCompanies!}
            lotDetailsState={lotDetails}
            handleStateChange={updatedLotDetails => setLotDetails(updatedLotDetails)}
          />
        );
      case StepType.Media:
        return (
          <MediaDetails lotId={lotId!} isValid={mediaValid => setMediaAllValid(mediaValid)} uploadMedia={uploadMedia} mediaUploaded={mediaUploaded} />
        );
      case StepType.Options:
        return (
          <LotPropertiesDetails
            validationState={lotPropertiesValidation}
            lotPropertiesState={lotProperties}
            bucketState={allBuckets}
            lotTitle={lotDetails?.title}
            auctionListing={auctionListing}
            handleAuctionStateChange={listing => setAuctionListing(listing)}
            handleStateChange={updatedLotProperties => setLotProperties(updatedLotProperties)}
          />
        );
      case StepType.Completed:
        return <Completed />;
      default:
        enqueueSnackbar("Could not determine your step", variantError);
    }
  };

  const handleSave = () => {
    history.push("/AuctionManagement/Auctions");
  };

  const handleCancel = () => {
    deleteLot(lotId).then(() => {
      history.push("/AuctionManagement/Auctions");
    });
  };

  return (
    <>
      <CssBaseline />
      {loading ? (
        <LoadingComponent label="Loading" />
      ) : (
        <>
          <LotFormStepper steps={steps} activeStep={activeStep} />
          {getStepContent(activeStep)}
          <LotFormButtons
            activeStep={activeStep}
            mediaAllValid={mediaAllValid}
            hideCancel={auctionId != null}
            handleCancel={handleCancel}
            handleSave={handleSave}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        </>
      )}
    </>
  );
};
