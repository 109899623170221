import * as React from "react";
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, FormControl, CardMedia, Grid } from "@material-ui/core";

import { IMedia } from "../../../interfaces/lots/IMedia";
import * as MediaManagerStyles from "../styles/MediaManagerStyles";

interface IProps {
  lotId: string;
  open: boolean;
  media: IMedia;
  handleClose: () => void;
  showAlertMessage(success: boolean, message: string): void;
}

export const ConfigureDisplayImageModal: React.FC<IProps> = props => {
  const classes = MediaManagerStyles.configureDisplayImageModal();
  const [formDirty, setFormDirty] = React.useState(false);
  const [mediaProperties, setMediaProperties] = React.useState({
    title: props.media.title,
    description: props.media.description,
  });

  const formUpdated = (property: string, value: string) => {
    setFormDirty(true);
    switch (property) {
      case "title": {
        setMediaProperties({ ...mediaProperties, title: value });
        break;
      }
      case "description": {
        setMediaProperties({ ...mediaProperties, description: value });
        break;
      }
    }
  };

  const updateMedia = async (): Promise<void> => {
    if (formDirty) {
      props.media.title = mediaProperties.title;
      props.media.description = mediaProperties.description;
      // const setMetadata = await VehicleLotService.SetMediaMetadata(
      //   props.lotId,
      //   props.media
      // );
      // setUpdatingMedia(false);
      props.handleClose();
      // if (setMetadata.parsedBody) {
      //   props.media.metadataSet = true;
      //   props.showAlertMessage(true, "Media updated successfully");
      // } else {
      //   props.showAlertMessage(false, "Error updating media");
      // }
      // TODO: upload this on save
    }
  };

  const handleCancel = () => {
    props.handleClose();
  };

  const dialogContents = (
    <>
      <DialogTitle className={classes.titleStyle} id="form-dialog-title">
        Set media properties
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column">
          <Grid item>
            <CardMedia className={classes.media} image={props.media.url} />
          </Grid>
          <Grid item>
            <FormControl fullWidth={true}>
              <TextField
                margin="dense"
                id="title"
                label="Title"
                value={mediaProperties.title}
                onChange={value => formUpdated("title", value.target.value)}
                fullWidth={true}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl fullWidth={true}>
              <TextField
                margin="dense"
                id="description"
                label="Description"
                value={mediaProperties.description}
                onChange={value => formUpdated("description", value.target.value)}
                fullWidth={true}
              />
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button disabled={!formDirty} onClick={updateMedia} color="primary">
          Update
        </Button>
      </DialogActions>
    </>
  );

  return (
    <Dialog open={props.open} onClose={handleCancel} fullWidth={true} aria-labelledby="form-dialog-title">
      {dialogContents}
    </Dialog>
  );
};
