import * as React from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { Button, Grid, TextField, Select, MenuItem, InputLabel, FormControl, Typography } from "@material-ui/core";
import { LoadingComponent } from "../loading/LoadingComponent";
import * as AuctionService from "../../services/AuctionService";
import * as BucketService from "../../services/BucketService";
import { EditBucketUnbucketed } from "./editing/EditBucketUnbucketed";
import { EditBucketAdded } from "./editing/EditBucketAdded";
import { BucketAuctionDragItemItemStyles } from "./styles/BucketAuctionDragItemStyles";
import { IBucketItem } from "../../interfaces/buckets/IBucketItem";
import { IAuctionInfo } from "../../services/AuctionService";
import { EditBucketCardSkeleton } from "./editing/EditBucketCardSkeleton";
import { AuctionSeparations } from "./AuctionSeparations";
import { searchAuctionsAwaitingBucketing, SearchComponent } from "./filtering/SearchAuction";
import { sort, SortAuction } from "./filtering/SortAuction";
import { AuctionStatus } from "../../interfaces/auctions/IAuction";
import { useSnackbar } from "notistack";
import { is24HoursInFuture, isValidDateForBucket } from "../../interfaces/buckets/IBucket";

const bucketTitleLength = 50; //Note this is 10 more characters than the edit screen to account for date characters added to title
interface EditBucketContainerProps {
  bucketId: string;
  isActiveBucket: boolean;
}

export const EditBucketContainer: React.FC<EditBucketContainerProps> = ({ bucketId, isActiveBucket }) => {
  const history = useHistory();
  const classes = BucketAuctionDragItemItemStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [bucket, setBucket, isBucketLoading, , putBucket] = BucketService.useBucket(bucketId, isActiveBucket, () => {
    enqueueSnackbar("There was a problem with your bucket request, please try again.", { variant: "error" });
  });
  const [sourceAuctionsUnbucketed, isAuctionsAwaitingBucketingLoading] = AuctionService.useAuctionsAwaitingBucketing(() => {});
  const [sourceAuctionsBucketed, isAuctionsInBucketLoading] = BucketService.useAuctionsInBucket(bucketId, () => {});
  const [hasChanges, setHasChanges] = React.useState(false);

  const [insuranceCompanyFilter, setInsuranceCompanyFilter] = React.useState<string>("");
  const [lotTypeFilter, setLotTypeFilter] = React.useState<string>("");
  const [sitePostcodeFilter, setSitePostcodeFilter] = React.useState<string>("");
  const [makeFilter, setMakeFilter] = React.useState<string>("");
  const [searchText, setSearchText] = React.useState<string>("");

  const [sortBy, setSortBy] = React.useState<string>("");

  const [isValid, setIsValid] = React.useState(true);

  // We want a joint list of auctions awaiting bucketing, plus auctions already in bucket.
  const [allAuctions, setAllAuctions] = React.useState<IAuctionInfo[]>([]);

  const isActiveAnd24HoursInFuture = !isBucketLoading && bucket && isActiveBucket && !is24HoursInFuture(moment(bucket!.scheduledDate)) && !hasChanges;

  React.useEffect(() => {
    if (sourceAuctionsUnbucketed !== null && sourceAuctionsBucketed !== null) {
      setAllAuctions(sourceAuctionsUnbucketed.concat(sourceAuctionsBucketed));
    }
  }, [sourceAuctionsUnbucketed, sourceAuctionsBucketed]);

  // Synthesise lists of auctions for the main drag drop component to use.
  const allAuctionsInBucket = bucket !== null ? bucket?.bucketItems.map(f => allAuctions.filter(g => g.id === f.auctionId)[0]) : [];
  const allAuctionsNotInBucket = allAuctions.filter(f => allAuctionsInBucket.every(g => g.id !== f.id));

  // Reset the Has Changes if the bucket is reloaded.
  React.useEffect(() => setHasChanges(false), [isBucketLoading]);

  // Operations for manipulating the lists based on callbacks from the drag drop component.
  const onRemove = (auctionId: string) => {
    var auctionState = allAuctions.find(f => f.id === auctionId)?.status;

    if (auctionState !== AuctionStatus.CreatedAwaitingPublishing && auctionState !== AuctionStatus.CreatedAwaitingBucketing) {
      enqueueSnackbar("Auctions can't be removed from an published bucket", { variant: "error" });
      return;
    }

    setBucket({
      ...bucket!,
      bucketItems: bucket!.bucketItems.filter(f => f.auctionId !== auctionId),
    });
    setHasChanges(true);
  };

  const onEnsure = (auctionId: string, index: number) => {
    setSortBy("");
    const itemsBefore = bucket!.bucketItems.filter(f => f.auctionId !== auctionId).slice(0, index);
    const itemsAfter = bucket!.bucketItems.filter(f => f.auctionId !== auctionId).slice(index);
    const items = itemsBefore.concat([{ auctionId: auctionId, dateAdded: moment().toISOString() }]).concat(itemsAfter);

    setBucket({
      ...bucket!,
      bucketItems: items,
    });
    setHasChanges(true);
  };

  const onAddAll = () => {
    if (!bucket) return;

    const newItems: IBucketItem[] = auctionsNotInBucket?.map(f => ({ auctionId: f.id, dateAdded: moment().toISOString() }));
    const items = bucket.bucketItems.concat(newItems);
    setBucket({
      ...bucket,
      bucketItems: items,
    });
    setHasChanges(true);
  };

  const onRemoveAll = () => {
    if (!bucket) return;
    if (!isActiveBucket) {
      setBucket({
        ...bucket,
        bucketItems: [],
      });
      setHasChanges(true);
    }
  };

  const onSave = async () => {
    const valid = isValidDateForBucket(moment(bucket?.scheduledDate)) ?? false;
    setIsValid(valid);
    if (valid) {
      await putBucket();
      history.push("/Buckets/");
    }
  };

  const onTitleChange = (title: string) => {
    setBucket({
      ...bucket!,
      title: title,
    });
    setHasChanges(true);
  };

  const onScheduleDateChange = (date: string) => {
    const dateMoment = moment(date);
    setIsValid(isValidDateForBucket(dateMoment));
    setBucket({
      ...bucket!,
      scheduledDate: dateMoment.format(),
    });
    setHasChanges(true);
  };

  //This filters the auctions awaiting bucketing
  const auctionsNotInBucket: IAuctionInfo[] = searchAuctionsAwaitingBucketing(
    allAuctionsNotInBucket,
    searchText,
    insuranceCompanyFilter,
    makeFilter,
    sitePostcodeFilter,
    lotTypeFilter
  );

  //This sorts the auctions in the bucket
  const auctionsInBucket = sort(allAuctionsInBucket, sortBy);

  React.useEffect(() => {
    if (bucket && auctionsInBucket) {
      const orderedAuctionIds = auctionsInBucket.map(f => f.id);
      const orderedBucketItems = bucket.bucketItems.sort((a, b) => orderedAuctionIds.indexOf(a.auctionId) - orderedAuctionIds.indexOf(b.auctionId));
      setBucket({
        ...bucket,
        bucketItems: orderedBucketItems,
      });
      setHasChanges(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy]);

  const ButtonsComponent = () => (
    <Grid item xs={12} className={classes.buttonContainer}>
      <Button
        disabled={isBucketLoading || isAuctionsAwaitingBucketingLoading || auctionsNotInBucket.length === 0}
        onClick={onAddAll}
        variant="outlined"
        color="primary"
        className={classes.controlButton}
      >
        Add all
      </Button>
      {!isActiveBucket && (
        <Button
          disabled={isBucketLoading || isAuctionsAwaitingBucketingLoading || auctionsInBucket?.length === 0}
          onClick={onRemoveAll}
          variant="outlined"
          color="primary"
          className={classes.controlButton}
        >
          Remove all
        </Button>
      )}
      <Button
        onClick={onSave}
        disabled={isBucketLoading || isAuctionsAwaitingBucketingLoading || !isValid || !hasChanges}
        variant="contained"
        color="primary"
        className={classes.controlButton}
      >
        Save
      </Button>
    </Grid>
  );

  return (
    <Grid container spacing={4}>
      {isBucketLoading && <LoadingComponent label="Loading bucket" marginTop="8px" marginBottom="4px" />}
      {!isActiveAnd24HoursInFuture && (
        <>
          {!isBucketLoading && bucket !== null && (
            <>
              <Grid item xs={12} sm={5}>
                <TextField
                  fullWidth
                  id="bucket-title"
                  label={`Title (${bucketTitleLength - bucket.title.length} characters left)`}
                  type="text"
                  value={bucket.title}
                  margin="normal"
                  variant="outlined"
                  onChange={e => {
                    onTitleChange(e.target.value);
                  }}
                  inputProps={{ maxLength: bucketTitleLength }}
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <TextField
                  fullWidth
                  id="auctionEnd"
                  label="Bucket Date"
                  type="datetime-local"
                  margin="normal"
                  variant="outlined"
                  value={moment(bucket.scheduledDate).format("YYYY-MM-DD[T]HH:mm")}
                  onChange={e => {
                    onScheduleDateChange(e.target.value);
                  }}
                  error={!isValid}
                  helperText={!isValid ? "Bucket Date must be after 8:30 and at least 24 hours in the future" : ""}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormControl variant="outlined" fullWidth margin="normal">
                  <InputLabel id="auctionSeparationLabel">Auction separation</InputLabel>
                  <Select
                    id="auctionSeparation"
                    label="Auction separation"
                    value={bucket.auctionSeparationInSeconds}
                    onChange={e => {
                      setBucket({ ...bucket!, auctionSeparationInSeconds: e.target.value as number });
                      setHasChanges(true);
                    }}
                  >
                    {AuctionSeparations.map(f => (
                      <MenuItem key={f.int} value={f.int}>
                        {f.desc}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </>
          )}

          <ButtonsComponent />

          <Grid item xs={6} className={classes.leftPane}>
            <Typography variant="h6" align="center" className={classes.paneHeading}>
              Auctions not in a bucket yet
            </Typography>
            <SearchComponent
              insuranceCompanyFilter={insuranceCompanyFilter}
              sitePostcodeFilter={sitePostcodeFilter}
              makeFilter={makeFilter}
              searchText={searchText}
              lotTypeFilter={lotTypeFilter}
              setInsuranceCompanyFilter={setInsuranceCompanyFilter}
              setSitePostcodeFilter={setSitePostcodeFilter}
              setMakeFilter={setMakeFilter}
              setSearchText={setSearchText}
              setLotTypeFilter={setLotTypeFilter}
            />
            <div className={classes.dropContainer}>
              {isAuctionsAwaitingBucketingLoading && <EditBucketCardSkeleton items={3} />}
              {!isAuctionsAwaitingBucketingLoading && !isBucketLoading && !isAuctionsInBucketLoading && auctionsNotInBucket && (
                <EditBucketUnbucketed auctionsNotInBucket={auctionsNotInBucket} onRemove={onRemove} />
              )}
            </div>
          </Grid>
          <Grid item xs={6} className={classes.rightPane}>
            <Typography variant="h6" align="center" className={classes.paneHeading}>
              Auctions in this bucket
            </Typography>
            <SortAuction sortBy={sortBy} setSortBy={setSortBy} />
            <div className={classes.dropContainer}>
              {isAuctionsInBucketLoading && <EditBucketCardSkeleton items={2} />}
              {!isAuctionsInBucketLoading && !isBucketLoading && !isAuctionsAwaitingBucketingLoading && auctionsInBucket && bucket && (
                <EditBucketAdded
                  auctionsInBucket={auctionsInBucket}
                  onEnsure={onEnsure}
                  bucketDate={bucket.scheduledDate}
                  auctionSeparationInSeconds={bucket.auctionSeparationInSeconds}
                />
              )}
            </div>
          </Grid>

          <ButtonsComponent />
        </>
      )}
      {isActiveAnd24HoursInFuture && !isBucketLoading && bucket !== null && (
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom className={classes.dateWarningText}>
            This buckets schedule date is in less than 24 hours on the {moment(bucket.scheduledDate).format("Do MMM YYYY")} at{" "}
            {moment(bucket.scheduledDate).format("HH:mm")}, you can't edit a bucket which is ending in less than 24 hours
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};
