import "typeface-inter/inter.css";

import { createMuiTheme } from "@material-ui/core/styles";
import { lime, deepOrange, yellow } from "@material-ui/core/colors";

const white = "#FFFFFF";
const black = "#000000";
const success = "#43a047";
const error = "#d32f2f";

const orange = "#EC6E13";
const grey = "#D1D1D1";
const darkGrey = "#393939";
const lightGrey = "#F8F8F8";

const darkBlue = "#387AEB";

const lightGreen = lime[100];
const lightYellow = yellow[100];
const lightRed = deepOrange[100];

const salesDocumentHighlight = "#FFFACD";

const theme = createMuiTheme({
  typography: {
    fontFamily: "inter",
  },
  palette: {
    primary: {
      main: orange,
      contrastText: white,
    },
    secondary: {
      main: grey,
      light: lightGrey,
    },
    background: {
      default: white,
    },
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        boxShadow: "none",
        backgroundColor: "transparent",
      },
    },
  },
});

export { white, black, success, error, orange, grey, darkGrey, lightGrey, darkBlue, lightGreen, lightYellow, lightRed, salesDocumentHighlight };
export default theme;
