import * as React from "react";
import { Card, CardContent, Typography, CardActions, Chip } from "@material-ui/core";
import YouTubeIcon from "@material-ui/icons/YouTube";
import WarningIcon from "@material-ui/icons/Warning";
import { useSnackbar, OptionsObject } from "notistack";

import { IMedia, MediaTypes } from "../../../interfaces/lots/IMedia";
import * as MediaManagerStyles from "../styles/MediaManagerStyles";
import { DisplayedImageActions } from "./DisplayedImageActions";
import { ConfigureDisplayImageModal } from "./ConfigureDisplayImageModal";

const variantSuccess: OptionsObject = { variant: "success" };
const variantError: OptionsObject = { variant: "error" };

const minimumImageWidth = 800;
const minimumImageHeight = 600;

interface DisplayImageContainerProps {
  lotId: string;
  media: IMedia;
  mediaUpdated: (newMedia: IMedia) => void;
}

export const DisplayImageContainer: React.FC<DisplayImageContainerProps> = ({ lotId, media, mediaUpdated }) => {
  const classes = MediaManagerStyles.displayImageContainer();
  const { enqueueSnackbar } = useSnackbar();

  const [configureImageOpen, setConfigureImageOpen] = React.useState(false);
  const [mediaDetailsSet, setMediaDetailsSet] = React.useState(false);

  React.useEffect(() => {
    if (media.description.length > 0 && media.title.length > 0) {
      setMediaDetailsSet(true);
    } else {
      setMediaDetailsSet(false);
    }
  }, [media, mediaDetailsSet, media.title, media.description]);

  const showAlertMessage = (success: boolean, message: string) => {
    if (success) {
      enqueueSnackbar(message, variantSuccess);
    } else {
      enqueueSnackbar(message, variantError);
    }
  };

  const handleEdit = () => {
    setConfigureImageOpen(true);
  };

  const handleCloseEdit = () => {
    setConfigureImageOpen(false);
    mediaUpdated(media);
  };

  const handleDelete = () => {
    media.flagForDeletion = true;
    //  await VehicleLotService.DeleteMediaItem(media);
    mediaUpdated(media);
  };

  const onImageLoad = ({ target }: any, media: IMedia) => {
    if (target.naturalWidth < minimumImageWidth || target.naturalHeight < minimumImageHeight) {
      media.invalidImage = true;
      media.errorMessage = `This image is too small. It must be at least ${minimumImageWidth}px by ${minimumImageHeight}px. This image is ${target.naturalWidth}px by ${target.naturalHeight}px`;
      mediaUpdated(media);
    }
  };
  const onImageError = (event: any, media: IMedia) => {
    media.invalidImage = true;
    media.errorMessage = `This is not a valid image.`;
    mediaUpdated(media);
  };
  const validImage = () => {
    return (
      <>
        <CardContent>
          {!mediaDetailsSet && (
            <div className={classes.mediaDetailsNotSet}>
              <Typography variant="h6" component="h1">
                <WarningIcon className={classes.warningIcon} /> Media details not set
              </Typography>
            </div>
          )}
          <Typography gutterBottom variant="h6" component="h1">
            {media.title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {media.description}
          </Typography>
        </CardContent>
        <CardActions>
          <DisplayedImageActions onEdit={handleEdit} onDelete={handleDelete} mediaDetailsSet={mediaDetailsSet}></DisplayedImageActions>
        </CardActions>
      </>
    );
  };
  const invalidImage = () => {
    return (
      <CardContent>
        <Typography gutterBottom>{media.errorMessage}</Typography>
        <Typography gutterBottom>This image will not be uploaded.</Typography>
      </CardContent>
    );
  };
  return (
    <>
      <ConfigureDisplayImageModal
        showAlertMessage={showAlertMessage}
        open={configureImageOpen}
        handleClose={handleCloseEdit}
        lotId={lotId}
        media={media}
      ></ConfigureDisplayImageModal>
      <Card className={classes.card}>
        <div className={classes.mediaContainer}>
          {media.mediaType === MediaTypes.YouTube && (
            <Chip icon={<YouTubeIcon />} label="YouTube Video" className={classes.primaryChip} size="small" color="secondary"></Chip>
          )}
          {media.invalidImage && (
            <Chip
              icon={<WarningIcon className={classes.invalidIcon} />}
              label={"Invalid Image"}
              className={classes.primaryChip}
              size="small"
              color="secondary"
            ></Chip>
          )}
          <img
            className={classes.media}
            src={media.url}
            alt={media.title}
            onLoad={event => onImageLoad(event, media)}
            onError={event => onImageError(event, media)}
          ></img>
        </div>
        {!media.invalidImage && validImage()}
        {media.invalidImage && invalidImage()}
      </Card>
    </>
  );
};
