import * as React from "react";
import { IBidder, BidderDefault } from "../../interfaces/bidders/IBidder";
import { BidderDetailsChangeHandler } from "./BidderDetailsChangeHandler";
import * as BidderService from "../../services/BidderService";
import { LoadingComponent } from "../loading/LoadingComponent";

export const BidderDetailsContainer: React.FC<{ bidderId: string }> = ({ bidderId }) => {
  const [bidder, setBidder] = React.useState<IBidder>(BidderDefault);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    BidderService.GetBidder(bidderId).then(result => {
      if (result.parsedBody !== undefined) {
        setBidder(result.parsedBody);
        setLoading(false);
      }
    });
  }, [loading, bidderId]);

  return loading ? <LoadingComponent label="Loading bidder details" /> : <BidderDetailsChangeHandler bidder={bidder} setBidder={setBidder} />;
};
