import { IVerificationSubmission } from "./IVerificationSubmission";

export interface IVerification {
  status: VerificationStatus;
  lastUpdated: string;
  verificationDocumentSubmission: Array<IVerificationSubmission>;
}

export enum VerificationStatus {
  Unknown = 0,
  Verified = 1,
  AwaitingVerification = 2,
  DocumentsSubmitted = 3,
  FailedVerification = 4,
}
