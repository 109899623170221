import { useRef, useEffect } from "react";

const useInterval = (callback: () => void, delay: number | null) => {
  const savedCallback: React.MutableRefObject<(() => void) | undefined> = useRef();
  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current!();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};
export default useInterval;
