import * as React from "react";
import { IconButton, Tooltip, TableCell, DialogContentText } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { useHistory } from "react-router-dom";
import { Lot, LotStatus } from "../../../services/LotService";
import * as LotService from "../../../services/LotService";
import { useSnackbar } from "notistack";
import { HttpResponse } from "../../../services/ApiService";
import { ILotMgmtFilter } from "../../../services/LotManagementService";
import { DialogComponent } from "../DialogComponent";

interface ActionIconsProps {
  lot: Lot;
  refreshLot: (toPost?: ILotMgmtFilter | undefined) => void;
}
export const ActionIcons: React.FC<ActionIconsProps> = ({ lot, refreshLot }) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [openDeletionDialog, setOpenDeletionDialog] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const createHandler = (action: (auctionId: string) => Promise<HttpResponse<unknown>>) => async (auctionId: string) => {
    setLoading(true);
    try {
      await action(auctionId);
    } catch {
      enqueueSnackbar("There was a problem performing your action. Please try again.", { variant: "error" });
    } finally {
      refreshLot();
      setLoading(false);
    }
  };

  const handleDeletion = createHandler(lotId => LotService.deleteLot(lotId));

  const DeleteDialog = () => (
    <DialogComponent
      open={openDeletionDialog}
      onClose={setOpenDeletionDialog}
      title="Confirm remove lot"
      changeHandler={handleDeletion}
      contentElement={<DialogContentText>Are you sure you want to remove this auction?</DialogContentText>}
      id={lot.id}
      buttonDisabled={loading}
    />
  );

  return (
    <>
      {DeleteDialog()}
      <TableCell>
        {!(lot.status === LotStatus.Deleted || lot.status === LotStatus.InUseByAuction || lot.status === LotStatus.Sold) ? (
          <Tooltip title="Edit auction">
            <IconButton aria-label="Edit" onClick={() => history.push(`/UpdateLot/${lot.id}`)}>
              <EditIcon color="primary" />
            </IconButton>
          </Tooltip>
        ) : (
          <IconButton aria-label="Edit" disabled>
            <EditIcon />
          </IconButton>
        )}
        <Tooltip title="View audit log">
          <IconButton aria-label="View Audit log">
            <AssignmentIcon color="primary" />
          </IconButton>
        </Tooltip>
        {lot.status === LotStatus.AwaitingAuctionCreation || lot.status === LotStatus.Draft ? (
          <Tooltip title="Delete auction">
            <IconButton aria-label="Delete auction" onClick={() => setOpenDeletionDialog(true)}>
              <DeleteIcon color="primary" />
            </IconButton>
          </Tooltip>
        ) : (
          <IconButton aria-label="Delete auction" disabled>
            <DeleteIcon />
          </IconButton>
        )}
      </TableCell>
    </>
  );
};
