import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import moment from "moment";
import * as React from "react";
import { BucketDefault, isValidDateForBucket } from "../../../interfaces/buckets/IBucket";
import * as BucketService from "../../../services/BucketService";
import { LoadingComponent } from "../../loading/LoadingComponent";
import { AuctionSeparations } from "../AuctionSeparations";
import { CreateBucketStyles } from "../styles/CreateBucket";

const emptyBucket = BucketDefault;
const bucketTitleLength = 39; //Note this is 10 less characters than the edit screen to account for date characters added to title

interface ICreateBucketProps {
  onNewBucketAdded: () => void;
}

export function CreateBucket(props: ICreateBucketProps) {
  const classes = CreateBucketStyles();
  const [open, setOpen] = React.useState(false);
  const [creatingBucket, setCreatingBucket] = React.useState(false);
  const [bucket, setBucket] = React.useState(emptyBucket);
  const [isValid, setIsValid] = React.useState(true);

  const handleSetScheduleDate = (event: any) => {
    const dateMoment = moment(event.target.value);
    setBucket({
      ...bucket,
      scheduledDate: dateMoment.format(),
    });
    setIsValid(isValidDateForBucket(dateMoment));
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBucket({
      ...bucket,
      title: event.target.value,
    });
  };

  const handleSeparationChange = (newValue: number) => {
    setBucket({
      ...bucket,
      auctionSeparationInSeconds: newValue,
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setBucket(emptyBucket);
  };

  const handleSave = () => {
    const valid = isValidDateForBucket(moment(bucket.scheduledDate));
    setIsValid(valid);

    if (valid) {
      setCreatingBucket(true);
      const draftBucket = { ...bucket, title: `${bucket.title} ${moment(bucket.scheduledDate).format("DD/MM/YYYY")}` };
      BucketService.createDraftBucket(draftBucket)
        .then(result => {
          if (result.parsedBody !== undefined) {
            setCreatingBucket(false);
            setOpen(false);
            props.onNewBucketAdded();
            setBucket(emptyBucket);
          }
        })
        .catch(async response => {
          setCreatingBucket(false);
          setOpen(false);
        });
    }
  };

  const addBucketButton = () => {
    return (
      <Fab color="primary" aria-label="add" size="medium" variant="extended" onClick={handleClickOpen}>
        <AddIcon />
        <Typography variant="button" display="inline">
          Create Bucket
        </Typography>
      </Fab>
    );
  };

  return (
    <>
      {addBucketButton()}

      <Dialog open={open} onClose={handleClose}>
        {creatingBucket ? (
          <div className={classes.loading}>
            <LoadingComponent label="Creating bucket" />
          </div>
        ) : (
          <>
            <DialogTitle id="add-bucket-title">Create New Bucket</DialogTitle>
            <DialogContent>
              <DialogContentText>Create a new bucket by completing the form below.</DialogContentText>
              <Grid container spacing={1} direction="column">
                <Grid item>
                  <TextField
                    fullWidth
                    id="auctionEnd"
                    label="Bucket Date"
                    type="datetime-local"
                    defaultValue={moment(bucket.scheduledDate).format("YYYY-MM-DD[T]HH:mm:ss")}
                    onChange={handleSetScheduleDate}
                    error={!isValid}
                    helperText={!isValid ? "Must be after 8.30am and at least 24 hours in the future" : ""}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    fullWidth={true}
                    margin="normal"
                    id="title"
                    label={`Title (${bucketTitleLength - bucket.title.length} characters left)`}
                    value={bucket.title}
                    onChange={handleTitleChange}
                    inputProps={{ maxLength: bucketTitleLength }}
                  />
                </Grid>
                <Grid item>
                  <FormControl variant="standard" fullWidth margin="normal">
                    <InputLabel id="auctionSeparationLabel">Auction separation</InputLabel>
                    <Select
                      id="auctionSeparation"
                      label="Auction separation"
                      value={bucket.auctionSeparationInSeconds}
                      onChange={e => {
                        handleSeparationChange(e.target.value as number);
                      }}
                    >
                      {AuctionSeparations.map(f => (
                        <MenuItem key={f.int} value={f.int}>
                          {f.desc}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleSave} color="primary" disabled={!isValid}>
                Create Bucket
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
}
