import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, FormControl, InputLabel, Grid, TextField, Select } from "@material-ui/core";
import { CreateAdmin, CreateAdminUser, EmptyCreateAdmin } from "../../services/PermissionService";
import { IValidation } from "../../interfaces/IValidation";
import { MemberPermission } from "../../services/MemberService";
import { AppContext } from "../../contexts/AppContext";
import { useSnackbar } from "notistack";

interface AddAdminDialogDialog {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const AddAdminDialog: React.FC<AddAdminDialogDialog> = ({ open, setOpen }) => {
  const context = React.useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();

  const [user, setUser] = React.useState<CreateAdmin>(EmptyCreateAdmin);
  const [validation, setValidation] = React.useState<IValidation>({});

  const isError = (field: string): boolean => {
    return validation[field] !== undefined && validation[field].field !== "";
  };

  const errorMessage = (field: string): string => {
    return validation[field] !== undefined && validation[field].field !== "" ? validation[field].error : "";
  };

  const submit = async () => {
    if (user.firstName === "" || user.lastName === "" || user.email === "") {
      if (user.firstName === "") {
        validation["firstName"] = { field: "firstName", error: "First name is a required field" };
      } else {
        validation["firstName"] = { field: "", error: "" };
      }
      if (user.lastName === "") {
        validation["lastName"] = { field: "lastName", error: "Last name is a required field" };
      } else {
        validation["firstName"] = { field: "", error: "" };
      }
      if (user.email === "") {
        validation["email"] = { field: "email", error: "Email is a required field" };
      } else {
        validation["firstName"] = { field: "", error: "" };
      }
      setValidation({
        ...validation,
      });
      return;
    }
    const result = await CreateAdminUser(user);
    if (result.parsedBody?.errorMessage) {
      enqueueSnackbar(result.parsedBody?.errorMessage, { variant: "error" });
    } else {
      enqueueSnackbar("Admin user created", { variant: "success" });
      closeDialog();
    }
  };

  const hasSuperAdminPermission = () => {
    if (!context) return false;
    if (context.memberPermissions === MemberPermission.SuperAdmin) return true;
    return false;
  };

  const hasPermission = (memberPermission: MemberPermission) => {
    if (!context) return false;
    if (context.memberPermissions >= memberPermission) return true;
    return false;
  };

  const closeDialog = () => {
    setUser(EmptyCreateAdmin);
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={() => closeDialog()} fullWidth maxWidth="md">
      <DialogTitle>Create Admin User</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={7}>
            <FormControl>
              <InputLabel>Permission Level</InputLabel>
              <Select
                native
                value={user.permission}
                onChange={value => {
                  setUser({
                    ...user,
                    permission: Number(value.target.value),
                  });
                }}
              >
                <option value={MemberPermission.None}>None</option>
                <option value={MemberPermission.BucketAdmin}>Auction Admin</option>
                <option value={MemberPermission.AccountAdmin}>Bidder Admin</option>
                <option value={MemberPermission.LiveAuctionAdmin}>Auction Manager</option>
                {hasPermission(MemberPermission.FullMember) && <option value={MemberPermission.FullMember}>Full Access</option>}
                {hasSuperAdminPermission() && <option value={MemberPermission.SuperAdmin}>e2e Admin</option>}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={7}>
            <FormControl fullWidth error={isError("firstName")}>
              <TextField
                required
                type="text"
                label="First Name"
                id="firstName"
                name="firstName"
                value={user.firstName}
                onChange={value =>
                  setUser({
                    ...user,
                    firstName: value.target.value,
                  })
                }
                error={isError("firstName")}
                helperText={errorMessage("firstName")}
              />
            </FormControl>
          </Grid>
          <Grid item xs={7}>
            <FormControl fullWidth error={isError("lastName")}>
              <TextField
                required
                type="text"
                label="Last Name"
                id="lastName"
                name="lastName"
                aria-describedby="lastName-error"
                value={user.lastName}
                onChange={value =>
                  setUser({
                    ...user,
                    lastName: value.target.value,
                  })
                }
                error={isError("lastName")}
                helperText={errorMessage("firstName")}
              />
            </FormControl>
          </Grid>
          <Grid item xs={7}>
            <FormControl fullWidth error={isError("email")}>
              <TextField
                required
                type="email"
                label="Email"
                id="email"
                name="email"
                aria-describedby="email-error"
                value={user.email}
                onChange={value =>
                  setUser({
                    ...user,
                    email: value.target.value,
                  })
                }
                error={isError("email")}
                helperText={errorMessage("firstName")}
              />
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => closeDialog()}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            submit();
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
