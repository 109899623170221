import * as React from "react";
import { IBidder } from "../../interfaces/bidders/IBidder";
import { useSnackbar } from "notistack";
import { Typography, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Hidden, TablePagination } from "@material-ui/core";
import { LoadingComponent } from "../loading/LoadingComponent";
import { useAuctionsBidderBidOn } from "../../services/AuctionService";
import { BidHistoryRow } from "./BidHistoryRow";

interface BidderBidsTabProps {
  bidder: IBidder;
}

export const BidderBidsTab: React.FC<BidderBidsTabProps> = ({ bidder }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [pageNumber, setPageNumber] = React.useState<number>(0);
  const [pageSize, setPageSize] = React.useState<number>(10);

  const [bidderBidHistory, loadingBidderBidHistory] = useAuctionsBidderBidOn(bidder.id, pageNumber, pageSize, () => {
    enqueueSnackbar("There was a problem loading the bid history for this bidder. Please try again.", { variant: "error" });
  });

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPageSize(parseInt(event.target.value, 10));
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPageNumber(newPage);
  };

  const paginationComponent = (
    <TablePagination
      component="div"
      count={bidderBidHistory?.count ? bidderBidHistory?.count : 0}
      page={pageNumber}
      rowsPerPage={pageSize}
      rowsPerPageOptions={[
        { value: 10, label: "10" },
        { value: 20, label: "20" },
        { value: 30, label: "30" },
      ]}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
  );

  return (
    <>
      <Typography variant="h4" component="h1" gutterBottom>
        Bid History
      </Typography>
      {loadingBidderBidHistory && bidderBidHistory === null && <LoadingComponent label="Loading bid history" />}
      {!loadingBidderBidHistory && bidderBidHistory && (
        <>
          {paginationComponent}
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Title</TableCell>
                  <Hidden smDown>
                    <TableCell>Member</TableCell>
                    <TableCell>Auction End</TableCell>
                    <TableCell>Bid</TableCell>
                    <TableCell>IP Address</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Ref no</TableCell>
                    <TableCell>Registration</TableCell>
                    <TableCell></TableCell>
                  </Hidden>
                </TableRow>
              </TableHead>
              <TableBody>
                {bidderBidHistory?.results.map((bidHistory, index) => (
                  <TableRow key={index}>
                    <BidHistoryRow auctionListing={bidHistory} bidder={bidder} />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {paginationComponent}
        </>
      )}
    </>
  );
};
