import { makeStyles } from "@material-ui/styles";

import { Theme, createStyles } from "@material-ui/core";

export const ContainerStyles = makeStyles((theme: Theme) =>
  createStyles({
    width100: { width: "100%" },
    searchButton: { margin: "8px 8px 8px 8px", padding: "7px 20px 7px 20px" },
    createReportButton: { margin: "8px 0px 8px 0px", padding: "7px 20px 7px 20px" },
  })
);
