import React, { useState } from "react";
import { IconButton, Tooltip, DialogContentText, Dialog, DialogTitle, DialogContent, DialogActions, Button, TableCell, TextField, Select, FormControl, InputLabel, MenuItem } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import HistoryIcon from "@material-ui/icons/History";
import DateRangeIcon from "@material-ui/icons/DateRange";
import PaymentIcon from "@material-ui/icons/Payment";
import AssignmentIcon from "@material-ui/icons/Assignment";
import PauseIcon from "@material-ui/icons/Pause";
import SwapCallsIcon from "@material-ui/icons/SwapCalls";
import NoteIcon from "@material-ui/icons/Note";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import StopIcon from "@material-ui/icons/Stop";
import CodeIcon from "@material-ui/icons/Code";
import { AuctionStatus } from "../../../interfaces/auctions/IAuction";
import { useHistory } from "react-router-dom";
import { IAuctionInfo } from "../../../services/AuctionService";
import { useSnackbar } from "notistack";
import { IAuctionMgmtFilter } from "../../../services/AuctionLotMgmtService";
import * as AuctionService from "../../../services/AuctionService";
import { HttpResponse } from "../../../services/ApiService";
import { DialogComponent } from "../DialogComponent";
import { HistoryDialog } from "./AuctionHistoryDialog";
import { BucketDialog } from "./BucketDialog";
import { AuctionIconStyles } from "../styles/ActionIconStyles";
import { NotInterested } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { AuctionAuditDialog } from "./AuctionAuditDialog";
import { FileResult } from "../../../services/ReportsService";
import { downloadXML } from "./DowloadXml";
import { AppContext } from "../../../contexts/AppContext";
import { BucketStatus, publishBucket } from "../../../interfaces/buckets/IBucket";
import * as BucketService from "../../../services/BucketService";
import PublishIcon from "@material-ui/icons/Publish";
import CategoryIcon from "@material-ui/icons/Category";
import { AuctionTypeChangeDialog } from "./AuctionTypeChangeDialog";
import moment from "moment";

interface ActionIconsProps {
  auction: IAuctionInfo;
  refreshAuctions: (toPost?: IAuctionMgmtFilter | undefined) => void;
  showProxyBids: boolean;
}

enum DialogType {
  unknown,
  suspension,
  statusChange,
  bucket,
  archive,
  auctionHistory,
  relist,
  collectionCode,
  endImmediately,
  collectionFailed,
  deletion,
  auditLog,
  typeChange,
  lotCollectedDate,
  lotPaymentDate,
}

export const ActionIcons: React.FC<ActionIconsProps> = ({ auction, refreshAuctions, showProxyBids }) => {
  const classes = AuctionIconStyles();
  const context = React.useContext(AppContext);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [dialogType, setDialogType] = useState<DialogType>(DialogType.unknown);
  const [open, setOpen] = useState<boolean>(false);
  const [openArchiveDialog, setOpenArchiveDialog] = useState<boolean>(false);

  const [bucketId, setBucketId] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const [lotCollectedDate, setLotCollectedDate] = useState<string>(auction.lotCollectedDate != null ? auction.lotCollectedDate : moment().format("YYYY-MM-DD[T]HH:mm:ss"));
  const [lotPaymentDate, setLotPaymentDate] = useState<string>(auction.lotPaymentDate != null ? auction.lotPaymentDate : moment().format("YYYY-MM-DD[T]HH:mm:ss"));

  const [xmlOutput, setXmlOutput] = useState<FileResult>();

  const [bucket, , bucketLoading] =
    auction.bucketId && auction.status === AuctionStatus.CreatedAwaitingPublishing && auction.memberId === context?.memberId
      ? BucketService.useBucket(auction.bucketId, false, () => { })
      : [null, null, null];

  const [loadingReasons, setLoadingReasons] = React.useState<boolean>(false);
  const [reasons, setReasons] = React.useState<AuctionService.EnumOption[]>();
  const [relistRequest, setRelistRequest] = React.useState<AuctionService.AuctionRelistRequest>(AuctionService.UseDefaultAuctionRelistRequest);

  const handleRelistReasonChange = (event: any, property: string) => {
    if (relistRequest) {
      if (event.target.value === "All") {
        setRelistRequest({ ...relistRequest, [property]: null });
      } else {
        setRelistRequest({ ...relistRequest, [property]: event.target.value });
      }
    }
  };

  React.useEffect(() => {
    if (xmlOutput) {
      downloadXML(xmlOutput.byteArray, `AuctionSale-${auction.metadata.referenceNumber}.xml`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [xmlOutput]);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await AuctionService.getAuctionRelistReasons();
        if (response.parsedBody) {
          setReasons(response.parsedBody);

          if(auction.currentBidAmount == 0){
          setRelistRequest({ relistReason: 300});
          } else if(auction.currentBidAmount < auction.reservePrice){
            setRelistRequest({ relistReason: 200});
            } else {
              setRelistRequest({ relistReason: 100});
            }
        } else {
          console.error('Failed to fetch reasons.');
        }
      } catch (error) {
        console.error('Error fetching reasons:', error);
      } finally {
        setLoadingReasons(false);
      }
    };

    if (!loadingReasons && reasons === undefined) {
      setLoadingReasons(true);
      fetchData();
    }
  }, [loadingReasons, reasons]);

  const createHandler = (action: (auctionId: string) => Promise<HttpResponse<unknown>>) => async (auctionId: string) => {
    setLoading(true);
    try {
      await action(auctionId);
    } catch {
      enqueueSnackbar("There was a problem performing your action. Please try again.", { variant: "error" });
    } finally {
      refreshAuctions();
      setLoading(false);
    }
  };

  const handleXmlOutput = async () => {
    setLoading(true);
    try {
      const output = await AuctionService.auctionXmlOutput(auction.id);
      if (output.parsedBody) {
        setXmlOutput(output.parsedBody);
      } else {
        enqueueSnackbar("There was a problem performing your action. Please try again.", { variant: "error" });
      }
    } catch {
      enqueueSnackbar("There was a problem performing your action. Please try again.", { variant: "error" });
    } finally {
      refreshAuctions();
      setLoading(false);
    }
  };

  const handleUnsuspend = createHandler(auctionId => AuctionService.unsuspendAuction(auctionId));
  const handleSuspendedAuction = createHandler(auctionId => AuctionService.suspendAuction(auctionId));
  const handleAddToBucket = createHandler(auctionId => AuctionService.quickAddAuctionToBucket(bucketId, auctionId));
  const handleArchive = createHandler(auctionId => AuctionService.archiveAuction(auctionId));
  const handleLotCollected = createHandler(auctionId => AuctionService.auctionLotCollected(auctionId));
  const handleLotNotCollected = createHandler(auctionId => AuctionService.auctionLotNotCollected(auctionId));

  const handleEndManually = createHandler(auctionId => AuctionService.endAuctionManually(auctionId));
  const handleSuspendAndEndManually = async () => {
    setLoading(true);
    try {
      await AuctionService.suspendAuction(auction.id);
      await AuctionService.endAuctionManually(auction.id);
    } catch {
      enqueueSnackbar("There was a problem performing your action. Please try again.", { variant: "error" });
    } finally {
      refreshAuctions();
      setLoading(false);
    }
  };

  const handleRelistAndEditLater = createHandler(auctionId => AuctionService.relistAuction(auctionId, relistRequest));
  const handleRelist = async () => {
    try {
      const response = await AuctionService.relistAuction(auction.id, relistRequest);
      if (response.parsedBody) {
        await AuctionService.publishAuction(response.parsedBody?.id);
      }
    } catch {
      enqueueSnackbar("There was a problem performing your action. Please try again.", { variant: "error" });
    } finally {
      refreshAuctions();
      setOpen(false);
      setLoading(false);
    }
  };
  const handleEditAndRelist = async () => {
    try {
      const response = await AuctionService.relistAuction(auction.id, relistRequest);
      if (response.parsedBody) {
        history.push(`/UpdateLot/${auction.lotId}/${response.parsedBody?.id}`);
      }
    } catch {
      enqueueSnackbar("There was a problem performing your action. Please try again.", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  const handleEndedAndWon = async (action: (auctionId: string) => Promise<void>) => {
    try {
      if (auction.status === AuctionStatus.AuctionEndedAndWon) {
        await handleLotNotCollected(auction.id);
      }
      await action(auction.id);
    } catch {
      enqueueSnackbar("There was a problem performing your action. Please try again.", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  const SuspendDialog = () => (
    <DialogComponent
      open={open}
      onClose={setOpen}
      title="Confirm Auction Suspension"
      changeHandler={handleSuspendedAuction}
      contentElement={<DialogContentText>Are you sure you want to suspend this auction?</DialogContentText>}
      id={auction.id}
      buttonDisabled={loading}
    />
  );

  const ArchiveDialog = () => (
    <DialogComponent
      open={openArchiveDialog}
      onClose={setOpenArchiveDialog}
      title="Confirm Delete / Archive Lot"
      changeHandler={() => {
        setLoading(true);
        handleEndedAndWon(handleArchive);
      }}
      contentElement={<DialogContentText>Please confirm you would like to delete/archive this lot?</DialogContentText>}
      id={auction.id}
      buttonDisabled={loading}
    />
  );

  const StatusChangeDialog = () => (
    <>
      {ArchiveDialog()}
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
        <DialogTitle>Change Lot Status</DialogTitle>
        <DialogContent>
          {auction.status === AuctionStatus.AuctionEndedAndWon && (
            <DialogContentText className={classes.dialogContentText} color="error">
              This auction is a sold auction any of the following changes will mark the auction as Not Collected before completing the status change.
            </DialogContentText>
          )}
          <DialogContentText className={classes.dialogContentText}>
            Relisting the lot will change the status to Holding so that it can be re-auctioned
          </DialogContentText>
          <Button variant="contained" color="primary" disabled={loading} onClick={() => setDialogType(DialogType.relist)}>
            Relist
          </Button>
          <DialogContentText className={classes.dialogContentText}>
            Deleting / archiving the lot will remove the lot from use in the site
          </DialogContentText>
          <Button disabled={loading} variant="contained" color="primary" onClick={() => setOpenArchiveDialog(true)}>
            Delete / Archive
          </Button>
          <DialogContentText className={classes.dialogContentText}>Sell direct to a bidder</DialogContentText>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              history.push(`/SellDirectToBidder/${auction.id}`);
            }}
            disabled={loading}
          >
            Sell direct
          </Button>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={() => setOpen(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );

  const CollectionCodeDialog = () => (
    <DialogComponent
      open={open}
      onClose={setOpen}
      title={"View collection code"}
      changeHandler={handleLotCollected}
      buttonText="Mark as collected"
      contentElement={
        <DialogContentText className={classes.dialogContentText}>
          Collection code: <b>{auction.collectionCode}</b>
        </DialogContentText>
      }
      id={auction.id}
      buttonDisabled={loading}
    />
  );

  const EndImmediatelyDialog = () => (
    <DialogComponent
      open={open}
      onClose={setOpen}
      title={"Confirm end auction"}
      changeHandler={auction.status === AuctionStatus.Active ? handleSuspendAndEndManually : handleEndManually}
      buttonText="End immediately"
      contentElement={<DialogContentText> Confirm end auction immediately?</DialogContentText>}
      id={auction.id}
      buttonDisabled={loading}
    />
  );

  const LotNotCollectedDialog = () => (
    <DialogComponent
      open={open}
      onClose={setOpen}
      title={"Confirm buyer hasn't collected"}
      changeHandler={handleLotNotCollected}
      buttonText="Not collected"
      contentElement={
        <DialogContentText>
          This will change the auction status back to Closed - Not Sold, so you can choose whether to delete/archive it, re-list, or sell direct.
          Please confirm that the lot wasn't collected?
        </DialogContentText>
      }
      id={auction.id}
      buttonDisabled={loading}
    />
  );

  const RelistDialog = () => (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
      <DialogTitle>Relist Lot</DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.dialogContentText}>Edit relisted lot now?</DialogContentText>
        <FormControl fullWidth>
          <InputLabel id="relist-reason-label">Relist Reason</InputLabel>
          <Select labelId="relist-reason-label" id="relist-reason" label="Relist reason.." value={relistRequest?.relistReason}
            onChange={value => {
              handleRelistReasonChange(value, 'relistReason');
            }}>
            {reasons?.map(reason => (
              <MenuItem key={reason.value} value={reason.value}>
                {reason.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disabled={loading || !relistRequest?.relistReason}
          onClick={() => {
            setLoading(true);
            handleEndedAndWon(handleRelist);
          }}
        >
          Relist
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={loading || !relistRequest?.relistReason}
          onClick={() => {
            setLoading(true);
            handleEndedAndWon(handleEditAndRelist);
          }}
        >
          Edit &amp; Relist
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={loading || !relistRequest?.relistReason}
          onClick={() => {
            setLoading(true);
            handleEndedAndWon(handleRelistAndEditLater);
          }}
        >
          relist and edit later
        </Button>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );

  const InfoDialog = () => {
    switch (dialogType) {
      case DialogType.suspension:
        return SuspendDialog();
      case DialogType.statusChange:
        return StatusChangeDialog();
      case DialogType.bucket:
        return (
          <BucketDialog
            open={open}
            setOpen={setOpen}
            auction={auction}
            handleAddToBucket={handleAddToBucket}
            setBucketId={setBucketId}
            loading={loading}
          />
        );
      case DialogType.auctionHistory:
        return <HistoryDialog open={open} setOpen={setOpen} auctionId={auction.id} showProxyBids={showProxyBids} />;
      case DialogType.relist:
        return RelistDialog();
      case DialogType.collectionCode:
        return CollectionCodeDialog();
      case DialogType.endImmediately:
        return EndImmediatelyDialog();
      case DialogType.collectionFailed:
        return LotNotCollectedDialog();
      case DialogType.lotCollectedDate:
        return LotCollectedDateDialog();
      case DialogType.lotPaymentDate:
        return LotPaymentDateDialog();
      case DialogType.deletion:
        return DeleteDialog();
      case DialogType.auditLog:
        return <AuctionAuditDialog open={open} setOpen={setOpen} auctionId={auction.id} />;
      case DialogType.typeChange:
        return <AuctionTypeChangeDialog open={open} setOpen={setOpen} auction={auction} refreshAuctions={refreshAuctions} />;
      default:
        return null;
    }
  };

  const handleLotCollectedDate = async (auctionId: string) => {
    try {
      await AuctionService.lotCollectedDate(auctionId, lotCollectedDate);
      setOpen(false);
      refreshAuctions();
    } catch {
      enqueueSnackbar("There was a problem performing your action. Please try again.", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  const LotCollectedDateDialog = () => (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Set Lot Collected Date</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          id="lotCollectedDate"
          label="Lot Collected Date"
          type="datetime-local"
          margin="normal"
          variant="outlined"
          value={moment(lotCollectedDate).format("YYYY-MM-DD[T]HH:mm")}
          onChange={e => {
            const dateMoment = moment(e.target.value);
            setLotCollectedDate(dateMoment.format());
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disabled={loading}
          onClick={() => {
            setLoading(true);
            handleLotCollectedDate(auction.id);
          }}
        >
          Save
        </Button>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );

  const handleLotPaymentDate = async (auctionId: string) => {
    try {
      await AuctionService.lotPaymentDate(auctionId, lotPaymentDate);
      setOpen(false);
      refreshAuctions();
    } catch {
      enqueueSnackbar("There was a problem performing your action. Please try again.", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  const LotPaymentDateDialog = () => (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Set Lot Payment Date</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          id="lotPaymentDate"
          label="Lot Payment Date"
          type="datetime-local"
          margin="normal"
          variant="outlined"
          value={moment(lotPaymentDate).format("YYYY-MM-DD[T]HH:mm")}
          onChange={e => {
            const dateMoment = moment(e.target.value);
            setLotPaymentDate(dateMoment.format());
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disabled={loading}
          onClick={() => {
            setLoading(true);
            handleLotPaymentDate(auction.id);
          }}
        >
          Save
        </Button>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );

  const handleDeletion = createHandler(auctionId => AuctionService.DeleteAuction(auctionId));

  const DeleteDialog = () => (
    <DialogComponent
      open={open}
      onClose={setOpen}
      title="Confirm deletion of auction"
      changeHandler={handleDeletion}
      contentElement={<DialogContentText>Are you sure you want to remove this auction?</DialogContentText>}
      id={auction.id}
      buttonDisabled={loading}
    />
  );

  const onPublish = async () => {
    if (bucket) {
      await publishBucket(
        bucket,
        message => enqueueSnackbar(message, { variant: "error" }),
        async () => {
          setLoading(true);
          try {
            await BucketService.createActiveBucket(bucket.id).then(result => {
              if (result.parsedBody !== undefined && result.parsedBody.title == "error: invalid start price") {
                enqueueSnackbar("One or more of the Auctions in this bucket, do not have a valid start price.", { variant: "error" });
              } else {
                enqueueSnackbar("Bucket published", { variant: "success" });
              }
            });
          } catch {
            enqueueSnackbar("There was a problem with publishing the bucket, please try again.", { variant: "error" });
          } finally {
            refreshAuctions();
            setLoading(false);
          }
        }
      );
    }
  };

  const canPublish = (auction: IAuctionInfo) => {
    if (!bucketLoading && !bucket) {
      return false;
    }

    if (bucket) {
      return (
        auction.status === AuctionStatus.CreatedAwaitingPublishing && bucket.status === BucketStatus.Unpublished && bucket.bucketItems.length > 0
      );
    }
  };

  const canEdit = () => {
    return !(
      auction.status === AuctionStatus.DeletedBeforeBucketing ||
      auction.status === AuctionStatus.CompletedAsLotCollected ||
      auction.status === AuctionStatus.CompletedAsArchived ||
      auction.status === AuctionStatus.CompletedAsReListed ||
      auction.status === AuctionStatus.AuctionCollectedRecently ||
      auction.status === AuctionStatus.AuctionEndedButNotWon ||
      auction.status === AuctionStatus.AuctionEndedButSuspended ||
      auction.status === AuctionStatus.AuctionEndedAndWon
    );
  };

  return (
    <TableCell className={classes.cellWidth}>
      {InfoDialog()}

      {canEdit() ? (
        <Tooltip title="Edit lot">
          <IconButton aria-label="Edit" onClick={() => history.push(`/UpdateLot/${auction.lotId}/${auction.id}`)} disabled={loading}>
            <EditIcon color="primary" />
          </IconButton>
        </Tooltip>
      ) : (
        <IconButton aria-label="Edit" disabled>
          <EditIcon />
        </IconButton>
      )}

      {canEdit() ? (
        <Tooltip title="Change lot type">
          <IconButton
            aria-label="Change lot type"
            onClick={() => {
              setDialogType(DialogType.typeChange);
              setOpen(true);
            }}
          >
            <CategoryIcon color="primary" />
          </IconButton>
        </Tooltip>
      ) : (
        <IconButton aria-label="Change lot type" disabled>
          <CategoryIcon />
        </IconButton>
      )}

      <Tooltip title="View history">
        <IconButton
          aria-label="View history"
          onClick={() => {
            setDialogType(DialogType.auctionHistory);
            setOpen(true);
          }}
          disabled={loading}
        >
          <HistoryIcon color="primary" />
        </IconButton>
      </Tooltip>
      <Tooltip title="View audit log">
        <IconButton
          aria-label="View Audit log"
          onClick={() => {
            setDialogType(DialogType.auditLog);
            setOpen(true);
          }}
          disabled={loading}
        >
          <AssignmentIcon color="primary" />
        </IconButton>
      </Tooltip>
      {auction.status === AuctionStatus.AuctionEndedAndWon ||
        auction.status === AuctionStatus.AuctionCollectedRecently ||
        auction.status === AuctionStatus.CompletedAsLotCollected ? (
        <Tooltip title="Download auction sale Xml">
          <IconButton
            aria-label="DownloadAuctionXMl"
            onClick={() => {
              handleXmlOutput();
            }}
            disabled={loading}
          >
            <CodeIcon color="primary" />
          </IconButton>
        </Tooltip>
      ) : (
        <IconButton aria-label="Status change" disabled>
          <CodeIcon />
        </IconButton>
      )}

      {auction.status === AuctionStatus.AuctionEndedButNotWon ||
        auction.status === AuctionStatus.AuctionEndedButSuspended ||
        auction.status === AuctionStatus.AuctionEndedAndWon ? (
        <Tooltip title="Status change">
          <IconButton
            aria-label="Status change"
            onClick={() => {
              setDialogType(DialogType.statusChange);
              setOpen(true);
            }}
            disabled={loading}
          >
            <SwapCallsIcon color="primary" />
          </IconButton>
        </Tooltip>
      ) : (
        <IconButton aria-label="Status change" disabled>
          <SwapCallsIcon />
        </IconButton>
      )}

      {auction.status === AuctionStatus.AuctionEndedAndWon ? (
        <Tooltip title="Collection code">
          <IconButton
            aria-label="Collection code"
            onClick={() => {
              setDialogType(DialogType.collectionCode);
              setOpen(true);
            }}
            disabled={loading}
          >
            <NoteIcon color="primary" />
          </IconButton>
        </Tooltip>
      ) : (
        <IconButton aria-label="Collection code" disabled>
          <NoteIcon />
        </IconButton>
      )}

      {/* The member check is required for e2e owned lots, the e2e member cannot manage buckets. */}
      {auction.status === AuctionStatus.CreatedAwaitingBucketing && auction.memberId === context?.memberId ? (
        <Tooltip title="Add to bucket">
          <IconButton
            aria-label="Add to bucket"
            onClick={() => {
              setDialogType(DialogType.bucket);
              setOpen(true);
            }}
            disabled={loading}
          >
            <DateRangeIcon color="primary" />
          </IconButton>
        </Tooltip>
      ) : (
        <IconButton aria-label="Add to bucket" disabled>
          <DateRangeIcon />
        </IconButton>
      )}

      {canPublish(auction) ? (
        <Tooltip title="Publish bucket">
          <IconButton aria-label="publish bucket" onClick={() => onPublish()} disabled={loading}>
            <PublishIcon color="primary" />
          </IconButton>
        </Tooltip>
      ) : (
        <IconButton aria-label="publish bucket" disabled>
          <PublishIcon />
        </IconButton>
      )}

      {auction.status === AuctionStatus.Active ? (
        <Tooltip title="Suspend">
          <IconButton
            aria-label="Suspend"
            onClick={() => {
              setDialogType(DialogType.suspension);
              setOpen(true);
            }}
            disabled={loading}
          >
            <PauseIcon color="primary" />
          </IconButton>
        </Tooltip>
      ) : auction.status === AuctionStatus.ActiveButSuspended ? (
        <Tooltip title="Unsuspend">
          <IconButton aria-label="Unsuspend" onClick={async () => await handleUnsuspend(auction.id)} disabled={loading}>
            <PlayArrowIcon color="primary" />
          </IconButton>
        </Tooltip>
      ) : (
        <IconButton aria-label="Unsuspend" disabled>
          <PauseIcon />
        </IconButton>
      )}

      {auction.status === AuctionStatus.Active || auction.status === AuctionStatus.ActiveButSuspended ? (
        <Tooltip title="End immediately">
          <IconButton
            aria-label="End immediately"
            onClick={() => {
              setDialogType(DialogType.endImmediately);
              setOpen(true);
            }}
            disabled={loading}
          >
            <StopIcon color="primary" />
          </IconButton>
        </Tooltip>
      ) : (
        <IconButton aria-label="End immediately" disabled>
          <StopIcon />
        </IconButton>
      )}

      {auction.status === AuctionStatus.AuctionCollectedRecently || auction.status === AuctionStatus.AuctionEndedAndWon ? (
        <Tooltip title="Buyer didn't collect">
          <IconButton
            aria-label="Buyer didn't collect"
            onClick={() => {
              setDialogType(DialogType.collectionFailed);
              setOpen(true);
            }}
            disabled={loading}
          >
            <NotInterested color="primary" />
          </IconButton>
        </Tooltip>
      ) : (
        <IconButton aria-label="Buyer didn't collect" disabled>
          <NotInterested />
        </IconButton>
      )}

      {(auction.status === AuctionStatus.AuctionEndedAndWon || auction.status === AuctionStatus.AuctionCollectedRecently || auction.status === AuctionStatus.CompletedAsLotCollected) && auction.memberId === context?.memberId ? (
        <Tooltip title="Set Lot Collected Date">
          <IconButton
            aria-label="Set Lot Collected Date"
            onClick={() => {
              setDialogType(DialogType.lotCollectedDate);
              setOpen(true);
            }}
            disabled={loading}
          >
            <DateRangeIcon color="primary" />
          </IconButton>
        </Tooltip>
      ) : (
        <IconButton aria-label="Set Lot Collected Date" disabled>
          <DateRangeIcon />
        </IconButton>
      )}

      {(auction.status === AuctionStatus.AuctionEndedAndWon || auction.status === AuctionStatus.AuctionCollectedRecently || auction.status === AuctionStatus.CompletedAsLotCollected) && auction.memberId === context?.memberId ? (
        <Tooltip title="Set Lot Payment Date">
          <IconButton
            aria-label="Set Lot Payment Date"
            onClick={() => {
              setDialogType(DialogType.lotPaymentDate);
              setOpen(true);
            }}
            disabled={loading}
          >
            <PaymentIcon color="primary" />
          </IconButton>
        </Tooltip>
      ) : (
        <IconButton aria-label="Set Lot Payment Date" disabled>
          <PaymentIcon />
        </IconButton>
      )}

      {auction.status === AuctionStatus.CreatedPartially || auction.status === AuctionStatus.CreatedAwaitingBucketing ? (
        <Tooltip title="Delete auction">
          <IconButton
            aria-label="Delete auction"
            onClick={() => {
              setDialogType(DialogType.deletion);
              setOpen(true);
            }}
            disabled={loading}
          >
            <DeleteIcon color="primary" />
          </IconButton>
        </Tooltip>
      ) : (
        <IconButton aria-label="Delete auction" disabled>
          <DeleteIcon />
        </IconButton>
      )}
    </TableCell>
  );
};
