import * as React from "react";
import { Grid } from "@material-ui/core";
import { Navigation } from "./Navigation";
import * as Styles from "./styles/StandardNavigationStyles";
import { NavLink } from "react-router-dom";
import { NavbarAuthentication } from "../authentication/NavbarAuthentication";
import logo from "../../assets/logo-white.png";

const StandardNavigation: React.FC = () => {
  const classes = Styles.StandardNavigationStyles();

  return (
    <div className={classes.root}>
      <Grid className={classes.headerBarContainer} container direction="row" justify="space-between" alignItems="center">
        <Grid item>
          <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
            <Grid item>
              <NavLink to={"/"}>
                <img className={classes.logo} alt="logo" src={logo} />
              </NavLink>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <NavbarAuthentication />
        </Grid>
      </Grid>

      <Navigation lightTheme={true} />
    </div>
  );
};

export default StandardNavigation;
