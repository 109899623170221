import { ApiService, HttpResponse } from "./ApiService";
import { AuctionStatus } from "../interfaces/auctions/IAuction";
import { IAuctionEnded } from "../interfaces/auction-ended/IAuctionEnded";
import { GetClientSettings } from "./SettingsService";
import { ISalvageClientSettings } from "../interfaces/ISalvageClientSettings";
import { useVersionedData, useReadonlyData } from "./useVersionedData";
import { IAuctionMetadata } from "../interfaces/auctions/IAuctionMetadata";
import { BidderContactDetails } from "../interfaces/bidders/IBidderContactDetails";
import { FileResult } from "./ReportsService";
import { IPagingResults } from "../models/paging";
const ClientSettings: ISalvageClientSettings = GetClientSettings();

export interface LiveAuctionResults {
  pageNumber: number;
  pageSize: number;
  count: number;
  isMore: boolean;
  results: LiveBidItem[];
}

export interface LiveBidItem {
  id: string;
  previousAuctionId: string;
  lotId: string;
  memberId: string;
  memberName: string;
  title: string;
  referenceNumber: string;
  make: string;
  model: string;
  trimLevel: string;
  damageCategory: string;
  registrationNumber: string;
  mileage: number;
  location: string;
  auctionEnd: Date;
  cost: number;
  startPrice: number;
  reservePrice: number;
  isProxy: boolean;
  proxyAmount: number;
  bidderId: string;
  bidderName: number;
  bidderCountryCode: string;
  amount: number;
  bidderPurchaseCount: number;
  bidderSuspended: boolean;
  currentProfit: number;
  preAccidentValue: number;
  adminFee: number;
  additionalFee: number;
  webFee: number;
  auctionFee: number;
  isBuyBack: boolean;
  bidCount: number;
  viewCount: number;
  watchCount: number;
  insuranceCompany: string;
}

export interface Watch {
  bidderId: string;
  bidderName: string;
  lastUpdated: Date;
}

export interface View {
  bidderId: string;
  bidderName: string;
  lastUpdated: Date;
  metric: number;
}

export interface Bid {
  bidId: string;
  bidderId: string;
  bidderName: string;
  isProxy: boolean;
  amount: number;
  bidDateTime: Date;
  isBuyBack: boolean;
  suspended: boolean;
  isWinningBid: boolean;
}

export interface AuctionReserve {
  reservePrice: number;
  currentReserve: number;
  currentBidAmount: number;
}

export interface AuctionRelistRequest {
  relistReason?: number | null;
}

export const UseDefaultAuctionRelistRequest = (): AuctionRelistRequest => {
  return { relistReason: null };
};

export const useLiveBidsSearch = (
  signalRConnectionId: string,
  pageNumber: number,
  pageSize: number,
  search: string,
  siteId: string = '',
  insuranceCompany: string = '',
  onError?: (httpStatusCode: number, resultBody?: string) => void,
  shouldGetImmediately: boolean = true,
) =>
  useReadonlyData<LiveAuctionResults>(
    `${ClientSettings.AuctionPrivateApiUrl}/auctions/livebids/${signalRConnectionId}/paged/${pageNumber}/${pageSize}/${siteId}/${insuranceCompany}/${search}`,
    onError,
    shouldGetImmediately
  );

export const useLiveBid = (
  auctionId: string,
  onError?: (httpStatusCode: number, resultBody?: string) => void,
  shouldGetImmediately: boolean = true
) => useReadonlyData<LiveBidItem>(`${ClientSettings.AuctionPrivateApiUrl}/auctions/livebids/id/${auctionId}`, onError, shouldGetImmediately);

export const useLiveBidsWatch = (auctionId: string, onError?: (httpStatusCode: number, resultBody?: string) => void) =>
  useReadonlyData<Watch[]>(`${ClientSettings.AuctionPrivateApiUrl}/auctions/livebids/${auctionId}/watches/`, onError);

export const useLiveBidsViews = (auctionId: string, onError?: (httpStatusCode: number, resultBody?: string) => void) =>
  useReadonlyData<View[]>(`${ClientSettings.AuctionPrivateApiUrl}/auctions/livebids/${auctionId}/views/`, onError);

export const useAuctionReserve = (auctionId: string, onError?: (httpStatusCode: number, resultBody?: string) => void) =>
  useVersionedData<AuctionReserve>(`${ClientSettings.AuctionPrivateApiUrl}/auctions/id/${auctionId}/reserve`, onError);

export const useEndedAuctions = (onError?: (httpStatusCode: number, resultBody?: string) => void) =>
  useReadonlyData<IAuctionEnded[]>(`${ClientSettings.AuctionPrivateApiUrl}/auctions/ended`, onError);

export const useSuspendedAuctions = (onError?: (httpStatusCode: number, resultBody?: string) => void) =>
  useReadonlyData<IAuctionInfo[]>(`${ClientSettings.AuctionPrivateApiUrl}/auctions/suspended`, onError);

export const useAuctionReadonly = (auctionId: string | null, onError?: (httpStatusCode: number, resultBody?: string) => void) =>
  useReadonlyData<IAuctionInfo>(auctionId === null ? null : `${ClientSettings.AuctionPrivateApiUrl}/auctions/id/${auctionId}/admin`, onError);

export const useAuctionListing = (auctionId: string, onError?: (httpStatusCode: number, resultBody?: string) => void) =>
  useVersionedData<IAuctionListing>(`${ClientSettings.AuctionPrivateApiUrl}/auctions/id/${auctionId}/listing`, onError);

export const usePartiallyCreatedAuctions = (onError?: (httpStatusCode: number, resultBody?: string) => void) =>
  useReadonlyData<IAuctionListing[]>(`${ClientSettings.AuctionPrivateApiUrl}/auctions/createdpartially`, onError);

export const useAuctionsAwaitingBucketing = (onError?: (httpStatusCode: number, resultBody?: string) => void) =>
  useReadonlyData<IAuctionInfo[]>(`${ClientSettings.AuctionPrivateApiUrl}/auctions/awaitingbucketing`, onError);

export const useAuctionBidHistory = (auctionId: string, onError?: (httpStatusCode: number, resultBody?: string) => void) =>
  useReadonlyData<Bid[]>(`${ClientSettings.AuctionPrivateApiUrl}/auctions/id/${auctionId}/bidhistory/bids`, onError);

export const useAuctionCurrentBid = (auctionId: string, onError?: (httpStatusCode: number, resultBody?: string) => void) =>
  useReadonlyData<Bid>(`${ClientSettings.AuctionPrivateApiUrl}/auctions/id/${auctionId}/bids/currentbid`, onError);

export const useBiddersOnAuction = (auctionId: string, onError?: (httpStatusCode: number, resultBody?: string) => void) =>
  useReadonlyData<BidderContactDetails[]>(`${ClientSettings.AuctionPrivateApiUrl}/auctions/id/${auctionId}/bidders/`, onError);

export const usePreviousAuctionReadonly = (previousAuctionId: string | null, onError?: (httpStatusCode: number, resultBody?: string) => void) =>
  useReadonlyData<PreviousAuction>(
    previousAuctionId === null ? null : `${ClientSettings.AuctionPrivateApiUrl}/auctions/id/${previousAuctionId}/previousauctiondetails`,
    onError
  );

export const useAuctionsBidderBidOn = (
  bidderId: string,
  page: number,
  pageSize: number,
  onError?: (httpStatusCode: number, resultBody?: string) => void
) =>
  useReadonlyData<IPagingResults<IAuctionInfoWithAdminOnlyData>>(
    `${ClientSettings.AuctionPrivateApiUrl}/auctions/bidder/id/${bidderId}/bids/paged/${page}/${pageSize}`,
    onError
  );

export const useAuctionsBidderWonRecently = (bidderId: string, onError?: (httpStatusCode: number, resultBody?: string) => void) =>
  useReadonlyData<IAuctionInfo[]>(`${ClientSettings.AuctionPrivateApiUrl}/auctions/bidder/id/${bidderId}/won/recent`, onError);

export const useAuctionsWatching = (
  bidderId: string,
  page: number,
  pageSize: number,
  onError?: (httpStatusCode: number, resultBody?: string) => void
) =>
  useReadonlyData<IPagingResults<IAuctionInfoWithAdminOnlyData>>(
    `${ClientSettings.AuctionPrivateApiUrl}/auctions/bidder/id/${bidderId}/watching/paged/${page}/${pageSize}`,
    onError
  );

export const publishAuction = async (auctionId: string): Promise<HttpResponse<unknown>> => {
  return await ApiService().Post<unknown>(`${ClientSettings.AuctionPrivateApiUrl}/auctions/awaitingbucketing/id/${auctionId}`, null);
};

export const quickAddAuctionToBucket = async (bucketId: string, auctionId: string): Promise<HttpResponse<unknown>> => {
  return await ApiService().Post<unknown>(`${ClientSettings.AuctionPrivateApiUrl}/buckets/id/${bucketId}/auctions/id/${auctionId}`, null);
};

export const relistAuction = async (auctionId: string, request: AuctionRelistRequest): Promise<HttpResponse<IAuctionListing>> => {
  return await ApiService().Post<IAuctionListing>(`${ClientSettings.AuctionPrivateApiUrl}/auctions/relisted/id/${auctionId}`, request);
};

export const archiveAuction = async (auctionId: string): Promise<HttpResponse<unknown>> => {
  return await ApiService().Post<unknown>(`${ClientSettings.AuctionPrivateApiUrl}/auctions/archive/id/${auctionId}`, null);
};

export const sellDirectToBidAuction = async (auctionId: string, bidId: string): Promise<HttpResponse<unknown>> => {
  return await ApiService().Post<unknown>(
    `${ClientSettings.AuctionPrivateApiUrl}/auctions/ended/solddirect/id/${auctionId}/winningBid/${bidId}`,
    null
  );
};

export const sellDirectWithNewBidAuction = async (auctionId: string, bidderId: string, amount: number): Promise<HttpResponse<unknown>> => {
  return await ApiService().Post<unknown>(
    `${ClientSettings.AuctionPrivateApiUrl}/auctions/ended/solddirect/id/${auctionId}/bidder/${bidderId}/amount/${amount}`,
    null
  );
};

export const suspendAuction = async (auctionId: string): Promise<HttpResponse<unknown>> => {
  return await ApiService().Post<unknown>(`${ClientSettings.AuctionPrivateApiUrl}/auctions/suspended/id/${auctionId}`, null);
};

export const unsuspendAuction = async (auctionId: string): Promise<HttpResponse<unknown>> => {
  return await ApiService().Post<unknown>(`${ClientSettings.AuctionPrivateApiUrl}/auctions/unsuspended/id/${auctionId}`, null);
};

export const endAuctionManually = async (auctionId: string): Promise<HttpResponse<unknown>> => {
  return await ApiService().Post<unknown>(`${ClientSettings.AuctionPrivateApiUrl}/auctions/ended/manually/id/${auctionId}`, null);
};

export const auctionLotCollected = async (auctionId: string): Promise<HttpResponse<unknown>> => {
  return await ApiService().Post<unknown>(`${ClientSettings.AuctionPrivateApiUrl}/auctions/ended/collected/id/${auctionId}`, null);
};

export const auctionLotNotCollected = async (auctionId: string): Promise<HttpResponse<unknown>> => {
  return await ApiService().Post<unknown>(`${ClientSettings.AuctionPrivateApiUrl}/auctions/ended/notcollected/id/${auctionId}`, null);
};

export const updateAuctionMetadata = async (auctionId: string): Promise<HttpResponse<unknown>> => {
  return await ApiService().Put<unknown>(`${ClientSettings.AuctionPrivateApiUrl}/auctions/id/${auctionId}/metadata`, null);
};

export const DeleteAuction = async (auctionId: string) => {
  return await ApiService().Delete<unknown>(`${ClientSettings.AuctionPrivateApiUrl}/auctions/id/${auctionId}`, null);
};

export const auctionXmlOutput = async (auctionId: string): Promise<HttpResponse<FileResult>> => {
  return await ApiService().Get<FileResult>(`${ClientSettings.AuctionPrivateApiUrl}/auctions/output/xml/${auctionId}`);
};

export const lotCollectedDate = async (auctionId: string, lotCollectedDate: string): Promise<HttpResponse<unknown>> => {
  return await ApiService().Put<unknown>(`${ClientSettings.AuctionPrivateApiUrl}/auctions/lotCollectedDate/id/${auctionId}`, { lotCollectedDate: lotCollectedDate });
};

export const lotPaymentDate = async (auctionId: string, lotPaymentDate: string): Promise<HttpResponse<unknown>> => {
  return await ApiService().Put<unknown>(`${ClientSettings.AuctionPrivateApiUrl}/auctions/lotPaymentDate/id/${auctionId}`, { lotPaymentDate: lotPaymentDate });
};

export const getAuctionRelistReasons = async (): Promise<HttpResponse<EnumOption[]>> => {
  return await ApiService().Get<EnumOption[]>(`${ClientSettings.AuctionPublicApiUrl}/auctions/relistreasons`);
};

export interface EnumOption {
  name: string;
  value: number;
}

export interface IAuctionListing {
  id: string;
  lotId: string;
  memberId: string;
  memberName: string;
  auctionEnd: string;
  status: AuctionStatus;
  startPrice: number;
  reservePrice: number;
  bidIncrement: number;
  title: string;
  isFeatured: boolean;
  isRelistedAuction: boolean;
  previousAuctionId: string;
  thumbnailImageUrl: string;
  collectionCode: string;
  bucketId: string;
}

export interface PreviousAuction {
  id: string;
  startPrice: number;
  reservePrice: number;
  bidIncrement: number;
  auctionEnd: string;
  totalBids: number;
  finalBidAmount: number;
  numberOfListings: number;
}

// This is a read-only structure for an auction once it's created. Unlike the IAuctionListing (which is mutable) this structure contains the metadata etc.
export interface IAuctionInfo extends IAuctionListing {
  metadata: IAuctionMetadata;
  bucketTitle: string;
  lotAuctionCount: number;
  currentBidAmount: number;
  lotCollectedDate: string;
  lotPaymentDate: string;
}

// Some endpoints can supply confidential data such as Vehicle Registration.

interface IAdminOnlyData {
  registrationNumber: string;
  bidderIp: string;
}
export interface IAuctionInfoWithAdminOnlyData extends IAuctionInfo {
  adminOnly: IAdminOnlyData;
}
