import { makeStyles } from "@material-ui/styles";

import { Theme, createStyles } from "@material-ui/core";

export const ExistingBucketsStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonCell: {
      textAlign: "end",
      maxWidth: "350px",
      width: "350px",
    },
    numberCell: {
      maxWidth: "104px",
    },
    iconWrapContainer: {
      display: "inline-block",
    },
  })
);
