export interface IBusiness {
  businessName: string;
  vatNumber: string;
  registrationNumber: string;
  atfNumber: string;
}

export const BusinessDefault: IBusiness = {
  businessName: "",
  vatNumber: "",
  registrationNumber: "",
  atfNumber: "",
};
