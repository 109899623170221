import { View, Text } from "@react-pdf/renderer";
import { styles } from "../receipt-pdf/styles/PdfStyles";
import React from "react";
import { ReceiptData } from "../../services/ReceiptService";
import { currencyFormat } from "../../helpers/text-format/TextFormat";

interface ReceiptPaymentProps {
  receiptDetails: ReceiptData;
  isRefundReceipt: boolean;
  orderStatus: string | undefined;
}

export const ReceiptPayment: React.FC<ReceiptPaymentProps> = ({ receiptDetails, isRefundReceipt, orderStatus }) => (
  <>
    <View style={styles.paymentBox}>
    {(orderStatus == 'Refunded' && isRefundReceipt) ? (
        <>
          <View style={styles.payment}>
            <View style={styles.refundColumn}>
              <Text style={styles.refund}>REFUND OF SUBSCRIPTION</Text>
            </View>
          </View>
        </>        
      ) : (
        <>
          <View style={styles.titleRow}>
            <View style={styles.titleColumn}>
              <Text style={styles.titleFont}>Payment</Text>
            </View>
          </View>
          <View style={styles.payment}>
            <View style={styles.paymentColumn}>
              <View style={styles.text}>
                <Text>
                  {receiptDetails.paymentType} GBP {currencyFormat(receiptDetails.totalIncVat / 100)}
                </Text>
                <Text>
                Transaction No.:{" "}{receiptDetails.transactionNumber}
                </Text>
              </View>
            </View>
            <View style={styles.paidColumn}>
              <Text style={styles.paid}>* PAID IN FULL *</Text>
            </View>
          </View>
        </>
      )}
    </View>
  </>
);
