import { makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";

export const LiveBidListStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    overflowX: "auto",
  },
  tableRow: {
    "&:nth-of-type(odd)": {
      backgroundColor: grey[300],
    },
  },
  tableImageCell: {
    width: "55px",
    fontSize: "11px",
    borderLeft: "1px dashed rgba(224, 224, 224, 1)",
    borderRight: "1px dashed rgba(224, 224, 224, 1)",
    padding: "4px",
  },
  tableCell: {
    fontSize: "11px",
    borderLeft: "1px dashed rgba(224, 224, 224, 1)",
    borderRight: "1px dashed rgba(224, 224, 224, 1)",
    padding: "4px",
  },
  tableTitle: {
    fontSize: "11px",
  },
  rotateTableCell: {
    transform: "rotate(-90deg)",
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  MuiTableCellHead:{
    height: "65px"
  }
}));
