import { makeStyles, lighten } from "@material-ui/core/styles";
import { lightRed, lightGrey } from "../../../theme";

export const BiddersAwaitingDeDupeStyles = makeStyles(theme => ({
  mainContainer: {
    padding: "20px 0 20px 0",
    marginTop: "10px",
  },
  suspendedBidderRow: {
    backgroundColor: lightRed,
    // Bring in when we can navigator to the bidder cursor: "pointer",
    "&:hover": {
      backgroundColor: lighten(lightRed, 0.5),
      transition: "background-color 0.1s ease",
    },
  },
  otherBidderRow: {
    backgroundColor: lightGrey,
    // Bring in when we can navigator to the bidder  cursor: "pointer",
    "&:hover": {
      backgroundColor: lighten(lightGrey, 0.5),
      transition: "background-color 0.1s ease",
    },
  },
  duplicateRows: {
    cursor: "pointer",
    color: theme.palette.secondary.dark,
    "&:hover": {
      backgroundColor: lightGrey,
      transition: "background-color 0.1s ease",
    },
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
}));
