import { Typography, useTheme } from "@material-ui/core";
import moment from "moment";
import * as React from "react";
import { useDrag, useDrop } from "react-dnd";
import { IAuctionInfo } from "../../../services/AuctionService";
import { dropTypographyStyle, BucketAuctionDragItemItemStyles } from "../styles/BucketAuctionDragItemStyles";
import { IAuctionDraggable, IDragOverType } from "./DragDrop";
import { EditBucketAuctionCard } from "./EditBucketAuctionCard";

interface IEditBucketAddedProps {
  auctionsInBucket: IAuctionInfo[];
  onEnsure(auctionId: string, position: number): void;
  bucketDate: string;
  auctionSeparationInSeconds: number;
}

export const EditBucketAdded: React.FC<IEditBucketAddedProps> = ({ auctionsInBucket, onEnsure, bucketDate, auctionSeparationInSeconds }) => {
  const auctionEnd = (position: number) =>
    moment(bucketDate)
      .add(position * auctionSeparationInSeconds, "seconds")
      .format("HH:mm:ss");

  return (
    <>
      {auctionsInBucket.map((auction, i) => (
        <AuctionInBucketComponent key={i} auction={auction} onAuctionInserted={id => onEnsure(id, i)} bucketSlotTime={auctionEnd(i)} />
      ))}
      <EmptySlotInBucketComponent
        onAuctionInserted={id => onEnsure(id, auctionsInBucket.length)}
        bucketSlotTime={auctionEnd(auctionsInBucket.length)}
      />
    </>
  );
};

interface IAuctionInBucketProps {
  auction: IAuctionInfo;
  bucketSlotTime: string;
  onAuctionInserted(auctionId: string): void;
}
interface IEmptySlotInBucketProps {
  bucketSlotTime: string;
  onAuctionInserted(auctionId: string): void;
}

const AuctionInBucketComponent: React.FC<IAuctionInBucketProps> = ({ auction, onAuctionInserted, bucketSlotTime }) => {
  const [{ isOver, canDrop }, drop] = useDrop<IAuctionDraggable, void, IDragOverType>({
    accept: "auction",
    drop: item => onAuctionInserted(item.auctionId),
    collect: monitor => ({ isOver: !!monitor.isOver(), canDrop: !!monitor.canDrop() }),
  });

  const [, drag] = useDrag<IAuctionDraggable, void, void>({
    item: { auctionId: auction.id, type: "auction" },
  });

  const theme = useTheme();

  return (
    <>
      <div ref={drop} style={dropTypographyStyle(theme, canDrop)}>
        <Typography variant="subtitle1" align="center">
          {bucketSlotTime}
        </Typography>
        {isOver && (
          <Typography component="p" align="center">
            Insert auction here
          </Typography>
        )}
      </div>
      <div ref={drag}>
        <EditBucketAuctionCard auction={auction} />
      </div>
    </>
  );
};

const EmptySlotInBucketComponent: React.FC<IEmptySlotInBucketProps> = ({ onAuctionInserted, bucketSlotTime }) => {
  const [{ isOver, canDrop }, drop] = useDrop<IAuctionDraggable, void, IDragOverType>({
    accept: "auction",
    drop: item => onAuctionInserted(item.auctionId),
    collect: monitor => ({ isOver: !!monitor.isOver(), canDrop: !!monitor.canDrop() }),
  });

  const classes = BucketAuctionDragItemItemStyles();
  const theme = useTheme();

  return (
    <div ref={drop} style={dropTypographyStyle(theme, canDrop)} className={classes.dropArea}>
      <Typography component="p" align="center">
        (empty slot)
      </Typography>
      <Typography component="p" align="center">
        {bucketSlotTime}
      </Typography>
      {isOver && (
        <Typography component="p" align="center">
          Insert auction here
        </Typography>
      )}
    </div>
  );
};
