import * as React from "react";
import { CardMedia, Grid, TableCell, Tooltip, Typography } from "@material-ui/core";
import { IAuctionInfo } from "../../../services/AuctionService";
import { bidPlacedFormat, currencyFormat, lotCollectedDateFormat } from "../../../helpers/text-format/TextFormat";
import { AuctionStatus, auctionStatusGroupingString, auctionStatusString } from "../../../interfaces/auctions/IAuction";
import BrokenImageIcon from "@material-ui/icons/BrokenImage";
import { GetClientSettings } from "../../../services/SettingsService";
import { ISalvageClientSettings } from "../../../interfaces/ISalvageClientSettings";
import { AuctionRowStyles } from "../styles/AuctionRowStyles";
import FlagIcon from "@material-ui/icons/Flag";

interface AuctionRowProps {
  auction: IAuctionInfo;
}

export const AuctionRow: React.FC<AuctionRowProps> = ({ auction }) => {
  const classes = AuctionRowStyles();
  const clientSettings: ISalvageClientSettings = GetClientSettings();

  const statusClass = () => {
    switch (auctionStatusGroupingString(auction.status)) {
      case "Incomplete":
        return classes.incompleteFlag;
      case "Holding":
        return classes.holdingFlag;
      case "Live":
        return classes.liveFlag;
      case "Closed - Sold":
        return classes.soldFlag;
      case "Closed - Not Sold":
        return classes.notSoldFlag;
      case "Archived":
        return classes.archivedFlag;
      default: {
        return "";
      }
    }
  };

  const lotAuctionCount = () => {
    var preListingStatus = [AuctionStatus.CreatedPartially, AuctionStatus.CreatedAwaitingBucketing, AuctionStatus.CreatedAwaitingPublishing];
    if (preListingStatus.includes(auction.status)) {
      return auction.lotAuctionCount - 1;
    }
    return auction.lotAuctionCount;
  };

  const imageContainer = () => {
    if (!auction.thumbnailImageUrl) {
      return;
    }

    switch (auction.status) {
      case AuctionStatus.CreatedAwaitingBucketing:
      case AuctionStatus.CreatedAwaitingPublishing:
      case AuctionStatus.CreatedPartially:
      case AuctionStatus.DeletedBeforeBucketing:
      case AuctionStatus.Undefined: {
        return (
          <CardMedia
            className={classes.image}
            component="img"
            alt={auction.metadata.title}
            image={auction.thumbnailImageUrl}
            title={auction.metadata.title}
          />
        );
      }
      default: {
        return (
          <a
            style={{ textDecoration: "none", color: "black" }}
            href={`${clientSettings.AuctionSiteUrl}Lot/${auction.id}/`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <CardMedia
              className={classes.image}
              component="img"
              alt={auction.metadata.title}
              image={auction.thumbnailImageUrl}
              title={auction.metadata.title}
            />
          </a>
        );
      }
    }
  };

  return (
    <>
      <TableCell className={classes.tableCell}>
        {!auction.thumbnailImageUrl ? (
          <div style={{ padding: "20%" }}>
            <BrokenImageIcon color={"secondary"} />
          </div>
        ) : (
          imageContainer()
        )}
      </TableCell>
      <TableCell className={classes.tableCell}>
        <Typography variant="subtitle2" className={classes.title}>
          {auction.metadata.title}
        </Typography>
        <Typography variant="body2" className={classes.title}>
          {auction.metadata.registrationNumber}
        </Typography>
        <Typography variant="body2" className={classes.title}>
          {auction.metadata.nsgId}
        </Typography>
        <Typography variant="body2" className={classes.title}>
          {auction.metadata.lotType}
        </Typography>
      </TableCell>
      <TableCell className={classes.tableCell} width="1%">
        <Grid container spacing={1} direction="column" justify-content="center" alignItems="center">
          <Grid item>
            <Tooltip title={`Damage Category: ${auction.metadata.damageCategory ?? "Unknown"}`}>
              <Typography variant="h6" className={classes.infoSymbol}>
                <strong>{auction.metadata.damageCategory ?? "?"}</strong>
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title={`Number of times through auction: ${lotAuctionCount()}`}>
              <Typography variant="h6" className={classes.infoSymbol}>
                <strong>{lotAuctionCount()}</strong>
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title={`Status: ${auctionStatusGroupingString(auction.status)}`}>
              <FlagIcon className={statusClass()} />
            </Tooltip>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell className={`${classes.tableCell} ${classes.infoCell}`}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={3}>
            <Typography component="p" className={classes.p}>
              Ref
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <Typography component="p" className={classes.p}>
              {auction.metadata.referenceNumber}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography component="p" className={classes.p}>
              Closing
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <Typography component="p" className={classes.p}>
              {auction.auctionEnd === "0001-01-01T00:00:00+00:00" ? "Not set" : bidPlacedFormat(auction.auctionEnd)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography component="p" className={classes.p}>
              Location
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <Typography component="p" className={classes.p}>
              {auction.memberName} {auction.metadata.location}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography component="p" className={classes.p}>
              Bucket
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <Typography component="p" className={classes.p}>
              {auction.bucketTitle}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography component="p" className={classes.p}>
              Status
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <Typography component="p" className={classes.p}>
              {auctionStatusGroupingString(auction.status)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}></Grid>
          <Grid item xs={12} md={9}>
            <Typography component="p" className={classes.p}>
              {auctionStatusString(auction.status)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography component="p" className={classes.p}>
              Highest Bid
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <Typography component="p" className={classes.p}>
              {auction.currentBidAmount === 0 ? "" : currencyFormat(auction.currentBidAmount)}
            </Typography>
          </Grid>

          <Grid item xs={12} md={3}>
            <Typography component="p" className={classes.p}>
            Lot Collected Date
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <Typography component="p" className={classes.p}>            
            {lotCollectedDateFormat(auction.lotCollectedDate)}          
            </Typography>
          </Grid> 

          <Grid item xs={12} md={3}>
            <Typography component="p" className={classes.p}>
            Lot Payment Date
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <Typography component="p" className={classes.p}>            
            {lotCollectedDateFormat(auction.lotPaymentDate)}          
            </Typography>
          </Grid>        

        </Grid>
      </TableCell>
    </>
  );
};
