import { ApiService, HttpResponse } from "./ApiService";
import { GetClientSettings } from "./SettingsService";
import { ISalvageClientSettings } from "../interfaces/ISalvageClientSettings";
import { IMedia } from "../interfaces/lots/IMedia";
import { BlobTokenModel } from "../models/BlobTokenModel";
const ClientSettings: ISalvageClientSettings = GetClientSettings();

export const GetLotImageSasToken = async (id: string): Promise<HttpResponse<BlobTokenModel>> => {
  const url: string = ClientSettings.LotPrivateApiUrl + "/lot/media/sasstoken/" + id;
  return await ApiService().Get<BlobTokenModel>(url);
};

export const GetLotMedia = async (lotId: string): Promise<HttpResponse<Array<IMedia>>> => {
  const url: string = ClientSettings.LotPublicApiUrl + "/lot/media/" + lotId;
  return await ApiService().Get<Array<IMedia>>(url, true);
};

export const SetMediaMetadata = async (lotId: string, media: IMedia): Promise<HttpResponse<IMedia>> => {
  const url: string = ClientSettings.LotPrivateApiUrl + "/lot/media/" + lotId + "/metadata";
  return await ApiService().Post<IMedia>(url, media);
};

export const DeleteMediaItem = async (lotId: string, media: IMedia): Promise<HttpResponse<IMedia>> => {
  const url: string = ClientSettings.LotPrivateApiUrl + "/lot/media/" + lotId + "/delete";
  return await ApiService().Post<IMedia>(url, media);
};
