import * as React from "react";
import { useSnackbar } from "notistack";
import { Button, Grid, TextField } from "@material-ui/core";
import { DefaultBidderReportRequest, useBidderSignUpReport, useBidderSignUpReportOptions } from "../../services/ReportsService";
import { ContainerStyles } from "./styles/ContainerStyles";
import { SearchSkeleton } from "../auction-management/SearchSkeleton";
import { downloadCSV } from "./DownloadCsv";
import { Filter } from "../../helpers/Filter";
import { LoadingComponent } from "../loading/LoadingComponent";

export const BidderSignUpReportContainer: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = ContainerStyles();

  const [filterOptions, isOptionsLoading, ,] = useBidderSignUpReportOptions(() =>
    enqueueSnackbar("Could not retrieve list of filter options", { variant: "error" })
  );
  const [filterRequest, setFilterRequest, apiPostAsync, filterResponse, isLoadingReport, ,] = useBidderSignUpReport(() =>
    enqueueSnackbar("Could not retrieve list of auctions", { variant: "error" })
  );

  React.useEffect(() => {
    setFilterRequest(DefaultBidderReportRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (filterResponse && filterResponse?.requestId === filterRequest?.requestId) {
      downloadCSV(filterResponse.byteArray, "BidderSignUpReport.csv");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterResponse]);

  const changeHandler = (event: any, property: string) => {
    if (filterRequest) {
      if (event.target.value === "All") {
        event.target.value = null;
      }
      setFilterRequest({ ...filterRequest, [property]: event.target.value, requestId: Date.now.toString() });
    }
  };

  const changeDirection = (sortDescending: boolean) => {
    if (filterRequest) {
      setFilterRequest({ ...filterRequest, sortDescending: sortDescending, requestId: Date.now.toString() });
    }
  };

  const changeDate = (event: any, property: string) => {
    if (filterRequest) {
      setFilterRequest({ ...filterRequest, [property]: event.target.value, requestId: Date.now.toString() });
    }
  };

  const search = () => {
    if (filterRequest !== null) {
      apiPostAsync();
    }
  };

  const reset = () => {
    if (filterRequest !== null) {
      setFilterRequest(DefaultBidderReportRequest());
    }
  };

  return (
    <>
      {isLoadingReport && <LoadingComponent label="Loading report" />}
      {isOptionsLoading && <SearchSkeleton />}
      {!isLoadingReport && (
        <Grid container spacing={1}>
          <Grid item xs={12} lg={2}>
            {!isOptionsLoading && (
              <TextField
                variant="outlined"
                id="date"
                label="Date From"
                type="date"
                margin="dense"
                value={filterRequest?.createdAfter ? filterRequest?.createdAfter : ""}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={event => changeDate(event, "createdAfter")}
                fullWidth
              />
            )}
          </Grid>

          <Grid item xs={12} lg={2}>
            {!isOptionsLoading && (
              <TextField
                variant="outlined"
                id="date"
                label="Date To"
                type="date"
                margin="dense"
                value={filterRequest?.createdBefore ? filterRequest?.createdBefore : ""}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={event => changeDate(event, "createdBefore")}
                fullWidth
              />
            )}
          </Grid>

          <Grid item xs={12} lg={2}>
            {!isOptionsLoading && filterOptions?.sortBy !== null && (
              <Filter
                title="Sort by"
                filterOptions={filterOptions?.sortBy}
                filterPropertyName="sortBy"
                changeHandler={changeHandler}
                filterValue={filterRequest?.sortBy ? filterRequest.sortBy : ""}
                enumCase={true}
                all={false}
              />
            )}
          </Grid>

          <Grid item xs={12} lg={2}>
            {!isOptionsLoading && (
              <Filter
                title="Direction"
                filterOptions={["Descending", "Ascending"]}
                filterPropertyName="sortDescending"
                changeHandler={value => changeDirection(value.target.value === "Descending")}
                filterValue={filterRequest?.sortDescending ? "Descending" : "Ascending"}
                all={false}
              />
            )}
          </Grid>

          <Grid item xs={12} lg={1}>
            {!isOptionsLoading && (
              <Button color="primary" className={classes.searchButton} onClick={reset}>
                Reset
              </Button>
            )}
          </Grid>

          <Grid item xs={12}>
            {!isOptionsLoading && (
              <Button className={classes.createReportButton} variant="contained" color="primary" onClick={search} fullWidth>
                Create Report
              </Button>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};
