import * as React from "react";
import { Route, Switch } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import * as Styles from "./styles/PagesStyles";

//Components
import { AppContextProvider } from "../contexts/AppContext";
import StandardNavigation from "../components/layout/StandardNavigation";
import Footer from "../components/layout/Footer";

//Pages
import AuctionManagement from "../pages/AuctionManagement";
import BidderDetails from "../pages/BidderDetails";
import BidderMailingListReporting from "../pages/BidderMailingListReporting";
import BidderSearch from "../pages/BidderSearch";
import BidderSignUpReporting from "../pages/BidderSignUpReporting";
import Buckets from "../pages/Buckets";
import ClosedAuctionReporting from "../pages/ClosedAuctionReporting";
import CreateLot from "../pages/CreateLot";
import EditActiveBucket from "../pages/EditActiveBucket";
import EditBucket from "../pages/EditBucket";
import EndedAuctions from "../pages/EndedAuctions";
import FeaturedOrdering from "../pages/FeaturedOrdering";
import Homepage from "../pages/HomePage";
import IdVerification from "../pages/IdVerification";
import InsuranceCompanies from "../pages/InsuranceCompanies";
import LiveBids from "../pages/LiveBids";
import LotCompletion from "../pages/LotCompletion";
import Lots from "../pages/Lots";
import MemberDetails from "../pages/MemberDetails";
import AuctionStatusReporting from "../pages/AuctionStatusReporting";
import Permissions from "../pages/Permissions";
import SellDirectToBidder from "../pages/SellDirectToBidder";
import UpdateLot from "../pages/UpdateLot";
import Unauthorised from "../pages/Unauthorised";
import PackImport from "../pages/PackImport";
import ConfirmAccount from "../pages/ConfirmAccount";
import SalesDocument from "../pages/SalesDocument";
import MarginShareReporting from "../pages/MarginShareReporting";
import PaymentReceipt from "../pages/PaymentReceipt";
import Refunds from "../pages/Refunds";
import DisconnectedNotification from "../components/notifications/DisconnectedNotification";
import RulesAndFees from "../pages/RulesAndFees";
import StripeProducts from "../pages/StripeProducts";
import CreateStripeProduct from "../pages/CreateStripeProduct";
import EditStripeProduct from "../pages/EditStripeProduct";
import PaymentReconcilliationReporting from "../pages/PaymentReconcilliationReporting";
import AdminSettings from "../pages/AdminSettings";
import MaliciousBidderReport from "../pages/MaliciousBidderReport";
import LiveLotDetailsReport from "../pages/LiveLotDetailsReport";
import Alerts from "../pages/Alerts";
import CreateAlert from "../pages/CreateAlert";
import EditAlert from "../pages/EditAlert";

const Pages = (): any => {
  const classes = Styles.PagesStyles();

  return (
    <SnackbarProvider maxSnack={4}>
      <AppContextProvider>
        <DisconnectedNotification />
        <Route
          render={() => {
            return <StandardNavigation />;
          }}
        />
        <div className={classes.pageContainer}>
          <Switch>
            <Route path="/" exact={true} component={Homepage} />
            <Route path="/CreateLot" exact={true} component={CreateLot} />
            <Route path="/NewLot/:id" exact={true} component={CreateLot} />
            <Route path="/UpdateLot/:id/:auctionId?" exact={true} component={UpdateLot} />
            <Route path="/LotCompleted/:id" exact={true} component={LotCompletion} />
            <Route path="/Lots/" exact={true} component={Lots} />
            <Route path="/LiveBids" exact={true} component={LiveBids} />
            <Route path="/Buckets" exact={true} component={Buckets} />
            <Route path="/Buckets/EditBucket/:id" exact={true} component={EditBucket} />
            <Route path="/Buckets/EditActiveBucket/:id" exact={true} component={EditActiveBucket} />
            <Route path="/BidderSearch" exact={true} component={BidderSearch} />
            <Route path="/BidderDetailsChange/:bidderId" exact={true} component={BidderDetails} />
            <Route path="/IdVerification" exact={true} component={IdVerification} />
            <Route path="/ManageFeaturedAuctions" exact={true} component={FeaturedOrdering} />
            <Route path="/ManageAuctions" exact={true} component={EndedAuctions} />
            <Route path="/SellDirectToBidder/:auctionId" exact={true} component={SellDirectToBidder} />
            <Route path="/Configuration/InsuranceCompanies" exact={true} component={InsuranceCompanies} />
            <Route path="/MemberDetails" exact={true} component={MemberDetails} />
            <Route path="/AuctionManagement/:type" exact={true} component={AuctionManagement} />
            <Route path="/ClosedAuctionReporting" exact={true} component={ClosedAuctionReporting} />
            <Route path="/AuctionStatusReporting" exact={true} component={AuctionStatusReporting} />
            <Route path="/BidderSignUpReporting" exact={true} component={BidderSignUpReporting} />
            <Route path="/BidderMailingListReporting" exact={true} component={BidderMailingListReporting} />
            <Route path="/Permissions" exact={true} component={Permissions} />
            <Route path="/PackImport" exact={true} component={PackImport} />
            <Route path="/ConfirmAccount" exact={true} component={ConfirmAccount} />
            <Route path="/SalesDocument/:auctionId" exact={true} component={SalesDocument} />
            <Route path="/Payments/Document/:id/(status)?/:status?" component={PaymentReceipt} exact={true} />
            <Route path="/MarginShareReporting" exact={true} component={MarginShareReporting} />
            <Route path="/Refunds" exact={true} component={Refunds} />
            <Route path="/RulesAndFees" exact={true} component={RulesAndFees} />
            <Route path="/401" exact={true} component={Unauthorised} />
            <Route path="/StripeProducts" exact={true} component={StripeProducts} />
            <Route path="/StripeProducts/Create" exact={true} component={CreateStripeProduct} />
            <Route path="/StripeProducts/Edit/:id" exact={true} component={EditStripeProduct} />
            <Route path="/PaymentReconcilliationReporting" exact={true} component={PaymentReconcilliationReporting} />
            <Route path="/Configuration/Admin" exact={true} component={AdminSettings} />
            <Route path="/MaliciousBidderReport" exact={true} component={MaliciousBidderReport} />
            <Route path="/LiveLotDetailsReport" exact={true} component={LiveLotDetailsReport} />
            <Route path="/Alerts" exact={true} component={Alerts} />
            <Route path="/Alerts/Create" exact={true} component={CreateAlert} />
            <Route path="/Alerts/Edit/:id" exact={true} component={EditAlert} />
          </Switch>
        </div>
        <Footer />
      </AppContextProvider>
    </SnackbarProvider>
  );
};

export default Pages;
