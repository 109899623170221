import * as React from "react";
import { Typography, Container, Box } from "@material-ui/core";
import { SecureArea } from "../components/authentication/SecureArea";
import { MemberPermission } from "../services/MemberService";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../services/TelemetryService";
import { LiveLotDetailsReportContainer } from "../components/reporting/LiveLotDetailsReportContainer";

const LiveLotDetailsReport = () => (
  <Container maxWidth={"lg"} fixed>
    <SecureArea memberPermission={MemberPermission.BucketAdmin}>
      <Typography variant="h4" color="primary" gutterBottom>
        LIVE Lot Details Report
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Create an export for the LIVE lot details report.
      </Typography>
      <Box mt={4}>
        <LiveLotDetailsReportContainer />
      </Box>
    </SecureArea>
  </Container>
);

export default withAITracking(reactPlugin, LiveLotDetailsReport, "LiveLotDetailsReport");
