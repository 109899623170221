import { useTheme } from "@material-ui/core";
import * as React from "react";
import { useDrag, useDrop } from "react-dnd";
import { IAuctionInfo } from "../../../services/AuctionService";
import { dropDivStyle } from "../styles/BucketAuctionDragItemStyles";
import { IAuctionDraggable, IDragOverType } from "./DragDrop";
import { EditBucketAuctionCard } from "./EditBucketAuctionCard";

interface IEditBucketUnbucketedProps {
  auctionsNotInBucket: IAuctionInfo[];
  onRemove(auctionId: string): void;
}

export const EditBucketUnbucketed: React.FC<IEditBucketUnbucketedProps> = ({ auctionsNotInBucket, onRemove }) => {
  const [{ canDrop }, dropRemove] = useDrop<IAuctionDraggable, void, IDragOverType>({
    accept: "auction",
    drop: item => onRemove(item.auctionId),
    collect: monitor => ({ isOver: !!monitor.isOver(), canDrop: !!monitor.canDrop() }),
  });

  const theme = useTheme();

  return (
    <div ref={dropRemove} style={dropDivStyle(theme, canDrop)}>
      {auctionsNotInBucket.map((auction, i) => (
        <AuctionNotInBucketComponent key={i} auction={auction} />
      ))}
    </div>
  );
};

interface IAuctionNotInBucketProps {
  auction: IAuctionInfo;
}

const AuctionNotInBucketComponent: React.FC<IAuctionNotInBucketProps> = ({ auction }) => {
  const [, drag] = useDrag<IAuctionDraggable, void, void>({
    item: { auctionId: auction.id, type: "auction" },
  });

  return (
    <div ref={drag}>
      <EditBucketAuctionCard auction={auction} />
    </div>
  );
};
