import YouTubeIcon from "@material-ui/icons/YouTube";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  InputAdornment,
  Button,
  DialogContentText,
  DialogActions,
  CardMedia,
  Card,
  CardActionArea,
  CardHeader,
  Typography,
  Paper,
} from "@material-ui/core";
import { Options } from "react-youtube";
import YouTube from "react-youtube";
import * as MediaManagerStyles from "../styles/MediaManagerStyles";
import React from "react";
import { IMedia, IYouTubeVideo, MediaTypes } from "../../../interfaces/lots/IMedia";

export const AddYouTubeVideo: React.FC<{ videoAdded: (newMedia: IMedia) => void }> = ({ videoAdded }) => {
  const classes = MediaManagerStyles.addYouTubeVideo();

  const [newYouTubeUrl, setNewYouTubeUrl] = React.useState("");
  const [showAdd, setShowAdd] = React.useState(false);
  const [validVideo, setValidVideo] = React.useState(false);

  const [videoTitle, setVideoTitle] = React.useState("");
  const [videoAuthor, setVideoAuthor] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");

  const youTubeStartUrls = ["https://youtu.be/", "https://www.youtube.com/watch?v="];
  const handleClose = () => {
    setNewYouTubeUrl("");
    setVideoTitle("");
    setVideoAuthor("");
    setValidVideo(false);
    setShowAdd(false);
  };
  const handleYouTubeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMessage("");

    let newUrl = event.target.value;
    youTubeStartUrls.forEach(p => {
      if (newUrl.startsWith(p)) {
        newUrl = newUrl.substring(p.length);
      }
    });

    setNewYouTubeUrl(newUrl);
  };

  const playerOptions: Options = {
    height: "200",
    width: "200",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      mute: 1,
      fs: 0,
      modestbranding: 1,
      controls: 0,
    },
  };

  const invalidVideo = () => {
    setVideoTitle("");
    setVideoAuthor("");
    if (newYouTubeUrl === "") {
      setErrorMessage(""); // There is just no video loaded.
    } else {
      setErrorMessage("There is an error with the video you have provided. Check that it is publicly listed, and allowed to be embedded in a player");
    }

    setValidVideo(false);
  };
  const stateChange = (event: { target: any; data: number }) => {
    const data = event.target.getVideoData();
    if (event.data === 1) {
      event.target.pauseVideo();
    }
    setVideoTitle(data.title);
    setVideoAuthor(data.author);
    setValidVideo(data.title !== "");
  };
  const addVideo = () => {
    const video: IYouTubeVideo = {
      videoId: newYouTubeUrl,
      title: videoTitle,
      description: videoTitle,
      mediaType: MediaTypes.YouTube,
      isDirty: true,
      url: `http://i3.ytimg.com/vi/${newYouTubeUrl}/maxresdefault.jpg`,
      flagForDeletion: false,
      invalidImage: false,
      errorMessage: "",
      order: 99,
    };
    videoAdded(video);
    handleClose();
  };
  return (
    <>
      <Paper className={classes.root} elevation={6} onClick={() => setShowAdd(true)}>
        <YouTubeIcon className={classes.youtubeIcon} />
        <Typography variant="h5" gutterBottom>
          Add Video
        </Typography>
      </Paper>
      <Dialog open={showAdd} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add Video</DialogTitle>
        <DialogContent>
          <DialogContentText>Please enter the YouTube URL</DialogContentText>

          <TextField
            id="youtube-id"
            autoFocus
            fullWidth
            value={newYouTubeUrl}
            helperText={errorMessage}
            error={errorMessage !== ""}
            onChange={handleYouTubeChange}
            InputProps={{
              startAdornment: <InputAdornment position="start">https://youtube.com/watch?v=</InputAdornment>,
            }}
          />
          <div style={{ display: "none" }}>
            <YouTube videoId={newYouTubeUrl} opts={playerOptions} onStateChange={stateChange} onError={invalidVideo} />
          </div>
          {validVideo && (
            <div>
              <Card className={classes.mediaRoot}>
                <CardHeader title={videoTitle} subheader={videoAuthor} />
                <CardActionArea>
                  <CardMedia image={`http://i3.ytimg.com/vi/${newYouTubeUrl}/hqdefault.jpg`} title={videoTitle} className={classes.media} />
                </CardActionArea>
              </Card>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={addVideo} color="primary" disabled={!validVideo}>
            Add Video
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
