import * as React from "react";
import { Typography, Container } from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import { SellDirectToBidderComponent } from "../components/ended-auctions/SellDirectToBidderComponent";
import { SecureArea } from "../components/authentication/SecureArea";
import { MemberPermission } from "../services/MemberService";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../services/TelemetryService";

type TParams = { auctionId: string };

function SellDirectToBidder({ match }: RouteComponentProps<TParams>) {
  return (
    <Container maxWidth={"lg"} fixed>
      <SecureArea memberPermission={MemberPermission.BucketAdmin}>
        <Typography variant="h4" color="primary" gutterBottom>
          Sell Direct to Bidder
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          This page shows the bid history and the relevant contact information for the bidders for the auction.
        </Typography>
        <SellDirectToBidderComponent auctionId={match.params.auctionId} />
      </SecureArea>
    </Container>
  );
}

export default withAITracking(reactPlugin, SellDirectToBidder, "SellDirectToBidder");
