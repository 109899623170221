import { ApiService, HttpResponse } from "./ApiService";
import { GetClientSettings } from "./SettingsService";
import { ISalvageClientSettings } from "../interfaces/ISalvageClientSettings";
import { IAddress, AddressDefault } from "../interfaces/IAddress";
import { useVersionedData, useReadonlyData } from "./useVersionedData";
import { IAuction } from "../interfaces/auctions/IAuction";
const ClientSettings: ISalvageClientSettings = GetClientSettings();

export enum LotStatus {
  Undefined = 0,
  Draft = 100,
  AwaitingAuctionCreation = 110,
  InUseByAuction = 200,
  Sold = 400,
  Deleted = 500,
}

export const lotStatusString = (lotStatus: LotStatus) => {
  switch (lotStatus) {
    case LotStatus.Undefined: {
      return "Unknown";
    }
    case LotStatus.Draft: {
      return "Draft";
    }
    case LotStatus.AwaitingAuctionCreation: {
      return "Awaiting to be assigned to auction";
    }
    case LotStatus.InUseByAuction: {
      return "Assigned to auction";
    }
    case LotStatus.Sold: {
      return "Sold";
    }
    case LotStatus.Deleted: {
      return "Deleted";
    }
    default: {
      return "Unknown";
    }
  }
};

export const lotStatusGroupingString = (lotStatus: LotStatus) => {
  switch (lotStatus) {
    case LotStatus.Deleted: {
      return "Deleted";
    }
    case LotStatus.Draft: {
      return "Incomplete";
    }
    case LotStatus.AwaitingAuctionCreation: {
      return "Incomplete";
    }
    case LotStatus.InUseByAuction: {
      return "Live";
    }
    case LotStatus.Sold: {
      return "Sold";
    }
    default: {
      return "Unknown";
    }
  }
};

export interface Lot {
  id: string;
  memberId: string;
  city: string;
  referenceNumber: string;
  auctionImage: string;
  status: LotStatus;
  lotDetails: LotDetails;
  lotProperties: LotProperties;
  financials: Financials;
  thumbnailImageUrl: string;
}

export interface LotDetails {
  title: string;
  lotType: string;
  dataFromHpiLookup: boolean;
  registrationNumber: string;
  nsgId: string;
  referenceNumber: string;
  make: string;
  model: string;
  year: number;
  engineSize: string;
  mileage: number;
  trimLevel: string;
  description: string;
  doors: number;
  transmissionGears: number;
  transmissionType: string;
  bodyShape: string;
  fuelType: string;
  colour: string;
  damageCategory: string;
  insuranceCompany: string;
  branchCode: string;
  hasServiceHistory: boolean | null;
  hasKeys: boolean | null;
  hasStereo: boolean | null;
  hasLogBook: boolean | null;
  hasVIN: boolean | null;
  vatIncluded: boolean;
  doesStart: boolean | null;
  doesDrive: boolean | null;
}

export interface LotProperties {
  isE2eLot: boolean;
  canItemBeViewed: boolean | null;
  canItemBeDelivered: boolean;
  isItemOffSite: boolean;
  isFreeSubscription: boolean;
  siteId: string;
  siteName: string;
  location: IAddress;
  // auction
  isFeatured: boolean;
  title: string;
  startPrice: number;
  reservePrice: number;
  bidIncrement: number;
  // financials
  preAccidentValue: number;
  cost: number;
  additionalFee: number;
}

export interface Financials {
  preAccidentValue: number;
  cost: number;
  additionalFee: number;
}

export const LotDetailsDefault: LotDetails = {
  title: "",
  lotType: "",
  dataFromHpiLookup: false,
  registrationNumber: "",
  nsgId: "",
  referenceNumber: "",
  make: "",
  model: "",
  year: 0,
  engineSize: "",
  mileage: 0,
  trimLevel: "",
  description: "",
  doors: 0,
  transmissionGears: 0,
  transmissionType: "",
  bodyShape: "",
  fuelType: "",
  colour: "",
  damageCategory: "",
  hasServiceHistory: false,
  hasKeys: false,
  hasStereo: false,
  hasLogBook: false,
  hasVIN: false,
  vatIncluded: false,
  doesStart: false,
  doesDrive: false,
  insuranceCompany: "",
  branchCode: "",
};

export const LotPropertiesDefault: LotProperties = {
  isE2eLot: false,
  canItemBeViewed: false,
  canItemBeDelivered: false,
  isItemOffSite: false,
  isFreeSubscription: false,
  siteId: "",
  siteName: "",
  location: AddressDefault,
  // auction
  isFeatured: false,
  title: "",
  startPrice: 0,
  reservePrice: 0,
  bidIncrement: 0,
  // financials
  preAccidentValue: 0,
  cost: 0,
  additionalFee: 0,
};

export const FinancialsDefault: Financials = {
  preAccidentValue: 0,
  cost: 0,
  additionalFee: 0,
};

export const LotDefault: Lot = {
  id: "",
  memberId: "",
  city: "",
  referenceNumber: "",
  auctionImage: "",
  status: LotStatus.Undefined,
  lotDetails: LotDetailsDefault,
  lotProperties: LotPropertiesDefault,
  financials: FinancialsDefault,
  thumbnailImageUrl: "",
};

export const createLot = async (lotType: string): Promise<HttpResponse<Lot>> => {
  var lot = LotDefault;
  lot.lotDetails.lotType = lotType;
  return await ApiService().Post<Lot>(`${ClientSettings.LotPrivateApiUrl}/lots`, lot);
};

export const publishLot = async (lotId: string): Promise<HttpResponse<string>> => {
  return await ApiService().Put<string>(`${ClientSettings.LotPrivateApiUrl}/lots/id/${lotId}/publish`, LotDefault);
};

export const deleteLot = async (lotId: string): Promise<HttpResponse<string>> => {
  return await ApiService().Put<string>(`${ClientSettings.LotPrivateApiUrl}/lots/id/${lotId}/delete`, LotDefault);
};

export const useLot = (lotId: string, onError: (httpStatusCode: number, resultBody?: string) => void) =>
  useVersionedData<Lot>(`${ClientSettings.LotPrivateApiUrl}/lots/id/${lotId}`, onError);

export const useLotDetails = (lotId: string, onError: (httpStatusCode: number, resultBody?: string) => void) =>
  useVersionedData<LotDetails>(`${ClientSettings.LotPrivateApiUrl}/lots/id/${lotId}/lotdetails`, onError);

export const useLotFinancials = (lotId: string, onError: (httpStatusCode: number, resultBody?: string) => void) =>
  useVersionedData<Financials>(`${ClientSettings.LotPrivateApiUrl}/lots/id/${lotId}/financials`, onError);

export const useLotProperties = (lotId: string, onError: (httpStatusCode: number, resultBody?: string) => void) =>
  useVersionedData<LotProperties>(`${ClientSettings.LotPrivateApiUrl}/lots/id/${lotId}/lotproperties`, onError);

export const useLotsInDraft = (onError?: (httpStatusCode: number, resultBody?: string) => void) =>
  useReadonlyData<Lot[]>(`${ClientSettings.LotPrivateApiUrl}/lots/indraft`, onError);

export const useLotsAwaitingAuctionCreation = (onError?: (httpStatusCode: number, resultBody?: string) => void) =>
  useReadonlyData<Lot[]>(`${ClientSettings.LotPrivateApiUrl}/lots/awaitingauctioncreation`, onError);

// TODO IAuction needs changing to Auction
export const createAuctionFromLotId = async (lotId: string): Promise<HttpResponse<IAuction>> => {
  return await ApiService().Post<IAuction>(`${ClientSettings.LotPrivateApiUrl}/lots/id/${lotId}/auctions`, null);
};
