import { GetClientSettings } from "./SettingsService";
import { ISalvageClientSettings } from "../interfaces/ISalvageClientSettings";
import { useReadonlyData, useVersionedData } from "./useVersionedData";
import { INotificationOptionSet } from "../components/member-details/helpers/RuleIdentifiers";
import { INotificationPreferences } from "../interfaces/members/INotificationPreferences";
const ClientSettings: ISalvageClientSettings = GetClientSettings();

export const useUpdateMemberNotificationPreferences = (onError: (httpStatusCode: number, resultBody?: string) => void) =>
  useVersionedData<INotificationPreferences[]>(`${ClientSettings.UsersPrivateApiUrl}/member/membernotifications/preferences/`, onError);

export const useNotificationOptions = (onError: (httpStatusCode: number, resultBody?: string) => void) =>
  useReadonlyData<INotificationOptionSet[]>(`${ClientSettings.UsersPrivateApiUrl}/member/membernotifications/availableoptions`, onError);
