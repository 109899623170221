import * as React from "react";
import { useDropzone } from "react-dropzone";
import { Grid, Typography, Paper, Divider, Container, Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import { useSnackbar, OptionsObject } from "notistack";
import { IMedia, ILocalFileMedia, MediaTypes, IBlobMedia, IYouTubeVideo } from "../../interfaces/lots/IMedia";
import * as MediaService from "../../services/MediaService";
import * as BlobStorageService from "../../services/BlobStorageService";
import * as MediaManagerStyles from "./styles/MediaManagerStyles";
import { DisplayImageContainer } from "./containers/DisplayImageContainer";
import { LoadingComponent } from "../loading/LoadingComponent";
import { DraggableMediaContainer } from "./containers/DraggableMediaContainer";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { AddYouTubeVideo } from "./containers/AddYoutubeVideo";
const variantError: OptionsObject = { variant: "error" };

interface MediaManagerProps {
  lotId: string;
  isValid: (mediaLoaded: boolean) => void;
  uploadMedia: boolean;
  mediaUploaded: (mediaUploaded: boolean) => void;
}

export const MediaManager: React.FC<MediaManagerProps> = ({ lotId, isValid, uploadMedia, mediaUploaded }) => {
  const classes = MediaManagerStyles.mediaManager();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [media, setMedia] = React.useState<IMedia[]>([]);
  const [invalidMedia, setInvalidMedia] = React.useState<IMedia[]>([]);

  React.useEffect(() => {
    const isAnyMediaInvalid = media.filter(p => !p.invalidImage).some(p => p.isDirty && (p.description.length === 0 || p.title.length === 0));
    isValid(media.length > 0 && !isAnyMediaInvalid && !uploadMedia);
  }, [isValid, media, uploadMedia]);

  React.useEffect(() => {
    async function UploadMedia(): Promise<void> {
      const token = await MediaService.GetLotImageSasToken(lotId);
      if (!token.parsedBody) {
        enqueueSnackbar("We were unable to get permission to upload the images you selected. Please try again.", variantError);
        mediaUploaded(false);
        return;
      }
      for (let x = 0; x < media.length; x++) {
        try {
          if (media[x].isDirty && !media[x].invalidImage) {
            media[x].order = x;

            const file = (media[x] as ILocalFileMedia).file;
            const youTube = media[x] as IYouTubeVideo;

            let metaData = {
              description: media[x].description,
              title: media[x].title,
              order: `${x}`,
            };

            if (file) {
              const blobName = "original/" + file.name;
              await BlobStorageService.UploadFileToBlobStorage(token.parsedBody, blobName, file, metaData);
            } else if (youTube.videoId) {
              const blobName = "original/" + youTube.videoId;

              metaData["video"] = youTube.videoId;

              const blob = new Blob([`http://i3.ytimg.com/vi/${youTube.videoId}/maxresdefault.jpg`], { type: "text/html" });
              await BlobStorageService.UploadFileToBlobStorage(token.parsedBody, blobName, blob, metaData);
            } else {
              await MediaService.SetMediaMetadata(lotId, media[x]);
            }
          }
        } catch (exception) {
          enqueueSnackbar(`An error occurred whilst uploading ${media[x].title}`, variantError);
          mediaUploaded(false);
          return;
        }
      }
      mediaUploaded(true);
    }
    if (uploadMedia) {
      UploadMedia();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadMedia]);

  // Used to load any saved data.
  React.useEffect(() => {
    async function initializeForm(): Promise<void> {
      const existingMedia = await MediaService.GetLotMedia(lotId);
      setMedia(existingMedia.parsedBody ?? []);
    }

    if (loading) {
      initializeForm().finally(() => setLoading(false));
    }
  }, [loading, lotId]);

  const isImage = (file: File): boolean => {
    const fileType = file.type;
    return fileType.includes("image");
  };

  const onDrop = (acceptedFiles: Array<File>): void => {
    const newMedias: Array<IMedia> = [];
    for (let x = 0; x < acceptedFiles.length; x++) {
      const file = acceptedFiles[x];
      if (isImage(file)) {
        const newMedia = convertFileToMedia(file);
        newMedia.order = x + media.length;
        newMedias.push(newMedia);
      } else {
        enqueueSnackbar("Incorrect file type", variantError);
      }
    }
    if (newMedias.length > 0) {
      setMedia(existingMedia => [...existingMedia, ...newMedias]);
    }
  };

  const convertFileToMedia = (file: File): ILocalFileMedia => {
    const newMedia: ILocalFileMedia = {
      url: URL.createObjectURL(file),
      description: file.name,
      title: file.name,
      file: file,
      mediaType: MediaTypes.Image,
      flagForDeletion: false,
      isDirty: true,
      invalidImage: false,
      errorMessage: "",
      order: media.length,
    };
    return newMedia;
  };

  const { isDragActive, getRootProps, getInputProps } = useDropzone({
    onDrop,
    noDragEventsBubbling: true,
  });

  const mediaUpdated = async (updatedMedia: IMedia): Promise<void> => {
    updatedMedia.isDirty = true;

    if (updatedMedia.flagForDeletion) {
      setMedia(media => media.filter(p => p.url !== updatedMedia.url));

      // this item has been deleted
      if (!(updatedMedia as ILocalFileMedia).file) {
        try {
          await MediaService.DeleteMediaItem(lotId, updatedMedia as IBlobMedia);
        } catch (err) {
          enqueueSnackbar("There was an error removing the selected image", variantError);
          // best add it back in!
          setMedia(media => [...media, updatedMedia]);

          return;
        }
      }
    } else if (updatedMedia.invalidImage) {
      setMedia(media => media.filter(p => p.url !== updatedMedia.url));
      setInvalidMedia(invalidMedia => [...invalidMedia, updatedMedia]);
    } else {
      setMedia(media => [...media]);
    }
  };
  const moveMedia = React.useCallback(
    (dragIndex: number, hoverIndex: number) => {
      setMedia(media => {
        media.splice(hoverIndex, 0, media.sort((a, b) => a.order - b.order).splice(dragIndex, 1)[0]);

        for (var x = 0; x < media.length; x++) {
          if (media[x].order !== x) {
            media[x].isDirty = true;
            media[x].order = x;
          }
        }

        return [...media];
      });
      // }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [media]
  );
  const mediaList = (): JSX.Element => {
    if (media !== undefined && (media.length > 0 || invalidMedia.length > 0)) {
      return (
        <>
          <Grid container spacing={3}>
            <DndProvider backend={HTML5Backend}>
              {media
                .filter(item => !item.flagForDeletion)
                .sort((a, b) => a.order - b.order)
                .map((item: IMedia, index: number) => (
                  <DraggableMediaContainer
                    key={item.url}
                    lotId={lotId}
                    index={index}
                    media={item}
                    mediaUpdated={mediaUpdated}
                    moveMedia={moveMedia}
                  ></DraggableMediaContainer>
                ))}
            </DndProvider>
          </Grid>

          {invalidMedia.length > 0 && (
            <div className={classes.invalidContainer}>
              <Typography variant="h6" component="h1" gutterBottom>
                Invalid Images
              </Typography>
              <Typography gutterBottom variant="subtitle1">
                The media below is not valid, and will not be uploaded as part of the lot.
              </Typography>
              <Grid container spacing={3}>
                {invalidMedia.map((item: IMedia) => (
                  <Grid item xs={3} key={item.url}>
                    <DisplayImageContainer lotId={lotId} media={item} mediaUpdated={() => { }}></DisplayImageContainer>
                  </Grid>
                ))}
              </Grid>
            </div>
          )}
        </>
      );
    } else {
      return (
        <Typography variant="h6" component="h1" align="center">
          No images uploaded
        </Typography>
      );
    }
  };

  const uploadAreaComponent = (
    <div {...getRootProps()}>
      <input {...getInputProps()} />

      <Paper className={classes.root} elevation={6}>
        {isDragActive ? (
          <Typography variant="h5" component="h3" align="center" className={classes.dropTypography}>
            Drop file
          </Typography>
        ) : (
          <>
            <PhotoCameraIcon className={classes.dropIcon} />
            <Typography variant="h5" component="h3" className={classes.dropTypography}>
              Click here or drop a file to upload
            </Typography>
          </>
        )}
      </Paper>
    </div>
  );

  const videoAdded = (newMedia: IMedia) => {
    setMedia(existingMedia => [...existingMedia, newMedia]);
  };

  return loading ? (
    <LoadingComponent label={"Loading Media"} />
  ) : (
    <Container>
      <Grid container>
        <Grid item xs={6}>
          {uploadAreaComponent}
        </Grid>
        <Grid item xs={6}>
          <AddYouTubeVideo videoAdded={videoAdded} />
        </Grid>
      </Grid>

      <Divider className={classes.extraMargin} />
      {mediaList()}
      <Dialog open={uploadMedia} fullWidth={true} maxWidth="sm">
        <DialogTitle>Please Wait</DialogTitle>
        <DialogContent>
          <LoadingComponent label={"Uploading Media"} />
        </DialogContent>
      </Dialog>
    </Container>
  );
};
