import { Text, View, Image } from "@react-pdf/renderer";
import { SalesDocument } from "../../../services/SalesDocumentService";
import { booleanToYesNo, salesDocumentDateFormat } from "../../../helpers/text-format/TextFormat";
import { styles } from "../styles/PdfStyles";
import React from "react";

interface VehicleDetails {
  salesDocumentInfo: SalesDocument;
}

const IsUnknown = (string: string | null) => {
  if (string === null) {
    return "Unknown";
  }
  return string;
};

export const VehicleDetails: React.FC<VehicleDetails> = ({ salesDocumentInfo }) => (
  <View>
    <View style={styles.rowWidth100}>
      <View style={styles.splitTitle}>
        <Text style={styles.splitTitle}>Vehicle Details</Text>
      </View>
      <View style={styles.splitTitle}>
        <Text style={styles.collectionCode}>Collection Code: {salesDocumentInfo.collectionCode}</Text>
      </View>
    </View>

    <View style={styles.row}>
      <View style={styles.imageBlock}>
        <Image style={styles.width100} src={salesDocumentInfo.lotImageUrl} />
      </View>
      <View style={styles.titleBlock}>
        <Text style={styles.littleGreyText}>Vehicle title</Text>
        <Text style={styles.largeText}> {salesDocumentInfo?.title}</Text>
        <Text style={styles.greyText}>
          It is your responsibility to satisfy yourself as to the condition and history of your purchase. We recommend at least a HPI or equivalent
          check. You must notify us of any potential claims relating to the condition and the history of your purchase before the vehicle is removed
          from site.
        </Text>
      </View>
    </View>
    <View style={styles.rowPaddingBottom4}>
      <View style={styles.lotDetailsBlock}>
        <Text style={styles.littleGreyTextLotDetails}>Ref</Text>
        <Text style={styles.greyText}>{IsUnknown(salesDocumentInfo?.referenceNumber)}</Text>
        <Text style={styles.littleGreyTextLotDetails}>Year</Text>
        <Text style={styles.greyText}>{salesDocumentInfo?.year !== 0 ? salesDocumentInfo?.year : ""}</Text>
        <Text style={styles.littleGreyTextLotDetails}>Make</Text>
        <Text style={styles.greyText}>{IsUnknown(salesDocumentInfo?.make)}</Text>
        <Text style={styles.littleGreyTextLotDetails}>Trim</Text>
        <Text style={styles.greyText}>{IsUnknown(salesDocumentInfo?.trimLevel)}</Text>
        <Text style={styles.littleGreyTextLotDetails}>Fuel</Text>
        <Text style={styles.greyText}>{IsUnknown(salesDocumentInfo?.fuelType)}</Text>
        <Text style={styles.littleGreyTextLotDetails}>Speedo</Text>
        <Text style={styles.greyText}>{salesDocumentInfo?.mileage !== 0 ? salesDocumentInfo?.mileage : "Unknown"}</Text>
        <Text style={styles.littleGreyTextLotDetails}>V5</Text>
        <Text style={styles.greyText}>{booleanToYesNo(salesDocumentInfo?.hasLogBook)}</Text>
        <Text style={styles.littleGreyTextLotDetails}>Starts</Text>
        <Text style={styles.greyText}>{booleanToYesNo(salesDocumentInfo?.doesStart)}</Text>
        <Text style={styles.littleGreyTextLotDetails}>Location</Text>
        <Text style={styles.greyText}>{IsUnknown(salesDocumentInfo?.collectionLocation?.city)}</Text>
      </View>
      <View style={styles.lotDetailsBlock}>
        <Text style={styles.littleGreyTextLotDetails}>Auction closed</Text>
        <Text style={styles.greyText}>{salesDocumentDateFormat(salesDocumentInfo?.auctionEnd)}</Text>
        <Text style={styles.littleGreyTextLotDetails}>Reg. No.</Text>
        <Text style={styles.greyText}>{IsUnknown(salesDocumentInfo?.registrationNumber)}</Text>
        <Text style={styles.littleGreyTextLotDetails}>Vat</Text>
        <Text style={styles.greyText}>{booleanToYesNo(salesDocumentInfo?.vatIncluded)}</Text>
        <Text style={styles.littleGreyTextLotDetails}>Model</Text>
        <Text style={styles.greyText}>{IsUnknown(salesDocumentInfo?.model)}</Text>
        <Text style={styles.littleGreyTextLotDetails}>Colour</Text>
        <Text style={styles.greyText}>{IsUnknown(salesDocumentInfo?.colour)}</Text>
        <Text style={styles.littleGreyTextLotDetails}>CC</Text>
        <Text style={styles.greyText}>{salesDocumentInfo?.engineSize !== "0" ? salesDocumentInfo?.engineSize : ""}</Text>
        <Text style={styles.littleGreyTextLotDetails}>Category</Text>
        <Text style={styles.greyText}>{IsUnknown(salesDocumentInfo?.damageCategory)}</Text>
        <Text style={styles.littleGreyTextLotDetails}>Keys</Text>
        <Text style={styles.greyText}>{booleanToYesNo(salesDocumentInfo?.hasKeys)}</Text>
        <Text style={styles.littleGreyTextLotDetails}>Drives</Text>
        <Text style={styles.greyText}>{booleanToYesNo(salesDocumentInfo?.doesDrive)}</Text>
      </View>

      <View style={styles.lotDescriptionBlock}>
        <Text style={styles.littleGreyTextLotDetails}>Description</Text>
        <Text style={styles.greyText}>{salesDocumentInfo.description}</Text>
      </View>
    </View>
  </View>
);
