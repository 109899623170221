import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { IValidation } from "../../interfaces/IValidation";
import { IBusiness } from "../../interfaces/bidders/IBusiness";
import { isError, ErrorMessage } from "./helpers/ErrorHandlers";

interface IProps {
  validation: IValidation;
  setValidation: React.Dispatch<React.SetStateAction<IValidation>>;
  business: IBusiness;
  onBusinessChange: React.Dispatch<React.SetStateAction<IBusiness | null>>;
}

export const BusinessDetailsForm: React.FC<IProps> = props => {
  const onBusinessFieldChange = (e: any) => {
    const fieldValue = e.target.value;
    const fieldName = e.target.name;
    props.onBusinessChange({
      ...props.business,
      [fieldName]: fieldValue,
    });
  };
  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            error={isError("Business.BusinessName", props.validation)}
            helperText={ErrorMessage("Business.BusinessName", props.validation)}
            name="businessName"
            label="Business Name"
            value={props.business.businessName}
            onChange={value => onBusinessFieldChange(value)}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            error={isError("Business.RegistrationNumber", props.validation)}
            helperText={ErrorMessage("Business.RegistrationNumber", props.validation)}
            name="registrationNumber"
            label="Registration Number"
            value={props.business.registrationNumber}
            onChange={value => onBusinessFieldChange(value)}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            error={isError("Business.VATNumber", props.validation)}
            helperText={ErrorMessage("Business.VATNumber", props.validation)}
            name="vatNumber"
            label="VAT Number"
            value={props.business.vatNumber}
            onChange={value => onBusinessFieldChange(value)}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            name="atfNumber"
            label="ATF Number"
            value={props.business.atfNumber}
            onChange={value => onBusinessFieldChange(value)}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
