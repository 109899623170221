import * as React from "react";
import { Typography, Container } from "@material-ui/core";
import { BucketsContainer } from "../components/buckets/BucketsContainer";
import { SecureArea } from "../components/authentication/SecureArea";
import { MemberPermission } from "../services/MemberService";
import { reactPlugin } from "../services/TelemetryService";
import { withAITracking } from "@microsoft/applicationinsights-react-js";

function Buckets() {
  return (
    <Container maxWidth={"lg"} fixed>
      <SecureArea memberPermission={MemberPermission.BucketAdmin}>
        <Typography variant="h4" color="primary" gutterBottom>
          Bucket Management
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Manage and create new buckets.
        </Typography>
        <BucketsContainer />
      </SecureArea>
    </Container>
  );
}

export default withAITracking(reactPlugin, Buckets, "Buckets");
