import * as React from "react";
import { CreateBucket } from "./create-bucket/CreateBucket";
import { ExistingBuckets } from "./ExistingBuckets";
import { DefaultBucketFilter, useBucketFilter } from "../../services/BucketService";
import { useSnackbar } from "notistack";
import { Grid, Button } from "@material-ui/core";
import { Filter } from "../../helpers/Filter";

export const BucketsContainer: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [filterRequest, setFilterRequest, apiPostAsync, filterResponse, isBucketsLoading, ,] = useBucketFilter(() =>
    enqueueSnackbar("Could not retrieve the buckets", { variant: "error" })
  );
  React.useEffect(() => {
    apiPostAsync(DefaultBucketFilter);
    setFilterRequest(DefaultBucketFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function refreshBuckets(): void {
    setFilterRequest(DefaultBucketFilter);
    apiPostAsync(DefaultBucketFilter);
  }

  const changeHandler = (event: any, property: string) => {
    if (filterRequest) {
      if (event.target.value === "All") {
        event.target.value = null;
      }
      const newRequest = { ...filterRequest, [property]: event.target.value, pageNumber: 0 };
      setFilterRequest(newRequest);
      apiPostAsync(newRequest);
    }
  };

  const changeDirection = (sortDescending: boolean) => {
    if (filterRequest) {
      const newRequest = { ...filterRequest, sortDescending: sortDescending, pageNumber: 0 };
      setFilterRequest(newRequest);
      apiPostAsync(newRequest);
    }
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    const newFilter = { ...filterRequest!, pageNumber: newPage, sortDescending: false };
    setFilterRequest(newFilter);
    apiPostAsync(newFilter);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newFilter = { ...filterRequest, numberPerPage: parseInt(event.target.value, 10), pageNumber: 0, sortDescending: false };
    setFilterRequest(newFilter);
    apiPostAsync(newFilter);
  };

  const bucketStatusFilterStrings = ["PublishedAndUnpublished", "Published", "Unpublished", "Closed"];

  const reset = () => {
    if (filterRequest !== null) {
      setFilterRequest(DefaultBucketFilter);
      apiPostAsync(DefaultBucketFilter);
    }
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid xs={12} item style={{ textAlign: "end" }}>
          <CreateBucket onNewBucketAdded={refreshBuckets} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Filter
            title="Bucket Status"
            filterOptions={bucketStatusFilterStrings}
            filterPropertyName="bucketStatus"
            changeHandler={changeHandler}
            filterValue={filterRequest?.bucketStatus ? filterRequest.bucketStatus : ""}
            enumCase={true}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Filter
            title="Sort by"
            filterOptions={["AuctionScheduleDate"]}
            filterPropertyName="sortBy"
            changeHandler={changeHandler}
            filterValue={filterRequest?.sortBy ? filterRequest.sortBy : ""}
            enumCase={true}
            all={false}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Filter
            title="Direction"
            filterOptions={["Descending", "Ascending"]}
            filterPropertyName="sortDescending"
            changeHandler={value => changeDirection(value.target.value === "Descending")}
            filterValue={filterRequest?.sortDescending ? "Descending" : "Ascending"}
            all={false}
          />
        </Grid>
        <Grid item xs={12} md={1}>
          <Button style={{ margin: "8px 8px 8px 8px", padding: "7px 20px 7px 20px" }} color="primary" onClick={reset}>
            Reset
          </Button>
        </Grid>
      </Grid>

      <ExistingBuckets
        loadingBuckets={isBucketsLoading}
        bucketsResults={filterResponse}
        refreshBuckets={refreshBuckets}
        changePage={handleChangePage}
        changeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
};
