import { Typography } from "@material-ui/core";
import React from "react";

export function AuthenticationInProgress() {
  return (
    <Typography variant="subtitle1" color="textPrimary" gutterBottom>
      Authenticating...
    </Typography>
  );
}
