import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import * as SalvageColours from "../../../theme";

const mediaManager = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3, 2),
      paddingTop: "30px",
      textAlign: "center",
      minHeight: "150px",
    },
    filesUploaded: {
      padding: theme.spacing(3, 2),
      textAlign: "center",
      marginTop: "7px",
    },
    extraMargin: {
      marginTop: "15px",
      marginBottom: "15px",
    },
    title: {
      marginTop: "20px",
      marginBottom: "20px",
    },
    success: {
      backgroundColor: SalvageColours.success,
    },
    error: {
      backgroundColor: SalvageColours.error,
    },
    icon: {
      fontSize: 20,
    },
    iconVariant: {
      opacity: 0.9,
      marginRight: theme.spacing(1),
    },
    message: {
      display: "flex",
      alignItems: "center",
    },
    dropTypography: {},
    dropIcon: {
      color: "#EC6E13",
      fontSize: "48px",
    },
    warning: {
      paddingTop: "40px",
      textAlign: "center",
      color: SalvageColours.error,
    },
    invalidContainer: {
      paddingTop: "32px",
    },
  })
);

const configureDisplayImageModal = makeStyles({
  card: {
    maxWidth: 300,
  },
  media: {
    height: 225,
  },
  titleStyle: {
    textAlign: "center",
  },
});

const displayImageContainer = makeStyles({
  card: {
    maxWidth: 300,
  },
  media: {
    height: 225,
  },
  primaryChip: {
    position: "absolute",
    top: "5px",
    right: "5px",
  },
  mediaContainer: {
    position: "relative",
  },
  warningIcon: {
    verticalAlign: "middle",
    display: "inline-flex",
    color: SalvageColours.error,
  },
  invalidIcon: {
    color: SalvageColours.error,
  },
  mediaDetailsNotSet: {
    verticalAlign: "middle",
    display: "inline-flex",
  },
});

const mediaManagerSnackbar = makeStyles((theme: Theme) =>
  createStyles({
    success: {
      backgroundColor: SalvageColours.success,
    },
    error: {
      backgroundColor: SalvageColours.error,
    },
    icon: {
      fontSize: 20,
      opacity: 0.9,
      marginRight: theme.spacing(1),
    },
    message: {
      display: "flex",
      alignItems: "center",
    },
  })
);

const addYouTubeVideo = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: "center",
      height: "100%",
      padding: "16px",
      cursor: "pointer",
      marginLeft: "24px",
      paddingTop: "30px",
    },
    mediaRoot: {
      marginTop: "12px",
      maxWidth: 345,
      marginLeft: "auto",
      marginRight: "auto",
    },
    media: {
      height: 0,
      paddingTop: "56.25%", // 16:9
    },
    youtubeIcon: {
      color: "#EC6E13",
      fontSize: "48px",
    },
  })
);
export { mediaManager, configureDisplayImageModal, displayImageContainer, mediaManagerSnackbar, addYouTubeVideo };
