import { makeStyles } from "@material-ui/styles";
import { Theme, createStyles } from "@material-ui/core";

export const AuctionIconStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContentText: {
      paddingTop: "8px",
    },
    cellWidth: {
      minWidth: "350px",
    },
  })
);
