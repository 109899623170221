import React from "react";
import { useEffect } from "react";
import { useSnackbar, OptionsObject } from "notistack";

import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import { IAddress, AddressDefault } from "../../../interfaces/IAddress";
import { LocationDetails } from "./LocationDetails";
import { IValidation, ValidationDefault } from "../../../interfaces/IValidation";
import { LotProperties, LotPropertiesDefault } from "../../../services/LotService";
import { useMemberSites } from "../../../services/MemberSiteService";
import { FormHelperText, CircularProgress, Typography, TextField } from "@material-ui/core";
import { bucketStatusString, canStillAddLotsToBucket, IBucket } from "../../../interfaces/buckets/IBucket";
import { IAuctionListing } from "../../../services/AuctionService";
import { AuctionStatus } from "../../../interfaces/auctions/IAuction";
import { TriStateCheckbox } from "../components/TriStateCheckbox";

interface LotPropertiesProps {
  validationState: IValidation | null;
  lotPropertiesState: LotProperties | null;
  bucketState: IBucket[];
  lotTitle: string | undefined;
  auctionListing: IAuctionListing | null;
  handleAuctionStateChange: (auctionListing: IAuctionListing | null) => void;
  handleStateChange: (lotProperties: LotProperties) => void;
}

const variantError: OptionsObject = { variant: "error" };

export const LotPropertiesDetails: React.FC<LotPropertiesProps> = ({
  validationState,
  lotPropertiesState,
  bucketState,
  lotTitle,
  auctionListing,
  handleAuctionStateChange,
  handleStateChange,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [memberSites, memberSitesLoading, ,] = useMemberSites(() => {
    enqueueSnackbar("There was a problem with your request, please try again.", variantError);
  });

  const [lotProperties, setLotProperties] = React.useState<LotProperties>(lotPropertiesState !== null ? lotPropertiesState : LotPropertiesDefault);
  const [validation, setValidation] = React.useState<IValidation>(validationState === null ? ValidationDefault : validationState);

  useEffect(() => {
    handleStateChange(lotProperties);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lotProperties]);

  useEffect(() => {
    setLotProperties({
      ...lotProperties,
      title: lotTitle ?? "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lotTitle]);

  useEffect(() => {
    setValidation(validationState === null ? ValidationDefault : validationState);
  }, [validationState]);

  const isError = (field: string): boolean => {
    return validation[field] !== undefined && validation[field].field !== "";
  };

  const ErrorMessage = (field: string): string => {
    var message = validation[field] !== undefined && validation[field].field !== "" ? validation[field].error : "";
    return message;
  };

  const onFieldChange = (e: any, errorName: string, allowDecimal: boolean = false) => {
    setValidation({
      ...validation,
      [errorName]: { field: "", error: "" },
    });

    var value: any;

    if (e.target.type === "checkbox") {
      value = e.target.checked;
    } else if (e.target.type === "number") {
      if (allowDecimal) {
        value = e.target.value === "" ? 0 : e.target.value;
      } else {
        if (e.target.value % 1 !== 0) {
          setValidation({
            ...validation,
            [errorName]: { field: e.target.name, error: "Must be a whole number." },
          });
        }
        value = e.target.value === "" ? 0 : Math.floor(e.target.value);
      }
    } else {
      value = e.target.value;
    }

    setLotProperties({
      ...lotProperties,
      [e.target.name]: value,
    });

    if (e.target.type === "text") {
      const cursor = e.target.selectionStart;
      const element = e.target;
      window.requestAnimationFrame(() => {
        element.selectionStart = cursor;
        element.selectionEnd = cursor;
      });
    }
  };

  const onTriStateChange = (propertyName: string, value: boolean | null) => {
    setLotProperties({
      ...lotProperties,
      [propertyName]: value,
    });
  };

  const onSiteSelected = (e: any) => {
    if (memberSites !== null) {
      const location = memberSites.filter(memberSite => memberSite.id === (e.target.value as string))[0];
      setValidation({
        ...validation,
        SiteId: { field: "", error: "" },
      });
      setLotProperties({
        ...lotProperties,
        siteId: location.id,
        siteName: location.siteName,
        location: location.location,
      });
    }
  };

  const onBucketSelected = (e: any) => {
    if (bucketState !== null) {
      if (e.target.value === "") {
        handleAuctionStateChange({
          ...auctionListing!,
          bucketId: "",
        });
      } else {
        const bucket = bucketState.filter(bucket => bucket.id === (e.target.value as string))[0];
        handleAuctionStateChange({
          ...auctionListing!,
          bucketId: bucket.id,
        });
      }
    }
  };

  const handleLocationChanges = (itemOffSiteLocation: IAddress) => {
    setLotProperties({
      ...lotProperties,
      location: itemOffSiteLocation,
    });
  };

  const auctionIsCreated = (auctionListing: IAuctionListing | null) => {
    if (auctionListing && auctionListing.status !== undefined) {
      switch (auctionListing.status) {
        case AuctionStatus.CreatedPartially:
        case AuctionStatus.CreatedAwaitingBucketing:
        case AuctionStatus.CreatedAwaitingPublishing: {
          return true;
        }
        case AuctionStatus.Active:
        case AuctionStatus.ActiveButSuspended:
        case AuctionStatus.AuctionEndedAndWon:
        case AuctionStatus.AuctionCollectedRecently:
        case AuctionStatus.AuctionEndedButNotWon:
        case AuctionStatus.AuctionEndedButSuspended:
        case AuctionStatus.CompletedAsLotCollected:
        case AuctionStatus.CompletedAsReListed:
        case AuctionStatus.CompletedAsArchived:
        case AuctionStatus.DeletedBeforeBucketing:
        default: {
          return false;
        }
      }
    } else {
      return true;
    }
  };

  const bucketDisabled = () => {
    return (
      !auctionIsCreated(auctionListing) ||
      auctionListing?.status === AuctionStatus.CreatedAwaitingBucketing ||
      auctionListing?.status === AuctionStatus.CreatedAwaitingPublishing
    );
  };

  const reservePrice = auctionIsCreated(auctionListing) ? lotProperties.reservePrice : auctionListing?.reservePrice;

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" color="primary" gutterBottom>
            Options
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControlLabel
            value="isFeatured"
            label="Featured Lot"
            labelPlacement="end"
            control={
              <Checkbox
                name="isFeatured"
                checked={lotProperties.isFeatured}
                onChange={value => onFieldChange(value, "IsFeatured")}
                value={lotProperties.isFeatured}
              />
            }
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControlLabel
            value="isFreeSubscription"
            label="Free Subscription"
            labelPlacement="end"
            control={
              <Checkbox
                name="isFreeSubscription"
                checked={lotProperties.isFreeSubscription}
                onChange={value => onFieldChange(value, "IsFreeSubscription")}
                value={lotProperties.isFreeSubscription}
              />
            }
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControlLabel
            value="isE2eLot"
            label="e2e Lot"
            labelPlacement="end"
            control={
              <Checkbox
                name="isE2eLot"
                checked={lotProperties.isE2eLot}
                onChange={value => onFieldChange(value, "IsE2eLot")}
                value={lotProperties.isE2eLot}
              />
            }
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TriStateCheckbox
            title={"Can be viewed"}
            appendNullTitle={"(Appointment only)"}
            checked={lotProperties.canItemBeViewed}
            callback={value => onTriStateChange("CanItemBeViewed", value)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControlLabel
            value="canItemBeDelivered"
            label="Can lot be delivered"
            labelPlacement="end"
            control={
              <Checkbox
                name="canItemBeDelivered"
                checked={lotProperties.canItemBeDelivered}
                onChange={value => onFieldChange(value, "CanItemBeDelivered")}
                value={lotProperties.canItemBeDelivered}
              />
            }
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" color="primary" gutterBottom>
            Financials
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            id="preAccidentValue"
            name="preAccidentValue"
            label="Pre Accident Value"
            type="number"
            error={isError("PreAccidentValue")}
            helperText={ErrorMessage("PreAccidentValue")}
            value={lotProperties.preAccidentValue === 0 ? "" : lotProperties.preAccidentValue}
            onChange={value => onFieldChange(value, "PreAccidentValue", true)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            id="cost"
            name="cost"
            label="Cost"
            type="number"
            error={isError("Cost")}
            helperText={ErrorMessage("Cost")}
            value={lotProperties.cost === 0 ? "" : lotProperties.cost}
            onChange={value => onFieldChange(value, "Cost", true)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            id="additionalFee"
            name="additionalFee"
            label="Additional Fee"
            type="number"
            error={isError("AdditionalFee")}
            helperText={ErrorMessage("AdditionalFee")}
            value={lotProperties.additionalFee === 0 ? "" : lotProperties.additionalFee}
            onChange={value => onFieldChange(value, "AdditionalFee")}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" color="primary" gutterBottom>
            Auction
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            error={isError("Title")}
            helperText={ErrorMessage("Title")}
            name="title"
            label="Title"
            value={lotProperties.title ?? ""}
            onChange={value => onFieldChange(value, "Title")}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            fullWidth
            id="startPrice"
            name="startPrice"
            label="Start Price"
            type="number"
            error={isError("StartPrice")}
            helperText={ErrorMessage("StartPrice")}
            value={lotProperties.startPrice === 0 ? "" : lotProperties.startPrice}
            onChange={value => onFieldChange(value, "StartPrice")}
            disabled={!auctionIsCreated(auctionListing)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            id="reservePrice"
            name="reservePrice"
            label="Reserve Price"
            type="number"
            error={isError("ReservePrice")}
            helperText={ErrorMessage("ReservePrice")}
            value={reservePrice === 0 ? "" : reservePrice}
            onChange={value => onFieldChange(value, "ReservePrice")}
            disabled={!auctionIsCreated(auctionListing)}
          />
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            id="bidIncrement"
            name="bidIncrement"
            label="Bid Increment"
            type="number"
            error={isError("BidIncrement")}
            helperText={ErrorMessage("BidIncrement")}
            value={lotProperties.bidIncrement === 0 ? "" : lotProperties.bidIncrement}
            onChange={value => onFieldChange(value, "BidIncrement")}
          />
        </Grid> */}
        <Grid item xs={12} md={6}>
          <InputLabel>Bucket</InputLabel>
          <Select
            fullWidth
            required
            value={auctionListing?.bucketId === null ? "" : auctionListing?.bucketId}
            onChange={onBucketSelected}
            disabled={bucketDisabled()}
            error={isError("BucketId")}
          >
            <MenuItem value="">No bucket</MenuItem>
            {bucketState !== null &&
              bucketState
                .filter(bucket => (canStillAddLotsToBucket(bucket) || bucket.id === auctionListing?.bucketId) ?? -1)
                .map((bucket, index) => (
                  <MenuItem key={index} value={bucket.id}>
                    {bucket.title} - {bucketStatusString(bucket.status)}
                  </MenuItem>
                ))}
          </Select>
          <FormHelperText error>{ErrorMessage("BucketId")}</FormHelperText>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" color="primary" gutterBottom>
            Location
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <InputLabel>Site</InputLabel>
          {memberSitesLoading && (
            <Typography variant="subtitle1" gutterBottom>
              <CircularProgress color="primary" size={16} thickness={8} /> Loading sites...
            </Typography>
          )}
          {!memberSitesLoading && (
            <Select
              fullWidth
              required
              value={lotProperties.siteId === null ? "" : lotProperties.siteId}
              onChange={onSiteSelected}
              disabled={memberSitesLoading}
              error={isError("SiteId")}
            >
              {memberSites !== null &&
                memberSites.map((site, index) => (
                  <MenuItem key={index} value={site.id}>
                    {site.siteName}
                  </MenuItem>
                ))}
            </Select>
          )}
          <FormHelperText error>{ErrorMessage("SiteId")}</FormHelperText>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            value="isItemNotOnSite"
            label="Lot to be collected from a different address"
            labelPlacement="end"
            control={
              <Checkbox
                name="isItemOffSite"
                checked={lotProperties.isItemOffSite}
                onChange={value => onFieldChange(value, "IsItemOffSite")}
                value={lotProperties.isItemOffSite}
              />
            }
          />
        </Grid>
        <Grid item xs={12}>
          <LocationDetails
            validationState={validation}
            isDisabled={!lotProperties.isItemOffSite}
            locationState={lotProperties.location ? lotProperties.location : AddressDefault}
            handleStateChange={handleLocationChanges}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
