import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Typography,
  CardMedia,
  Paper,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useActiveBuckets, useDraftBuckets } from "../../../services/BucketService";
import { bucketStatusString, IBucket } from "../../../interfaces/buckets/IBucket";
import { IAuctionInfo, quickAddAuctionToBucket } from "../../../services/AuctionService";
import { IAuctionMgmtFilter, useAuctionMgmtFilter } from "../../../services/AuctionLotMgmtService";
import { BulkProcessDialogStyles } from "../styles/BulkProcessDialogStyles";
import { endDateFormat, sentenceCaseTextFromCamelCase } from "../../../helpers/text-format/TextFormat";
import BrokenImageIcon from "@material-ui/icons/BrokenImage";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import PublishIcon from "@material-ui/icons/Publish";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { useHistory } from "react-router";
import { BulkProcessSkeleton } from "./BulkProcessSkeleton";
import { BulkProcessLoading } from "./BulkProcessLoading";

import pluralize from "pluralize";

interface BulkProcessDialogProps {
  open: boolean;
  setOpen: (value: React.SetStateAction<boolean>) => void;
  auctionFilters: IAuctionMgmtFilter;
  refreshSearchResults: (toPost?: IAuctionMgmtFilter | undefined) => Promise<void>;
}

const imageContainer = (auction: IAuctionInfo) => {
  if (auction.thumbnailImageUrl !== undefined) {
    return (
      <CardMedia
        style={{ maxWidth: "170px" }}
        component="img"
        alt={auction.metadata.title}
        image={auction.thumbnailImageUrl}
        title={auction.metadata.title}
      />
    );
  }
};

const allString = (valueString: string | undefined) => (valueString !== undefined && valueString !== null ? valueString : "All");

export const BulkProcessDialog: React.FC<BulkProcessDialogProps> = ({ open, setOpen, auctionFilters, refreshSearchResults }) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const classes = BulkProcessDialogStyles();
  const [allBuckets, setAllBuckets] = useState<IBucket[]>();

  const [auctionsAlreadyInBucket, setAuctionsAlreadyInBucket] = useState<IAuctionInfo[]>();
  const [auctionsForProcess, setAuctionsForProcess] = useState<IAuctionInfo[]>();
  const [failedAuctions, setFailedAuctions] = useState<IAuctionInfo[]>([]);

  const [bulkProcessDialogContent, setBulkProcessDialogContent] = useState<string>("SelectionDialog");

  const [draftBuckets, isDraftBucketsLoading] = useDraftBuckets(() => {
    enqueueSnackbar("There was a problem loading draft buckets, please try again.", { variant: "error" });
  });
  const [activeBuckets, isActiveBucketsLoading] = useActiveBuckets(() => {
    enqueueSnackbar("There was a problem loading active buckets, please try again.", { variant: "error" });
  });

  useEffect(() => {
    const buckets: IBucket[] = [];
    if (draftBuckets) buckets.push(...draftBuckets);
    if (activeBuckets) buckets.push(...activeBuckets);
    setAllBuckets(buckets);
  }, [draftBuckets, activeBuckets]);

  const [filterRequest, setFilterRequest, apiPostAsync, filterResponse, isLoading, ,] = useAuctionMgmtFilter(() =>
    enqueueSnackbar("Could not retrieve list of auctions", { variant: "error" })
  );

  const auctionFilterForBulkProcess = { ...auctionFilters, numberPerPage: 100, pageNumber: 0, auctionStatusGrouping: "Holding" };

  useEffect(() => {
    if (auctionFilters) {
      setFilterRequest(auctionFilterForBulkProcess);
      apiPostAsync(auctionFilterForBulkProcess);
    }
    setBulkProcessDialogContent("SelectionDialog");
    setFailedAuctions([]);
    setAuctionsAlreadyInBucket([]);
    setAuctionsForProcess([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (filterResponse) {
      setAuctionsAlreadyInBucket(filterResponse.pageResults.filter(a => a.bucketId !== null));
      setAuctionsForProcess(filterResponse.pageResults.filter(a => a.bucketId === null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterResponse]);

  const tryAddAuctionsToBucket = async (bucket: IBucket) => {
    if (auctionsForProcess && auctionsForProcess?.length > 0) {
      const failedProcessAuctions = auctionsForProcess.filter(a => a.bucketId !== null);

      for (const auction of auctionsForProcess) {
        try {
          await quickAddAuctionToBucket(bucket.id, auction.id);
        } catch {
          failedProcessAuctions.push(auction);
        }
      }

      failedProcessAuctions && failedProcessAuctions.length > 0
        ? setBulkProcessDialogContent("FailureDialog")
        : setBulkProcessDialogContent("SuccessDialog");

      setFailedAuctions(failedProcessAuctions);
      refreshSearchResults();
    }
  };

  const SelectionDialog = () => (
    <>
      {!isLoading && !isActiveBucketsLoading && !isDraftBucketsLoading && filterResponse !== null && filterRequest !== null && (
        <>
          <DialogContent>
            <Typography variant="body1" className={classes.dialogText}>
              Items matching your criteria will be bulk processed. Items will process in the selected order.
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow className={classes.tableRow}>
                    <TableCell>Status</TableCell>
                    <TableCell>{allString(filterRequest.auctionStatusGrouping)}</TableCell>
                  </TableRow>
                  <TableRow className={classes.tableRow}>
                    <TableCell>Location</TableCell>
                    <TableCell>{allString(filterRequest.location)}</TableCell>
                  </TableRow>
                  <TableRow className={classes.tableRow}>
                    <TableCell>Client</TableCell>
                    <TableCell>{allString(filterRequest.insuranceCompany)}</TableCell>
                  </TableRow>
                  <TableRow className={classes.tableRow}>
                    <TableCell>Sort</TableCell>
                    <TableCell>{sentenceCaseTextFromCamelCase(filterRequest.sortBy ? filterRequest.sortBy : "Default", true)}</TableCell>
                  </TableRow>
                  <TableRow className={classes.tableRow}>
                    <TableCell>Direction</TableCell>
                    <TableCell>{filterRequest.sortDescending ? "Descending" : "Ascending"}</TableCell>
                  </TableRow>
                  <TableRow className={classes.tableRow}>
                    <TableCell>Type</TableCell>
                    <TableCell>{allString(filterRequest.lotType)}</TableCell>
                  </TableRow>
                  <TableRow className={classes.tableRow}>
                    <TableCell>Body Type</TableCell>
                    <TableCell>{allString(filterRequest.bodyType)}</TableCell>
                  </TableRow>
                  <TableRow className={classes.tableRow}>
                    <TableCell>Search</TableCell>
                    <TableCell>{filterRequest.searchText ? filterRequest.searchText : "Not Set"}</TableCell>
                  </TableRow>
                  {filterResponse && (
                    <TableRow className={classes.tableRow}>
                      <TableCell>Items matching criteria</TableCell>
                      <TableCell className={filterResponse.totalNumber === 0 ? classes.warningCell : undefined}>
                        {filterResponse.totalNumber > 0 ? filterResponse.totalNumber : "No available auctions to process"}
                      </TableCell>
                    </TableRow>
                  )}
                  {filterResponse && filterResponse.totalNumber > 100 && (
                    <TableRow className={classes.tableRow}>
                      <TableCell className={classes.warningCell} colSpan={2}>
                        Maximum of 100 items will process, you have {filterResponse.totalNumber} selected
                      </TableCell>
                    </TableRow>
                  )}
                  {auctionsAlreadyInBucket && auctionsAlreadyInBucket?.length > 0 && (
                    <TableRow className={classes.tableRow}>
                      <TableCell className={classes.warningCell} colSpan={2}>
                        {pluralize("auction", auctionsAlreadyInBucket?.length, true)} matching the criteria{" "}
                        {pluralize("are", auctionsAlreadyInBucket?.length)} already in buckets and {pluralize("is", auctionsAlreadyInBucket?.length)}{" "}
                        unavailable to be processed.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Typography variant="h6" className={classes.dialogText}>
              Select bucket to begin process
            </Typography>
            <Typography variant="body1" className={classes.dialogText}>
              For items to process they must meet the following conditions. The auctions status must be "Holding", the auction must not belong to any
              other buckets, if the item fails to meet these conditions it will fail to process.
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  {allBuckets &&
                    allBuckets.length > 0 &&
                    allBuckets.map((bucket, index) => (
                      <TableRow key={index} className={classes.tableRow}>
                        <TableCell>{bucket.title}</TableCell>
                        <TableCell>{endDateFormat(bucket.scheduledDate, "")}</TableCell>
                        <TableCell> {bucketStatusString(bucket.status)}</TableCell>
                        <TableCell>
                          <Button
                            color="primary"
                            variant="contained"
                            aria-label="Start bulk process"
                            className={classes.searchButton}
                            onClick={() => {
                              tryAddAuctionsToBucket(bucket);
                              setBulkProcessDialogContent("Loading");
                            }}
                            disabled={auctionsForProcess && (auctionsForProcess.length === 0 || auctionsForProcess.length > 100)}
                          >
                            Bulk Process
                            <PublishIcon className={classes.bulkProcessIcon} />
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  {allBuckets === undefined && (
                    <TableRow className={classes.tableRow}>
                      <TableCell colSpan={3}> No available buckets </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
          </DialogActions>
        </>
      )}
    </>
  );

  const SuccessDialog = () => (
    <>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="h6">Auctions processed to bucket succesfully</Typography>
        <CheckCircleOutlineIcon className={classes.outcomeIcons} color="primary" />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            history.push("/Buckets");
          }}
        >
          Bucket Management
        </Button>
        <Button variant="contained" color="primary" onClick={() => setOpen(false)}>
          Close
        </Button>
      </DialogActions>
    </>
  );

  const FailureDialog = () => (
    <>
      <DialogContent className={classes.dialogContent}>
        <HighlightOffIcon className={classes.outcomeIcons} color="primary" />
        <Typography variant="h6" className={classes.dialogText}>
          The following {pluralize("auction", failedAuctions?.length)} failed to process to the bucket
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell}></TableCell>
                <TableCell className={classes.tableCell}>Item</TableCell>
                <TableCell className={classes.tableCell}>Ref</TableCell>
                <TableCell className={classes.tableCell}>Bucket name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {failedAuctions.map((auction, index) => (
                <TableRow key={index} className={classes.tableRow}>
                  <TableCell className={classes.tableCell}>
                    {!auction.thumbnailImageUrl ? (
                      <div className={classes.brokenImageIcon}>
                        <BrokenImageIcon color={"secondary"} />
                      </div>
                    ) : (
                      imageContainer(auction)
                    )}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography variant="subtitle2" className={classes.title}>
                      {auction.metadata.title}
                    </Typography>
                    <Typography variant="body2">{auction.metadata.registrationNumber}</Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>{auction.metadata.referenceNumber}</TableCell>
                  <TableCell className={classes.tableCell}>{auction.bucketTitle ? auction.bucketTitle : "No bucket set"}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            history.push("/Buckets");
          }}
        >
          Bucket Management
        </Button>
        <Button variant="contained" color="primary" onClick={() => setOpen(false)}>
          Close
        </Button>
      </DialogActions>
    </>
  );

  const BulkProcessDialogContent = () => {
    switch (bulkProcessDialogContent) {
      case "SelectionDialog":
        return SelectionDialog();
      case "SuccessDialog":
        return SuccessDialog();
      case "FailureDialog":
        return FailureDialog();
      case "Loading":
        return BulkProcessLoading();
      default:
        return null;
    }
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth={"md"} fullWidth disableBackdropClick disableEscapeKeyDown>
      <DialogTitle>Bulk Process</DialogTitle>
      {(isLoading || isActiveBucketsLoading || isDraftBucketsLoading) && BulkProcessSkeleton()}
      {BulkProcessDialogContent()}
    </Dialog>
  );
};
