import { Grid, Typography } from "@material-ui/core";
import React from "react";

export function NotLoggedIn() {
  return (
    <Grid item>
      <Typography variant="h5" color="primary" gutterBottom>
        Not Logged In
      </Typography>
      <Typography variant="subtitle1" color="textPrimary" gutterBottom>
        Please click login to access the platform.
      </Typography>
    </Grid>
  );
}
