import * as React from "react";
import { AppBar, Tabs, Tab, Paper, Typography } from "@material-ui/core";
import { IBidder } from "../../interfaces/bidders/IBidder";
import { TabPanel } from "../../helpers/TabPanel";
import { BidderNotes, NotesType } from "./BidderNotes";
import { AddNote } from "./AddNote";
import { BidderDetailsTab } from "./BidderDetailsTab";
import { SubscriptionTab } from "./SubscriptionTab";
import { BidderBidsTab } from "./BidderBidsTab";

import moment from "moment";
import { BidderStatusIcon } from "../../enums/BidderStatus";
import { LoadingComponent } from "../loading/LoadingComponent";
import { BidderWatchingTab } from "./BidderWatchingTab";
import { BidderNoteFilterType } from "../../enums/BidderNoteFilterType";
interface IProps {
  bidder: IBidder;
  setBidder: React.Dispatch<React.SetStateAction<IBidder>>;
}

export const BidderDetailsChangeHandler: React.FC<IProps> = ({ bidder, setBidder }) => {
  const [openTab, setOpenTab] = React.useState(0);
  const [reloadNotes, setReloadNotes] = React.useState(false);
  const [defaultFilter, setDefaultFilter] = React.useState<BidderNoteFilterType | null>(null);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setDefaultFilter(null);
    setOpenTab(newValue);
  };

  const delay = ms => new Promise(res => setTimeout(res, ms));

  const lastLoginDate = moment(bidder.lastLogin);
  const noteAdded = async () => {
    setReloadNotes(true);
    await delay(1000);
    setReloadNotes(false);
  };

  const showSuspensionHistory = () => {
    setDefaultFilter(BidderNoteFilterType.AccountSuspensions);
    setOpenTab(3);
  };

  

  const calendarStrings = {
    lastDay: "[Yesterday at] h:mm:ss A",
    sameDay: "[Today at] h:mm:ss A",
    nextDay: "[Tomorrow at] h:mm:ss A",
    lastWeek: "[Last] dddd [at] h:mm:ss A",
    nextWeek: "dddd [at] h:mm:ss A",
    sameElse: "DD/MM/YYYY",
  };

  return (
    <>
      <Typography variant="h4" color="primary">
        {bidder.firstName} {bidder.lastName} - {bidder.email} {BidderStatusIcon(bidder.bidderStatus)}
      </Typography>
      <Typography variant="subtitle1">
        {lastLoginDate.year() === 0 ? (
          "This user has never Logged In"
        ) : (
          <>Last seen {lastLoginDate.calendar(calendarStrings).toLocaleLowerCase() + " (" + lastLoginDate.fromNow() + ")"}</>
        )}
      </Typography>
      <Paper>
        <AppBar position="static">
          <Tabs value={openTab} onChange={handleChange} indicatorColor="primary" textColor="primary" centered>
            <Tab label="Bidder Details" />
            <Tab label="Subscriptions" />
            <Tab label="Site Activity" />
            <Tab label="Notes/Communication" />
            <Tab label="Bid History" />
            <Tab label="Watch History" />
          </Tabs>
        </AppBar>

        <TabPanel value={openTab} index={0}>
          <BidderDetailsTab bidder={bidder} setBidder={setBidder} showSuspensionHistory={showSuspensionHistory} />
        </TabPanel>
        <TabPanel value={openTab} index={1}>
          <SubscriptionTab bidder={bidder} setBidder={setBidder} />
        </TabPanel>
        <TabPanel value={openTab} index={2}>
          <BidderNotes bidder={bidder} noteType={NotesType.Site} />
        </TabPanel>
        <TabPanel value={openTab} index={3}>
          <AddNote bidder={bidder} noteAdded={noteAdded}></AddNote>
          {!reloadNotes && <BidderNotes bidder={bidder} noteType={NotesType.Communication} defaultFilter={defaultFilter} />}
          {reloadNotes && <LoadingComponent label="Loading Notes"></LoadingComponent>}
        </TabPanel>
        <TabPanel value={openTab} index={4}>
          <BidderBidsTab bidder={bidder} />
        </TabPanel>
        <TabPanel value={openTab} index={5}>
          <BidderWatchingTab bidder={bidder} />
        </TabPanel>
      </Paper>
    </>
  );
};
