import React from "react";
import { useSnackbar } from "notistack";
import { MemberSite, OpeningDays, useMember } from "../../services/MemberService";
import { Divider, Grid, Link, Typography, Button } from "@material-ui/core";
import { openingTimes } from "../../helpers/text-format/TextFormat";
import { LoadingComponent } from "../loading/LoadingComponent";
import * as Styles from "./styles/MemberDetailsStyles";
import { NotificationPreferencesContainer } from "./NotificationPreferences";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import { MemberPermission } from "../../services/MemberService";
import { AppContext } from "../../contexts/AppContext";

export const MemberDetailsContainer: React.FC = () => {
  const classes = Styles.MemberDetailsStyles();
  const context = React.useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();

  const hasPermission = (memberPermission: MemberPermission) => {
    if (!context) {
      return false;
    }

    if (context.memberPermissions >= memberPermission) {
      return true;
    }

    return false;
  };

  const [member, , memberLoading] = useMember(() => {
    enqueueSnackbar("There was a problem with your member request, please try again.", { variant: "error" });
  });

  const [openNotificationPreferences, setOpenNotificationPreferences] = React.useState<boolean>(false);
  const [seller, setSeller] = React.useState<MemberSite>();
  const [sites, setSites] = React.useState<MemberSite[]>();

  React.useEffect(() => {
    const sellerSites = member?.sites.filter(site => {
      return site.isMainSite;
    });

    const siteArray = member?.sites.filter(site => {
      return !site.isMainSite;
    });

    if (sellerSites) {
      setSeller(sellerSites[0]);
    }

    if (siteArray) {
      setSites(siteArray);
    }
  }, [member]);

  const openingTimesComponent = (openingDays: OpeningDays) => {
    if (openingDays) {
      return (
        <Grid container>
          <Grid item xs={6}>
            <Typography align="left">Monday</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align="left">{openingTimes(openingDays.monday)}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align="left">Tuesday</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align="left">{openingTimes(openingDays.tuesday)}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align="left">Wednesday</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align="left">{openingTimes(openingDays.wednesday)}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align="left">Thursday</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align="left">{openingTimes(openingDays.thursday)}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align="left">Friday</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align="left">{openingTimes(openingDays.friday)}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align="left">Saturday</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align="left">{openingTimes(openingDays.saturday)}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align="left">Sunday</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align="left">{openingTimes(openingDays.sunday)}</Typography>
          </Grid>
        </Grid>
      );
    }
  };
  return (
    <>
      {memberLoading && <LoadingComponent label="Loading Memmber" />}
      {!memberLoading && member != null && (
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Typography className={classes.containerTitle} variant="h5" align="left">
              {member?.name}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography align="left"><b>Site Name:</b> {seller?.siteName}</Typography>
            <Typography align="left">{seller?.location.line1}</Typography>
            <Typography align="left">{seller?.location.line2}</Typography>
            <Typography align="left">{seller?.location.line3}</Typography>
            <Typography align="left">{seller?.location.city}</Typography>
            <Typography align="left">{seller?.location.county}</Typography>
            <Typography align="left">{seller?.location.postcode}</Typography>
            <Typography align="left">{<Link href={`tel:${seller?.sitePhoneNumber}`}>{seller?.sitePhoneNumber ?? ""}</Link>}</Typography>
            <Typography align="left">{<Link href={`mailto:${seller?.siteEmail}`}>{seller?.siteEmail ?? ""}</Link>}</Typography>
          </Grid>

          <Grid item xs={2}>
            <Typography align="left">Phone </Typography>
            <Tooltip title="Email which auction site users will contact you on for help and support">
              <Typography align="left">
                Support Email <InfoIcon color="primary" fontSize="small" className={classes.infoIcon} />
              </Typography>
            </Tooltip>
            <Tooltip title="Email which new bidder registration alerts will be sent to for you to review">
              <Typography align="left">
                Admin Email <InfoIcon color="primary" fontSize="small" className={classes.infoIcon} />
              </Typography>
            </Tooltip>
          </Grid>

          <Grid item xs={6}>
            <Typography align="left">{<Link href={`tel:${member?.phoneNumber}`}>{member?.phoneNumber}</Link>}</Typography>
            <Typography align="left"> {<Link href={`mailto:${member?.email}`}>{member?.email}</Link>}</Typography>
            <Typography align="left"> {<Link href={`mailto:${member?.adminEmail}`}>{member?.adminEmail}</Link>}</Typography>
          </Grid>

          {hasPermission(MemberPermission.FullMember) && (
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setOpenNotificationPreferences(!openNotificationPreferences);
                }}
              >
                {openNotificationPreferences ? "Hide Notification Preferences" : "Manage Notifications"}
              </Button>
              {openNotificationPreferences && <NotificationPreferencesContainer />}
            </Grid>
          )}

          {sites && sites.length > 0 && (
            <Grid item xs={12} md={12}>
              <Typography className={classes.containerTitle} variant="h5" align="left">
                Sites
              </Typography>
            </Grid>
          )}

          {sites?.map((site, index) => (
            <React.Fragment key={index}>
              <Grid item xs={12} md={12}>
                <Divider />
                <br />
                <Typography align="left"><b>Site Name:</b> {site?.siteName}</Typography>
                <Typography align="left">{site?.location.line1}</Typography>
                <Typography align="left">{site?.location.line2}</Typography>
                <Typography align="left">{site?.location.line3}</Typography>
                <Typography align="left">{site?.location.city}</Typography>
                <Typography align="left">{site?.location.county}</Typography>
                <Typography align="left">{site?.location.postcode}</Typography>
                <Typography align="left">{<Link href={`tel:${site?.sitePhoneNumber}`}>{site?.sitePhoneNumber ?? ""}</Link>}</Typography>
                <Typography align="left">{<Link href={`mailto:${site?.siteEmail}`}>{site?.siteEmail ?? ""}</Link>}</Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography className={classes.containerTitle} align="left">
                  Opening times
                </Typography>
                {site?.openingDays !== undefined && openingTimesComponent(site?.openingDays)}
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography className={classes.containerTitle} align="left">
                  Collection times
                </Typography>
                {site?.collectionDays !== undefined && openingTimesComponent(site?.collectionDays)}
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      )}
    </>
  );
};
