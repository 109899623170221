import { ApiService } from "./ApiService";
import { GetClientSettings } from "./SettingsService";
import { ISalvageClientSettings } from "../interfaces/ISalvageClientSettings";
import { useReadonlyData } from "./useVersionedData";
import { IAddress } from "../interfaces/IAddress";
const ClientSettings: ISalvageClientSettings = GetClientSettings();

export interface Member {
  id: string;
  name: string;
  phoneNumber: string;
  email: string;
  adminEmail: string;
  websiteUrl: string;
  sites: MemberSite[];
}

export interface MemberSite {
  id: string;
  isMainSite: boolean;
  siteName: string;
  sitePhoneNumber: string;
  siteEmail: string;
  location: IAddress;
  openingDays: OpeningDays;
  collectionDays: OpeningDays;
}

export interface OpeningDays {
  monday: OpeningTime;
  tuesday: OpeningTime;
  wednesday: OpeningTime;
  thursday: OpeningTime;
  friday: OpeningTime;
  saturday: OpeningTime;
  sunday: OpeningTime;
}

export interface OpeningTime {
  openingTimeStatus: OpeningTimesStatus;
  open: string;
  close: string;
}

export enum OpeningTimesStatus {
  Unknown = 0,
  Closed = 1,
  Open = 2,
  PleaseCall = 3,
}

export enum MemberPermission {
  None = 0,
  AccountAdmin = 1,
  BucketAdmin = 2,
  LiveAuctionAdmin = 3,
  FullMember = 4,
  SuperAdmin = 5,
}

export interface MyMemberDetailsDto {
  userId: string;
  memberId: string;
  memberPermissions: MemberPermission;
  name: string;
  isE2eMember: boolean;
}

export const useMember = (onError?: (httpStatusCode: number, resultBody?: string) => void) =>
  useReadonlyData<Member>(`${ClientSettings.UsersPrivateApiUrl + "/member/"}`, onError);

export const useMyMember = (isLoggedIn: boolean, onError?: (httpStatusCode: number, resultBody?: string) => void) =>
  useReadonlyData<MyMemberDetailsDto>(isLoggedIn ? `${ClientSettings.UsersPrivateApiUrl}/members/me` : null, onError);

export async function GetAllMembers() {
  return ApiService().Get<Array<Member>>(ClientSettings.UsersPrivateApiUrl + "/members/");
}

export async function GetMembersInsuranceCompanies() {
  const insuranceCompanies = ApiService().Get<Array<string>>(ClientSettings.UsersPrivateApiUrl + "/member/insurance-companies/");
  return insuranceCompanies;
}

export const useInsuranceCompanies = (onError: (httpStatusCode: number, resultBody?: string) => void) =>
  useReadonlyData<string[]>(`${ClientSettings.UsersPrivateApiUrl}/member/insurance-companies/`, onError);

export async function DeleteMembersInsuranceCompanies(item: string) {
  const insuranceCompanies = ApiService().Delete<string>(ClientSettings.UsersPrivateApiUrl + "/member/insurance-companies/" + item, "");
  return insuranceCompanies;
}

export async function AddMembersInsuranceCompanies(item: string) {
  const insuranceCompanies = ApiService().Put<string>(ClientSettings.UsersPrivateApiUrl + "/member/insurance-companies/" + item, "");
  return insuranceCompanies;
}
