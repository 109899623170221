import * as React from "react";
import { AppBar, Tabs, Tab, Paper, Fab, Grid, Typography } from "@material-ui/core";
import { TabPanel } from "../../helpers/TabPanel";
import { LotContainer } from "./lots/LotContainer";
import { AuctionsContainer } from "./auctions/AuctionsContainer";
import { useHistory } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import { CreateBucket } from "../buckets/create-bucket/CreateBucket";

export const AuctionManagementContainer: React.FC<{ tab: number }> = ({ tab }) => {
  const history = useHistory();
  const [openTab, setOpenTab] = React.useState(tab);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setOpenTab(newValue);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid xs={12} item style={{ textAlign: "end" }}>
          <CreateBucket onNewBucketAdded={() => {}} />
          <Fab
            color="primary"
            aria-label="add"
            size="medium"
            variant="extended"
            onClick={() => history.push(`/CreateLot`)}
            style={{ marginLeft: "8px" }}
          >
            <AddIcon />
            <Typography variant="button" display="inline">
              Create Lot
            </Typography>
          </Fab>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <AppBar position="static">
              <Tabs value={openTab} onChange={handleChange} indicatorColor="primary" textColor="primary" centered>
                <Tab label="Auctions" />
                <Tab label="Incomplete Lots" />
              </Tabs>
            </AppBar>
            <TabPanel value={openTab} index={0}>
              <AuctionsContainer />
            </TabPanel>
            <TabPanel value={openTab} index={1}>
              <LotContainer />
            </TabPanel>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
