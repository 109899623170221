import React from "react";
import Pages from "./routes/Pages";
import { Router, Route } from "react-router";
import history from "./history";
import { BrowserRouter } from "react-router-dom";
import { MsalProvider } from "./authentication/MsalProvider";
import { MsalClient } from "./authentication/MsalContext";
import { useHandleRedirect } from "./authentication/useHandleRedirect";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./services/TelemetryService";
import { HubProvider } from "./hooks/HubProvider";

const App: React.FC = () => {
  useHandleRedirect();

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <MsalProvider instance={MsalClient}>
        <HubProvider>
          <Router history={history}>
            <BrowserRouter>
              <Route component={Pages} />
            </BrowserRouter>
          </Router>
        </HubProvider>
      </MsalProvider>
    </AppInsightsContext.Provider>
  );
};

export default App;
