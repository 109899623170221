import React, { useRef } from "react";
import { useDrag, useDrop, DropTargetMonitor } from "react-dnd";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import { FeaturedDragItemTypes } from "./FeaturedDragItemTypes";
import { XYCoord } from "dnd-core";
import { Grid } from "@material-ui/core";
import * as Styles from "./styles/FeaturedDragItemStyles";

export interface FeaturedDragItemProps {
  id: any;
  index: number;
  auctionTitle: string;
  auctionImage: string;
  moveFeaturedAuction: (dragIndex: number, hoverIndex: number) => void;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export const FeaturedDragItem: React.FC<FeaturedDragItemProps> = ({ id, index, auctionTitle, auctionImage, moveFeaturedAuction }) => {
  const classes = Styles.FeaturedDragItem();

  const ref = useRef<HTMLDivElement>(null);
  const [, drop] = useDrop({
    accept: FeaturedDragItemTypes.CARD,
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveFeaturedAuction(dragIndex, hoverIndex);

      // Time to set the hover index to current items
      item.index = hoverIndex;
    },
  });

  const [, drag] = useDrag({
    item: { type: FeaturedDragItemTypes.CARD, id, index },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));
  return (
    <div ref={ref} className={classes.auctionDragItemWrapper}>
      <Grid container>
        <Grid item xs={1}>
          <div
            className={classes.auctionImage}
            style={{
              backgroundImage: `url("${auctionImage}")`,
              backgroundSize: "cover",
            }}
          ></div>
        </Grid>
        <Grid item xs={10} className={classes.auctionTitle}>
          {auctionTitle}
        </Grid>
        <Grid item xs={1} className={classes.auctionDragHandleWrapper}>
          <div className={classes.auctionDragItem}>
            <DragHandleIcon />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default FeaturedDragItem;
