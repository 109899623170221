import { makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";

export const LiveBidItemDialogStyles = makeStyles(theme => ({
  inputRoot: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  inputButton: {
    padding: "2px 4px",
    display: "inline-block",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  tableRow: {
    "&:nth-of-type(odd)": {
      backgroundColor: grey[300],
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 0,
  },
  tableRowPrimary: {
    background: theme.palette.grey[50],
  },
  tableRowSecondary: {},
  box: {
    position: "absolute",
    width: "50%",
    height: "50%",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: `translate(-50%, -50%)`,
  },
  list: {
    marginTop: "8px",
    maxHeight: 350,
    overflow: "auto",
  },
  purpleChip: {
    backgroundColor: "rgb(128, 0, 128, 0.6)",
    color: "white",
    marginBottom: "4px",
  },
  dialogHeader: {
    backgroundColor: "#474747",
    color: "white",
  },
  padding8: { padding: "8px" },
  redChip: {
    fontSize: "10px",
    backgroundColor: "rgba(255, 0, 0, 0.6)",
    color: "white",
    marginLeft: "2px",
    borderRadius: "4px",
  },
  yellowChip: {
    fontSize: "10px",
    backgroundColor: "rgb(255, 255, 0, 0.6)",
    color: "black",
    marginLeft: "2px",
    borderRadius: "4px",
  },
  defaultChip: {
    marginLeft: "2px",
    borderRadius: "4px",
  },
  cancelIcon: {
    paddingLeft: "8px",
    verticalAlign: "text-bottom",
  },
}));
