import { IEntityBase } from "../IEntityBase";
import { IBucketItem } from "./IBucketItem";
import { IBucketOwner } from "./IBucketOwner";
import moment, { Moment } from "moment";

export enum BucketStatus {
  Undefined = 0,
  Unpublished = 100,
  Published = 200,
  Closed = 300,
  Deleted = 400,
}

export interface IBucket extends IEntityBase {
  status: BucketStatus;
  scheduledDate: string;
  bucketItems: Array<IBucketItem>;
  bucketOwner: IBucketOwner;
  auctionSeparationInSeconds: number;
  title: string;
  anyActiveAuctions: boolean;
  anySuspendedAuctions: boolean;
}

export const BucketDefault: IBucket = {
  id: "",
  status: BucketStatus.Undefined,
  scheduledDate: moment()
    .add(7, "days")
    .format("YYYY-MM-DD[T]HH:mm:00Z"),
  bucketItems: [] as IBucketItem[],
  bucketOwner: { memberId: "00000000-0000-0000-0000-000000000000", memberName: "TEST" } as IBucketOwner,
  auctionSeparationInSeconds: 30,
  title: "",
  anyActiveAuctions: false,
  anySuspendedAuctions: false,
  _etag: "00000000-0000-0000-0000-000000000000",
};

export const bucketStatusString = (bucketStatus: BucketStatus) => {
  switch (bucketStatus) {
    case BucketStatus.Unpublished: {
      return "Unpublished";
    }
    case BucketStatus.Published: {
      return "Published";
    }
    case BucketStatus.Closed: {
      return "Closed";
    }
    default: {
      return "Unknown";
    }
  }
};
export const canStillAddLotsToBucket = (bucket: IBucket): boolean => {
  return canStillAddLotsToBucketWithStartDate(bucket.scheduledDate);
};
export const canStillAddLotsToBucketWithStartDate = (startDate: string): boolean => {
  return isValidDateForBucket(moment(startDate));
};

export const isValidDateForBucket = (startDate: Moment): boolean => {
  // Verify that the start date is past 8:30 and that the date is 24 hours in the future
  return isAfter830(startDate) && is24HoursInFuture(startDate);
};

export const publishBucket = async (bucket: IBucket, errorFunction: (message: string) => void, successFunction: () => Promise<void>) => {
  const bucketDate = moment(bucket.scheduledDate);
  if (!is24HoursInFuture(bucketDate)) {
    errorFunction("Bucket date must be at least 24 hours in the future");
  } else if (!isAfter830(bucketDate)) {
    errorFunction("Bucket schedule time must be after 8.30am");
  } else {
    await successFunction();
  }
};

export const isAfter830 = (date: Moment): boolean => {
  return date.hour() * 60 + date.minute() >= 510;
};

export const is24HoursInFuture = (date: Moment): boolean => {
  return date.diff(moment(), "hours") >= 24;
};
