import * as React from "react";
import { useHistory } from "react-router-dom";
import { Button, Checkbox, CssBaseline, FormControlLabel, Grid, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import { useSnackbar, OptionsObject } from "notistack";
import { LoadingComponent } from "../loading/LoadingComponent";
import * as GlobalAlertService from "../../services/GlobalAlertService";
import { IGlobalAlert, GlobalAlertDefault } from "../../interfaces/global-alerts/IGlobalAlert";
import { IValidation, ValidationDefault, ValidationErrorDefault } from "../../interfaces/IValidation";
import * as Styles from "./styles/AlertFormComponentStyles";
import moment from "moment";
import { alertTypeDescription, allAlertType } from "../../interfaces/global-alerts/AlertType";

const variantError: OptionsObject = { variant: "error" };
const variantSuccess: OptionsObject = { variant: "success" };

interface AlertFormProps {
    id: string | null;
}

export const AlertFormComponent: React.FC<AlertFormProps> = ({ id }) => {
    const history = useHistory();
    const classes = Styles.AlertFormComponentStyles();
    const { enqueueSnackbar } = useSnackbar();

    var apiError: boolean = false;

    const [loading, setLoading] = React.useState<boolean>(true);
    const [publishing, setPublishing] = React.useState(false);
    const [toLoad, setToLoad] = React.useState<number>(1);
    const [loaded, setLoaded] = React.useState<number>(0);
    const [alertDetails, setAlertDetails] = React.useState<IGlobalAlert>(GlobalAlertDefault);

    const [validation, setValidation] = React.useState<IValidation>(ValidationDefault);
    const [selectedPrice, setSelectedPrice] = React.useState<number>(-1);

    const incrementLoad = () => {
        let newTotal = loaded + 1

        setLoaded(newTotal);

        if (newTotal >= toLoad) {
            setLoading(false);
        }
    }

    React.useEffect(() => {

        if (id !== undefined && id != null && id.length > 0) {
            GlobalAlertService.GetById(id)
                .then(productResult => {
                    if (productResult.parsedBody !== undefined) {
                        setAlertDetails(productResult.parsedBody);
                        incrementLoad();
                    }
                })
                .catch(async response => {
                    console.log("error searching:" + response);
                });

        } else {
            incrementLoad();
        }

    }, []);

    const onFieldChange = (e: any, errorName: string, capitalTextField: boolean = false) => {
        var value: any;

        if (e.target.type === "checkbox") {
            value = e.target.checked;
        } else if (e.target.type === "number") {
            value = e.target.value === "" ? 0 : Math.floor(e.target.value);
        } else if (capitalTextField) {
            value = e.target.value.toUpperCase();
        } else {
            value = e.target.value;
        }

        setAlertDetails({
            ...alertDetails,
            [e.target.name]: value,
        });
        if (e.target.type === "text") {
            const cursor = e.target.selectionStart;
            const element = e.target;
            window.requestAnimationFrame(() => {
                element.selectionStart = cursor;
                element.selectionEnd = cursor;
            });
        }
    };

    const dateFieldChange = (value: string, name: string) => {
        setAlertDetails({
            ...alertDetails,
            [name]: value,
        });
    };

    const handleValidation = (validationObj) => {
        setValidation(ValidationDefault);

        let list: IValidation = {};
        Object.entries(validationObj)
            .forEach(([key, value]) => {
                let lowercasedName = (value as any).field.charAt(0).toLowerCase() + (value as any).field.slice(1);
                list[lowercasedName] = { field: (value as any).field, error: (value as any).error };
                setValidation(list);
            });
    };

    const isError = (field: string): boolean => {
        let check = validation[field] !== undefined && validation[field].field !== "";
        return check;
    };

    const ErrorMessage = (field: string): string => {
        return validation[field] !== undefined && validation[field].field !== "" ? validation[field].error : "";
    };

    const handleBack = () => {
        history.push(`/Alerts`);
    };

    const save = () => {
        let valid = true;

        if (valid) {
            setPublishing(true);

            GlobalAlertService.CheckForConflict(alertDetails)
                .then(conflictresult => {
                    if (conflictresult.parsedBody == false) {

                        if (alertDetails.id !== undefined && alertDetails.id != null && alertDetails.id.length > 0) {
                            GlobalAlertService.Update(alertDetails, alertDetails.id)
                                .then(result => {
                                    enqueueSnackbar("Saved Successfully.", variantSuccess);
                                    history.push(`/Alerts`);
                                })
                                .catch(async response => {
                                    if (response.status == 400) {
                                        handleValidation(response.validation);
                                        apiError = true;
                                    }
                                    enqueueSnackbar("Could not update alert, please try again.", variantError);
                                    setPublishing(false);
                                });
                        } else {
                            GlobalAlertService.Create(alertDetails)
                                .then(result => {
                                    console.log(result);
                                    enqueueSnackbar("Saved Successfully.", variantSuccess);
                                    history.push(`/Alerts`);
                                })
                                .catch(async response => {
                                    if (response.status == 400) {
                                        handleValidation(response.validation);
                                        apiError = true;
                                    }

                                    enqueueSnackbar("Could not create alert, please try again.", variantError);
                                    setPublishing(false);
                                });
                        }
                    } else {
                        setPublishing(false);
                        enqueueSnackbar("Could not create alert, the dates selected conflict with another date.", variantError);
                    }
                })
                .catch(async response => {
                    enqueueSnackbar("Could not create alert, please try again.", variantError);
                    setPublishing(false);
                });

        }

    };





    return (
        <>
            <CssBaseline />
            {loading ? (
                <LoadingComponent label="Loading" />
            ) : (
                <div>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                required
                                fullWidth
                                error={isError("description")}
                                helperText={ErrorMessage("description")}
                                name="description"
                                label="Description"
                                value={alertDetails.description === null ? "" : alertDetails.description}
                                onChange={value => {
                                    onFieldChange(value, "description", false);
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <InputLabel htmlFor="alertType">Alert Type</InputLabel>
                            <Select
                                required
                                fullWidth
                                id="alertType"
                                name="alertType"
                                error={isError("alertType")}
                                value={alertDetails.alertType === null ? "" : alertDetails.alertType}
                                onChange={value => onFieldChange(value, "alertType")}
                            >
                                {allAlertType.map(status => (
                                    <MenuItem key={status} value={status}>
                                        {alertTypeDescription(status)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item xs={12} sm={12}></Grid>

                        <Grid item xs={12} sm={6}>
                            <InputLabel htmlFor="startDate">Start Date and Time</InputLabel>
                            <TextField
                                required
                                fullWidth
                                id="startDate"
                                name="startDate"
                                type="datetime-local"
                                margin="normal"
                                variant="outlined"
                                error={isError("startDate")}
                                helperText={ErrorMessage("startDate")}
                                value={alertDetails.startDate === null ? "" : moment(alertDetails.startDate).format("YYYY-MM-DD[T]HH:mm")}
                                onChange={e => {
                                    const dateMoment = moment(e.target.value);
                                    dateFieldChange(dateMoment.format(), "startDate");
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <InputLabel htmlFor="endDate">End Date and Time</InputLabel>
                            <TextField
                                required
                                fullWidth
                                id="endDate"
                                name="endDate"
                                type="datetime-local"
                                margin="normal"
                                variant="outlined"
                                error={isError("endDate")}
                                helperText={ErrorMessage("endDate")}
                                value={alertDetails.endDate === null ? "" : moment(alertDetails.endDate).format("YYYY-MM-DD[T]HH:mm")}
                                onChange={e => {
                                    const dateMoment = moment(e.target.value);
                                    dateFieldChange(dateMoment.format(), "endDate");
                                }}
                            />
                        </Grid>



                    </Grid>

                    <div className={classes.buttons}>
                        <Button variant="contained" className={classes.button} onClick={handleBack}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="primary" className={classes.button} onClick={save} disabled={publishing}>
                            Confirm
                        </Button>
                    </div>

                </div>

            )}
        </>
    );
};