import React from "react";
import { Button, Grid, Dialog, DialogTitle, DialogContent, DialogActions, Box, DialogContentText, Paper } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { endAuctionManually, suspendAuction, useAuctionBidHistory } from "../../services/AuctionService";
import * as Styles from "./styles/LiveBidItemDialogStyles";
import { DialogViews } from "./DialogViews";
import { DialogBids } from "./DialogBids";
import { DialogWatches } from "./DialogWatches";
import { DialogReserveUpdate } from "./DialogReserveUpdate";
import { DialogAuctionHistory } from "./DialogAuctionHistory";
import { LoadingComponent } from "../loading/LoadingComponent";
import { DialogBuyback } from "./DialogBuyback";

interface LiveBidDialogProps {
  handleClose: () => void;
  auctionId: string;
  previousAuctionId: string;
  title: string;
  showProxyBids: boolean;
}
export const LiveBidDialog: React.FC<LiveBidDialogProps> = ({ handleClose, auctionId, previousAuctionId, title, showProxyBids }) => {
  const classes = Styles.LiveBidItemDialogStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [suspendLoading, setSuspendLoading] = React.useState(false);
  const [endLoading, setEndLoading] = React.useState(false);
  const [reload, setReload] = React.useState(false);
  const [openSuspendDialog, setOpenSuspendDialog] = React.useState(false);
  const [openEndDialog, setOpenEndDialog] = React.useState(false);
  const [bids, bidsLoading, , refreshBids] = useAuctionBidHistory(auctionId);

  const handleOpenSuspendedAuctionDialog = () => {
    setOpenSuspendDialog(true);
  };

  const handleCloseSuspendedAuctionDialog = () => {
    setOpenSuspendDialog(false);
  };

  const handleSuspendedAuction = () => {
    handleCloseSuspendedAuctionDialog();
    setSuspendLoading(true);
    suspendAuction(auctionId)
      .then(() => {
        enqueueSnackbar("Auction has been suspended.", { variant: "success" });
      })
      .catch(() => {
        enqueueSnackbar("We could not suspend this auction, please try again.", { variant: "error" });
      })
      .finally(() => {
        handleClose();
      });
  };

  const SuspendDialog = () => {
    return (
      <Dialog open={openSuspendDialog} onClose={handleCloseSuspendedAuctionDialog}>
        <DialogTitle>{"Confirm Auction Suspension?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to suspend this auction?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSuspendedAuctionDialog}>Cancel</Button>
          <Button onClick={() => handleSuspendedAuction()} variant="contained" color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleOpenEndAuctionDialog = () => {
    setOpenEndDialog(true);
  };

  const handleCloseEndAuctionDialog = () => {
    setOpenEndDialog(false);
  };

  const EndDialog = () => {
    return (
      <Dialog open={openEndDialog} onClose={handleCloseEndAuctionDialog}>
        <DialogTitle>{"Confirm Auction End?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to end this auction?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEndAuctionDialog}>Cancel</Button>
          <Button onClick={() => handleEndAuction()} variant="contained" color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleEndAuction = () => {
    handleCloseEndAuctionDialog();
    setEndLoading(true);
    suspendAuction(auctionId)
      .then(() => {
        endAuctionManually(auctionId)
          .then(() => {
            enqueueSnackbar("Auction has ended.", { variant: "success" });
          })
          .catch(() => {
            enqueueSnackbar("The auction could not end, please try again.", { variant: "error" });
          })
          .finally(() => {
            handleClose();
          });
      })
      .catch(() => {
        enqueueSnackbar("The auction could not be suspend, please try again.", { variant: "error" });
      })
      .finally(() => {
        handleClose();
      });
  };

  const update = () => {
    setReload(true);
    refreshBids();
    setReload(false);
  };

  const disableBuyback = bidsLoading || (bids?.some(b => b.isBuyBack) ?? false);

  return (
    <div>
      <Dialog fullWidth maxWidth={"lg"} open={true} onClose={handleClose}>
        <DialogTitle className={classes.dialogHeader} id="alert-dialog-title">
          {title}
        </DialogTitle>

        <DialogContent>
          {suspendLoading && <LoadingComponent marginTop="32px" marginBottom="32px" label="Suspending auction" />}
          {endLoading && <LoadingComponent marginTop="32px" marginBottom="32px" label="Ending auction" />}
          {!suspendLoading && !endLoading && (
            <Grid container spacing={1}>
              <Grid item xs={4}>
                {!reload && <DialogReserveUpdate auctionId={auctionId} updated={update} />}
              </Grid>
              <Grid item xs={4}>
                {!reload && <DialogBuyback auctionId={auctionId} buybackDisabled={disableBuyback} update={update} />}
              </Grid>
              <Grid item xs={4} style={{ display: "flex", flexDirection: "row-reverse" }}>
                <Box className={classes.inputButton}>
                  <Button variant="contained" color="primary" onClick={() => handleOpenEndAuctionDialog()}>
                    End Immediately
                  </Button>
                </Box>
                <Box className={classes.inputButton}>
                  <Button variant="contained" color="primary" onClick={() => handleOpenSuspendedAuctionDialog()}>
                    Suspend Auction
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Grid item xs={12}>
                  <Paper elevation={3} variant="outlined" style={{ margin: "8px", padding: "8px" }}>
                    {!reload && (
                      <>
                        <DialogAuctionHistory auctionId={auctionId} isCurrentAuction={true} />
                        <DialogAuctionHistory auctionId={previousAuctionId} isCurrentAuction={false} />
                      </>
                    )}
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper elevation={3} variant="outlined" style={{ margin: "8px", padding: "8px" }}>
                    <DialogViews auctionId={auctionId} />
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper elevation={3} variant="outlined" style={{ margin: "8px", padding: "8px" }}>
                    <DialogWatches auctionId={auctionId} />
                  </Paper>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid item xs={12}>
                  <Paper elevation={3} variant="outlined" style={{ margin: "8px", padding: "8px" }}>
                    {!reload && <DialogBids auctionId={auctionId} bids={bids} bidsLoading={bidsLoading} refreshBids={update} showProxyBids={showProxyBids} />}
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {SuspendDialog()}
      {EndDialog()}
    </div>
  );
};
