import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { IBidder } from "../../interfaces/bidders/IBidder";
import { suspendBidder } from "../../services/BidderService";
import { useAuctionsBidderWonRecently } from "../../services/AuctionService";
import * as BidderService from "../../services/BidderService";
import { LoadingComponent } from "../loading/LoadingComponent";
import * as Styles from "./styles/BidderDetailsStyles";
import moment from "moment";
import { allSuspensionStatusType, suspensionStatusDescription, SuspensionStatusType } from "./../../enums/SuspensionStatusType";

interface IBidderSuspendDialogProps {
  isOpen: boolean;
  closeDialog: () => void;
  bidder: IBidder;
  setBidder: React.Dispatch<React.SetStateAction<IBidder>>;
}

export const BidderSuspendDialog = ({ isOpen, closeDialog, bidder, setBidder }: IBidderSuspendDialogProps) => {
  const [auctions, isAuctionsLoading] = useAuctionsBidderWonRecently(bidder.id);
  const { enqueueSnackbar } = useSnackbar();
  const classes = Styles.BidderDetailsStyles();

  const [reason, setReason] = useState("");
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [performingAction, setPerformingAction] = useState(false);
  const [showAuctions, setShowAuctions] = useState(false);
  const [suspensionStatusType, setSuspensionStatusType] = React.useState<SuspensionStatusType>(SuspensionStatusType.Other);

  const doSuspendBidder = async () => {
    setPerformingAction(true);
    try {
      await suspendBidder(bidder.id, suspensionStatusType, reason, checkedList);
      handleClose();
      const updatedBidder = await BidderService.GetBidder(bidder.id);
      if (updatedBidder.parsedBody) setBidder(updatedBidder.parsedBody);
    } catch {
      enqueueSnackbar("Suspending the bidder failed. Please try again.", { variant: "error" });
    } finally {
      setPerformingAction(false);
    }
  };

  const handleToggle = (checked: boolean) => {
    setShowAuctions(checked);
    setCheckedList([]);
  };

  const handleCheckboxChange = (id: string, checked: boolean) => {
    if (checked) {
      setCheckedList([...checkedList, id]);
    } else {
      setCheckedList(checkedList.filter(i => i !== id));
    }
  };

  const handleClose = () => {
    setSuspensionStatusType(SuspensionStatusType.Other);
    setReason("");
    setCheckedList([]);
    setShowAuctions(false);
    closeDialog();
  };

  const handleSuspensionStatusChange = event => {
    setSuspensionStatusType(event.target.value);
  };

  return (
    <>
      {auctions && (
        <Dialog open={isOpen} onClose={handleClose}>
          <DialogTitle id="form-dialog-title">Suspend Bidder</DialogTitle>
          <DialogContent>
            <DialogContentText>Please select the type of suspension</DialogContentText>
            <FormControl variant="outlined" margin="dense" fullWidth style={{ marginBottom: "16px" }}>
              <InputLabel variant="outlined">Suspension Type</InputLabel>
              <Select label="Suspension Type" value={suspensionStatusType} onChange={handleSuspensionStatusChange} variant="outlined">
                {allSuspensionStatusType.map(status => (
                  <MenuItem key={status} value={status}>
                    {suspensionStatusDescription(status)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <DialogContentText>Please enter the reason why you are suspending this bidder</DialogContentText>
            <TextField
              autoFocus
              id="name"
              label="Suspension Reason"
              value={reason}
              onChange={target => setReason(target.target.value)}
              fullWidth
              multiline
              required
            />

            <Tooltip title={auctions.length === 0 ? "No lots won in the past 3 weeks" : ""}>
              <FormControlLabel
                className={classes.lotSwitch}
                control={<Switch color="primary" onChange={e => handleToggle(e.target.checked)} />}
                label="Related to a lot"
                disabled={auctions.length === 0}
              />
            </Tooltip>

            {showAuctions && auctions.length !== 0 && (
              <>
                {isAuctionsLoading && auctions === null && <LoadingComponent label="Loading bid history" />}
                <DialogContentText>Please select any auctions related to the suspension</DialogContentText>
                <FormGroup>
                  <FormHelperText>Auctions Recently Won</FormHelperText>
                  <TableContainer style={{ maxHeight: 400 }}>
                    <Table size="small">
                      <TableBody>
                        {auctions?.map(auction => (
                          <TableRow>
                            <TableCell padding="none">
                              <Checkbox color="primary" value={auction.id} onChange={e => handleCheckboxChange(e.target.value, e.target.checked)} />
                            </TableCell>
                            <TableCell className={classes.auctionTableCell}>{auction.metadata.referenceNumber}</TableCell>
                            <TableCell className={classes.auctionTableCell + " " + classes.noWrap}>{auction.metadata.registrationNumber}</TableCell>
                            <TableCell className={classes.auctionTableCell}>{auction.metadata.title}</TableCell>
                            <TableCell className={classes.auctionTableCell + " " + classes.noWrap}>
                              {moment(auction.auctionEnd).format("DD-MM-YYYY")}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </FormGroup>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" disabled={performingAction}>
              Cancel
            </Button>
            <Button onClick={doSuspendBidder} color="primary" disabled={reason === "" || performingAction}>
              Suspend Bidder
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
