import * as React from "react";
import { Typography, Container, Box } from "@material-ui/core";
import { SecureArea } from "../components/authentication/SecureArea";
import { MemberDetailsContainer } from "../components/member-details/MemberDetailsContainer";
import { MemberPermission } from "../services/MemberService";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../services/TelemetryService";

function MemberDetails() {
  return (
    <Container maxWidth={"lg"} fixed>
      <SecureArea memberPermission={MemberPermission.AccountAdmin}>
        <Typography variant="h4" color="primary" gutterBottom>
          Member details
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Your member details are displayed below, with a list of all sites and opening times. If you want to update or change any of the below
          information please contact e2e.
        </Typography>
        <Box mt={4}>
          <MemberDetailsContainer />
        </Box>
      </SecureArea>
    </Container>
  );
}

export default withAITracking(reactPlugin, MemberDetails, "MemberDetails");
