import { GetClientSettings } from "./SettingsService";
import { useReadonlyData, usePostedData } from "./useVersionedData";
import { ISalvageClientSettings } from "../interfaces/ISalvageClientSettings";
import { Lot } from "./LotService";
const ClientSettings: ISalvageClientSettings = GetClientSettings();

export interface ILotMgmtFilterOptions {
  lotTypes: string[];
  bodyTypes: string[];
  locations: string[];
  insuranceCompanies: string[];
  sortBy: string[];
}

export interface ILotMgmtFilter {
  lotStatusGrouping?: string[];
  lotType?: string;
  bodyType?: string;
  location?: string;
  insuranceCompany?: string;
  searchText?: string;
  sortBy?: string;
  sortDescending: boolean;
  pageNumber: number;
  numberPerPage: number;
  requestId: string;
}

export const requestId = () => {
  return Date.now().toString();
};

export const DefaultLotMgmtFilter = (): ILotMgmtFilter => {
  return {
    sortDescending: false,
    pageNumber: 0,
    numberPerPage: 20,
    lotStatusGrouping: ["Incomplete"],
    requestId: requestId(),
  };
};

export interface ILotMgmtFilterResult {
  pageNumber: number;
  numberPerPage: number;
  totalPages: number;
  totalNumber: number;
  pageResults: Lot[];
  requestId: string;
}

export const useLotMgmtFilterOptions = (onError: (httpStatusCode: number) => void) =>
  useReadonlyData<ILotMgmtFilterOptions>(`${ClientSettings.AuctionPrivateApiUrl}/lotmanagement/searchoptions/`, onError);

export const useLotMgmtFilter = (onError: (httpStatusCode: number) => void) =>
  usePostedData<ILotMgmtFilterResult, ILotMgmtFilter>(ClientSettings.AuctionPrivateApiUrl + `/lotmanagement/searches`, onError);
