import * as React from "react";
import { useHistory } from "react-router-dom";
import { Button, Checkbox, CssBaseline, FormControlLabel, Grid, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import { useSnackbar, OptionsObject } from "notistack";
import { LoadingComponent } from "../loading/LoadingComponent";
import * as StripeService from "../../services/StripeService";
import * as StripeProductService from "../../services/StripeProductService";
import { IStripeNetProduct } from "../../interfaces/stripe-products/IStripeNetProduct";
import { IStripeProduct, StripeProductDefault } from "../../interfaces/stripe-products/IStripeProduct";
import { IValidation, ValidationDefault, ValidationErrorDefault } from "../../interfaces/IValidation";
import * as Styles from "./styles/StripeProductFormStyles";

const variantError: OptionsObject = { variant: "error" };

interface StripeProductFormProps {
  id: string | null;
}

export const StripeProductFormComponent: React.FC<StripeProductFormProps> = ({ id }) => {
  const history = useHistory();
  const classes = Styles.StripeProductFormStyles();
  const { enqueueSnackbar } = useSnackbar();

  var apiError: boolean = false;

  const [loading, setLoading] = React.useState<boolean>(true);
  const [publishing, setPublishing] = React.useState(false);
  const [toLoad, setToLoad] = React.useState<number>(1);
  const [loaded, setLoaded] = React.useState<number>(0);
  const [stripeNetProducts, setStripeNetProducts] = React.useState<IStripeNetProduct[]>([]);
  const [productDetails, setProductDetails] = React.useState<IStripeProduct>(StripeProductDefault);



  // const [productDetails, setProductDetails, , , putProductDetails, , productValidation] = StripeProductService.useProductDetails(id!, httpStatusCode => {
  //   apiError = true;
  //   if (httpStatusCode !== 400) {
  //     enqueueSnackbar("There was a problem with your lot details request, please try again.", variantError);
  //   }
  // });




  const [validation, setValidation] = React.useState<IValidation>(ValidationDefault);
  const [selectedPrice, setSelectedPrice] = React.useState<number>(-1);

  const incrementLoad = () => {
    let newTotal = loaded + 1

    setLoaded(newTotal);

    if (newTotal >= toLoad) {
      setLoading(false);
    }
  }

  React.useEffect(() => {

    StripeService.GetProducts()
      .then(result => {
        if (result.parsedBody !== undefined) {
          setStripeNetProducts(result.parsedBody);



          if (id !== undefined && id != null && id.length > 0) {
            StripeProductService.GetById(id)
              .then(productResult => {
                if (productResult.parsedBody !== undefined) {
                  setProductDetails(productResult.parsedBody);

                  let stripeId = productResult.parsedBody.stripeId;
                  let priceId = productResult.parsedBody.priceId;
                  let products = result.parsedBody as IStripeNetProduct[];

                  if (stripeId !== undefined && stripeId != null && stripeId.length > 0 && priceId !== undefined && priceId != null && priceId.length > 0) {
                    let idx = products.findIndex(o => o.id == productResult.parsedBody?.stripeId);

                    if (idx > -1 && products[idx].prices.filter(o => o.id == priceId).length > 0) {
                      let multiplier = productResult.parsedBody.vatInclusive ? 1 : 1.2;
                      setSelectedPrice(products[idx].prices.filter(o => o.id == priceId)[0].unitAmount / 100);
                    }
                  }
                  incrementLoad();
                }
              })
              .catch(async response => {
                console.log("error searching:" + response);
              });

          } else {
            incrementLoad();
          }
        }
      })
      .catch(async response => {
        console.log("error searching:" + response);
      });
  }, []);

  const onFieldChange = (e: any, errorName: string, capitalTextField: boolean = false) => {
    var value: any;

    if (e.target.type === "checkbox") {
      value = e.target.checked;
    } else if (e.target.type === "number") {
      value = e.target.value === "" ? 0 : Math.floor(e.target.value);
    } else if (capitalTextField) {
      value = e.target.value.toUpperCase();
    } else {
      value = e.target.value;
    }

    setProductDetails({
      ...productDetails,
      [e.target.name]: value,
    });
    if (e.target.type === "text") {
      const cursor = e.target.selectionStart;
      const element = e.target;
      window.requestAnimationFrame(() => {
        element.selectionStart = cursor;
        element.selectionEnd = cursor;
      });
    }
  };

  const onStripeIdChange = (e: any, errorName: string, capitalTextField: boolean = false) => {
    let value = e.target.value;

    if (value !== undefined && value != null && value.length > 0) {
      let idx = stripeNetProducts.findIndex(o => o.id == value);

      if (idx > -1 && stripeNetProducts[idx].prices.filter(o => o.active).length > 0) {

        let details = productDetails;
        details.stripeId = value;
        details.priceId = stripeNetProducts[idx].prices.filter(o => o.active)[0].id
        setProductDetails(details);

        let multiplier = details.vatInclusive ? 1 : 1.2;
        setSelectedPrice((stripeNetProducts[idx].prices.filter(o => o.active)[0].unitAmount / 100) * multiplier);
      } else {
        onFieldChange(e, errorName, capitalTextField);
      }
    } else {
      onFieldChange(e, errorName, capitalTextField);
    }

  }

  const onVatChange = (e: any, errorName: string, capitalTextField: boolean = false) => {
    let details = productDetails;

    if (details.stripeId !== undefined && details.stripeId != null && details.stripeId.length > 0) {
      let idx = stripeNetProducts.findIndex(o => o.id == details.stripeId);

      if (idx > -1 && stripeNetProducts[idx].prices.filter(o => o.active).length > 0) {
        let multiplier = details.vatInclusive ? 1 : 1.2;
        setSelectedPrice((stripeNetProducts[idx].prices.filter(o => o.active)[0].unitAmount / 100) * multiplier);
        onFieldChange(e, errorName, capitalTextField);
      } else {
        onFieldChange(e, errorName, capitalTextField);
      }
    } else {
      onFieldChange(e, errorName, capitalTextField);
    }

  }

  const handleValidation = (validationObj) => {
    setValidation(ValidationDefault);

    let list: IValidation = {};
    Object.entries(validationObj)
      .forEach(([key, value]) => {
        let lowercasedName = (value as any).field.charAt(0).toLowerCase() + (value as any).field.slice(1);
        list[lowercasedName] = { field: (value as any).field, error: (value as any).error };
        setValidation(list);
      });
  };

  const isError = (field: string): boolean => {
    let check = validation[field] !== undefined && validation[field].field !== "";
    return check;
  };

  const ErrorMessage = (field: string): string => {
    return validation[field] !== undefined && validation[field].field !== "" ? validation[field].error : "";
  };

  const handleBack = () => {
    history.push(`/StripeProducts`);
  };

  const save = () => {
    let valid = true;

    if (valid) {
      setPublishing(true);

      if (productDetails.id !== undefined && productDetails.id != null && productDetails.id.length > 0) {
        StripeProductService.Update(productDetails, productDetails.id)
          .then(result => {
            history.push(`/StripeProducts`);
          })
          .catch(async response => {
            if (response.status == 400) {
              handleValidation(response.validation);
              apiError = true;
            }

            enqueueSnackbar("Could not update plan, please try again.", variantError);
            setPublishing(false);
          });
      } else {
        StripeProductService.Create(productDetails)
          .then(result => {
            history.push(`/StripeProducts`);
          })
          .catch(async response => {
            if (response.status == 400) {
              handleValidation(response.validation);
              apiError = true;
            }

            enqueueSnackbar("Could not create plan, please try again.", variantError);
            setPublishing(false);
          });
      }

    }

  };



  return (
    <>
      <CssBaseline />
      {loading ? (
        <LoadingComponent label="Loading" />
      ) : (
        <div>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                error={isError("name")}
                helperText={ErrorMessage("name")}
                name="name"
                label="Name"
                value={productDetails.name === null ? "" : productDetails.name}
                onChange={value => {
                  onFieldChange(value, "Name", false);
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="durationInMonths">Duration *</InputLabel>
              <Select
                required
                fullWidth
                id="durationInMonths"
                name="durationInMonths"
                error={isError("durationInMonths")}
                value={productDetails.durationInMonths === null ? "" : productDetails.durationInMonths}
                onChange={value => onFieldChange(value, "")}
              >
                <MenuItem value={12}>12 Months</MenuItem>
                <MenuItem value={24}>24 Months</MenuItem>
                <MenuItem value={60}>60 Months</MenuItem>
              </Select>
            </Grid>


            <Grid item xs={12} sm={12}>
              <TextField
                required
                fullWidth
                error={isError("description")}
                helperText={ErrorMessage("description")}
                name="description"
                label="Description"
                value={productDetails.description === null ? "" : productDetails.description}
                onChange={value => {
                  onFieldChange(value, "description", false);
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="stripeId">Stripe Product *</InputLabel>
              <Select
                required
                fullWidth
                id="stripeId"
                name="stripeId"
                error={isError("stripeId")}
                value={productDetails.stripeId === null ? "" : productDetails.stripeId}
                onChange={value => onStripeIdChange(value, "")}
              >
                {stripeNetProducts.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                name="Price"
                label="Stripe Product Price"
                disabled={true}
                value={selectedPrice <= 0 ? "" : selectedPrice}
              />
            </Grid>

            <Grid item xs={4} sm={6}>
              <FormControlLabel
                value="active"
                control={
                  <Checkbox
                    name="active"
                    checked={productDetails.active}
                    onChange={value => onFieldChange(value, "")}
                    value={productDetails.active}
                  />
                }
                label="Active"
                labelPlacement="end"
              />
            </Grid>
            <Grid item xs={4} sm={6}>
              <FormControlLabel
                value="vatInclusive"
                control={
                  <Checkbox
                    name="vatInclusive"
                    checked={productDetails.vatInclusive}
                    onChange={value => onVatChange(value, "")}
                    value={productDetails.vatInclusive}
                  />
                }
                label="VAT Inclusive"
                labelPlacement="end"
              />
            </Grid>


          </Grid>

          <div className={classes.buttons}>
            <Button variant="contained" className={classes.button} onClick={handleBack}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" className={classes.button} onClick={save}>
              Confirm
            </Button>
          </div>

        </div>

      )}
    </>
  );
};
