import React from "react";
import { useEffect } from "react";

import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { IValidation, ValidationDefault } from "../../../interfaces/IValidation";

import { LotDetails, LotDetailsDefault } from "../../../services/LotService";
import { IConfiguration } from "../../../interfaces/vehicleConfigurations/IConfiguration";
import LotFieldComponent, {
  RegistrationNumberAllow,
  ReferenceNumberAllow,
  MakeAllow,
  ModelAllow,
  YearAllow,
  MileageAllow,
  DescriptionAllow,
  EngineSizeAllow,
  TrimLevelAllow,
  TransmissionGearsAllow,
  NumberOfDoorsAllow,
  BodyShapeAllow,
  FuelTypeAllow,
  ColourAllow,
  DamageCategoryAllow,
  HasServiceHistoryAllow,
  HasKeysAllow,
  HasStereoAllow,
  HasLogBookAllow,
  HasVinAllow,
  VatIncludedAllow,
  DoesStartAllow,
  DoesDriveAllow,
  LotTypeAllow,
} from "../components/LotFieldComponent";
import { Link, useLocation } from "react-router-dom";
import { TriStateCheckbox } from "../components/TriStateCheckbox";

interface VehicleProps {
  validationState: IValidation | null;
  configuration: IConfiguration | null;
  insuranceCompanies: string[];
  lotDetailsState: LotDetails | null;
  handleStateChange: (vehicle: LotDetails) => void;
}

export const LotDetailsForm: React.FC<VehicleProps> = ({
  validationState,
  configuration,
  lotDetailsState,
  insuranceCompanies,
  handleStateChange,
}) => {
  const [lotDetails, setLotDetails] = React.useState<LotDetails>(lotDetailsState === null ? LotDetailsDefault : lotDetailsState);
  const [validation, setValidation] = React.useState<IValidation>(validationState === null ? ValidationDefault : validationState);

  const location = useLocation();
  const lotTypeParam = new URLSearchParams(location.search).get("lotType");

  useEffect(() => {
    if (lotTypeParam) {
      setLotDetails({
        ...lotDetails,
        lotType: lotTypeParam,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lotTypeParam]);

  useEffect(() => {
    handleStateChange(lotDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lotDetails]);

  useEffect(() => {
    setValidation(validationState === null ? ValidationDefault : validationState);
  }, [validationState]);

  const isError = (field: string): boolean => {
    return validation[field] !== undefined && validation[field].field !== "";
  };

  const ErrorMessage = (field: string): string => {
    return validation[field] !== undefined && validation[field].field !== "" ? validation[field].error : "";
  };

  const onAutocomplete = (fieldName: string, value: string, errorName: string) => {
    setValidation({
      ...validation,
      [errorName]: { field: "", error: "" },
    });
    setLotDetails({
      ...lotDetails,
      [fieldName]: value,
    });
  };

  const onFieldChange = (e: any, errorName: string, capitalTextField: boolean = false) => {
    setValidation({
      ...validation,
      [errorName]: { field: "", error: "" },
    });

    var value: any;

    if (e.target.type === "checkbox") {
      value = e.target.checked;
    } else if (e.target.type === "number") {
      if (e.target.value % 1 !== 0) {
        setValidation({
          ...validation,
          [errorName]: { field: e.target.name, error: "Must be a whole number." },
        });
      }
      value = e.target.value === "" ? 0 : Math.floor(e.target.value);
    } else if (capitalTextField) {
      value = e.target.value.toUpperCase();
    } else {
      value = e.target.value;
    }

    setLotDetails({
      ...lotDetails,
      [e.target.name]: value,
    });
    if (e.target.type === "text") {
      const cursor = e.target.selectionStart;
      const element = e.target;
      window.requestAnimationFrame(() => {
        element.selectionStart = cursor;
        element.selectionEnd = cursor;
      });
    }
  };

  const onTriStateChange = (propertyName: string, value: boolean | null) => {
    setLotDetails({
      ...lotDetails,
      [propertyName]: value,
    });
  };

  const getMakes = () => {
    return configuration?.item.vehicleMake.map(vehicleMake => vehicleMake.make) ?? [""];
  };

  const getModels = () => {
    var models = configuration?.item.vehicleMake.filter(e => e.make === lotDetails.make)[0]?.models;
    if (!models) {
      return ["Other"];
    }
    return models;
  };

  return (
    <>
      <Grid container spacing={3}>
        <LotFieldComponent currentLotType={lotDetails.lotType} allowLotTypes={RegistrationNumberAllow}>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              error={isError("RegistrationNumber")}
              helperText={ErrorMessage("RegistrationNumber")}
              name="registrationNumber"
              label="Registration Number"
              value={lotDetails.registrationNumber === null ? "" : lotDetails.registrationNumber}
              onChange={value => {
                onFieldChange(value, "RegistrationNumber", true);
              }}
            />
          </Grid>
        </LotFieldComponent>
        <LotFieldComponent currentLotType={lotDetails.lotType} allowLotTypes={ReferenceNumberAllow}>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              error={isError("ReferenceNumber")}
              helperText={ErrorMessage("ReferenceNumber")}
              name="referenceNumber"
              label="Reference Number"
              value={lotDetails.referenceNumber === null ? "" : lotDetails.referenceNumber}
              onChange={value => {
                onFieldChange(value, "ReferenceNumber");
              }}
            />
          </Grid>
        </LotFieldComponent>
        <LotFieldComponent currentLotType={lotDetails.lotType} allowLotTypes={RegistrationNumberAllow}>
          <Grid item xs={6}>
            <TextField              
              fullWidth
              error={isError("NsgId")}
              helperText={ErrorMessage("NsgId")}
              name="nsgId"
              label="NSG Id"
              value={lotDetails.nsgId === null ? "" : lotDetails.nsgId}
              onChange={value => {
                onFieldChange(value, "NsgId", true);
              }}
            />
          </Grid>
        </LotFieldComponent>
        <LotFieldComponent currentLotType={lotDetails.lotType} allowLotTypes={MakeAllow}>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              autoComplete
              autoHighlight
              options={getMakes()}
              getOptionLabel={(make: string) => make}
              defaultValue={lotDetails.make === null ? "" : lotDetails.make}
              onChange={(event: any, make: string | null) => {
                onAutocomplete("make", make === null ? "" : make, "make");
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  required
                  fullWidth
                  label="Make"
                  margin="none"
                  name="make"
                  error={isError("Make")}
                  helperText={ErrorMessage("Make")}
                  onChange={value => {
                    onFieldChange(value, "Make");
                  }}
                />
              )}
            />
          </Grid>
        </LotFieldComponent>
        <LotFieldComponent currentLotType={lotDetails.lotType} allowLotTypes={ModelAllow}>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              autoComplete
              autoHighlight
              options={getModels()}
              getOptionLabel={(model: string) => model}
              defaultValue={lotDetails.model === null ? "" : lotDetails.model}
              onChange={(event: any, model: string | null) => {
                onAutocomplete("model", model === null ? "" : model, "Model");
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  required
                  fullWidth
                  label="Model"
                  margin="none"
                  name="model"
                  error={isError("Model")}
                  helperText={ErrorMessage("Model")}
                  onChange={value => {
                    onFieldChange(value, "Model");
                  }}
                />
              )}
            />
          </Grid>
        </LotFieldComponent>
        <LotFieldComponent currentLotType={lotDetails.lotType} allowLotTypes={YearAllow}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              error={isError("Year")}
              helperText={ErrorMessage("Year")}
              name="year"
              label="Year"
              type="number"
              value={lotDetails.year === 0 ? "" : lotDetails.year}
              onChange={value => onFieldChange(value, "Year")}
            />
          </Grid>
        </LotFieldComponent>
        <LotFieldComponent currentLotType={lotDetails.lotType} allowLotTypes={MileageAllow}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              error={isError("Mileage")}
              helperText={ErrorMessage("Mileage")}
              name="mileage"
              label="Mileage"
              type="number"
              value={lotDetails.mileage === 0 ? "" : lotDetails.mileage}
              onChange={value => onFieldChange(value, "Mileage")}
            />
          </Grid>
        </LotFieldComponent>
        <LotFieldComponent currentLotType={lotDetails.lotType} allowLotTypes={EngineSizeAllow}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              error={isError("EngineSize")}
              helperText={ErrorMessage("EngineSize")}
              name="engineSize"
              label="Engine Size CC"
              value={lotDetails.engineSize === null ? "" : lotDetails.engineSize}
              onChange={value => onFieldChange(value, "EngineSize")}
            />
          </Grid>
        </LotFieldComponent>
        <LotFieldComponent currentLotType={lotDetails.lotType} allowLotTypes={TrimLevelAllow}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              name="trimLevel"
              label="Trim Level"
              value={lotDetails.trimLevel === null ? "" : lotDetails.trimLevel}
              onChange={value => onFieldChange(value, "TrimLevel")}
            />
          </Grid>
        </LotFieldComponent>
        <LotFieldComponent currentLotType={lotDetails.lotType} allowLotTypes={TransmissionGearsAllow}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              error={isError("TransmissionGears")}
              helperText={ErrorMessage("TransmissionGears")}
              name="transmissionGears"
              label="Transmission Gears"
              type="number"
              value={lotDetails.transmissionGears === 0 ? "" : lotDetails.transmissionGears}
              onChange={value => onFieldChange(value, "TransmissionGears")}
            />
          </Grid>
        </LotFieldComponent>
        <LotFieldComponent currentLotType={lotDetails.lotType} allowLotTypes={TransmissionGearsAllow}>
          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="transmissionType">Transmission Type</InputLabel>
            <Select
              fullWidth
              id="transmissionType"
              name="transmissionType"
              value={lotDetails.transmissionType === null ? "" : lotDetails.transmissionType}
              onChange={value => onFieldChange(value, "")}
            >
              {configuration?.item.transmissionTypes.map((t, index) => {
                return (
                  <MenuItem key={index} value={t}>
                    {t}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
        </LotFieldComponent>
        <LotFieldComponent currentLotType={lotDetails.lotType} allowLotTypes={NumberOfDoorsAllow}>
          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="doors">Doors</InputLabel>
            <Select
              fullWidth
              id="doors"
              name="doors"
              value={lotDetails.doors === null || lotDetails.doors === 0 ? "" : lotDetails.doors}
              onChange={value => onFieldChange(value, "")}
            >
              {configuration?.item.numberOfDoors.map((d, index) => {
                return (
                  <MenuItem key={index} value={d}>
                    {d}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
        </LotFieldComponent>
        <LotFieldComponent currentLotType={lotDetails.lotType} allowLotTypes={BodyShapeAllow}>
          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="bodyShape">Body Shape</InputLabel>
            <Select
              fullWidth
              id="bodyShape"
              name="bodyShape"
              value={lotDetails.bodyShape === null ? "" : lotDetails.bodyShape}
              onChange={value => onFieldChange(value, "")}
            >
              {configuration?.item.bodyShapes.map((b, index) => {
                return (
                  <MenuItem key={index} value={b}>
                    {b}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
        </LotFieldComponent>
        <LotFieldComponent currentLotType={lotDetails.lotType} allowLotTypes={FuelTypeAllow}>
          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="fuelType">Fuel Type</InputLabel>
            <Select
              fullWidth
              id="fuelType"
              name="fuelType"
              value={lotDetails.fuelType === null ? "" : lotDetails.fuelType}
              onChange={value => onFieldChange(value, "")}
            >
              {configuration?.item.fuelType.map((f, index) => {
                return (
                  <MenuItem key={index} value={f}>
                    {f}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
        </LotFieldComponent>
        <LotFieldComponent currentLotType={lotDetails.lotType} allowLotTypes={ColourAllow}>
          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="colour">Colour</InputLabel>
            <Select
              fullWidth
              id="colour"
              name="colour"
              value={lotDetails.colour === null ? "" : lotDetails.colour}
              onChange={value => onFieldChange(value, "")}
            >
              {configuration?.item.colours.map((c, index) => {
                return (
                  <MenuItem key={index} value={c}>
                    {c}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
        </LotFieldComponent>

        <LotFieldComponent currentLotType={lotDetails.lotType} allowLotTypes={[lotDetails.lotType]}>
          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="insuranceCompany">
              Client <Link to="/Configuration/InsuranceCompanies">(Edit)</Link>
            </InputLabel>
            <Select
              fullWidth
              id="insuranceCompany"
              name="insuranceCompany"
              value={lotDetails.insuranceCompany === null ? "" : lotDetails.insuranceCompany}
              onChange={value => onFieldChange(value, "")}
            >
              <MenuItem aria-label="None" value="">
                Not Selected
              </MenuItem>
              {insuranceCompanies?.map((c, index) => {
                return (
                  <MenuItem key={index} value={c}>
                    {c}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
        </LotFieldComponent>
        <LotFieldComponent currentLotType={lotDetails.lotType} allowLotTypes={[lotDetails.lotType]}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              name="branchCode"
              label="Branch Code"
              value={lotDetails.branchCode === null ? "" : lotDetails.branchCode}
              onChange={value => onFieldChange(value, "")}
            />
          </Grid>
        </LotFieldComponent>
        <LotFieldComponent currentLotType={lotDetails.lotType} allowLotTypes={DamageCategoryAllow}>
          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="damageCategory">Damage Category</InputLabel>
            <Select
              fullWidth
              id="damageCategory"
              name="damageCategory"
              value={lotDetails.damageCategory === null ? "" : lotDetails.damageCategory}
              onChange={value => onFieldChange(value, "")}
            >
              {configuration?.item.damageCategories.map((c, index) => {
                return (
                  <MenuItem key={index} value={c}>
                    {c}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
        </LotFieldComponent>
        <LotFieldComponent currentLotType={lotDetails.lotType} allowLotTypes={LotTypeAllow}>
          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="lotType">Lot Type</InputLabel>
            <Select
              fullWidth
              id="lotType"
              name="lotType"
              value={lotDetails.lotType === null ? "" : lotDetails.lotType}
              onChange={value => onFieldChange(value, "")}
            >
              {configuration?.item.lotTypes.map((c, index) => {
                return (
                  <MenuItem key={index} value={c}>
                    {c}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
        </LotFieldComponent>
        <LotFieldComponent currentLotType={lotDetails.lotType} allowLotTypes={DescriptionAllow}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              error={isError("Description")}
              helperText={ErrorMessage("Description")}
              multiline
              required
              name="description"
              label="Description"
              value={lotDetails.description === null ? "" : lotDetails.description}
              onChange={value => onFieldChange(value, "")}
            />
          </Grid>
        </LotFieldComponent>
      </Grid>
      <Grid container spacing={3}>
        <LotFieldComponent currentLotType={lotDetails.lotType} allowLotTypes={HasServiceHistoryAllow}>
          <Grid item xs={12} sm={4}>
            <TriStateCheckbox
              title={"Service History"}
              checked={lotDetails.hasServiceHistory}
              callback={value => onTriStateChange("hasServiceHistory", value)}
            />
          </Grid>
        </LotFieldComponent>
        <LotFieldComponent currentLotType={lotDetails.lotType} allowLotTypes={HasKeysAllow}>
          <Grid item xs={12} sm={4}>
            <TriStateCheckbox title={"Keys"} checked={lotDetails.hasKeys} callback={value => onTriStateChange("hasKeys", value)} />
          </Grid>
        </LotFieldComponent>
        <LotFieldComponent currentLotType={lotDetails.lotType} allowLotTypes={HasStereoAllow}>
          <Grid item xs={12} sm={4}>
            <TriStateCheckbox title={"Stereo"} checked={lotDetails.hasStereo} callback={value => onTriStateChange("hasStereo", value)} />
          </Grid>
        </LotFieldComponent>
        <LotFieldComponent currentLotType={lotDetails.lotType} allowLotTypes={HasLogBookAllow}>
          <Grid item xs={12} sm={4}>
            <TriStateCheckbox title={"Log Book"} checked={lotDetails.hasLogBook} callback={value => onTriStateChange("hasLogBook", value)} />
          </Grid>
        </LotFieldComponent>
        <LotFieldComponent currentLotType={lotDetails.lotType} allowLotTypes={HasVinAllow}>
          <Grid item xs={12} sm={4}>
            <TriStateCheckbox title={"VIN"} checked={lotDetails.hasVIN} callback={value => onTriStateChange("hasVIN", value)} />
          </Grid>
        </LotFieldComponent>
        <LotFieldComponent currentLotType={lotDetails.lotType} allowLotTypes={VatIncludedAllow}>
          <Grid item xs={12} sm={4}>
            <FormControlLabel
              value="vatIncluded"
              control={
                <Checkbox
                  name="vatIncluded"
                  checked={lotDetails.vatIncluded}
                  onChange={value => onFieldChange(value, "")}
                  value={lotDetails.vatIncluded}
                />
              }
              label="Subject to VAT"
              labelPlacement="end"
            />
          </Grid>
        </LotFieldComponent>
        <LotFieldComponent currentLotType={lotDetails.lotType} allowLotTypes={DoesStartAllow}>
          <Grid item xs={12} sm={4}>
            <TriStateCheckbox title={"Starts"} checked={lotDetails.doesStart} callback={value => onTriStateChange("doesStart", value)} />
          </Grid>
        </LotFieldComponent>
        <LotFieldComponent currentLotType={lotDetails.lotType} allowLotTypes={DoesDriveAllow}>
          <Grid item xs={12} sm={4}>
            <TriStateCheckbox title={"Drives"} checked={lotDetails.doesDrive} callback={value => onTriStateChange("doesDrive", value)} />
          </Grid>
        </LotFieldComponent>
      </Grid>
    </>
  );
};
