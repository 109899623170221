export interface IssueRefundDto {
  orderId: string;
  reason: string;
  referenceNumber: string;
}

export const DefaultIssueRefundDto: IssueRefundDto = {
  orderId: "",
  reason: "",
  referenceNumber: "",
};
