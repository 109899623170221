import * as React from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  FormControl,
  Select,
  MenuItem,
  ButtonGroup,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  TextField,
  Typography,
} from "@material-ui/core";
import { AuctionStatus, auctionStatusString } from "../../interfaces/auctions/IAuction";
import { useSnackbar } from "notistack";
import { LoadingComponent } from "../loading/LoadingComponent";
import * as Styles from "./styles/EndedAuctionsStyles";
import * as AuctionService from "../../services/AuctionService";
import { useHistory } from "react-router-dom";
import { HttpResponse } from "../../services/ApiService";

interface IAuctionNeedingAttention {
  id: string;
  lotId: string;
  title: string;
  status: AuctionStatus;
  collectionCode?: string;
}

const useAuctionsNeedingAttention = (): [IAuctionNeedingAttention[] | null, boolean, boolean, () => Promise<void>] => {
  const [auctionsPartial, auctionsPartialLoading, partialError, refreshAuctionsPartial] = AuctionService.usePartiallyCreatedAuctions();
  const [auctionsSusp, auctionsSuspLoading, suspError, refreshAuctionsSusp] = AuctionService.useSuspendedAuctions();
  const [auctionsEnded, auctionsEndedLoading, endError, refreshAuctionsEnded] = AuctionService.useEndedAuctions();

  const [isApiError, setIsApiError] = React.useState(false);
  const [isApiLoading, setIsApiLoading] = React.useState(false);

  const [auctions, setAuctions] = React.useState<IAuctionNeedingAttention[] | null>(null);

  React.useEffect(() => setIsApiLoading(auctionsPartialLoading || auctionsSuspLoading || auctionsEndedLoading), [
    auctionsPartialLoading,
    auctionsSuspLoading,
    auctionsEndedLoading,
  ]);
  React.useEffect(() => setIsApiError(!!(partialError || suspError || endError)), [partialError, suspError, endError]);
  React.useEffect(
    () =>
      setAuctions(
        isApiError || isApiLoading
          ? null
          : ([] as IAuctionNeedingAttention[])
            .concat(auctionsPartial ?? [])
            .concat(auctionsSusp ?? [])
            .concat(auctionsEnded ?? [])
      ),
    [auctionsPartial, auctionsSusp, auctionsEnded, isApiError, isApiLoading]
  );

  const refresh = async () => {
    await Promise.all([refreshAuctionsPartial(), refreshAuctionsSusp(), refreshAuctionsEnded()]);
  };

  return [auctions, isApiLoading, isApiError, refresh];
};

export const EndedAuctionsComponent: React.FC = () => {
  const classes = Styles.EndedAuctionsStyles();

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [allAuctions, isApiLoading, isApiError, refresh] = useAuctionsNeedingAttention();
  const [open, setOpen] = React.useState<boolean>(false);
  const [collectionAuction, setCollectionAuction] = React.useState<IAuctionNeedingAttention | null>(null);
  const [filter, setFilter] = React.useState<AuctionStatus | undefined>(undefined);
  const [searchText, setSearchText] = React.useState<string>("");
  const [isModifying, setIsModifying] = React.useState(false);

  React.useEffect(() => {
    if (isApiError) enqueueSnackbar("There was a problem retrieving the auctions. Please try again.", { variant: "error" });
  }, [isApiError, enqueueSnackbar]);

  const auctions = allAuctions?.filter(f => {
    if (searchText !== "") {
      return f.collectionCode === searchText;
    } else {
      return filter === undefined || filter === f.status;
    }
  });

  const CollectionCodeDialog = () => {
    const handleDialogClose = () => setOpen(false);

    if (collectionAuction === null) return <></>;

    return (
      <Dialog open={open} onClose={handleDialogClose}>
        <DialogTitle>Please check the collection code: {collectionAuction.collectionCode}</DialogTitle>
        <DialogActions>
          <Button onClick={async () => await handleLotCollected(collectionAuction.id)}>Confirm Collected</Button>
          <Button onClick={handleDialogClose}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  };

  const createHandler = (action: (auctionId: string) => Promise<HttpResponse<unknown>>) => async (auctionId: string) => {
    try {
      setIsModifying(true);
      await action(auctionId);
    } catch {
      enqueueSnackbar("There was a problem performing your action. Please try again.", { variant: "error" });
    } finally {
      await refresh();
      setIsModifying(false);
    }
  };

  const handleRelist = createHandler(auctionId => AuctionService.relistAuction(auctionId, AuctionService.UseDefaultAuctionRelistRequest()));
  const handleArchive = createHandler(auctionId => AuctionService.archiveAuction(auctionId));
  const handleUnsuspend = createHandler(auctionId => AuctionService.unsuspendAuction(auctionId));
  const handleEndManually = createHandler(auctionId => AuctionService.endAuctionManually(auctionId));
  const handleLotCollected = createHandler(auctionId => AuctionService.auctionLotCollected(auctionId));
  const handleLotNotCollected = createHandler(auctionId => AuctionService.auctionLotNotCollected(auctionId));

  return isApiLoading || auctions === null || isModifying ? (
    <LoadingComponent label={isModifying ? "Making your changes" : "Loading"} />
  ) : (
    <>
      {CollectionCodeDialog()}
      <Grid container>
        <Grid xs={12} item>
          <Typography variant="h4" color="primary" gutterBottom>
            Manage Auctions
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Here you can flag auctions as completed when the lot is collected. If there are auctions that are missing important details from their
            listing, then they will show here too. You can also handle any auctions that are suspended, or that have ended without a sale.
          </Typography>
        </Grid>
        <Grid xs={12} item className={classes.filterGrid}>
          <FormControl>
            <TextField
              margin="dense"
              id="textSearch"
              placeholder={"Search collection code"}
              onChange={e => setSearchText(e.target.value)}
              className={classes.filterWidth}
            />
            <Select value={filter} className={classes.filterWidth} onChange={event => setFilter(event.target.value as AuctionStatus)}>
              <MenuItem value={undefined}>All</MenuItem>
              <MenuItem value={AuctionStatus.CreatedPartially}>Auctions needing more details</MenuItem>
              <MenuItem value={AuctionStatus.ActiveButSuspended}>Auctions that are suspended</MenuItem>
              <MenuItem value={AuctionStatus.AuctionEndedAndWon}>Auctions ended and won</MenuItem>
              <MenuItem value={AuctionStatus.AuctionEndedButNotWon}>Auctions ended but not won</MenuItem>
              <MenuItem value={AuctionStatus.AuctionEndedButSuspended}>Auctions ended but suspended</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={12} item>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Title</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {auctions && auctions.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={3}>No auctions available</TableCell>
                  </TableRow>
                )}

                {auctions &&
                  auctions.map(auction => (
                    <TableRow key={auction.id}>
                      <TableCell>{auction.title === null ? "No title" : auction.title}</TableCell>
                      <TableCell>{auctionStatusString(auction.status)}</TableCell>
                      <TableCell style={{ textAlign: "end" }}>
                        {(auction.status === AuctionStatus.AuctionEndedButSuspended || auction.status === AuctionStatus.AuctionEndedButNotWon) && (
                          <ButtonGroup color="primary" fullWidth>
                            <Button disabled={isModifying} onClick={async () => await handleRelist(auction.id)}>
                              Relist
                            </Button>
                            <Button disabled={isModifying} onClick={async () => await handleArchive(auction.id)}>
                              Delete / Archive
                            </Button>
                            <Button
                              disabled={isModifying}
                              className={classes.buttonWidth}
                              onClick={() => {
                                history.push(`/SellDirectToBidder/${auction.id}`);
                              }}
                            >
                              Sell direct
                            </Button>
                          </ButtonGroup>
                        )}
                        {auction.status === AuctionStatus.AuctionEndedAndWon && auction.collectionCode && (
                          <ButtonGroup color="primary" fullWidth>
                            <Button
                              disabled={isModifying}
                              className={classes.buttonWidth}
                              onClick={() => {
                                setCollectionAuction(auction);
                                setOpen(true);
                              }}
                            >
                              Buyer is collecting
                            </Button>
                            <Button disabled={isModifying} onClick={async () => await handleLotNotCollected(auction.id)}>
                              Buyer failed to collect
                            </Button>
                          </ButtonGroup>
                        )}
                        {auction.status === AuctionStatus.ActiveButSuspended && (
                          <ButtonGroup color="primary" fullWidth>
                            <Button disabled={isModifying} onClick={async () => await handleUnsuspend(auction.id)}>
                              Unsuspend
                            </Button>
                            <Button disabled={isModifying} onClick={async () => await handleEndManually(auction.id)}>
                              End immediately
                            </Button>
                          </ButtonGroup>
                        )}
                        {auction.status === AuctionStatus.CreatedPartially && (
                          <ButtonGroup color="primary" fullWidth>
                            <Button
                              disabled={isModifying}
                              className={classes.buttonWidth}
                              onClick={() => {
                                history.push(`/UpdateLot/${auction.lotId}/${auction.id}`);
                              }}
                            >
                              Enter more details
                            </Button>
                          </ButtonGroup>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid xs={12} item className={classes.liveBidsArea}>
          <Typography variant="subtitle1">If you wish to see auctions that are being bid on right now then use the Live Bids feature.</Typography>
        </Grid>
        <Grid item xs={2}>
          <Button
            className={classes.liveBidsButton}
            variant="contained"
            color="primary"
            onClick={() => {
              history.push(`/LiveBids`);
            }}
          >
            Manage Live Bids
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
