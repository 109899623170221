export enum SuspensionStatusType {
  Undefined = 0,
  Other = 1,
  VehicleNotPaid = 2,
  OutstandingPayments = 3,
  RegistrationIssue = 4,
}

export const allSuspensionStatusType = Object.values(SuspensionStatusType)
  .filter(val => typeof val !== "string")
  .map(f => f as SuspensionStatusType);

export const suspensionStatusDescription = (filterType: SuspensionStatusType) => {
  switch (filterType) {
    case SuspensionStatusType.VehicleNotPaid:
      return "Vehicle Not Paid For";
    case SuspensionStatusType.OutstandingPayments:
      return "Outstanding Payments";
    case SuspensionStatusType.RegistrationIssue:
      return "Registration Issues";
    case SuspensionStatusType.Other:
      return "Other";
    default:
      return "";
  }
};
