import { AlertType } from "./AlertType";

export interface IGlobalAlert{
 id?: string;
 etag?: string;
 partitionKey?: string;
 description: string;
 alertType: AlertType;
 startDate: Date | null;
 endDate: Date | null;   
}

export const GlobalAlertDefault: IGlobalAlert = {
    id: undefined,
    description: "",
    alertType: AlertType.Alert,
    startDate: null,
    endDate: null
};

