import React, { useState } from "react";

import {
  DefaultAuctionMgmtFilter,
  useAuctionMgmtFilter,
  useAuctionMgmtFilterOptions,
  auctionStatusGroupingOptionString,
  IAuctionMgmtFilterResult,
  requestId,
  IBucketNameAndId,
} from "../../../services/AuctionLotMgmtService";
import { useSnackbar } from "notistack";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { IAuctionInfo } from "../../../services/AuctionService";
import { AuctionRow } from "./AuctionRow";
import PublishIcon from "@material-ui/icons/Publish";
import { ContainerStyles } from "../styles/ContainerStyles";
import { SearchSkeleton } from "../SearchSkeleton";
import { AuctionSkeleton } from "../AuctionSkeleton";
import { ActionIcons } from "./ActionIcons";
import { Filter } from "../../../helpers/Filter";
import { Pagination } from "../../../helpers/Pagination";
import { useLocation } from "react-router-dom";
import { BulkProcessDialog } from "../bulk-process/BulkProcessDialog";
import useDebounce from "../../../hooks/useDebounce";
import * as queryString from "query-string";
import { sentenceCaseTextFromCamelCase } from "../../../helpers/text-format/TextFormat";
import * as SettingsService from "../../../services/SettingsService";

interface bucketSearchOptions {
  bucketNameContains: string;
  bucketId: string;
}

function SetBucketSearchOption(parsedQuery: queryString.ParsedQuery): bucketSearchOptions {
  return {
    bucketNameContains: parsedQuery.bucketNameContains === undefined ? "" : (parsedQuery.bucketNameContains as string),
    bucketId: parsedQuery.bucketId === undefined ? "" : (parsedQuery.bucketId as string),
  };
}

export const AuctionsContainer: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = ContainerStyles();
  const location = useLocation();
  const [openBulkProcessDialog, setBulkProcessDialogOpen] = useState<boolean>(false);
  const [debounceLoading, setDebounceLoading] = useState<boolean>(true);
  const [showProxyBids, setShowProxyBids] = React.useState<boolean>(false);

  const [filterOptions, isOptionsLoading, ,] = useAuctionMgmtFilterOptions(() =>
    enqueueSnackbar("Could not retrieve list of filter options", { variant: "error" })
  );
  const [filterRequest, setFilterRequest, apiPostAsync, filterResponse, isLoading, ,] = useAuctionMgmtFilter(() =>
    enqueueSnackbar("Could not retrieve list of auctions", { variant: "error" })
  );

  const [latestData, setLatestData] = useState<IAuctionMgmtFilterResult | null>();

  React.useEffect(() => {

    SettingsService.GetCurrentMemberAdminSettings()
      .then(result => {
        if (result.parsedBody !== undefined) {
          setShowProxyBids(result.parsedBody.showProxyBidIndicatorOnCurrentBids);
        }
      })
      .catch(async response => {
        console.log("error searching:" + response);
      });
  }, []);


  React.useEffect(() => {
    if (filterResponse?.requestId === filterRequest?.requestId) {
      setLatestData(filterResponse);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterResponse]);

  const debouncedSearch = useDebounce(filterRequest, 500);

  const defaultBucketSearchOption = SetBucketSearchOption(queryString.parse(location.search));
  const defaultAuctionStatusGrouping = defaultBucketSearchOption.bucketId || defaultBucketSearchOption.bucketNameContains ? undefined : "Holding";
  const defaultFilter = {
    ...DefaultAuctionMgmtFilter(),
    auctionStatusGrouping: defaultAuctionStatusGrouping,
    bucketNameContains: defaultBucketSearchOption.bucketNameContains,
    bucketId: defaultBucketSearchOption.bucketId,
  };

  React.useEffect(() => {
    setFilterRequest(defaultFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeHandler = (event: any, property: string) => {
    if (filterRequest) {
      if (event.target.value === "All") {
        event.target.value = null;
      }
      setFilterRequest({ ...filterRequest, [property]: event.target.value, pageNumber: 0, requestId: requestId() });
    }
  };

  const changeDirection = (sortDescending: boolean) => {
    if (filterRequest) {
      setFilterRequest({ ...filterRequest, sortDescending: sortDescending, pageNumber: 0, requestId: requestId() });
    }
  };

  const changeIsPaidAndCollected = (event: any) => {
    if (filterRequest) {
      switch (event.target.value) {
        case "Paid and Collected":
          return setFilterRequest({ ...filterRequest, isPaidAndCollected: true, requestId: requestId(), pageNumber: 0 });
        case "Not Paid":
          return setFilterRequest({ ...filterRequest, isPaidAndCollected: false, requestId: requestId(), pageNumber: 0 });
        default:
          return setFilterRequest({ ...filterRequest, isPaidAndCollected: undefined, requestId: requestId(), pageNumber: 0 });
      }
    }
  };

  const changeEndsIn = (event: any) => {
    if (filterRequest) {
      switch (event.target.value) {
        case "Next 7 days":
          return setFilterRequest({ ...filterRequest, endDateLessThanDays: 7, requestId: requestId(), pageNumber: 0 });
        case "Next 14 days":
          return setFilterRequest({ ...filterRequest, endDateLessThanDays: 14, requestId: requestId(), pageNumber: 0 });
        case "Next 21 days":
          return setFilterRequest({ ...filterRequest, endDateLessThanDays: 21, requestId: requestId(), pageNumber: 0 });
        case "Next 28 days":
          return setFilterRequest({ ...filterRequest, endDateLessThanDays: 28, requestId: requestId(), pageNumber: 0 });
        case "Last 7 days":
          return setFilterRequest({ ...filterRequest, endDateLessThanDays: -7, requestId: requestId(), pageNumber: 0 });
        case "Last 14 days":
          return setFilterRequest({ ...filterRequest, endDateLessThanDays: -14, requestId: requestId(), pageNumber: 0 });
        case "Last 21 days":
          return setFilterRequest({ ...filterRequest, endDateLessThanDays: -21, requestId: requestId(), pageNumber: 0 });
        case "Last 28 days":
          return setFilterRequest({ ...filterRequest, endDateLessThanDays: -28, requestId: requestId(), pageNumber: 0 });
        default:
          return setFilterRequest({ ...filterRequest, endDateLessThanDays: undefined, requestId: requestId(), pageNumber: 0 });
      }
    }
  };

  const endsInArray = ["Next 7 days", "Next 14 days", "Next 21 days", "Next 28 days", "Last 7 days", "Last 14 days", "Last 21 days", "Last 28 days"];

  const endsInString = value => {
    switch (value) {
      case 7:
        return "Next 7 days";
      case 14:
        return "Next 14 days";
      case 21:
        return "Next 21 days";
      case 28:
        return "Next 28 days";
      case -7:
        return "Last 7 days";
      case -14:
        return "Last 14 days";
      case -21:
        return "Last 21 days";
      case -28:
        return "Last 28 days";
      default:
        return "";
    }
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setFilterRequest({
      ...filterRequest!,
      pageNumber: newPage,
      requestId: requestId(),
    });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFilterRequest({
      ...filterRequest!,
      numberPerPage: parseInt(event.target.value, 10),
      pageNumber: 0,
      requestId: requestId(),
    });
  };

  React.useEffect(() => {
    setDebounceLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterRequest]);

  React.useEffect(() => {
    if (filterRequest) {
      apiPostAsync();
      setDebounceLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  const reset = () => {
    filterRequest !== null && setFilterRequest(DefaultAuctionMgmtFilter());
  };

  return (
    <>
      {isOptionsLoading && <SearchSkeleton />}
      {filterRequest !== null && (
        <BulkProcessDialog
          auctionFilters={filterRequest}
          open={openBulkProcessDialog}
          setOpen={setBulkProcessDialogOpen}
          refreshSearchResults={apiPostAsync}
        />
      )}
      <Grid container spacing={1}>
        <Grid item xs={12} md={2}>
          {!isOptionsLoading && filterOptions?.lotTypes !== null && (
            <Filter
              title="Lot Type"
              filterOptions={filterOptions?.lotTypes}
              filterPropertyName="lotType"
              changeHandler={changeHandler}
              filterValue={filterRequest?.lotType ? filterRequest.lotType : ""}
            />
          )}
        </Grid>

        <Grid item xs={12} md={2}>
          {!isOptionsLoading && filterOptions?.bodyTypes !== null && (
            <Filter
              title="Body Type"
              filterOptions={filterOptions?.bodyTypes}
              filterPropertyName="bodyType"
              changeHandler={changeHandler}
              filterValue={filterRequest?.bodyType ? filterRequest.bodyType : ""}
            />
          )}
        </Grid>

        <Grid item xs={12} md={2}>
          {!isOptionsLoading && filterOptions?.locations !== null && (
            <Filter
              title="Location"
              filterOptions={filterOptions?.locations}
              filterPropertyName="location"
              changeHandler={changeHandler}
              filterValue={filterRequest?.location ? filterRequest.location : ""}
            />
          )}
        </Grid>

        <Grid item xs={12} md={2}>
          {!isOptionsLoading && (
            <Filter
              title="Ends in"
              filterOptions={endsInArray}
              filterPropertyName="endDateLessThanDays"
              changeHandler={changeEndsIn}
              filterValue={filterRequest?.endDateLessThanDays ? endsInString(filterRequest.endDateLessThanDays) : ""}
            />
          )}
        </Grid>

        <Grid item xs={12} md={2}>
          {!isOptionsLoading && (
            <Filter
              title="Paid"
              filterOptions={["Paid and Collected", "Not Paid"]}
              filterPropertyName="isPaidAndCollected"
              changeHandler={changeIsPaidAndCollected}
              filterValue={
                filterRequest?.isPaidAndCollected === undefined ? "" : filterRequest.isPaidAndCollected ? "Paid and Collected" : "Not Paid"
              }
            />
          )}
        </Grid>

        <Grid item xs={12} md={2}>
          {!isOptionsLoading && filterOptions?.bucketNameAndIds !== null && (
            <FormControl className={classes.width100} variant="outlined" margin="dense">
              <InputLabel variant="outlined">Bucket</InputLabel>
              <Select
                label={"Bucket"}
                onChange={value => {
                  changeHandler(value, "bucketId");
                }}
                value={filterRequest?.bucketId ? filterRequest.bucketId : ""}
                variant="outlined"
              >
                <MenuItem value={"All"}>All</MenuItem>
                {filterOptions?.bucketNameAndIds &&
                  filterOptions?.bucketNameAndIds.map((type: IBucketNameAndId, index: number) => (
                    <MenuItem value={type.id} key={index}>
                      {type.bucketName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
        </Grid>

        <Grid item xs={12} md={3}>
          {!isOptionsLoading && (
            <FormControl className={classes.width100} variant="outlined" margin="dense">
              <TextField
                className={classes.searchTextField}
                margin="dense"
                id="bucketNameSearch"
                placeholder={"Bucket Name Search"}
                variant="outlined"
                fullWidth
                value={filterRequest?.bucketNameContains ? filterRequest.bucketNameContains : ""}
                onChange={value => changeHandler(value, "bucketNameContains")}
              />
            </FormControl>
          )}
        </Grid>

        <Grid item xs={12} md={2}>
          {!isOptionsLoading && filterOptions?.auctionStatusGroupings !== null && (
            <FormControl className={classes.width100} variant="outlined" margin="dense">
              <InputLabel variant="outlined">Status</InputLabel>
              <Select
                label={"status"}
                value={filterRequest?.auctionStatusGrouping ? filterRequest?.auctionStatusGrouping : ""}
                onChange={value => changeHandler(value, "auctionStatusGrouping")}
                variant="outlined"
              >
                <MenuItem value={"All"}>All</MenuItem>
                {(isOptionsLoading || !filterOptions?.auctionStatusGroupings) && <MenuItem value={"Holding"}>Holding</MenuItem>}
                {filterOptions?.auctionStatusGroupings &&
                  filterOptions?.auctionStatusGroupings.map((type: string, index: number) => (
                    <MenuItem value={type} key={index}>
                      {auctionStatusGroupingOptionString(type)}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
        </Grid>

        <Grid item xs={12} md={3}>
          {!isOptionsLoading && filterOptions?.insuranceCompanies !== null && (
            <Filter
              title="Client"
              filterOptions={filterOptions?.insuranceCompanies}
              filterPropertyName="insuranceCompany"
              changeHandler={changeHandler}
              filterValue={filterRequest?.insuranceCompany ? filterRequest.insuranceCompany : ""}
            />
          )}
        </Grid>

        <Grid item xs={12} md={2}>
          {!isOptionsLoading && filterOptions?.sortBy !== null && (
            <FormControl className={classes.width100} variant="outlined" margin="dense">
              <InputLabel variant="outlined">Sort by</InputLabel>
              <Select
                label={"Sort by"}
                value={filterRequest?.sortBy ? filterRequest.sortBy : ""}
                onChange={value => changeHandler(value, "sortBy")}
                variant="outlined"
              >
                {(isOptionsLoading || !filterOptions?.sortBy) && <MenuItem value={"DateAdded"}>Date Added</MenuItem>}
                {filterOptions?.sortBy &&
                  filterOptions?.sortBy.map((type: string, index: number) => (
                    <MenuItem value={type} key={index}>
                      {sentenceCaseTextFromCamelCase(type)}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
        </Grid>

        <Grid item xs={12} md={2}>
          {!isOptionsLoading && (
            <Filter
              title="Direction"
              filterOptions={["Descending", "Ascending"]}
              filterPropertyName="sortDescending"
              changeHandler={value => changeDirection(value.target.value === "Descending")}
              filterValue={filterRequest?.sortDescending ? "Descending" : "Ascending"}
              all={false}
            />
          )}
        </Grid>

        <Grid item xs={12} md={7}>
          {!isOptionsLoading && (
            <FormControl className={classes.width100} variant="outlined" margin="dense">
              <TextField
                className={classes.searchTextField}
                margin="dense"
                id="textSearch"
                placeholder={"Search"}
                variant="outlined"
                fullWidth
                value={filterRequest?.searchText ? filterRequest.searchText : ""}
                onChange={value => {
                  changeHandler(value, "searchText");
                }}
              />
            </FormControl>
          )}
        </Grid>

        <Grid item xs={12} md={3} container>
          {!isOptionsLoading && (
            <Button color="primary" className={classes.searchButton} onClick={reset}>
              Reset
            </Button>
          )}
        </Grid>

        <Grid item xs={12} md={2} container>
          {!isOptionsLoading && (
            <Tooltip
              title={
                filterRequest && filterRequest.auctionStatusGrouping === "Holding"
                  ? ""
                  : "Bulk process is only available for auctions with a status of Holding"
              }
            >
              <div>
                <Button
                  className={classes.bulkProcessButton}
                  fullWidth
                  variant="outlined"
                  color="primary"
                  disabled={debounceLoading || isLoading || !filterRequest || filterRequest?.auctionStatusGrouping !== "Holding"}
                  onClick={() => setBulkProcessDialogOpen(true)}
                >
                  Bulk process
                  <PublishIcon
                    color={
                      debounceLoading || isLoading || !filterRequest || filterRequest?.auctionStatusGrouping !== "Holding" ? "disabled" : "primary"
                    }
                    className={classes.bulkProcessIcon}
                  />
                </Button>
              </div>
            </Tooltip>
          )}
        </Grid>

        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <Pagination
                    count={latestData?.totalNumber ? latestData?.totalNumber : 0}
                    rowsPerPage={filterRequest?.numberPerPage ? filterRequest?.numberPerPage : 0}
                    page={filterRequest?.pageNumber ? filterRequest?.pageNumber : 0}
                    label="Auctions per page"
                    colSpan={5}
                    rowsPerPageOptions={[20, 50, 100]}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableHeadCell}></TableCell>
                  <TableCell className={classes.tableHeadCell}>Item</TableCell>
                  <TableCell className={classes.tableHeadCell}></TableCell>
                  <TableCell className={classes.tableHeadCell}>Info</TableCell>
                  <TableCell className={classes.tableHeadCell}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(isLoading || debounceLoading || !latestData) && <AuctionSkeleton />}
                {!isLoading && !debounceLoading && latestData?.pageResults && latestData?.pageResults.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Typography variant="h5">No auctions available</Typography>
                    </TableCell>
                  </TableRow>
                )}
                {!isLoading &&
                  !debounceLoading &&
                  latestData?.pageResults &&
                  latestData?.pageResults.map((auction: IAuctionInfo, index: number) => (
                    <TableRow key={index} className={classes.tableRow}>
                      <AuctionRow auction={auction} />
                      <ActionIcons auction={auction} refreshAuctions={apiPostAsync} showProxyBids={showProxyBids} />
                    </TableRow>
                  ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <Pagination
                    count={latestData?.totalNumber ? latestData?.totalNumber : 0}
                    rowsPerPage={filterRequest?.numberPerPage ? filterRequest?.numberPerPage : 0}
                    page={filterRequest?.pageNumber ? filterRequest?.pageNumber : 0}
                    colSpan={5}
                    label="Auctions per page"
                    rowsPerPageOptions={[20, 50, 100]}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};
