import React, { useState } from "react";
import moment from "moment";
import useInterval from "./hooks/useInterval";
import { timeLeftFormat } from "../../helpers/text-format/TextFormat";
import { adjustedCurrentTime } from "../../helpers/TimeSync";
import { AuctionStatus } from "../../interfaces/auctions/IAuction";

const oneSecond = 1000;
const tenSeconds = 10000;
const oneMinute = 60000;
const tenMinutes = 600000;
const oneHour = 3600000;
const oneDay = 86400000;

const getIntervalDuration = (endDate: moment.Moment, CurrentDate: moment.Moment) => {
  const timeRemaining = endDate.diff(CurrentDate);

  if (timeRemaining < tenMinutes) {
    return oneSecond;
  }
  if (timeRemaining < oneHour) {
    return tenSeconds;
  }
  if (timeRemaining < oneDay) {
    return oneMinute;
  }
  return oneHour;
};

const TimerContainer: React.FC<{ endDateInput: Date; status: number; handleBiddingClosed: () => void }> = ({
  endDateInput,
  status,
  handleBiddingClosed,
}) => {
  const endDate = moment(endDateInput);

  const [currentDate, setCurrentDate] = useState(adjustedCurrentTime());
  const [interval, setInterval] = useState(getIntervalDuration(endDate, currentDate));

  const timeLeftMS = endDate.diff(currentDate);

  useInterval(() => {
    setCurrentDate(adjustedCurrentTime());
    setInterval(getIntervalDuration(endDate, currentDate));
  }, interval);

  React.useEffect(() => {
    if (timeLeftMS <= 0 && status !== AuctionStatus.Active) {
      handleBiddingClosed();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate]);

  const endingText = () => {
    if (status === AuctionStatus.Active) {
      return <p>Final call</p>;
    }
    return <p>Bidding closed</p>;
  };

  if (timeLeftMS >= 0) {
    const timeLeftDuration = moment.duration(timeLeftMS);
    const timeLeft = timeLeftFormat(timeLeftDuration);

    if (timeLeft === "") {
      return endingText();
    } else {
      return <p>{timeLeft}</p>;
    }
  } else {
    return endingText();
  }
};

export default TimerContainer;
