import * as React from "react";
import { ExistingBucketsStyles } from "./styles/ExistingBuckets";
import { LoadingComponent } from "../loading/LoadingComponent";
import { Grid, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, TableFooter } from "@material-ui/core";
import { IBucketFilterResult } from "../../services/BucketService";
import { BucketRow } from "./BucketRow";
import { Pagination } from "../../helpers/Pagination";

interface ExistingBucketsProps {
  loadingBuckets: boolean;
  bucketsResults: IBucketFilterResult | null;
  refreshBuckets: () => void;
  changePage: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
  changeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export const ExistingBuckets: React.FC<ExistingBucketsProps> = ({
  loadingBuckets,
  bucketsResults,
  refreshBuckets,
  changePage,
  changeRowsPerPage,
}) => {
  const classes = ExistingBucketsStyles();
  return (
    <>
      {loadingBuckets && <LoadingComponent label="Loading Buckets" />}
      {!loadingBuckets && (
        <Grid container>
          <Grid xs={12} item>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Schedule Date</TableCell>
                    <TableCell>Increment</TableCell>
                    <TableCell className={classes.numberCell}>Lots</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bucketsResults?.pageResults && bucketsResults?.pageResults.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={3}>No Buckets available</TableCell>
                    </TableRow>
                  )}
                  {bucketsResults?.pageResults &&
                    bucketsResults?.pageResults.map(bucket => <BucketRow key={bucket.id} bucket={bucket} refreshBuckets={refreshBuckets} />)}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <Pagination
                      count={bucketsResults?.totalNumber ? bucketsResults?.totalNumber : 0}
                      rowsPerPage={bucketsResults?.numberPerPage ? bucketsResults?.numberPerPage : 0}
                      page={bucketsResults?.pageNumber ? bucketsResults?.pageNumber : 0}
                      colSpan={6}
                      rowsPerPageOptions={[20, 50, 100]}
                      label="Buckets per page"
                      onChangePage={changePage}
                      onChangeRowsPerPage={changeRowsPerPage}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      )}
    </>
  );
};
