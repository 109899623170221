import * as React from "react";
import { Table, TableRow, TableCell, Card, CardActionArea, CardContent, CardActions, IconButton, TableBody, Typography } from "@material-ui/core";
import { IVerificationSubmission } from "../../interfaces/bidders/IVerificationSubmission";
import * as Styles from "./styles/BidderDocumentStyles";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import RotateRightIcon from "@material-ui/icons/RotateRight";
import { success } from "../../theme";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import WarningIcon from "@material-ui/icons/Warning";

export const BidderDocument: React.FC<{ document: IVerificationSubmission }> = ({ document }) => {
  const classes = Styles.BidderDocumentStyles();

  const [rotation, setRotation] = React.useState(document.rotation);

  React.useEffect(() => {
    setRotation(document.rotation);
  }, [document]);

  const documentStatus = () => {
    if (document.fileValid) {
      return (
        <Typography style={{ color: success }}>
          Accepted <CheckCircleIcon fontSize="small" className={classes.icon} />
        </Typography>
      );
    } else if (!document.fileValid && document.feedbackIfInvalid) {
      return (
        <Typography color="error">
          Rejected <CancelIcon fontSize="small" className={classes.icon} />
        </Typography>
      );
    } else {
      return (
        <Typography>
          Not approved <WarningIcon fontSize="small" className={classes.icon} />
        </Typography>
      );
    }
  };

  return (
    <Card className={classes.Card}>
      <CardActionArea className={classes.documentInfo}>
        <div style={{ overflow: "auto", height: "60vh", padding: rotation % 180 === 0 ? "inherit" : "8px 24px 8px 24px" }}>
          <img
            style={{
              maxWidth: rotation % 180 === 0 ? "100vw" : "100vh",
              maxHeight: rotation % 180 === 0 ? "100vh" : "100vw",
              transform: `translatex(0) translatey(calc(50vh - 50%)) rotate(${rotation}deg)`,
            }}
            src={document.url}
            alt="Bidder document"
          />
        </div>
      </CardActionArea>
      <CardActions disableSpacing className={classes.documentInfo}>
        <IconButton aria-label="Rotate Left" onClick={() => setRotation(curr => curr - 90)}>
          <RotateLeftIcon />
        </IconButton>
        <IconButton aria-label="Rotate Right" style={{ marginLeft: "auto" }} onClick={() => setRotation(curr => curr + 90)}>
          <RotateRightIcon />
        </IconButton>
      </CardActions>
      <CardContent className={classes.documentInfo}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell variant="head">Document type</TableCell>
              <TableCell align="left">{document.documentType}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Document approval status</TableCell>
              <TableCell align="left">{documentStatus()}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};
