import { GetClientSettings } from "./SettingsService";
import { useReadonlyData, usePostedData, useVersionedData } from "./useVersionedData";
import { ISalvageClientSettings } from "../interfaces/ISalvageClientSettings";

const ClientSettings: ISalvageClientSettings = GetClientSettings();

export interface AuctionReportRequest {
  location?: string;
  insuranceCompany?: string;
  sortBy?: string;
  sortDescending: boolean;
  dateFrom?: string;
  dateTo?: string;
  auctionStatusGrouping?: string[];
  requestId: string;
}

export const DefaultClosedAuctionReportRequest = (): AuctionReportRequest => {
  return { sortDescending: false, auctionStatusGrouping: ["Closed", "ClosedButNotSold", "ClosedAndSold"], requestId: Date.now.toString() };
};

export const DefaultAuctionStatusReportRequest = (): AuctionReportRequest => {
  return {
    sortDescending: false,
    auctionStatusGrouping: ["Incomplete", "Live", "Holding"],
    requestId: Date.now.toString(),
  };
};

export interface AuctionReportOptions {
  locations: string[];
  insuranceCompanies: string[];
  auctionStatusGroupings: string[];
  sortBy: string[];
}

export interface BidderReportOptions {
  sortBy: string[];
}

export interface BidderSignUpReportRequest {
  sortBy?: string;
  sortDescending: boolean;
  createdBefore?: string;
  createdAfter?: string;
  requestId: string;
}

export const DefaultBidderReportRequest = (): BidderSignUpReportRequest => {
  return { sortDescending: false, requestId: Date.now.toString() };
};

export interface BidderMailingListReportRequest {
  sortBy?: string;
  sortDescending: boolean;
  mailingListOptIn?: boolean;
  requestId: string;
}

export interface MemberNameAndId {
  id: string;
  memberName: string;
}

export interface MarginShareReportOptions {
  memberNameAndIds: MemberNameAndId[];
}

export interface MarginShareReportRequest {
  dateFrom?: string;
  dateTo?: string;
  memberId?: string;
  requestId: string;
}

export interface MaliciousBidderReportRequest {
  auctionDays: number;
  carsWon: number;
  carsWonNumberOfDays: number;
  chbOnMoreThanXDays: number;
}

export interface LiveLotDetailsReportRequest {
  sortBy?: string;
  sortDescending: boolean;
  memberId?: string;
  dateFrom?: string;
  dateTo?: string;
  requestId: string;
}

export const DefaultMarginShareReportRequest = (): MarginShareReportRequest => {
  return { requestId: Date.now.toString() };
};

export const DefaultMaliciousBidderReportRequest = (): MaliciousBidderReportRequest => {
  return { auctionDays: 5, carsWon: 1, carsWonNumberOfDays: 1, chbOnMoreThanXDays: 2 }
};

export const DefaultLiveLotDetailsReportRequest = (): LiveLotDetailsReportRequest => {
  return { requestId: Date.now.toString(), sortDescending: false };
};

export interface FileResult {
  byteArray: string;
  requestId: string;
}

export const useClosedReportOptions = (onError: (httpStatusCode: number) => void) =>
  useReadonlyData<AuctionReportOptions>(`${ClientSettings.AuctionPrivateApiUrl}/auctions/report/closed/options`, onError);

export const useAuctionClosedReport = (onError: (httpStatusCode: number) => void) =>
  usePostedData<FileResult, AuctionReportRequest>(ClientSettings.AuctionPrivateApiUrl + `/auctions/report/closed`, onError);

export const useAuctionStatusReportOptions = (onError: (httpStatusCode: number) => void) =>
  useReadonlyData<AuctionReportOptions>(`${ClientSettings.AuctionPrivateApiUrl}/auctions/report/auctionstatus/options`, onError);

export const useAuctionStatusReport = (onError: (httpStatusCode: number) => void) =>
  usePostedData<FileResult, AuctionReportRequest>(ClientSettings.AuctionPrivateApiUrl + `/auctions/report/auctionstatus`, onError);

export const useBidderSignUpReportOptions = (onError: (httpStatusCode: number) => void) =>
  useReadonlyData<BidderReportOptions>(`${ClientSettings.UsersPrivateApiUrl}/bidders/report/signup/options`, onError);

export const useBidderSignUpReport = (onError: (httpStatusCode: number) => void) =>
  usePostedData<FileResult, BidderSignUpReportRequest>(ClientSettings.UsersPrivateApiUrl + `/bidders/report/signup`, onError);

export const useBidderMailingListReportOptions = (onError: (httpStatusCode: number) => void) =>
  useReadonlyData<BidderReportOptions>(`${ClientSettings.UsersPrivateApiUrl}/bidders/report/mailinglist/options`, onError);

export const useBidderMailingListReport = (onError: (httpStatusCode: number) => void) =>
  usePostedData<FileResult, BidderMailingListReportRequest>(ClientSettings.UsersPrivateApiUrl + `/bidders/report/mailinglist`, onError);

export const useMarginShareReportOptions = (onError: (httpStatusCode: number) => void) =>
  useReadonlyData<MarginShareReportOptions>(`${ClientSettings.UsersPrivateApiUrl}/members/report/share/options`, onError);

export const useMarginShareReport = (onError: (httpStatusCode: number) => void) =>
  usePostedData<FileResult, MarginShareReportRequest>(`${ClientSettings.UsersPrivateApiUrl}/members/report/share`, onError);

export const usePaymentReconcilliationReport = (onError: (httpStatusCode: number) => void) =>
  usePostedData<FileResult, MarginShareReportRequest>(`${ClientSettings.UsersPrivateApiUrl}/members/report/paymentreconcilliation`, onError);

export const useMaliciousBidderReport = (onError: (httpStatusCode: number) => void) =>
  usePostedData<FileResult, MaliciousBidderReportRequest>(`${ClientSettings.AuctionPrivateApiUrl}/auctions/report/maliciousbidderreport`, onError);

  export const useLiveLotDetailsReportOptions = (onError: (httpStatusCode: number) => void) =>
  useReadonlyData<AuctionReportOptions>(`${ClientSettings.AuctionPrivateApiUrl}/auctions/report/livelotdetails/options`, onError);

  export const useLiveLotDetailsReport = (onError: (httpStatusCode: number) => void) =>
  usePostedData<FileResult, any>(`${ClientSettings.AuctionPrivateApiUrl}/auctions/report/livelotdetailsreport`, onError);