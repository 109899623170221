import { ApiService, HttpResponse } from "./ApiService";
import { IBidder } from "../interfaces/bidders/IBidder";
import { GetClientSettings } from "./SettingsService";
import { ISalvageClientSettings } from "../interfaces/ISalvageClientSettings";
import { useVersionedData, useReadonlyData } from "./useVersionedData";
import { IVerificationSubmission } from "../interfaces/bidders/IVerificationSubmission";
import { IBiddersWithPotentialDuplicates } from "../interfaces/bidders/IBiddersWithPotentialDuplicates";
import { IBidderNotes } from "../interfaces/bidders/IBidderNote";
import { ISubscriptionOrder } from "../interfaces/bidders/ISubscriptionOrder";
import { IAddManualPaymentDto } from "../interfaces/bidders/IAddManualPaymentDto";
import { BidderNoteFilterType } from "../enums/BidderNoteFilterType";
import { SuspensionStatusType } from "../enums/SuspensionStatusType";
import { IBid, IBidPageResult } from "../interfaces/auctions/IBid";

const ClientSettings: ISalvageClientSettings = GetClientSettings();

export async function GetBidder(bidderId: string) {
  const bidder = ApiService().Get<IBidder>(`${ClientSettings.UsersPrivateApiUrl}/bidder/${bidderId}`);
  return bidder;
}

export const UpdateBidder = async (bidder: IBidder): Promise<HttpResponse<IBidder>> => {
  return await ApiService().Put<IBidder>(ClientSettings.UsersPrivateApiUrl + "/bidder/", bidder);
};

export const useBidder = (bidderId: string, onError?: (httpStatusCode: number) => void) =>
  useReadonlyData<IBidder>(`${ClientSettings.UsersPrivateApiUrl}/bidder/${bidderId}`, onError);

export const useBiddersAwaitingVerification = (onError?: (httpStatusCode: number) => void) =>
  useReadonlyData<IBiddersWithPotentialDuplicates[]>(`${ClientSettings.UsersPrivateApiUrl}/verification/bidders/duplication-check/`, onError);

export const approveBidder = async (bidderId: string, reason: string): Promise<HttpResponse<undefined>> => {
  return await ApiService().Post<undefined>(ClientSettings.UsersPrivateApiUrl + `/bidder/${bidderId}/approve`, reason);
};

export const unsuspendBidder = async (bidderId: string, reason: string, suspensionIds: string[]): Promise<HttpResponse<undefined>> => {
  return await ApiService().Post<undefined>(ClientSettings.UsersPrivateApiUrl + `/bidder/${bidderId}/unsuspend`, {
    reason: reason,
    suspensionIds: suspensionIds,
  });
};

export const suspendBidder = async (bidderId: string, suspensionType: SuspensionStatusType, reason: string, auctionIds: string[] = []): Promise<HttpResponse<undefined>> => {
  return await ApiService().Post<undefined>(ClientSettings.UsersPrivateApiUrl + `/bidder/${bidderId}/suspend`, {
    reason: reason,
    associatedAuctionIds: auctionIds,
    suspensionType: suspensionType
  });
};

export const deleteBidder = async (bidderId: string): Promise<HttpResponse<boolean>> => {
  return await ApiService().Delete<boolean>(ClientSettings.UsersPrivateApiUrl + `/bidder/${bidderId}/delete`, {});
};

export const passwordResetBidder = async (bidderId: string): Promise<HttpResponse<undefined>> => {
  return await ApiService().Post<undefined>(ClientSettings.UsersPrivateApiUrl + `/bidder/${bidderId}/reset-password`, null);
};

export const useBiddersAwaitingDocumentChecks = (onError?: (httpStatusCode: number) => void) =>
  useReadonlyData<IBidder[]>(`${ClientSettings.UsersPrivateApiUrl}/verification/bidders/awaitingdocumentchecks/`, onError);

export const useBidderVerificationDocuments = (bidderId: string | null, onError?: (httpStatusCode: number, resultBody?: string) => void) =>
  useVersionedData<IVerificationSubmission[]>(
    bidderId === null ? null : `${ClientSettings.UsersPrivateApiUrl}/verification/bidder/${bidderId}/documents/`,
    onError
  );

export const getBidderSiteNotes = async (bidderId: string, type: string, page: number, pageSize: number): Promise<HttpResponse<IBidderNotes>> => {
  if (type === "All") {
    return await ApiService().Get<IBidderNotes>(ClientSettings.UsersPrivateApiUrl + `/bidder/${bidderId}/notes/site/${page}/${pageSize}`);
  }
  return await ApiService().Get<IBidderNotes>(ClientSettings.UsersPrivateApiUrl + `/bidder/${bidderId}/notes/site/type/${type}/${page}/${pageSize}`);
};

export const getBidderCommunicationNotes = async (
  bidderId: string,
  type: BidderNoteFilterType,
  page: number,
  pageSize: number
): Promise<HttpResponse<IBidderNotes>> => {
  return await ApiService().Get<IBidderNotes>(
    ClientSettings.UsersPrivateApiUrl + `/bidder/${bidderId}/notes/communication/type/${type}/${page}/${pageSize}`
  );
};

export const addBidderNote = async (bidderId: string, isPublic: boolean, message: string): Promise<HttpResponse<undefined>> => {
  return await ApiService().Post<undefined>(ClientSettings.UsersPrivateApiUrl + `/bidder/${bidderId}/notes`, { note: message, IsPublic: isPublic });
};
export const giveTrial = async (bidderId: string, weeks: number): Promise<HttpResponse<undefined>> => {
  return await ApiService().Post<undefined>(ClientSettings.PaymentsPrivateApiUrl + `/bidder/${bidderId}/free-trial/${weeks}`, false);
};

export const getOrderHistory = async (bidderId: string): Promise<HttpResponse<ISubscriptionOrder[]>> => {
  return await ApiService().Get<ISubscriptionOrder[]>(ClientSettings.PaymentsPrivateApiUrl + `/order/history/bidder/${bidderId}`, false);
};

export const addManualPayment = async (bidderId: string, paymentDetails: IAddManualPaymentDto): Promise<HttpResponse<undefined>> => {
  return await ApiService().Post<undefined>(ClientSettings.PaymentsPrivateApiUrl + `/bidder/${bidderId}/add-payment`, paymentDetails);
};

export const getBiddersBids = async (
  bidderId: string, 
  auctionId: string, 
  page: number, 
  pageSize: number
): Promise<HttpResponse<IBidPageResult>> => {
  return await ApiService().Get<IBidPageResult>(
    ClientSettings.UsersPrivateApiUrl + `/bidder/${bidderId}/bids/auction/${auctionId}/${page}/${pageSize}`
  );
};


