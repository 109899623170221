import * as React from "react";
import { Paper, List, IconButton, ListItem, ListItemText, ListItemSecondaryAction, TextField, Box } from "@material-ui/core";
import { useSnackbar, OptionsObject } from "notistack";
import { LoadingComponent } from "../loading/LoadingComponent";
import { GetMembersInsuranceCompanies, DeleteMembersInsuranceCompanies, AddMembersInsuranceCompanies } from "../../services/MemberService";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import * as Styles from "./styles/MaintenanceStyles";

const variantInfo: OptionsObject = { variant: "info" };
const variantError: OptionsObject = { variant: "error" };

export const InsuranceCompaniesComponent: React.FC = () => {
  const classes = Styles.MaintenanceStyles();

  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [insuranceCompanies, setInsuranceCompanies] = React.useState<Array<string>>([]);
  const [newItem, setNewItem] = React.useState<string>("");
  const [errorMessage, setErrorMessage] = React.useState<string>("");
  const [addingItem, setAddingItem] = React.useState<boolean>(false);

  React.useEffect(() => {
    async function fetchData() {
      try {
        const existingCompanies = await GetMembersInsuranceCompanies();
        if (existingCompanies.parsedBody) {
          setInsuranceCompanies(existingCompanies.parsedBody);
          setLoading(false);
        } else {
          enqueueSnackbar("We got an unexpected response while trying to load the list of insurance companies", variantError);
        }
      } catch (error) {
        enqueueSnackbar("We were unable to load your insurance companies, please try and reload the page", variantError);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function deleteItem(item: string) {
    try {
      await DeleteMembersInsuranceCompanies(item);
      enqueueSnackbar(`Removed the client ${item}`, variantInfo);
      setInsuranceCompanies(existing => existing.filter(p => p !== item));
    } catch (error) {
      enqueueSnackbar(`Unable to remove the client ${item} because an error occurred`, variantError);
    }
  }

  async function addItem() {
    if (newItem === "") {
      setErrorMessage("You must enter a client name");
      return;
    }
    if (insuranceCompanies.some(p => p === newItem)) {
      setErrorMessage("This client already exists");
      return;
    }
    try {
      setAddingItem(true);
      await AddMembersInsuranceCompanies(newItem);
      enqueueSnackbar(`Added the client ${newItem}`, variantInfo);
      setInsuranceCompanies(existing => [newItem, ...existing]);
    } catch (exception) {
      enqueueSnackbar(`Unable to add the client ${newItem} because an error occurred`, variantError);
    }
    setAddingItem(false);
    setErrorMessage("");
    setNewItem("");
  }

  function renderExistingItems() {
    return (
      <Paper elevation={3}>
        <List style={{ maxHeight: "200px", overflow: "auto" }} dense={true}>
          {insuranceCompanies
            .sort((a, b) => a.localeCompare(b))
            .map(item => (
              <ListItem key={item}>
                <ListItemText primary={item} />
                <ListItemSecondaryAction>
                  <IconButton color="primary" edge="end" aria-label="delete" onClick={() => deleteItem(item)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
        </List>
      </Paper>
    );
  }
  function renderAddCompany() {
    return (
      <Box className={classes.inputBoxWithIcon}>
        <TextField
          required
          fullWidth
          error={errorMessage !== ""}
          helperText={errorMessage}
          name={"addInsuranceCompany"}
          label="Add Client"
          value={newItem}
          onChange={value => setNewItem(value.target.value)}
        />
        <IconButton color="primary" type="submit" className={classes.iconButton} aria-label="Save" disabled={addingItem} onClick={() => addItem()}>
          <SaveIcon />
        </IconButton>
      </Box>
    );
  }
  return loading ? (
    <LoadingComponent label={"Loading Data"} />
  ) : (
    <Box className={classes.inputRoot}>
      {renderAddCompany()}
      <Box>{renderExistingItems()}</Box>
    </Box>
  );
};
