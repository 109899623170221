import { makeStyles } from "@material-ui/core/styles";

export const MemberDetailsStyles = makeStyles(theme => ({
  containerTitle: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  sellerName: { margin: "0 0 10px 0", textAlign: "left", fontWeight: "bold" },
  image: {
    height: "20%",
  },
  map: {
    paddingBottom: "8px",
    border: "none",
    width: "100%",
    height: "100%",
  },
  infoIcon: {
    verticalAlign: "text-top",
  },
}));
