import React, { useEffect } from "react";
import { CircularProgress, Box, TextField, IconButton, Tooltip } from "@material-ui/core";
import { useSnackbar, OptionsObject } from "notistack";
import SaveIcon from "@material-ui/icons/Save";
import * as Styles from "./styles/LiveBidItemDialogStyles";
import { IValidation, ValidationDefault } from "../../interfaces/IValidation";
import { useBuyback } from "../../services/BidService";
import { useSignalrLatestBid } from "../../hooks/useSignalR";

const variantError: OptionsObject = { variant: "error" };
const variantSuccess: OptionsObject = { variant: "success" };

interface DialogBuybackProps {
  auctionId: string;
  buybackDisabled: boolean;
  update: () => void;
}

export const DialogBuyback: React.FC<DialogBuybackProps> = ({ auctionId, buybackDisabled, update }) => {
  const classes = Styles.LiveBidItemDialogStyles();

  var error: boolean = false;

  const { enqueueSnackbar } = useSnackbar();
  const [validation, setValidation] = React.useState<IValidation>(ValidationDefault);

  const [signalrLatestBid] = useSignalrLatestBid(auctionId);

  const [buyback, setBuyback, buybackLoading, , putBuyback, refreshBuyback, buybackValidation] = useBuyback(
    auctionId,
    httpStatusCode => {
      error = true;
      if (httpStatusCode !== 400) {
        refreshBuyback();
        enqueueSnackbar("There was a problem with your buyback request, please try again.", variantError);
      }
    },
    signalrLatestBid !== null && signalrLatestBid !== undefined
  );

  useEffect(() => {
    refreshBuyback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signalrLatestBid]);

  useEffect(() => {
    setValidation(buybackValidation === null ? ValidationDefault : buybackValidation);
  }, [buybackValidation]);

  const isError = (field: string): boolean => {
    return validation[field] !== undefined;
  };

  const ErrorMessage = (field: string): string => {
    return validation[field] !== undefined ? validation[field].error : "";
  };

  const onFieldChange = (e: any, errorName: string) => {
    setValidation({
      ...validation,
      [errorName]: { field: "", error: "" },
    });

    var value: any;

    if (e.target.type === "checkbox") {
      value = e.target.checked;
    } else if (e.target.type === "number") {
      value = e.target.value === "" ? 0 : e.target.value;
    } else {
      value = e.target.value;
    }

    if (buyback) {
      setBuyback({
        ...buyback,
        [e.target.name]: value,
      });
    }
  };

  const updateBuyback = () => {
    error = false;
    putBuyback().then(() => {
      if (!error) {
        refreshBuyback();
        update();
        enqueueSnackbar("Buyback submitted.", variantSuccess);
      }
    });
  };

  const tooltipMessage = () => {
    if (buybackDisabled) {
      return "Buyback disabled, buyback has already been placed";
    } else {
      return "Submit Buyback";
    }
  };

  return (
    <Box className={classes.inputRoot}>
      <TextField
        required
        fullWidth
        type="number"
        error={isError("Amount")}
        helperText={ErrorMessage("Amount")}
        name={"amount"}
        label="Buyback Amount"
        value={buyback?.amount && buyback?.amount > 0 ? buyback?.amount : ""}
        onChange={value => onFieldChange(value, "Amount")}
        disabled={buybackDisabled}
        inputProps={{
          step: "1",
        }}
      />
      <Tooltip title={tooltipMessage()}>
        <div>
          <IconButton
            color={"primary"}
            type="submit"
            className={classes.iconButton}
            aria-label="Save"
            onClick={updateBuyback}
            disabled={buybackLoading || buybackDisabled}
          >
            <SaveIcon />
          </IconButton>
        </div>
      </Tooltip>
      {buybackLoading && <CircularProgress color="primary" size={16} thickness={8} />}
    </Box>
  );
};
