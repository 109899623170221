import * as React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Box, Hidden } from "@material-ui/core";
import { INavigationGroup } from "../../interfaces/navigation/INavigationGroup";
import { DesktopMenu } from "./DesktopMenu";
import { MobileMenu } from "./MobileMenu";
import { AppContext } from "../../contexts/AppContext";
import { MemberPermission } from "../../services/MemberService";
import { MsalConsumer } from "../../authentication/MsalContext";

interface NavigationProps {
  lightTheme: boolean;
}

export const Navigation: React.FC<NavigationProps> = ({ lightTheme }) => {
  const context = React.useContext(AppContext);

  const hasPermission = (memberPermission: MemberPermission) => {
    if (!context) {
      return false;
    }

    if (context.memberPermissions >= memberPermission) {
      return true;
    }

    return false;
  };

  const getMenuItems = () => {
    var menuItems: INavigationGroup[] = [];

    if (hasPermission(MemberPermission.AccountAdmin)) {
      menuItems.push({
        navigationGroupName: "Home",
        navigationGroupLinks: [{ title: "Home", path: "/" }],
      });
    }

    if (hasPermission(MemberPermission.BucketAdmin)) {
      menuItems.push({
        navigationGroupName: "Auction Mgt",
        navigationGroupLinks: [
          { title: "Lot Management", path: "/AuctionManagement/Auction" },
          { title: "Bucket Management", path: "/Buckets" },
          { title: "Pack Upload", path: "/PackImport" },
          { title: "Featured Lot Management", path: "/ManageFeaturedAuctions" },
        ],
      });
    }

    if (hasPermission(MemberPermission.SuperAdmin)) {
      menuItems.push({
        navigationGroupName: "Bidder Mgt",
        navigationGroupLinks: [
          { title: "Search Bidders", path: "/BidderSearch" },
          { title: "Id Verification", path: `/IdVerification` },
          // { title: "Refunds", path: "/Refunds" },
        ],
      });
    } else if (hasPermission(MemberPermission.AccountAdmin)) {
      menuItems.push({
        navigationGroupName: "Bidder Mgt",
        navigationGroupLinks: [
          { title: "Search Bidders", path: "/BidderSearch" },
          { title: "Id Verification", path: `/IdVerification` },
        ],
      });
    }

    if (hasPermission(MemberPermission.FullMember)) {
      menuItems.push({
        navigationGroupName: "Live Auctions",
        navigationGroupLinks: [{ title: "Manage Live Bids", path: `/LiveBids` }],
      });
    }

    if (hasPermission(MemberPermission.SuperAdmin)) {
      menuItems.push({
        navigationGroupName: "Reporting",
        navigationGroupLinks: [
          { title: "LIVE Lot Details Report", path: "/LiveLotDetailsReport" },
          { title: "Closed Auction Reporting", path: "/ClosedAuctionReporting" },
          { title: "Auction Status Reporting", path: "/AuctionStatusReporting" },
          { title: "Bidder Sign Up Reporting", path: "/BidderSignUpReporting" },
          { title: "Bidder Mailing List Reporting", path: "/BidderMailingListReporting" },
          { title: "Margin Share Reporting", path: "/MarginShareReporting" },
          { title: "Payment Reconcilliation", path: "/PaymentReconcilliationReporting" },
          { title: "Malicious Bidder Report", path: "/MaliciousBidderReport" },
        ],
      });
    } else if (hasPermission(MemberPermission.BucketAdmin)) {
      menuItems.push({
        navigationGroupName: "Reporting",
        navigationGroupLinks: [
          { title: "LIVE Lot Details Report", path: "/LiveLotDetailsReport" },
          { title: "Closed Auction Reporting", path: "/ClosedAuctionReporting" },
          { title: "Auction Status Reporting", path: "/AuctionStatusReporting" },
          { title: "Bidder Sign Up Reporting", path: "/BidderSignUpReporting" },
          { title: "Bidder Mailing List Reporting", path: "/BidderMailingListReporting" },
        ],
      });
    } else if (hasPermission(MemberPermission.AccountAdmin)) {
      menuItems.push({
        navigationGroupName: "Reporting",
        navigationGroupLinks: [
          { title: "LIVE Lot Details Report", path: "/LiveLotDetailsReport" },
          { title: "Bidder Sign Up Reporting", path: "/BidderSignUpReporting" },
          { title: "Bidder Mailing List Reporting", path: "/BidderMailingListReporting" },
        ],
      });
    }

    if (hasPermission(MemberPermission.LiveAuctionAdmin)) {
      menuItems.push({
        navigationGroupName: "Member MGT",
        navigationGroupLinks: [
          { title: "Member Details", path: `/MemberDetails/` },
          { title: "Administrators & Permissions", path: `/Permissions/` },
        ],
      });
    } else if (hasPermission(MemberPermission.AccountAdmin)) {
      menuItems.push({
        navigationGroupName: "Member",
        navigationGroupLinks: [{ title: "Member Details", path: `/MemberDetails/` }],
      });
    }

    if (hasPermission(MemberPermission.LiveAuctionAdmin)) {
      menuItems.push({
        navigationGroupName: "Configuration",
        navigationGroupLinks: [{ title: "Clients", path: `/Configuration/InsuranceCompanies` }],
      });
    }

    if (hasPermission(MemberPermission.None)) {
      menuItems.push({
        navigationGroupName: "RulesAndFees",
        navigationGroupLinks: [{ title: "Rules And Fees", path: `/RulesAndFees` }],
      });
    }

    return menuItems;
  };

  return (
    <>
      <AppBar position={"static"} style={{ backgroundColor: "#6C6C6C" }}>
        <Toolbar variant="regular">
          <MsalConsumer>
            {msal => (
              <>
                {msal.accounts.length > 0 && (
                  <>
                    <Hidden smDown>
                      <Box flexGrow={1} display="flex" justifyContent="center">
                        {getMenuItems().map((item: INavigationGroup, index: number) => {
                          return <DesktopMenu lightTheme={lightTheme} key={index} navigationGroup={item} />;
                        })}
                      </Box>
                      <Box></Box>
                    </Hidden>
                    <Hidden mdUp>
                      <MobileMenu navigationGroups={getMenuItems()} />
                    </Hidden>
                  </>
                )}
              </>
            )}
          </MsalConsumer>
        </Toolbar>
      </AppBar>
    </>
  );
};
