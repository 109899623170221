import { Grid } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import React from "react";

export const EditBucketCardSkeleton: React.FC<{ items: number }> = ({ items }) => (
  <>
    {Array.from(Array(items).keys()).map((_, index) => (
      <Grid key={index} container spacing={2} style={{ marginTop: "10px" }}>
        <Grid item xs={4}>
          <Skeleton variant="rect" height="20px" />
        </Grid>
        <Grid item xs={8}>
          <Skeleton variant="text" height="10px" animation="wave" width="100%" />
          <Skeleton variant="text" height="10px" animation="wave" width="100%" />
        </Grid>
      </Grid>
    ))}
  </>
);
