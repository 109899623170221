import React from "react";
import * as Styles from "../styles/LotFormStyles";
import { StepType } from "../types/StepTypes";
import { Step, Stepper, StepLabel } from "@material-ui/core";

interface LotFormStepperProps {
  steps: string[];
  activeStep: StepType;
}

export const LotFormStepper: React.FC<LotFormStepperProps> = ({ steps, activeStep }) => {
  const classes = Styles.LotFormStyles();

  return (
    <Stepper nonLinear activeStep={activeStep} className={classes.stepper}>
      {steps.map((label, index) => (
        <Step key={index}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};
