import { makeStyles } from "@material-ui/core/styles";

export const FooterStyles = makeStyles(theme => ({
  root: {
    height: "100px",
    backgroundColor: "#393939",
    flexGrow: 1,
    marginTop: "32px",
  },
  versionContainer: {
    position: "relative"
  },
  versionText: {
    position: "absolute", bottom: "0", right: "0", padding: "0", margin: "4px 12px", color: "white", fontSize: "8px"
  }
}));
