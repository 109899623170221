import React from "react";
import moment from "moment";
import { TableRow, TableCell, Table, TableHead, TableBody, Paper, Typography } from "@material-ui/core";
import { useLiveBidsWatch, Watch } from "../../services/AuctionService";
import * as Styles from "./styles/LiveBidItemDialogStyles";
import Skeleton from "@material-ui/lab/Skeleton";
import { endDateFormat } from "../../helpers/text-format/TextFormat";
import { Link } from "react-router-dom";

interface DialogWatchesProps {
  auctionId: string;
}
export const DialogWatches: React.FC<DialogWatchesProps> = ({ auctionId }) => {
  const classes = Styles.LiveBidItemDialogStyles();

  const [watches, watchesLoading] = useLiveBidsWatch(auctionId);

  const watchList = (): any => {
    if (watches && watches.length > 0) {
      return watches.map((watching: Watch, index: number) => (
        <TableRow key={index} className={classes.tableRow}>
          <TableCell align="left" size="small">
            <Link style={{ textDecoration: "none", color: "black" }} to={`/BidderDetailsChange/${watching.bidderId}`}>
              {watching.bidderName}
            </Link>
          </TableCell>
          <TableCell align="left" size="small">
            {endDateFormat(moment(watching.lastUpdated).toString(), "")}
          </TableCell>
        </TableRow>
      ));
    } else {
      return (
        <TableRow>
          <TableCell colSpan={2} align="left" size="small">
            No one is watching this auction
          </TableCell>
        </TableRow>
      );
    }
  };

  const headerCount = () => {
    if (watchesLoading) {
      return (
        <Typography variant="subtitle2" component="h1" gutterBottom>
          Watchers: <Skeleton style={{ display: "inline-block" }} variant="text" width={25} />
        </Typography>
      );
    } else {
      return (
        <Typography variant="subtitle2" component="h1" gutterBottom>
          Watchers: {watches ? watches.length : 0}
        </Typography>
      );
    }
  };

  return (
    <Paper className={classes.list}>
      {headerCount()}
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Bidder name</TableCell>
            <TableCell>Watched From</TableCell>
          </TableRow>
        </TableHead>
        {watchesLoading && (
          <TableBody>
            <TableRow>
              <TableCell>
                <Skeleton variant="text" height={20} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" height={20} />
              </TableCell>
            </TableRow>
          </TableBody>
        )}
        {!watchesLoading && <TableBody>{watchList()}</TableBody>}
      </Table>
    </Paper>
  );
};
