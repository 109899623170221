import { makeStyles } from "@material-ui/core/styles";
import { white, black } from "../../../theme";
import "typeface-inter/inter.css";

export const DesktopMenuStyles = makeStyles(theme => ({
  buttonLight: {
    color: white,
    margin: "0 12px",
    fontSize: "12px",
    fontFamily: "inter",
  },
  buttonDark: {
    color: black,
    margin: "0 12px",
    fontSize: "12px",
    fontFamily: "inter",
  },
  isOpen: { display: "none" },
  notOpen: { display: "" },
  popper: {
    zIndex: 10000,
  },
}));
