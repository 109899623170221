import { makeStyles } from "@material-ui/core/styles";

export const DesktopMenuStyles = makeStyles(theme => ({
  button: {
    fontSize: "32px",
  },
  draw: {
    minWidth: "400px",
  },
}));
