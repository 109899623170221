import * as React from "react";
import { BidderDetailsContainer } from "../components/bidder-details/BidderDetailsContainer";
import { RouteComponentProps } from "react-router";
import { Container, Box } from "@material-ui/core";
import { SecureArea } from "../components/authentication/SecureArea";
import { MemberPermission } from "../services/MemberService";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../services/TelemetryService";

type TParams = { bidderId: string };

function BidderDetails({ match }: RouteComponentProps<TParams>) {
  return (
    <Container maxWidth={"lg"} fixed>
      <Box mt={4}>
        <SecureArea memberPermission={MemberPermission.AccountAdmin}>
          <BidderDetailsContainer bidderId={match.params.bidderId} />
        </SecureArea>
      </Box>
    </Container>
  );
}

export default withAITracking(reactPlugin, BidderDetails, "BidderDetails");
