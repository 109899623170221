import * as React from "react";

import { Grid, Container } from "@material-ui/core";
import { ISalvageClientSettings } from "../../interfaces/ISalvageClientSettings";
import { GetClientSettings } from "../../services/SettingsService";

import * as styles from "./styles/FooterStyles";

const ClientSettings: ISalvageClientSettings = GetClientSettings();

const Footer: React.FC = () => {
  const classes = styles.FooterStyles();

  return (
    <footer>
      <div className={classes.root}>
        <Container maxWidth={"lg"} fixed style={{ fontSize: "12px" }}>
          <Grid container spacing={3}></Grid>
        </Container>
      </div>

      <div className={classes.versionContainer}>
        <p className={classes.versionText}>{ClientSettings.Version}</p>
      </div>
    </footer>
  );
};

export default Footer;
