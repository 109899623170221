import { makeStyles } from "@material-ui/core/styles";

export const RulesAndFeesStyles = makeStyles(() => ({
  text: {
    marginBottom: "1.5rem",
  },
  table: {
    marginBottom: "1rem",
  },
  heading: {
    marginTop: "2rem",
  },
}));
