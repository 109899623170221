import { makeStyles } from "@material-ui/core/styles";

export const BiddersDocumentsSubmittedStyles = makeStyles(theme => ({
  media: {
    maxHeight: "100%",
    maxWidth: "100%",
  },
  documentInfo: {
    backgroundColor: theme.palette.secondary.light,
  },
  middleDivider: {
    margin: "20px 0 0 0",
  },
  buttonSpacing: {
    margin: theme.spacing(1),
  },
  bottomCell: {
    border: "none",
  },
}));
