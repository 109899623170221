import React from "react";
import { useEffect } from "react";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

import { IAddress } from "../../../interfaces/IAddress";
import { IValidation } from "../../../interfaces/IValidation";

interface LocationProps {
  isDisabled: boolean;
  validationState: IValidation;
  locationState: IAddress;
  handleStateChange: (address: IAddress) => void;
}

export const LocationDetails: React.FC<LocationProps> = ({ isDisabled, validationState, locationState, handleStateChange }) => {
  const [address, setAddress] = React.useState<IAddress>(locationState);
  const [validation, setValidation] = React.useState<IValidation>(validationState);

  useEffect(() => {
    handleStateChange(address);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  useEffect(() => {
    setAddress(locationState);
  }, [locationState]);

  useEffect(() => {
    setValidation(validationState);
  }, [validationState]);

  const isError = (field: string): boolean => {
    return validation[field] !== undefined;
  };

  const ErrorMessage = (field: string): string => {
    return validation[field] !== undefined ? validation[field].error : "";
  };

  const onFieldChange = (e: any, errorName: string) => {
    setValidation({
      ...validation,
      [errorName]: { field: "", error: "" },
    });

    var value: any;

    if (e.target.type === "checkbox") {
      value = e.target.checked;
    } else if (e.target.type === "number") {
      value = e.target.value === "" ? 0 : e.target.value;
    } else {
      value = e.target.value;
    }

    setAddress({
      ...address,
      [e.target.name]: value,
    });
  };

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            required
            fullWidth
            id="line1"
            name="line1"
            label="Line 1"
            disabled={isDisabled}
            error={isError("ItemOffSiteLocation.Line1")}
            helperText={ErrorMessage("ItemOffSiteLocation.Line1")}
            value={address.line1 ?? ""}
            onChange={value => onFieldChange(value, "ItemOffSiteLocation.Line1")}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            id="line2"
            name="line2"
            label="Line 2"
            disabled={isDisabled}
            value={address.line2 ?? ""}
            onChange={value => onFieldChange(value, "")}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            id="line3"
            name="line3"
            label="Line 3"
            disabled={isDisabled}
            value={address.line3 ?? ""}
            onChange={value => onFieldChange(value, "")}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            fullWidth
            id="city"
            name="city"
            label="City"
            disabled={isDisabled}
            error={isError("ItemOffSiteLocation.City")}
            helperText={ErrorMessage("ItemOffSiteLocation.City")}
            value={address.city ?? ""}
            onChange={value => onFieldChange(value, "ItemOffSiteLocation.City")}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            fullWidth
            id="postcode"
            name="postcode"
            label="Postcode"
            disabled={isDisabled}
            error={isError("ItemOffSiteLocation.Postcode")}
            helperText={ErrorMessage("ItemOffSiteLocation.Postcode")}
            value={address.postcode ?? ""}
            onChange={value => onFieldChange(value, "ItemOffSiteLocation.Postcode")}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            id="county"
            name="county"
            label="County"
            disabled={isDisabled}
            value={address.county ?? ""}
            onChange={value => onFieldChange(value, "")}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            id="country"
            name="country"
            label="Country"
            disabled={isDisabled}
            value={address.country.name ?? ""}
            onChange={value => onFieldChange(value, "")}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
