import * as React from "react";
import { bucketStatusString, BucketStatus, IBucket, publishBucket, is24HoursInFuture } from "../../interfaces/buckets/IBucket";
import { ExistingBucketsStyles } from "./styles/ExistingBuckets";
import { useHistory } from "react-router-dom";
import {
  Grid,
  TableRow,
  TableCell,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import * as BucketService from "../../services/BucketService";
import { useSnackbar } from "notistack";
import { endDateFormat } from "../../helpers/text-format/TextFormat";
import { deleteBucket, suspendBucket, unsuspendBucket } from "../../services/BucketService";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import PublishIcon from "@material-ui/icons/Publish";
import ListIcon from "@material-ui/icons/List";
import PauseIcon from "@material-ui/icons/Pause";
import moment from "moment";
import { PlayArrow } from "@material-ui/icons";

interface BucketRowProps {
  bucket: IBucket;
  refreshBuckets: () => void;
}

export const BucketRow: React.FC<BucketRowProps> = ({ bucket, refreshBuckets }) => {
  const classes = ExistingBucketsStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDialogState, setDeleteDialogState] = React.useState<boolean>(false);
  const [suspendDialogState, setSuspendDialogState] = React.useState<boolean>(false);
  const [unsuspendDialogState, setUnsuspendDialogState] = React.useState<boolean>(false);

  const onPublish = async (bucket: IBucket) => {
    await publishBucket(
      bucket,
      message => enqueueSnackbar(message, { variant: "error" }),
      async () => {
        const doit = async () => {
          await BucketService.createActiveBucket(bucket.id)
            .then(result => {
              if (result.parsedBody !== undefined && result.parsedBody.title == "error: invalid start price") {
                enqueueSnackbar("One or more of the Auctions in this bucket, do not have a valid start price.", { variant: "error" });
              } else {
                enqueueSnackbar("Bucket published", { variant: "success" });
                refreshBuckets();
              }          
            })
            .catch(() => {
              enqueueSnackbar("There was a problem with publishing the bucket, please try again.", { variant: "error" });
            });
        };
        doit();
      }
    );
  };

  const handleDeleteDialogOpen = () => {
    setDeleteDialogState(true);
  };

  const handleSuspendDialogOpen = () => {
    setSuspendDialogState(true);
  };

  const handleUnsuspendDialogOpen = () => {
    setUnsuspendDialogState(true);
  };

  const handleDialogClose = () => {
    setDeleteDialogState(false);
    setSuspendDialogState(false);
    setUnsuspendDialogState(false);
  };

  const bucketDelete = () => {
    setDeleteDialogState(false);
    if (bucket.id) {
      deleteBucket(bucket.id)
        .then(data => {
          refreshBuckets();
        })
        .catch(() => {
          enqueueSnackbar("There was a problem with removing the bucket, please try again.", { variant: "error" });
        });
    }
  };

  const bucketSuspend = () => {
    setSuspendDialogState(false);
    if (bucket.id) {
      suspendBucket(bucket.id)
        .then(data => {
          refreshBuckets();
        })
        .catch(() => {
          enqueueSnackbar("There was a problem with suspending the bucket, please try again.", { variant: "error" });
        });
    }
  };

  const bucketUnsuspend = () => {
    setUnsuspendDialogState(false);
    if (bucket.id) {
      unsuspendBucket(bucket.id)
        .then(data => {
          refreshBuckets();
        })
        .catch(() => {
          enqueueSnackbar("There was a problem with unsuspending the bucket, please try again.", { variant: "error" });
        });
    }
  };

  const DeleteDialog = () => {
    return (
      <Dialog open={deleteDialogState} onClose={handleDialogClose}>
        <DialogTitle>{"Confirm Remove Bucket?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to remove this bucket?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={() => bucketDelete()} variant="contained" color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const SuspendBucketDialog = () => {
    return (
      <Dialog open={suspendDialogState} onClose={handleDialogClose}>
        <DialogTitle>{"Confirm Bucket Suspension?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to suspend all lots in this bucket?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={() => bucketSuspend()} variant="contained" color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const UnsuspendBucketDialog = () => {
    return (
      <Dialog open={unsuspendDialogState} onClose={handleDialogClose}>
        <DialogTitle>{"Confirm Bucket Unsuspension?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to unsuspend all lots in this bucket?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={() => bucketUnsuspend()} variant="contained" color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const bucketSuspensionButton = () => {
    if (bucket.status === BucketStatus.Published) {
      if (bucket.anyActiveAuctions) {
        return (
          <Tooltip title="Emergency suspend bucket">
            <IconButton
              aria-label="Suspend bucket"
              onClick={() => {
                handleSuspendDialogOpen();
              }}
            >
              <PauseIcon color="primary" />
            </IconButton>
          </Tooltip>
        );
      } else {
        return (
          <IconButton aria-label="Suspend bucket" disabled>
            <PauseIcon />
          </IconButton>
        );
      }
    } else {
      return (
        <IconButton aria-label="Suspend bucket" disabled>
          <PauseIcon />
        </IconButton>
      );
    }
  };

  const bucketUnsuspensionButton = () => {
    if (bucket.status === BucketStatus.Published) {
      if (bucket.anySuspendedAuctions) {
        return (
          <Tooltip title="Unsuspend bucket">
            <IconButton
              aria-label="Unsuspend bucket"
              onClick={() => {
                handleUnsuspendDialogOpen();
              }}
            >
              <PlayArrow color="primary" />
            </IconButton>
          </Tooltip>
        );
      } else {
        return (
          <IconButton aria-label="Unsuspend bucket" disabled>
            <PlayArrow />
          </IconButton>
        );
      }
    } else {
      return (
        <IconButton aria-label="Unsuspend bucket" disabled>
          <PlayArrow />
        </IconButton>
      );
    }
  };

  const bucketDeleteButton = () => {
    if (bucket.status === BucketStatus.Unpublished && bucket.bucketItems.length === 0) {
      return (
        <Tooltip title="Delete bucket">
          <IconButton
            aria-label="Delete lot"
            onClick={() => {
              handleDeleteDialogOpen();
            }}
          >
            <DeleteIcon color="primary" />
          </IconButton>
        </Tooltip>
      );
    } else {
      return (
        <IconButton aria-label="Delete bucket" disabled>
          <DeleteIcon />
        </IconButton>
      );
    }
  };

  return (
    <TableRow key={bucket.id}>
      {DeleteDialog()}
      {SuspendBucketDialog()}
      {UnsuspendBucketDialog()}
      <TableCell>{bucket.title === null ? "No title" : bucket.title}</TableCell>
      <TableCell>{bucketStatusString(bucket.status)}</TableCell>
      <TableCell>{endDateFormat(bucket.scheduledDate, "")}</TableCell>
      <TableCell>{bucket.auctionSeparationInSeconds} seconds</TableCell>
      <TableCell className={classes.numberCell}>{bucket.bucketItems.length}</TableCell>

      <TableCell className={classes.buttonCell}>
        <Grid container>
          <Grid item>
            <Tooltip title="View lots in bucket">
              <IconButton
                aria-label="View lots in bucket"
                onClick={() => history.push("/AuctionManagement/Search?bucketNameContains=" + bucket.title + "&bucketId=" + bucket.id)}
              >
                <ListIcon color="primary" />
              </IconButton>
            </Tooltip>
          </Grid>

          <Grid item>
            {bucket.status === BucketStatus.Unpublished && bucket.bucketItems.length > 0 ? (
              <Tooltip title="Publish bucket">
                <IconButton aria-label="publish bucket" onClick={() => onPublish(bucket)}>
                  <PublishIcon color="primary" />
                </IconButton>
              </Tooltip>
            ) : (
              <IconButton aria-label="publish bucket" disabled>
                <PublishIcon />
              </IconButton>
            )}
          </Grid>

          <Grid item>
            {bucket.status === BucketStatus.Unpublished ? (
              <Tooltip title="Edit bucket">
                <IconButton aria-label="Edit" onClick={() => history.push("/Buckets/EditBucket/" + bucket.id)}>
                  <EditIcon color="primary" />
                </IconButton>
              </Tooltip>
            ) : bucket.status === BucketStatus.Published && is24HoursInFuture(moment(bucket.scheduledDate)) ? (
              <Tooltip title="Edit published bucket">
                <IconButton aria-label="Edit" onClick={() => history.push("/Buckets/EditActiveBucket/" + bucket.id)}>
                  <EditIcon color="primary" />
                </IconButton>
              </Tooltip>
            ) : (
              <IconButton aria-label="Edit" disabled>
                <EditIcon />
              </IconButton>
            )}
          </Grid>

          <Grid item>{bucketSuspensionButton()}</Grid>

          <Grid item>{bucketUnsuspensionButton()}</Grid>

          <Grid item>{bucketDeleteButton()}</Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
};
