export enum NotificationOptionAvailability {
  Unavailable = 0,
  OptIn = 1,
  Mandatory = 2,
}

export interface INotificationOptionSet {
  ruleIdentifier: string;
  emailAvailability: NotificationOptionAvailability;
  smsAvailability: NotificationOptionAvailability;
  whatsAppAvailability: NotificationOptionAvailability;
}

export enum NotificationPreferenceType {
  Unknown,
  Sms,
  Email,
  WhatsApp,
}

export const getNotificationOptionName = (ruleIdentifier: string) => {
  switch (ruleIdentifier) {
    case "awaitingidvalidation":
      return "Notify when a new bidder registers";
    default:
      return ruleIdentifier;
  }
};
