import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const MaintenanceStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputRoot: {
      maxWidth: "700px",
      marginLeft: "auto",
      marginRight: "auto",
    },
    inputBoxWithIcon: {
      padding: "2px 4px",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      margin: "0px 0px 25px 0px",
    },
    iconButton: {
      padding: 10,
    },
  })
);
