import * as React from "react";

import { TableCell, TableRow } from "@material-ui/core";
import { AuctionRowStyles } from "./styles/AuctionRowStyles";
import Skeleton from "@material-ui/lab/Skeleton";

export const AuctionSkeleton = () => {
  const classes = AuctionRowStyles();
  return (
    <>
      <TableRow>
        <TableCell colSpan={1} className={classes.tableCell}>
          <Skeleton width="100%" height={60} />
        </TableCell>
        <TableCell colSpan={4} className={classes.tableCell}>
          <Skeleton width="100%" height={20} />
          <Skeleton width="100%" height={20} />
          <Skeleton width="100%" height={20} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={1} className={classes.tableCell}>
          <Skeleton width="100%" height={60} />
        </TableCell>
        <TableCell colSpan={4} className={classes.tableCell}>
          <Skeleton width="100%" height={20} />
          <Skeleton width="100%" height={20} />
          <Skeleton width="100%" height={20} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={1} className={classes.tableCell}>
          <Skeleton width="100%" height={60} />
        </TableCell>
        <TableCell colSpan={4} className={classes.tableCell}>
          <Skeleton width="100%" height={20} />
          <Skeleton width="100%" height={20} />
          <Skeleton width="100%" height={20} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={1} className={classes.tableCell}>
          <Skeleton width="100%" height={60} />
        </TableCell>
        <TableCell colSpan={4} className={classes.tableCell}>
          <Skeleton width="100%" height={20} />
          <Skeleton width="100%" height={20} />
          <Skeleton width="100%" height={20} />
        </TableCell>
      </TableRow>
    </>
  );
};
