export interface IMedia {
  title: string;
  description: string;
  url: string;
  mediaType: MediaTypes;
  flagForDeletion: boolean;
  isDirty: boolean;
  invalidImage: boolean;
  errorMessage: string;
  order: number;
}

export enum MediaTypes {
  Image = 1,
  YouTube = 2,
}

export interface ILocalFileMedia extends IMedia {
  file: File;
}

export interface IBlobMedia extends IMedia {
  id: string;
  mediaSize: string;
  metadataSet: boolean;
  versions: IVersions;
}

export interface IYouTubeVideo extends IMedia {
  videoId: string;
}

export interface IVersions {
  mobile: string;
  thumbnail: string;
}
