import * as React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, TextField, FormControl } from "@material-ui/core";
import { CompletePayzoneRefund, IRefund } from "../../services/RefundService";
import { useSnackbar } from "notistack";

interface IProps {
  open: boolean;
  setOpen: (value: React.SetStateAction<boolean>) => void;
  refund: IRefund;
  refreshRefunds: () => void;
}

export const CompleteRefundDialog: React.FC<IProps> = ({ open, setOpen, refund, refreshRefunds }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [transactionId, setTransactionId] = React.useState<string>();
  const [performingAction, setPerformingAction] = React.useState<boolean>(false);
  const [transactionIdValidation, setTransactionIdValidation] = React.useState<string>("");

  const completeRefund = async () => {
    if (!transactionId || transactionId!.length < 1) {
      setTransactionIdValidation("You must enter a transaction ID");
      return;
    }
    setTransactionIdValidation("");
    setPerformingAction(true);

    if (transactionId !== undefined) {
      try {
        await CompletePayzoneRefund(refund.id, transactionId, refund.bidderId);
        enqueueSnackbar("Successfully added completed refund.", { variant: "info" });
        setOpen(false);
      } catch {
        enqueueSnackbar("Something went wrong whilst trying to complete refund. Please try again.", { variant: "error" });
      } finally {
        setTransactionId(undefined);
        refreshRefunds();
      }
    }
    setPerformingAction(false);
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle id="form-dialog-title">Complete Refund</DialogTitle>
      <DialogContent>
        <DialogContentText>Enter the transaction ID below to complete the refund.</DialogContentText>
        <FormControl fullWidth>
          <TextField
            label="Transaction ID"
            value={transactionId ? transactionId : ""}
            onChange={event => setTransactionId(event.target.value)}
            required
            error={transactionIdValidation.length > 0}
            helperText={transactionIdValidation}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="primary" disabled={performingAction}>
          Cancel
        </Button>
        <Button onClick={completeRefund} color="primary" disabled={performingAction}>
          Complete Refund
        </Button>
      </DialogActions>
    </Dialog>
  );
};
