import React from "react";
import { PackUploadStyles } from "./styles/PackUploadStyles";
import { Typography, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";
import { useAuctionImportPackProgress } from "../../services/PackUploadService";
import { useSnackbar } from "notistack";
import useInterval from "../live-bids/hooks/useInterval";
import { LoadingComponent } from "../loading/LoadingComponent";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

interface PackUploadStatusProps {
  batchId: string;
  setIsProcessing: (isProcessing: boolean) => void;
}

const autoRefreshIntervalMs = 500;

export const PackUploadStatus: React.FC<PackUploadStatusProps> = ({ batchId, setIsProcessing }) => {
  const classes = PackUploadStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [progress, isLoadingProgress, , refreshProgress] = useAuctionImportPackProgress(batchId, () =>
    enqueueSnackbar(`Couldn't retrieve batch information`)
  );

  const isProcessing = isLoadingProgress || (progress && !progress.isFinished);

  const wasSuccessful = progress && progress.wasSuccessful;

  const wasFailure = !isProcessing && progress && progress.failedImports?.length > 0;

  const progressPercentage =
    progress && progress.processedCount + progress.remainingCount > 0
      ? (100 * progress.processedCount) / (progress.processedCount + progress.remainingCount)
      : null;

  React.useEffect(() => {
    setIsProcessing(!!isProcessing);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProcessing]);

  React.useEffect(() => {
    refreshProgress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batchId]);

  useInterval(() => !isLoadingProgress && refreshProgress(), isProcessing ? autoRefreshIntervalMs : 1000000);

  const FailedAuctions = () => (
    <TableContainer className={classes.statusContainer} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center" colSpan={2}>
              <HighlightOffIcon className={classes.outcomeIcons} color="primary" />
              <Typography variant="h5">Import completed but the following auctions could not be processed.</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableHead>
          <TableRow>
            <TableCell>VehicleID</TableCell>
            <TableCell>Reason</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {progress?.failedImports.map((f, i) => (
            <TableRow className={classes.tableRow} key={i}>
              <TableCell>{f.referenceNumber}</TableCell>
              <TableCell>{f.error}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const ProccessSuccess = () => (
    <Paper className={`${classes.paper} ${classes.statusContainer}`}>
      <Typography variant="h5">Import completed successfully.</Typography>
      <CheckCircleOutlineIcon className={classes.outcomeIcons} color="primary" />
    </Paper>
  );

  return (
    <>
      {isProcessing && (
        <Paper className={classes.processingPaper} elevation={3}>
          <LoadingComponent label="Import in progress, please wait" marginBottom="0px" marginTop="0px" />
          {progress && !!progressPercentage && progressPercentage < 100 && (
            <Typography variant="subtitle2">
              {Math.ceil(progressPercentage)}% ({progress.remainingCount} remaining)
            </Typography>
          )}
        </Paper>
      )}
      {wasSuccessful && <ProccessSuccess />}
      {wasFailure && <FailedAuctions />}
    </>
  );
};
