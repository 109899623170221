import * as React from "react";
import { useSnackbar } from "notistack";
import { Button, Grid } from "@material-ui/core";
import { DefaultBidderReportRequest, useBidderMailingListReport, useBidderMailingListReportOptions } from "../../services/ReportsService";
import { ContainerStyles } from "./styles/ContainerStyles";
import { SearchSkeleton } from "../auction-management/SearchSkeleton";
import { Filter } from "../../helpers/Filter";
import { LoadingComponent } from "../loading/LoadingComponent";
import { downloadCSV } from "./DownloadCsv";

export const BidderMailingListReportContainer: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = ContainerStyles();
  const [filterOptions, isOptionsLoading, ,] = useBidderMailingListReportOptions(() =>
    enqueueSnackbar("Could not retrieve list of filter options", { variant: "error" })
  );
  const [filterRequest, setFilterRequest, apiPostAsync, filterResponse, isLoadingReport, ,] = useBidderMailingListReport(() =>
    enqueueSnackbar("Could not retrieve list of auctions", { variant: "error" })
  );

  React.useEffect(() => {
    setFilterRequest(DefaultBidderReportRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeHandler = (event: any, property: string) => {
    if (filterRequest) {
      if (event.target.value === "All") {
        event.target.value = null;
      }
      setFilterRequest({ ...filterRequest, [property]: event.target.value, requestId: Date.now.toString() });
    }
  };

  const changeDirection = (sortDescending: boolean) => {
    if (filterRequest) {
      setFilterRequest({ ...filterRequest, sortDescending: sortDescending, requestId: Date.now.toString() });
    }
  };

  const search = () => {
    if (filterRequest !== null) {
      apiPostAsync();
    }
  };

  React.useEffect(() => {
    if (filterResponse && filterResponse?.requestId === filterRequest?.requestId) {
      downloadCSV(filterResponse.byteArray, "BidderMailingListReport.csv");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterResponse]);

  const reset = () => {
    if (filterRequest !== null) {
      setFilterRequest(DefaultBidderReportRequest());
    }
  };

  const changeIsOptedIn = (event: any) => {
    if (filterRequest) {
      switch (event.target.value) {
        case "Opted-in":
          return setFilterRequest({ ...filterRequest, mailingListOptIn: true, requestId: Date.now.toString() });
        case "Not Opted-in":
          return setFilterRequest({ ...filterRequest, mailingListOptIn: false, requestId: Date.now.toString() });
        default:
          return setFilterRequest({ ...filterRequest, mailingListOptIn: undefined, requestId: Date.now.toString() });
      }
    }
  };

  return (
    <>
      {isLoadingReport && <LoadingComponent label="Loading report" />}
      {isOptionsLoading && <SearchSkeleton />}
      {!isLoadingReport && (
        <Grid container spacing={1}>
          <Grid item xs={12} lg={2}>
            {!isOptionsLoading && (
              <Filter
                title="Opted status"
                filterOptions={["Opted-in", "Not Opted-in"]}
                filterPropertyName="mailingListOptIn"
                changeHandler={changeIsOptedIn}
                filterValue={filterRequest?.mailingListOptIn === undefined ? "" : filterRequest.mailingListOptIn ? "Opted-in" : "Not Opted-in"}
              />
            )}
          </Grid>

          <Grid item xs={12} lg={2}>
            {!isOptionsLoading && filterOptions?.sortBy !== null && (
              <Filter
                title="Sort by"
                filterOptions={filterOptions?.sortBy}
                filterPropertyName="sortBy"
                changeHandler={changeHandler}
                filterValue={filterRequest?.sortBy ? filterRequest.sortBy : ""}
                enumCase={true}
                all={false}
              />
            )}
          </Grid>

          <Grid item xs={12} lg={2}>
            {!isOptionsLoading && (
              <Filter
                title="Direction"
                filterOptions={["Descending", "Ascending"]}
                filterPropertyName="sortDescending"
                changeHandler={value => changeDirection(value.target.value === "Descending")}
                filterValue={filterRequest?.sortDescending ? "Descending" : "Ascending"}
                all={false}
              />
            )}
          </Grid>

          <Grid item xs={12} lg={1}>
            {!isOptionsLoading && (
              <Button color="primary" className={classes.searchButton} onClick={reset}>
                Reset
              </Button>
            )}
          </Grid>

          <Grid item xs={12}>
            {!isOptionsLoading && (
              <Button className={classes.createReportButton} variant="contained" color="primary" onClick={search} fullWidth>
                Create Report
              </Button>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};
