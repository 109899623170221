import * as React from "react";
import { Grid, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import { IValidation } from "../../interfaces/IValidation";
import { IBidder } from "../../interfaces/bidders/IBidder";
import { isError, ErrorMessage } from "./helpers/ErrorHandlers";
import { CountrySelect } from "./CountrySelect";

interface IProps {
  validation: IValidation;
  setValidation: React.Dispatch<React.SetStateAction<IValidation>>;
  bidder: IBidder;
  onBidderChange: React.Dispatch<React.SetStateAction<IBidder>>;
}

export const BidderDetailsForm: React.FC<IProps> = props => {
  const onBidderFieldChange = (e: any) => {
    const fieldValue = e.target.value;
    const fieldName = e.target.name;
    props.onBidderChange(b => ({
      ...b,
      [fieldName]: fieldValue,
    }));
  };

  const onAddressFieldChange = (e: any) => {
    const fieldValue = e.target.value;
    const fieldName = e.target.name;
    props.onBidderChange(b => ({
      ...b,
      address: {
        ...b.address,
        [fieldName]: fieldValue,
      },
    }));
  };
  const onFieldChange = (e: any) => {
    const fieldValue = e.target.value;
    const fieldName = e.target.name;
    props.onBidderChange(b => ({
      ...b,
      [fieldName]: fieldValue,
    }));
  };
  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h3>Name</h3>
          <InputLabel id="dropdown-label">Title</InputLabel>
          <Select labelId="dropdown-label" value={props.bidder.title} required fullWidth onChange={value => onFieldChange(value)} name="title">
            <MenuItem value="Mr">Mr</MenuItem>
            <MenuItem value="Mrs">Mrs</MenuItem>
            <MenuItem value="Ms">Ms</MenuItem>
            <MenuItem value="Miss">Miss</MenuItem>
            <MenuItem value="other">other</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            error={isError("firstname", props.validation)}
            helperText={ErrorMessage("firstname", props.validation)}
            name="firstName"
            label="First name"
            value={props.bidder.firstName}
            onChange={value => onFieldChange(value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            error={isError("lastname", props.validation)}
            helperText={ErrorMessage("lastname", props.validation)}
            name="lastName"
            label="Last name"
            value={props.bidder.lastName}
            onChange={value => onFieldChange(value)}
          />
        </Grid>
        <Grid item xs={12}>
          <h3>Address</h3>
          <TextField
            required
            fullWidth
            error={isError("Address.Line1", props.validation)}
            helperText={ErrorMessage("Address.Line1", props.validation)}
            name="line1"
            label="line 1"
            value={props.bidder.address.line1}
            onChange={value => onAddressFieldChange(value)}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            error={isError("Address.Line2", props.validation)}
            helperText={ErrorMessage("Address.Line2", props.validation)}
            name="line2"
            label="line 2"
            value={props.bidder.address.line2}
            onChange={value => onAddressFieldChange(value)}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            error={isError("Address.Line3", props.validation)}
            helperText={ErrorMessage("Address.Line3", props.validation)}
            name="line3"
            label="line 3"
            value={props.bidder.address.line3}
            onChange={value => onAddressFieldChange(value)}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            error={isError("Address.Postcode", props.validation)}
            helperText={ErrorMessage("Address.Postcode", props.validation)}
            name="postcode"
            label="postcode"
            value={props.bidder.address.postcode}
            onChange={value => onAddressFieldChange(value)}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            error={isError("Address.City", props.validation)}
            helperText={ErrorMessage("Address.City", props.validation)}
            name="city"
            label="city"
            value={props.bidder.address.city}
            onChange={value => onAddressFieldChange(value)}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            error={isError("Address.County", props.validation)}
            helperText={ErrorMessage("Address.County", props.validation)}
            name="county"
            label="county"
            value={props.bidder.address.county}
            onChange={value => onAddressFieldChange(value)}
          />
        </Grid>

        <Grid item xs={12}>
          <CountrySelect
            country={props.bidder.address.country}
            onChange={value => {
              onAddressFieldChange({
                target: {
                  value: value,
                  name: "country",
                },
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <h3>Landline Number</h3>

          <TextField
            required
            fullWidth
            error={isError("MobileNumber", props.validation)}
            helperText={ErrorMessage("MobileNumber", props.validation)}
            name="mobileNumber"
            label="Mobile Number"
            value={props.bidder.mobileNumber}
            onChange={value => onBidderFieldChange(value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            error={isError("LandlineNumber", props.validation)}
            helperText={ErrorMessage("LandlineNumber", props.validation)}
            name="landlineNumber"
            label="Landline Number"
            value={props.bidder.landlineNumber}
            onChange={value => onBidderFieldChange(value)}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
