import React, { ReactNode } from "react";

const All = [
  "Agricultural Vehicle",
  "Agricultural Equipment",
  "Car",
  "HGV",
  "LCV",
  "Motorhome",
  "Commercial",
  "Motorcycle",
  "Quad",
  "Plant",
  "Caravan",
  "Other",
];
export const Vehicle = ["Agricultural Vehicle", "Car", "HGV", "LCV", "Motorhome", "Commercial", "Motorcycle", "Quad"];
const VehicleExcludingQuadsAndMotorcycle = ["Agricultural Vehicle", "Car", "HGV", "LCV", "Motorhome", "Commercial"];

export const LotTypeAllow = All;
export const DescriptionAllow = All;
export const ReferenceNumberAllow = All;
export const VatIncludedAllow = All;
export const RegistrationNumberAllow = Vehicle;
export const MakeAllow = Vehicle;
export const ModelAllow = Vehicle;
export const YearAllow = Vehicle;
export const MileageAllow = Vehicle;
export const EngineSizeAllow = Vehicle;
export const TrimLevelAllow = Vehicle;
export const TransmissionGearsAllow = Vehicle;
export const TransmissionTypesAllow = Vehicle;
export const BodyShapeAllow = Vehicle;
export const FuelTypeAllow = Vehicle;
export const ColourAllow = Vehicle;
export const DamageCategoryAllow = Vehicle;
export const HasServiceHistoryAllow = Vehicle;
export const HasKeysAllow = Vehicle;
export const HasLogBookAllow = Vehicle;
export const HasVinAllow = Vehicle;
export const DoesStartAllow = Vehicle;
export const DoesDriveAllow = Vehicle;
export const HasStereoAllow = VehicleExcludingQuadsAndMotorcycle;
export const NumberOfDoorsAllow = VehicleExcludingQuadsAndMotorcycle;

type LotFieldComponentProps = {
  children: ReactNode;
  currentLotType: string;
  allowLotTypes: string[];
};

const LotFieldComponent: React.FC<LotFieldComponentProps> = ({ children, currentLotType, allowLotTypes }) => {
  return allowLotTypes.indexOf(currentLotType) !== -1 ? <>{children}</> : <></>;
};

export default LotFieldComponent;
