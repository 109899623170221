import { ApiService, HttpResponse } from "./ApiService";
import { GetClientSettings } from "./SettingsService";
import { ISalvageClientSettings } from "../interfaces/ISalvageClientSettings";
import { IBaseSearch } from "../interfaces/search/IBaseSearch";
import { IGlobalAlert } from "../interfaces/global-alerts/IGlobalAlert";
import { IGlobalAlertSearchResults } from "../interfaces/global-alerts/IGlobalAlertSearchResults";
import { IGlobalAlertResult } from "../interfaces/global-alerts/IGlobalAlertResult";

const ClientSettings: ISalvageClientSettings = GetClientSettings();

export const Search = async (search: IBaseSearch): Promise<HttpResponse<IGlobalAlertSearchResults>> => {
  return await ApiService().Post<IGlobalAlertSearchResults>(`${ClientSettings.UsersPrivateApiUrl}/globalalertsadmin/search/`, search);
};

export async function GetById(id: string) {
  const bidder = ApiService().Get<IGlobalAlert>(`${ClientSettings.UsersPrivateApiUrl}/globalalertsadmin/${id}`);
  return bidder;
}

export const Update = async (alert: IGlobalAlert, id: string): Promise<HttpResponse<IGlobalAlert>> => {
  return await ApiService().Put<IGlobalAlert>(ClientSettings.UsersPrivateApiUrl + `/globalalertsadmin/${id}`, alert);
};

export const Create = async (alert: IGlobalAlert): Promise<HttpResponse<IGlobalAlert>> => {
  return await ApiService().Post<IGlobalAlert>(ClientSettings.UsersPrivateApiUrl + `/globalalertsadmin`, alert);
};

export const CheckForConflict = async (alert: IGlobalAlert): Promise<HttpResponse<boolean>> => {
  return await ApiService().Post<boolean>(ClientSettings.UsersPrivateApiUrl + `/globalalertsadmin/checkforconflict`, alert);
};

export const Delete = async (id: string) => {
  return await ApiService().Delete<unknown>(`${ClientSettings.UsersPrivateApiUrl}/globalalertsadmin/${id}/delete`, null);
};
