export enum BidderNoteFilterType {
  Undefined = 0,
  AccountSuspensions = 1,
  BidderDetailsChanged = 2,
  PrivateNote = 3,
  PublicNote = 4,
  ResetBidderPassword = 5,
  SubscriptionAlerts = 6,
  PaymentMessages = 7,
  GivenTrial = 8,
  AccountActivated = 9,
  ValidationDocumentsInvalid = 10,
  AwaitingIdentification = 11,
  WelcomeEmail = 12,
}

export const allBidderNoteFilterType = Object.values(BidderNoteFilterType)
  .filter(val => typeof val !== "string" && val !== BidderNoteFilterType.Undefined)
  .map(f => f as BidderNoteFilterType);

export const bidderNoteFilterTypeDescription = (filterType: BidderNoteFilterType) => {
  switch (filterType) {
    case BidderNoteFilterType.AccountSuspensions:
      return "Account Suspensions";
    case BidderNoteFilterType.BidderDetailsChanged:
      return "Bidder Details Changed";
    case BidderNoteFilterType.PrivateNote:
      return "Private Note";
    case BidderNoteFilterType.PublicNote:
      return "Public Note";
    case BidderNoteFilterType.ResetBidderPassword:
      return "Reset Bidder Password";
    case BidderNoteFilterType.SubscriptionAlerts:
      return "Subscription Alerts";
    case BidderNoteFilterType.PaymentMessages:
      return "Payment Messages";
    case BidderNoteFilterType.GivenTrial:
      return "Given Trial";
    case BidderNoteFilterType.AccountActivated:
      return "Account Activated";
    case BidderNoteFilterType.ValidationDocumentsInvalid:
      return "Validation Documents Invalid";
    case BidderNoteFilterType.AwaitingIdentification:
      return "Awaiting Identification";
    case BidderNoteFilterType.WelcomeEmail:
      return "Welcome Email";
    default:
      return "All";
  }
};
