import * as React from "react";
import { BidderList } from "./BidderList";
import { Grid, Button, TablePagination, Table, TableHead, TableRow, TableCell } from "@material-ui/core";
import { BidderSearchTextField } from "./BidderSearchTextField";
import { IBidder } from "../../interfaces/bidders/IBidder";
import { useState } from "react";
import * as Styles from "./styles/BidderSearchStyles";
import { BidderStatusSearch } from "./BidderStatusSearch";
import { LoadingComponent } from "../loading/LoadingComponent";
import * as BidderSearchService from "../../services/BidderSearchService";
import useDebounce from "../../hooks/useDebounce";

const results: Array<IBidder> = [];

export const BidderSearchContainer: React.FC = () => {
  const classes = Styles.BidderSearchStyles();
  const [searchResults, setSearchResults] = useState(results);
  const [searchText, setSearchText] = useState<string>("");
  const [searchStatus, setSearchStatus] = React.useState("0");
  const [isLoading, setIsLoading] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchResultCount, setSearchResultCount] = React.useState(0);

  const debouncedSearch = useDebounce(searchText, 800);

  React.useEffect(() => {
    if (debouncedSearch !== undefined) {
      BidderSearchService.SearchBidders({
        search: debouncedSearch,
        filter: searchStatus,
        pageSize: rowsPerPage,
        pageNumber: page,
      })
        .then(result => {
          if (result.parsedBody !== undefined) {
            setSearchResults(result.parsedBody.results);
            setSearchResultCount(result.parsedBody.resultCount);
            setIsLoading(false);
          }
        })
        .catch(async response => {
          console.log("error searching:" + response);
        });
    }
  }, [debouncedSearch, searchStatus, rowsPerPage, page]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    setIsLoading(true);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setIsLoading(true);
  };

  const handleReset = () => {
    if (searchText !== "" || searchStatus !== "0" || page !== 0) {
      setSearchStatus("0");
      setSearchText("");
      setPage(0);
      setIsLoading(true);
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" className={classes.activeRowColour}>
                <h3 className={classes.margin}>Approved</h3>
              </TableCell>
              <TableCell align="center" className={classes.notApprovedRowColour}>
                <h3 className={classes.margin}>Not Approved</h3>
              </TableCell>
              <TableCell align="center" className={classes.suspendedRowColour}>
                <h3 className={classes.margin}>Suspended</h3>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </Grid>
      <Grid item xs={12} md={5}>
        <BidderSearchTextField
          searchText={searchText}
          setSearchText={setSearchText}
          setGetSearchResults={setIsLoading}
          setPage={setPage}
          getSearchResults={isLoading}
        />
      </Grid>
      <Grid item xs={12} md={5}>
        <BidderStatusSearch
          setSearchStatus={setSearchStatus}
          setGetSearchResults={setIsLoading}
          searchStatus={searchStatus}
          setPage={setPage}
          getSearchResults={isLoading}
        />
      </Grid>

      <Grid item xs={12} md={1}>
        <Button
          disabled={isLoading}
          color="primary"
          onClick={() => {
            handleReset();
          }}
        >
          Reset
        </Button>
      </Grid>
      {isLoading ? (
        <LoadingComponent label="Loading bidders" />
      ) : (
        <>
          <Grid item xs={12} className={classes.results}>
            <BidderList results={searchResults} />
          </Grid>
          <Grid item xs={12}>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={searchResultCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};
