import * as React from "react";
import { Typography, Container, Box } from "@material-ui/core";
import { SecureArea } from "../components/authentication/SecureArea";
import { MemberPermission } from "../services/MemberService";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../services/TelemetryService";
import { PackUpload } from "../components/pack-import/PackUpload";

function PackImport() {
  return (
    <Container maxWidth={"lg"} fixed>
      <SecureArea memberPermission={MemberPermission.BucketAdmin}>
        <Typography variant="h4" color="primary" gutterBottom>
          Pack Upload
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Upload a XML file for the pack upload below.
        </Typography>
        <Box mt={4}>
          <PackUpload />
        </Box>
      </SecureArea>
    </Container>
  );
}

export default withAITracking(reactPlugin, PackImport, "PackImport");
