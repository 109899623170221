import { makeStyles } from "@material-ui/core/styles";

export const BidderSearchStyles = makeStyles(theme => ({
  button: {
    top: "0",
    width: "100%",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: "8px 14px",
  },
  reset: {
    top: "0",
    width: "100%",
    color: theme.palette.secondary.main,
    padding: "8px 14px",
    textDecoration: "underline",
  },
  results: {
    marginTop: "0px",
  },
  activeRowColour: {
    backgroundColor: theme.palette.success.main,
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  suspendedRowColour: {
    backgroundColor: theme.palette.error.main,
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  notApprovedRowColour: {
    backgroundColor: theme.palette.grey[600],
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  margin: {
    margin: "0px",
  },
}));
