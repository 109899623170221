import * as React from "react";
import { Typography, Container, Box } from "@material-ui/core";
import { AlertsListContainer } from "../components/alert-list/AlertListContainer";
import { SecureArea } from "../components/authentication/SecureArea";
import { MemberPermission } from "../services/MemberService";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../services/TelemetryService";

function Alerts() {
    return (
        <Container maxWidth={"lg"} fixed>
            <SecureArea memberPermission={MemberPermission.SuperAdmin}>
                <Typography variant="h4" color="primary" gutterBottom>
                    Alerts
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    View and manage all alerts
                </Typography>
                <Box mt={4}>
                    <AlertsListContainer></AlertsListContainer>
                </Box>
            </SecureArea>
        </Container>
    );
}

export default withAITracking(reactPlugin, Alerts, "Alerts");
