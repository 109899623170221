import { makeStyles } from "@material-ui/core/styles";

export const FeaturedDragItem = makeStyles(theme => ({
  auctionDragItemWrapper: {
    border: "1px solid lightgrey",
    borderRadius: "4px",
    marginBottom: ".5rem",
    backgroundColor: "white",
    cursor: "move",
  },
  auctionImage: {
    height: "64px",
    width: "auto",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
  },
  auctionTitle: { display: "flex", flexDirection: "column", justifyContent: "center", paddingLeft: "16px" },
  auctionDragHandleWrapper: { display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "right" },
  auctionDragItem: { display: "flex", justifyContent: "right" },
}));
