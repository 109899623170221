import { makeStyles } from "@material-ui/core/styles";

export const SellDirectToBidderStyles = makeStyles(theme => ({
  margin: {
    margin: "0px",
  },
  buttonWidth: {
    minWidth: "180px",
  },
}));
