import * as React from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
} from "@material-ui/core";
import { IBidder } from "../../interfaces/bidders/IBidder";
import KeyboardIcon from "@material-ui/icons/Keyboard";

import * as BidderService from "../../services/BidderService";
import { IBidderNote } from "../../interfaces/bidders/IBidderNote";
import moment from "moment";
import { LoadingComponent } from "../loading/LoadingComponent";
import { allBidderNoteFilterType, BidderNoteFilterType, bidderNoteFilterTypeDescription } from "../../enums/BidderNoteFilterType";

interface IProps {
  bidder: IBidder;
  noteType: NotesType;
  defaultFilter?: BidderNoteFilterType | null;
}

export enum NotesType {
  Site,
  Communication,
}

export const BidderNotes: React.FC<IProps> = ({ bidder, noteType, defaultFilter }) => {
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(30);
  const [loadingNotes, setLoadingNotes] = React.useState(true);
  const [bidderNotes, setBidderNotes] = React.useState<IBidderNote[]>([]);
  const [bidderNotesCount, setBidderNotesCount] = React.useState(0);
  const [activityTypeFilter, setActivityTypeFilter] = React.useState("All");
  const [noteTypeFilter, setNoteTypeFilter] = React.useState<BidderNoteFilterType>(defaultFilter ?? BidderNoteFilterType.Undefined);

  const calendarStrings = {
    lastDay: "[Yesterday at] h:mm:ss A",
    sameDay: "[Today at] h:mm:ss A",
    nextDay: "[Tomorrow at] h:mm:ss A",
    lastWeek: "[Last] dddd [at] h:mm:ss A",
    nextWeek: "dddd [at] h:mm:ss A",
    sameElse: "DD/MM/YYYY [at] h:mm:ss A",
  };

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        if (noteType === NotesType.Site) {
          const results = await BidderService.getBidderSiteNotes(bidder.id, activityTypeFilter, page, pageSize);
          if (results.parsedBody) {
            setBidderNotes(results.parsedBody!.results);
            setBidderNotesCount(results.parsedBody!.resultCount);
          }
        } else {
          const results = await BidderService.getBidderCommunicationNotes(bidder.id, noteTypeFilter, page, pageSize);
          if (results.parsedBody) {
            setBidderNotes(results.parsedBody!.results);
            setBidderNotesCount(results.parsedBody!.resultCount);
          }
        }
      } catch (error) {}
      setLoadingNotes(false);
    };
    if (loadingNotes) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingNotes, activityTypeFilter]);

  React.useEffect(() => {
    setLoadingNotes(true);
  }, [page, pageSize]);

  const handleTypeChange = event => {
    setActivityTypeFilter(event.target.value);
    setPage(0);
    setLoadingNotes(true);
  };

  const handleNoteTypeChange = event => {
    setNoteTypeFilter(event.target.value);
    setPage(0);
    setLoadingNotes(true);
  };

  if (loadingNotes) {
    return <LoadingComponent label="Loading Notes" />;
  }
  const siteFilters = ["Auction Suspended", "Auction Won", "Bid Placed", "Login"];

  return (
    <>
      {noteType === NotesType.Site && (
        <Grid item sm={4} xs={12}>
          <FormControl variant="outlined" margin="dense" fullWidth>
            <InputLabel variant="outlined">Filter site activity</InputLabel>
            <Select label="Filter site activity" value={activityTypeFilter} onChange={handleTypeChange} variant="outlined">
              <MenuItem value="All">Show all site activities</MenuItem>

              {siteFilters.map(filter => (
                <MenuItem key={filter} value={filter}>
                  {filter}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}

      {noteType === NotesType.Communication && (
        <Grid container justify="space-between" alignItems="center">
          <h1>Previous Notes and communications</h1>
          <Grid item md={4} xs={12}>
            <FormControl variant="outlined" margin="dense" fullWidth style={{ marginBottom: "16px" }}>
              <InputLabel variant="outlined">Filter notes</InputLabel>
              <Select label="Filter notes" value={noteTypeFilter} onChange={handleNoteTypeChange} variant="outlined">
                <MenuItem value={BidderNoteFilterType.Undefined}>Show all notes</MenuItem>
                {allBidderNoteFilterType.map(filter => (
                  <MenuItem key={filter} value={filter}>
                    {bidderNoteFilterTypeDescription(filter)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      )}

      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Notes</TableCell>
              <TableCell>IP Address</TableCell>
              <TableCell>Entered By</TableCell>
              {noteType === NotesType.Communication && <TableCell>Member</TableCell>}
            </TableRow>
          </TableHead>

          <TableBody>
            {bidderNotes.map(row => (
              <TableRow key={row.id}>
                <TableCell>{row.systemAdded ? "" : <KeyboardIcon />}</TableCell>
                <TableCell component="th" scope="row">
                  {row.type}
                </TableCell>
                <TableCell style={{ whiteSpace: "nowrap" }}>{moment(row.dateAdded).format('DD/MM/YYYY h:mm a')}</TableCell>
                <TableCell style={{ wordBreak: "break-word" }}>{row.message}</TableCell>
                <TableCell>{row.ipAddress ?? "N/A"}</TableCell>
                <TableCell>{row.enteredByName}</TableCell>
                {noteType === NotesType.Communication && <TableCell>{row.enteredByMember}</TableCell>}
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 30, 50, 100]}
                colSpan={6}
                count={bidderNotesCount}
                rowsPerPage={pageSize}
                page={page}
                onChangePage={(evt, number) => setPage(number)}
                onChangeRowsPerPage={event => {
                  setPageSize(parseInt(event.target.value));
                }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};
