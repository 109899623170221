import { makeStyles } from "@material-ui/core/styles";

export const BidderItemStyles = makeStyles(theme => ({
  divider: {
    margin: theme.spacing(1, 0),
  },
  margin: {
    margin: "0px",
  },
  user: {
    textAlign: "center",
  },
  amount: {
    textAlign: "center",
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    marginRight: "10px",
  },
  orangeText: {
    color: theme.palette.primary.main,
  },
  greyText: {
    color: theme.palette.secondary.dark,
  },
  root: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    minWidth: 650,
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  tableRow: {
    cursor: "pointer",
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.grey[50],
    },
  },
  tableCell: { paddingTop: "0px", paddingBottom: "0px" },
  divBlock: {
    display: "block",
  },
  inline: {
    display: "inline",
    margin: "0px",
  },
  text: {
    margin: "8px",
  },
  activeRowColour: {
    backgroundColor: theme.palette.success.main,
  },
  suspendedRowColour: {
    backgroundColor: theme.palette.error.main,
  },
  notApprovedRowColour: {
    backgroundColor: theme.palette.grey[600],
  },
}));
