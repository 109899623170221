import { Text, View, Image } from "@react-pdf/renderer";
import { SalesDocument } from "../../../services/SalesDocumentService";
import { styles } from "../styles/PdfStyles";
import React from "react";
import * as envelope from "../../../assets/envelope-solid.png";
import * as phone from "../../../assets/phone-solid.png";
import { OpeningDays } from "./PdfOpeningDays";

interface AddressDetails {
  salesDocumentInfo: SalesDocument;
}

export const AddressDetails: React.FC<AddressDetails> = ({ salesDocumentInfo }) => {
  return (
    <View>
      <View style={styles.rowPaddingBottom4}>
        <View style={styles.buyerDetailsTitle}>
          <Text style={styles.title}>Buyer Details</Text>
        </View>
        <View style={styles.sellerDetailsTitle}>
          <Text style={styles.title}> Seller Details</Text>
        </View>
        <View style={styles.vehicleDetailsTitle}>
          <Text style={styles.title}>Vehicle Location Details</Text>
        </View>
      </View>
      <View style={styles.addressContainer}>
        <View style={styles.buyerBlock}>
          <View>
            <Text style={styles.text}>{salesDocumentInfo.bidderName}</Text>
            <Text style={styles.text}>{salesDocumentInfo.bidderAddress.line1}</Text>
            <Text style={styles.text}>{salesDocumentInfo.bidderAddress.line2}</Text>
            <Text style={styles.text}>{salesDocumentInfo.bidderAddress.line3}</Text>
            <Text style={styles.text}>{salesDocumentInfo.bidderAddress.city}</Text>
            <Text style={styles.text}>{salesDocumentInfo.bidderAddress.county}</Text>
            <Text style={styles.text}>
              {salesDocumentInfo.bidderAddress.postcode} {salesDocumentInfo.bidderAddress.country.countryCode}
            </Text>
          </View>
          <View style={styles.contactWrapper}>
            <View style={styles.contactImageBlock}>
              <Image style={styles.phoneIcon} src={phone.default} />
            </View>
            <View style={styles.contactTextBlock}>
              <Text style={styles.contactText}>{salesDocumentInfo.bidderNumber}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.contactImageBlock}>
              <Image style={styles.phoneIcon} src={envelope.default} />
            </View>
            <View style={styles.contactTextBlock}>
              <Text style={styles.contactText}>{salesDocumentInfo.bidderEmail}</Text>
            </View>
          </View>
        </View>
        <View style={styles.sellerBlock}>
          <View>
            <Text style={styles.text}>{salesDocumentInfo.memberName}</Text>
            {/* <Text style={styles.text}><b>Site Name:</b> {salesDocumentInfo.mainSiteName}</Text> */}
            <Text style={styles.text}>{salesDocumentInfo.mainSiteAddress.line1}</Text>
            <Text style={styles.text}>{salesDocumentInfo.mainSiteAddress.line2}</Text>
            <Text style={styles.text}>{salesDocumentInfo.mainSiteAddress.line3}</Text>
            <Text style={styles.text}>{salesDocumentInfo.mainSiteAddress.city}</Text>
            <Text style={styles.text}>{salesDocumentInfo.mainSiteAddress.county}</Text>
            <Text style={styles.text}>{salesDocumentInfo.mainSiteAddress.postcode}</Text>
          </View>

          <View style={styles.contactWrapper}>
            <View style={styles.contactImageBlock}>
              <Image style={styles.phoneIcon} src={phone.default} />
            </View>
            <View style={styles.contactTextBlock}>
              <Text style={styles.contactText}>{salesDocumentInfo.memberPhoneNumber}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.contactImageBlock}>
              <Image style={styles.phoneIcon} src={envelope.default} />
            </View>
            <View style={styles.contactTextBlock}>
              <Text style={styles.contactText}>{salesDocumentInfo.memberEmail}</Text>
            </View>
          </View>
        </View>
        <View style={styles.openingDaysBlock}>
          <View style={styles.padding2}>
            <Text style={styles.littleBlackText}>Opening days</Text>
          </View>
          {salesDocumentInfo.mainSiteOpeningDays && <OpeningDays openingDays={salesDocumentInfo.mainSiteOpeningDays} />}
        </View>
        <View style={styles.sellerBlock}>
          <View>
            <Text style={styles.text}>{salesDocumentInfo.memberName}</Text>
            {/* <Text style={styles.text}><b>Site Name:</b> {salesDocumentInfo.siteName}</Text> */}
            <Text style={styles.text}>{salesDocumentInfo?.collectionLocation?.line1}</Text>
            <Text style={styles.text}>{salesDocumentInfo?.collectionLocation?.line2}</Text>
            <Text style={styles.text}>{salesDocumentInfo?.collectionLocation?.line3}</Text>
            <Text style={styles.text}>{salesDocumentInfo?.collectionLocation?.city}</Text>
            <Text style={styles.text}>{salesDocumentInfo?.collectionLocation?.county}</Text>
            <Text style={styles.text}>{salesDocumentInfo?.collectionLocation?.postcode}</Text>
          </View>

          <View style={styles.contactWrapper}>
            <View style={styles.contactImageBlock}>
              <Image style={styles.phoneIcon} src={phone.default} />
            </View>
            <View style={styles.contactTextBlock}>
              <Text style={styles.contactText}>{salesDocumentInfo.sitePhoneNumber}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.contactImageBlock}>
              <Image style={styles.phoneIcon} src={envelope.default} />
            </View>
            <View style={styles.contactTextBlock}>
              <Text style={styles.contactText}>{salesDocumentInfo.siteEmail}</Text>
            </View>
          </View>
        </View>
        <View style={styles.openingDaysBlock}>
          <View style={styles.padding2}>
            <Text style={styles.littleBlackText}>Collection times</Text>
          </View>
          {salesDocumentInfo.collectionDays && <OpeningDays openingDays={salesDocumentInfo.collectionDays} />}
        </View>
      </View>
    </View>
  );
};
