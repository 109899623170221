import * as React from "react";
import { Typography, Container, Box } from "@material-ui/core";
import { FeaturedOrderingComponent } from "../components/featured-ordering/FeaturedOrderingComponent";
import { SecureArea } from "../components/authentication/SecureArea";
import { MemberPermission } from "../services/MemberService";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../services/TelemetryService";

function FeaturedOrdering() {
  return (
    <Container maxWidth={"lg"} fixed>
      <SecureArea memberPermission={MemberPermission.BucketAdmin}>
        <Typography variant="h4" color="primary" gutterBottom>
          Featured Lot Management
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          This page shows the current ordering for all featured auctions listed on the homepage. To reorder the featured auctions, drag one of the
          auctions listed below into the required position and press OK.
        </Typography>
        <Box mt={4}>
          <FeaturedOrderingComponent />
        </Box>
      </SecureArea>
    </Container>
  );
}

export default withAITracking(reactPlugin, FeaturedOrdering, "FeaturedOrdering");
