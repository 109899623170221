export interface IValidationError {
  field: string;
  error: string;
}

export interface IValidationErrors {
  errors: IValidationError[];
}

export interface IValidation {
  [key: string]: IValidationError;
}

export const ValidationErrorDefault: IValidationError = {
  field: "",
  error: "",
};

export const ValidationErrorsDefault: IValidationErrors = {
  errors: [],
};

export const ValidationDefault: IValidation = {};
