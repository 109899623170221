import { ApiService, HttpResponse } from "./ApiService";
import { GetClientSettings } from "./SettingsService";
import { ISalvageClientSettings } from "../interfaces/ISalvageClientSettings";
import { IStripeProductSearchResults } from "../interfaces/bidders/IStripeProductSearchResults";
import { IStripeProductSearch } from "../interfaces/stripe-products/IStripeProductSearch";
import { IStripeProduct } from "../interfaces/stripe-products/IStripeProduct";
import { useVersionedData } from "./useVersionedData";

const ClientSettings: ISalvageClientSettings = GetClientSettings();

export const SearchBidders = async (search: IStripeProductSearch): Promise<HttpResponse<IStripeProductSearchResults>> => {
  return await ApiService().Post<IStripeProductSearchResults>(`${ClientSettings.PaymentsPrivateApiUrl}/stripeproductsadmin/search/`, search);
};

export async function GetById(productId: string) {
  const bidder = ApiService().Get<IStripeProduct>(`${ClientSettings.PaymentsPrivateApiUrl}/stripeproductsadmin/${productId}`);
  return bidder;
}

export const Update = async (product: IStripeProduct, id: string): Promise<HttpResponse<IStripeProduct>> => {
  return await ApiService().Put<IStripeProduct>(ClientSettings.PaymentsPrivateApiUrl + `/stripeproductsadmin/${id}`, product);
};

export const Create = async (product: IStripeProduct): Promise<HttpResponse<IStripeProduct>> => {
  return await ApiService().Post<IStripeProduct>(ClientSettings.PaymentsPrivateApiUrl + `/stripeproductsadmin`, product);
};

export const Delete = async (id: string) => {
  return await ApiService().Delete<unknown>(`${ClientSettings.PaymentsPrivateApiUrl}/stripeproductsadmin/${id}/delete`, null);
};


export const useProductDetails = (id: string, onError: (httpStatusCode: number, resultBody?: string) => void) =>
  useVersionedData<IStripeProduct>(`${ClientSettings.PaymentsPrivateApiUrl}/stripeproductsadmin/${id}/details`, onError);