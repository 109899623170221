import { Card, Grid, Typography } from "@material-ui/core";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import * as React from "react";
import { isEndedOrCompleted } from "../../../interfaces/auctions/IAuction";
import { IAuctionInfo } from "../../../services/AuctionService";
import * as Styles from "../styles/EditBucketCardStyles";
interface IEditBucketAuctionCard {
  auction: IAuctionInfo;
}

export const EditBucketAuctionCard: React.FC<IEditBucketAuctionCard> = ({ auction }) => {
  const classes = Styles.EditBucketCardStyles(isEndedOrCompleted(auction.status))();

  return (
    <Card className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <div
            className={classes.auctionImage}
            style={{
              backgroundImage: `url("${auction.thumbnailImageUrl}")`,
              backgroundSize: "cover",
            }}
          />
        </Grid>
        <Grid item xs={9}>
          <Grid container>
            <Grid container item xs={12}>
              <Typography component="p" className={classes.fontSize}>
                {auction.metadata.referenceNumber}
              </Typography>
              {isEndedOrCompleted(auction.status) && (
                <Typography component="p" className={classes.alreadyEndedFont}>
                  (already ended)
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography component="p" className={classes.title}>
                {auction.metadata.title}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography component="p" className={classes.fontSize}>
                {auction.metadata.location}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography component="p" className={classes.fontSize}>
                {auction.metadata.registrationNumber}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography component="p" className={classes.fontSize}>
                {auction.metadata.insuranceCompany}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1}>
          <DragHandleIcon />
        </Grid>
      </Grid>
    </Card>
  );
};
