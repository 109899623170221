import React, { useState } from "react";

import { useSnackbar } from "notistack";
import {
  Button,
  FormControl,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";

import { LotRow } from "./LotRow";
import { ContainerStyles } from "../styles/ContainerStyles";
import { SearchSkeleton } from "../SearchSkeleton";
import { AuctionSkeleton } from "../AuctionSkeleton";
import { ActionIcons } from "./ActionIcons";
import { Lot } from "../../../services/LotService";
import {
  useLotMgmtFilterOptions,
  useLotMgmtFilter,
  DefaultLotMgmtFilter,
  requestId,
  ILotMgmtFilterResult,
} from "../../../services/LotManagementService";
import { Filter } from "../../../helpers/Filter";
import { Pagination } from "../../../helpers/Pagination";
import useDebounce from "../../../hooks/useDebounce";

export const LotContainer: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = ContainerStyles();

  const [filterOptions, isOptionsLoading, ,] = useLotMgmtFilterOptions(() =>
    enqueueSnackbar("Could not retrieve list of filter options", { variant: "error" })
  );
  const [filterRequest, setFilterRequest, apiPostAsync, filterResponse, isLoading, ,] = useLotMgmtFilter(() =>
    enqueueSnackbar("Could not retrieve list of draft", { variant: "error" })
  );

  const debouncedSearch = useDebounce(filterRequest, 500);
  const [debounceLoading, setDebounceLoading] = useState<boolean>(true);
  const [latestData, setLatestData] = useState<ILotMgmtFilterResult | null>();

  React.useEffect(() => {
    if (filterResponse?.requestId === filterRequest?.requestId) {
      setLatestData(filterResponse);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterResponse]);

  React.useEffect(() => {
    setFilterRequest(DefaultLotMgmtFilter());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeHandler = (event: any, property: string) => {
    if (filterRequest) {
      if (event.target.value === "All" && property === "lotStatusGrouping") {
        setFilterRequest({ ...filterRequest, lotStatusGrouping: ["New", "Holding"], requestId: requestId(), pageNumber: 0 });
      } else if (event.target.value === "All") {
        setFilterRequest({ ...filterRequest, [property]: null });
      } else if (property === "lotStatusGrouping") {
        setFilterRequest({ ...filterRequest, lotStatusGrouping: [event.target.value], requestId: requestId(), pageNumber: 0 });
      } else {
        setFilterRequest({ ...filterRequest, [property]: event.target.value, requestId: requestId(), pageNumber: 0 });
      }
    }
  };

  const changeDirection = (sortDescending: boolean) => {
    if (filterRequest) {
      setFilterRequest({ ...filterRequest, sortDescending: sortDescending, requestId: requestId(), pageNumber: 0 });
    }
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    const newFilter = { ...filterRequest!, pageNumber: newPage, sortDescending: false, requestId: requestId() };
    setFilterRequest(newFilter);
    apiPostAsync(newFilter);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newFilter = {
      ...filterRequest,
      numberPerPage: parseInt(event.target.value, 10),
      pageNumber: 0,
      sortDescending: false,
      requestId: requestId(),
    };
    setFilterRequest(newFilter);
    apiPostAsync(newFilter);
  };

  const reset = () => {
    if (filterRequest !== null) {
      setFilterRequest(DefaultLotMgmtFilter());
    }
  };

  React.useEffect(() => {
    setDebounceLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterRequest]);

  React.useEffect(() => {
    if (filterRequest) {
      apiPostAsync();
      setDebounceLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  return (
    <>
      {isOptionsLoading && <SearchSkeleton />}
      <Grid container spacing={1}>
        <Grid item xs={12} md={2}>
          {!isOptionsLoading && filterOptions?.lotTypes !== null && (
            <Filter
              title="Lot Type"
              filterOptions={filterOptions?.lotTypes}
              filterPropertyName="lotType"
              changeHandler={changeHandler}
              filterValue={filterRequest?.lotType ? filterRequest.lotType : ""}
            />
          )}
        </Grid>

        <Grid item xs={12} md={2}>
          {!isOptionsLoading && filterOptions?.bodyTypes !== null && (
            <Filter
              title="Body Type"
              filterOptions={filterOptions?.bodyTypes}
              filterPropertyName="bodyType"
              changeHandler={changeHandler}
              filterValue={filterRequest?.bodyType ? filterRequest.bodyType : ""}
            />
          )}
        </Grid>

        <Grid item xs={12} md={3}>
          {!isOptionsLoading && filterOptions?.locations !== null && (
            <Filter
              title="Location"
              filterOptions={filterOptions?.locations}
              filterPropertyName="location"
              changeHandler={changeHandler}
              filterValue={filterRequest?.location ? filterRequest.location : ""}
            />
          )}
        </Grid>

        <Grid item xs={12} md={3}>
          {!isOptionsLoading && filterOptions?.insuranceCompanies !== null && (
            <Filter
              title="Client"
              filterOptions={filterOptions?.insuranceCompanies}
              filterPropertyName="insuranceCompany"
              changeHandler={changeHandler}
              filterValue={filterRequest?.insuranceCompany ? filterRequest.insuranceCompany : ""}
            />
          )}
        </Grid>

        <Grid item xs={12} md={2}>
          {!isOptionsLoading && filterOptions?.sortBy !== null && (
            <Filter
              title="Sort by"
              filterOptions={filterOptions?.sortBy}
              filterPropertyName="sortBy"
              changeHandler={changeHandler}
              filterValue={filterRequest?.sortBy ? filterRequest.sortBy : ""}
              enumCase={true}
              all={false}
            />
          )}
        </Grid>

        <Grid item xs={12} md={2}>
          {!isOptionsLoading && (
            <Filter
              title="Direction"
              filterOptions={["Descending", "Ascending"]}
              filterPropertyName="insuranceCompany"
              changeHandler={event => changeDirection(event.target.value === "Descending")}
              filterValue={filterRequest?.sortDescending ? "Descending" : "Ascending"}
              all={false}
            />
          )}
        </Grid>

        <Grid item xs={12} md={5}>
          {!isOptionsLoading && (
            <FormControl className={classes.width100} variant="outlined" margin="dense">
              <TextField
                className={classes.searchTextField}
                margin="dense"
                id="textSearch"
                placeholder={"Search"}
                variant="outlined"
                fullWidth
                value={filterRequest?.searchText ? filterRequest?.searchText : ""}
                onChange={value => changeHandler(value, "searchText")}
              />
            </FormControl>
          )}
        </Grid>

        <Grid item xs={12} md={3}>
          {!isOptionsLoading && (
            <Button color="primary" className={classes.searchButton} onClick={reset}>
              Reset
            </Button>
          )}
        </Grid>

        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <Pagination
                    count={latestData?.totalNumber ? latestData?.totalNumber : 0}
                    rowsPerPage={filterRequest?.numberPerPage ? filterRequest?.numberPerPage : 0}
                    page={filterRequest?.pageNumber ? filterRequest?.pageNumber : 0}
                    colSpan={4}
                    rowsPerPageOptions={[20, 50, 100]}
                    label="Incomplete lots per page"
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableHeadCell}></TableCell>
                  <TableCell className={classes.tableHeadCell}>Item</TableCell>
                  <TableCell className={classes.tableHeadCell}>Info</TableCell>
                  <TableCell className={classes.tableHeadCell}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(isLoading || debounceLoading || !latestData) && <AuctionSkeleton />}
                {!isLoading && !debounceLoading && latestData?.pageResults && latestData?.pageResults.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={3}>
                      <Typography variant="h5">No auctions available</Typography>
                    </TableCell>
                  </TableRow>
                )}
                {!isLoading &&
                  !debounceLoading &&
                  latestData?.pageResults &&
                  latestData?.pageResults.map((lot: Lot, index: number) => (
                    <TableRow key={index}>
                      <LotRow lot={lot} />
                      <ActionIcons lot={lot} refreshLot={apiPostAsync} />
                    </TableRow>
                  ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <Pagination
                    count={latestData?.totalNumber ? latestData?.totalNumber : 0}
                    rowsPerPage={filterRequest?.numberPerPage ? filterRequest?.numberPerPage : 0}
                    page={filterRequest?.pageNumber ? filterRequest?.pageNumber : 0}
                    colSpan={4}
                    rowsPerPageOptions={[20, 50, 100]}
                    label="Incomplete lots per page"
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};
