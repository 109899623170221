/* eslint-disable no-use-before-define */
import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import { ICountry, countries } from "./ICountry";

// ISO 3166-1 alpha2
// ⚠️ No support for IE 11
function countryToFlag(isoCode: string) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : isoCode;
}

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
});
interface ICountrySelectProperties {
  country: ICountry;
  onChange: (newCountry: ICountry) => void;
  disabled?: boolean;
}
export const CountrySelect: React.FC<ICountrySelectProperties> = ({ country, onChange, disabled = false }) => {
  const classes = useStyles();
  const [selectedCountry, setSelectedCountry] = React.useState(country);

  React.useEffect(() => {
    onChange(selectedCountry);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry]);

  return (
    <Autocomplete
      id="country-select"
      disabled={disabled}
      fullWidth
      disableClearable
      options={countries as ICountry[]}
      classes={{
        option: classes.option,
      }}
      value={selectedCountry}
      onChange={(event, newValue) => setSelectedCountry(newValue!)}
      autoHighlight
      getOptionLabel={option => option.name}
      getOptionSelected={(a, b) => a.countryCode === b.countryCode}
      renderOption={option => (
        <React.Fragment>
          <span>{countryToFlag(option.alpha2)}</span>
          {option.name}
        </React.Fragment>
      )}
      renderInput={params => (
        <TextField
          {...params}
          label="Country"
          fullWidth
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
};

// From https://raw.githubusercontent.com/lukes/ISO-3166-Countries-with-Regional-Codes/master/slim-2/slim-2.json
