import { ApiService, HttpResponse } from "./ApiService";
import { GetClientSettings } from "./SettingsService";
import { ISalvageClientSettings } from "../interfaces/ISalvageClientSettings";
import { useVersionedData } from "./useVersionedData";
import { ILatestBid } from "../interfaces/auctions/ILatestBid";

const ClientSettings: ISalvageClientSettings = GetClientSettings();

export const useBuyback = (
  auctionId: string | null,
  onError?: (httpStatusCode: number, resultBody?: string) => void,
  shouldGetImmediately: boolean = true
) =>
  useVersionedData<ILatestBid>(
    auctionId === null ? null : `${ClientSettings.AuctionPrivateApiUrl}/auctions/id/${auctionId}/buyback`,
    onError,
    shouldGetImmediately
  );

export const RemoveCurrentBid = async (auctionId: string, bidId: string): Promise<HttpResponse<unknown>> => {
  return await ApiService().Delete<unknown>(`${ClientSettings.AuctionPrivateApiUrl}/auctions/id/${auctionId}/bid/${bidId}`, null);
};

export const RemoveBid = async (auctionId: string, bidId: string): Promise<HttpResponse<unknown>> => {
  return await ApiService().Delete<unknown>(`${ClientSettings.AuctionPrivateApiUrl}/auctions/id/${auctionId}/bid/${bidId}`, null);
};
