import * as React from "react";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";

export interface DialogComponentProps {
  open: boolean;
  onClose: (value: React.SetStateAction<boolean>) => void;
  title?: string;
  contentElement?: React.ReactNode;
  changeHandler: (event: any) => void;
  id: string;
  buttonText?: string;
  buttonDisabled?: boolean;
}

export const DialogComponent: React.FC<DialogComponentProps> = ({
  open,
  onClose,
  title,
  contentElement,
  changeHandler,
  id,
  buttonText = "Ok",
  buttonDisabled = false,
}) => {
  return (
    <Dialog open={open} onClose={() => onClose(false)} fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{contentElement}</DialogContent>
      <DialogActions>
        <Button disabled={buttonDisabled} onClick={() => onClose(false)}>
          Cancel
        </Button>
        <Button disabled={buttonDisabled} onClick={() => changeHandler(id)} variant="contained" color="primary">
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
