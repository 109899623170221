import { ApiService, HttpResponse } from "./ApiService";
import { GetClientSettings } from "../services/SettingsService";
import { ISalvageClientSettings } from "../interfaces/ISalvageClientSettings";
import { IBucket } from "../interfaces/buckets/IBucket";
import { useVersionedData, useReadonlyData, usePostedData } from "./useVersionedData";
import { IAuctionInfo } from "./AuctionService";

const ClientSettings: ISalvageClientSettings = GetClientSettings();

export const createDraftBucket = async (bucket: IBucket): Promise<HttpResponse<IBucket>> => {
  const url: string = ClientSettings.AuctionPrivateApiUrl + "/buckets";
  return await ApiService().Post<IBucket>(url, bucket);
};

export const useDraftBuckets = (onError: (httpStatusCode: number, resultBody?: string) => void) =>
  useReadonlyData<IBucket[]>(ClientSettings.AuctionPrivateApiUrl + "/buckets/indraft", onError);

export const useActiveBuckets = (onError: (httpStatusCode: number, resultBody?: string) => void) =>
  useReadonlyData<IBucket[]>(ClientSettings.AuctionPrivateApiUrl + "/buckets/active", onError);

export const useAuctionsInBucket = (bucketId: string, onError: (httpStatusCode: number, resultBody?: string) => void) =>
  useReadonlyData<IAuctionInfo[]>(`${ClientSettings.AuctionPrivateApiUrl}/buckets/id/${bucketId}/auctions`, onError);

export const useBucket = (bucketId: string, isActiveBucket: boolean, onError: (httpStatusCode: number, resultBody?: string) => void) =>
  useVersionedData<IBucket>(`${ClientSettings.AuctionPrivateApiUrl}/buckets/id/${bucketId}/${isActiveBucket ? "active" : "draft"}`, onError);

export const createActiveBucket = async (bucketIdInDraft: string): Promise<HttpResponse<IBucket>> => {
  const url: string = `${ClientSettings.AuctionPrivateApiUrl}/buckets/id/${bucketIdInDraft}/active`;
  return await ApiService().Post<IBucket>(url, null);
};

export const deleteBucket = async (bucketId: string): Promise<HttpResponse<string>> => {
  return await ApiService().Put(`${ClientSettings.AuctionPrivateApiUrl}/buckets/id/${bucketId}/delete`, bucketId);
};

export const suspendBucket = async (bucketId: string): Promise<HttpResponse<string>> => {
  const url: string = `${ClientSettings.AuctionPrivateApiUrl}/buckets/id/${bucketId}/suspend`;
  return await ApiService().Post<string>(url, bucketId);
}

export const unsuspendBucket = async (bucketId: string): Promise<HttpResponse<string>> => {
  const url: string = `${ClientSettings.AuctionPrivateApiUrl}/buckets/id/${bucketId}/unsuspend`;
  return await ApiService().Post<string>(url, bucketId);
}

export const useBucketFilter = (onError: (httpStatusCode: number) => void) =>
  usePostedData<IBucketFilterResult, IBucketFilter>(ClientSettings.AuctionPrivateApiUrl + `/buckets/filter`, onError);

export interface IBucketFilter {
  bucketStatus?: string;
  sortBy?: string;
  sortDescending: boolean;
  pageNumber: number;
  numberPerPage: number;
}

export const DefaultBucketFilter: IBucketFilter = {
  bucketStatus: "PublishedAndUnpublished",
  sortBy: "AuctionScheduleDate",
  sortDescending: false,
  pageNumber: 0,
  numberPerPage: 20,
};

export interface IBucketFilterResult {
  pageNumber: number;
  numberPerPage: number;
  totalPages: number;
  totalNumber: number;
  pageResults: IBucket[];
}
