import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "white",
  },
  headerLayout: {
    width: "100%",
    height: "10%",
    flexDirection: "row",
    paddingBottom: "8px",
    paddingTop: "10px",
  },
  logoRow: {
    flexDirection: "row",
  },
  smLogo: {
    height: "48px",
    width: "160px",
    right: 0,
    position: "absolute",
    marginTop: "10px",
    marginRight: "10px",
    flexDirection: "column",
  },
  nsgLogo: {
    flexDirection: "column",
    height: "200px",
    width: "15%",
    margin: "10px",
  },
  nsgAddress: {
    flexDirection: "column",
    width: "28%",
    fontSize: 10,
    marginTop: "10px",
  },
  nsgContact: {
    flexDirection: "column",
    width: "20%",
    fontSize: 10,
    marginTop: "54px",
    textAlign: "right",
  },
  headerRow: {
    flexDirection: "row",
    textAlign: "center",
    margin: "6px",
    paddingTop: "4x",
  },
  header: {
    fontFamily: "Helvetica-Bold",
    fontWeight: "bold",
  },
  customerBox: {
    width: "96%",
    height: "20%",
    paddingBottom: "10px",
    paddingTop: "8px",
    marginLeft: "10px",
    marginTop: "10px",
    border: "solid",
    borderWidth: 1,
    borderColor: "gray",
  },
  titleRow: {
    flexDirection: "row",
  },
  titleColumn: {
    flexDirection: "column",
    width: "100%",
  },
  titleFont: {
    fontFamily: "Helvetica-Bold",
    fontWeight: "bold",
    fontSize: 14,
    marginLeft: "10px",
    marginBottom: "10px",
    marginTop: "5px",
  },
  customerDetails: {
    flexDirection: "row",
  },
  customerContact: {
    flexDirection: "column",
    width: "50%",
  },
  text: {
    fontSize: 10,
    margin: "10px",
  },
  customerText: {
    fontSize: 10,
    marginTop: "5px",
    marginLeft: "10px",
  },
  descriptionBox: {
    width: "96%",
    height: "38%",
    paddingBottom: "2px",
    paddingTop: "8px",
    marginLeft: "10px",
    border: "solid",
    borderWidth: 1,
    borderColor: "gray",
  },
  description: {
    flexDirection: "row",
  },
  descriptionColumn: {
    flexDirection: "column",
    width: "82%",
  },
  textRight: {
    fontSize: 10,
    margin: "10px",
    paddingRight: "10px",
  },
  costsRow: {
    flexDirection: "row",
    right: 0,
    position: "absolute",
    bottom: 16,
  },
  costsColumn: {
    flexDirection: "column",
    width: "97%",
  },
  blank: {
    padding: "10px",
  },
  right: {
    fontSize: 10,
    textAlign: "right",
  },
  regColumn: {
    flexDirection: "column",
    width: "15%",
  },
  paymentBox: {
    width: "96%",
    height: "22%",
    paddingTop: "8px",
    marginLeft: "10px",
    border: "solid",
    borderWidth: 1,
    borderColor: "gray",
  },
  payment: {
    flexDirection: "row",
  },
  paymentColumn: {
    flexDirection: "column",
    width: "60%",
  },
  paidColumn: {
    flexDirection: "column",
    width: "37%",
  },
  paid: {
    textAlign: "right",
    fontFamily: "Helvetica-Bold",
    fontWeight: "bold",
    fontSize: 14,
    marginTop: "8px",
    color: "blue",
  },
  refundColumn: {
    flexDirection: "column",
    width: "97%",
  },
  refund: {
    textAlign: "right",
    fontFamily: "Helvetica-Bold",
    fontWeight: "bold",
    fontSize: 14,
    marginTop: "18px",
    marginRight: "18px",
    color: "red",
  },
  footerBox: {
    height: "5%",
    marginLeft: "10px",
    marginTop: "3px",
  },
  registeredRow: {
    flexDirection: "row",
  },
  registeredColumn: {
    flexDirection: "column",
    width: "50%",
  },
  registered: {
    fontSize: 8,
  },
  vatColumn: {
    flexDirection: "column",
    width: "47%",
  },
  vat: {
    textAlign: "right",
    fontSize: 8,
  },
});
