import moment from "moment";
import { HttpResponse } from "../services/ApiService";
import { GetServerTime } from "../services/TimeSyncService";

var cacheUpdateRequest: Promise<void> | null = null;

interface TimeDetails {
  localRequestTime: moment.Moment;
  response: HttpResponse<Date>;
  localResponseTime: moment.Moment;
  requestDelta: number;
}

const getTimeDetails = async (): Promise<TimeDetails> => {
  const localRequestTime = moment.utc();
  const response = await GetServerTime();
  const localResponseTime = moment.utc();
  const requestDelta = Math.abs(localResponseTime.diff(localRequestTime));

  return {
    localRequestTime: localRequestTime,
    response: response,
    localResponseTime: localResponseTime,
    requestDelta: requestDelta,
  };
};

const createCache = async () => {
  try {
    let timeDetails = await getTimeDetails();

    if (timeDetails.requestDelta > 1000) {
      //if the request took longer than 1 second, we will retry the request once more
      timeDetails = await getTimeDetails();
    }

    if (!timeDetails.response.parsedBody) throw Error("No response body");
    if (!moment.utc(timeDetails.response.parsedBody).isValid()) throw Error("Moment could not parse response");

    let serverTime = moment.utc(timeDetails.response.parsedBody);
    const localServerDelta = Math.abs(serverTime.diff(timeDetails.localRequestTime));

    if (timeDetails.requestDelta > localServerDelta) {
      serverTime = timeDetails.localRequestTime;
    }
    localStorage.setItem("serverTime", serverTime.toISOString());
    localStorage.setItem("localRequestTime", timeDetails.localRequestTime.toISOString());

    cacheUpdateRequest = null;
  } catch {
    setTimeout(() => {
      //If the request fails or the response is invalid, we wait 1 minute before allowing it to be retried
      cacheUpdateRequest = null;
    }, 60000);
  }
};

export const adjustedCurrentTime = () => {
  const storageServerTime = localStorage.getItem("serverTime");
  const storageLocalTime = localStorage.getItem("localRequestTime");

  if (!storageServerTime || !storageLocalTime || moment.utc(storageLocalTime).add(1, "hour") < moment.utc()) {
    if (!cacheUpdateRequest) {
      cacheUpdateRequest = createCache();
    }
    return moment.utc();
  }

  const currentLocalTime = moment.utc();
  const localServerDelta = moment.utc(storageServerTime).diff(moment.utc(storageLocalTime));

  return currentLocalTime.add(localServerDelta, "milliseconds");
};
