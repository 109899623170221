import { makeStyles } from "@material-ui/core/styles";

export const BidderDocumentStyles = makeStyles(theme => ({
  Card: {
    margin: "auto",
    width: "100%",
    height: "auto",
  },
  Media: {
    height: "600px",
    width: "100%",
  },
  documentInfo: {
    backgroundColor: theme.palette.secondary.light,
  },
  icon: {
    verticalAlign: "bottom",
  },
}));
