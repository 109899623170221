import React from "react";
import { IBidder } from "../../interfaces/bidders/IBidder";
import { Paper, Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";
import * as Styles from "./styles/BidderItemStyles";
import { BidderStatusIcon } from "../../enums/BidderStatus";
import { useHistory } from "react-router";

export const BidderList: React.FC<{ results: IBidder[] }> = ({ results }) => {
  const classes = Styles.BidderItemStyles();
  const history = useHistory();

  if (results.length >= 1) {
    return (
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell}>
                <h3 className={classes.margin}>Status</h3>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <h3 className={classes.margin}>Name</h3>
              </TableCell>
              <TableCell align="left" className={classes.tableCell}>
                <h3>Email Address</h3>
              </TableCell>
              <TableCell align="left" className={classes.tableCell}>
                <h3 className={classes.margin}>Contact Number</h3>
              </TableCell>
              <TableCell align="left" className={classes.tableCell}>
                <h3>Member Details</h3>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {results.map((item: IBidder, index: number) => (
              <TableRow
                hover
                key={item.id}
                role="checkbox"
                className={classes.tableRow}
                onClick={() => {
                  history.push(`/BidderDetailsChange/${item.id}`);
                }}
              >
                <TableCell className={classes.tableCell} component="th" scope="row">
                  {BidderStatusIcon(item.bidderStatus)}
                </TableCell>
                <TableCell className={classes.tableCell} component="th" scope="row">
                  <h4 className={classes.margin}>
                    {item.firstName} {item.lastName}
                  </h4>
                </TableCell>

                <TableCell className={classes.tableCell}>
                  <p className={classes.margin}> {item.email}</p>
                </TableCell>

                <TableCell className={classes.tableCell} align="left">
                  <p className={classes.margin}> {item.landlineNumber}</p>
                  {item.mobileNumber ? <p className={classes.margin}>{item.mobileNumber}</p> : null}
                </TableCell>
                <TableCell className={classes.tableCell} align="left">
                  <p>{item.memberName}</p>
                </TableCell>
                {/* <TableCell className={classes.tableCell} align="right">
                  <Route
                    render={({ history }) => (
                      <Button
                        fullWidth
                        onClick={() => {
                          history.push(`/BidderDetailsChange/${item.id}`);
                        }}
                      >
                        Edit Details
                      </Button>
                    )}
                  />
                </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  } else {
    return <h2>No bidders found</h2>;
  }
};
