import * as React from "react";
import { LiveBidSearch } from "../components/live-bids/LiveBidSearch";
import { Container, Typography, Box } from "@material-ui/core";
import { SecureArea } from "../components/authentication/SecureArea";
import { MemberPermission } from "../services/MemberService";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../services/TelemetryService";

function LiveBids() {
  return (
    <Container maxWidth={"lg"} fixed>
      <SecureArea memberPermission={MemberPermission.FullMember}>
        <Typography variant="h4" color="primary" gutterBottom>
          Manage Live Bids
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          View and Manage live auctions.
        </Typography>
        <Box mt={4}>
          <LiveBidSearch />
        </Box>
      </SecureArea>
    </Container>
  );
}

export default withAITracking(reactPlugin, LiveBids, "LiveBids");
