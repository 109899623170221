import { makeStyles } from "@material-ui/core/styles";

export const HomepageStyles = makeStyles(theme => ({
  subtitle: {
    height: "100%",
    marginBottom: "32px",
  },
  homepageModulePaper: {
    height: "100%",
  },
  homepageModuleTitle: {
    marginTop: "16px",
  },
}));
