import * as React from "react";
import { Typography, Container, Box } from "@material-ui/core";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../services/TelemetryService";

function Unauthorised() {
  return (
    <Container fixed style={{ minHeight: "350px", maxWidth: "1280px" }}>
      <Box style={{ paddingTop: "32px" }}>
        <Typography variant="h1" component="h1" align="center" color="primary" style={{ fontWeight: 700 }} gutterBottom>
          401
        </Typography>
        <Typography variant="h4" component="h2" align="center" color="primary" gutterBottom>
          Unauthorised
        </Typography>
        <Typography variant="h5" component="h3" align="center" gutterBottom>
          You do not have permission to view this page.
        </Typography>
      </Box>
    </Container>
  );
}

export default withAITracking(reactPlugin, Unauthorised, "Unauthorised");
