import moment from "moment";
import * as React from "react";
import {
  Typography,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  DialogContent,
  DialogActions,
  Card,
  CardContent,
  Box,
  MobileStepper,
  TextField,
  Divider,
  ButtonGroup,
  Grid
} from "@material-ui/core";
import * as Styles from "./styles/BiddersDocumentsSubmittedStyles";
import { IBidder } from "../../interfaces/bidders/IBidder";
import * as Address from "../../interfaces/IAddress";
import { LoadingComponent } from "../loading/LoadingComponent";
import { BidderDocument } from "./BidderDocument";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { useBiddersAwaitingDocumentChecks, useBidderVerificationDocuments } from "../../services/BidderService";
import { useSnackbar } from "notistack";
import { IVerificationSubmission } from "../../interfaces/bidders/IVerificationSubmission";

export const BiddersDocumentsSubmitted: React.FC = () => {
  const classes = Styles.BiddersDocumentsSubmittedStyles();

  const [showDocumentDialog, setShowDocumentDialog] = React.useState(false);
  const [bidderToView, setBidderToView] = React.useState<IBidder>();
  const [bidderDocumentNumberToView, setBidderDocumentNumberToView] = React.useState<number>(0);
  const [activeDocument, setActiveDocument] = React.useState<IVerificationSubmission | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [isReadyToSubmit, setIsReadyToSubmit] = React.useState(false);

  const [bidders, loadingBidders, , refreshBidders] = useBiddersAwaitingDocumentChecks(() =>
    enqueueSnackbar("A problem occurred retrieving the bidders", { variant: "error" })
  );
  const [documents, setDocuments, loadingDocuments, , putDocuments] = useBidderVerificationDocuments(
    bidderToView && bidderToView.id ? bidderToView.id : null,
    () => enqueueSnackbar("A problem occurred retrieving the documents", { variant: "error" })
  );


  React.useEffect(() => {
    if (bidderToView === undefined) {
      setShowDocumentDialog(false);
    } else {
      setShowDocumentDialog(true);
      setIsReadyToSubmit(false);
    }
  }, [bidderToView]);

  React.useEffect(
    () => setActiveDocument(documents !== null && documents.length > bidderDocumentNumberToView ? documents[bidderDocumentNumberToView] : null),
    [documents, bidderDocumentNumberToView]
  );

  const checkIsReadyToSubmit = () => documents !== null && documents.every(f => f.fileValid || (!f.fileValid && f.feedbackIfInvalid !== null));

  const replaceDocumentInSet = (alteredDocument: IVerificationSubmission) => {
    if (documents === null) return;

    const index = documents.findIndex(d => d.url === alteredDocument.url);
    documents.splice(index, 1, alteredDocument);
    setDocuments(documents);

    setActiveDocument(documents[bidderDocumentNumberToView]);
    setIsReadyToSubmit(checkIsReadyToSubmit());
  };

  const markActiveDocumentAsValid = () => replaceDocumentInSet({ ...activeDocument!, fileValid: true, feedbackIfInvalid: "" });

  const markActiveDocumentAsInvalid = (reason: string) => replaceDocumentInSet({ ...activeDocument!, fileValid: false, feedbackIfInvalid: reason });

  const documentDialog = () => {
    if (loadingDocuments) {
      return (
        <Dialog open={showDocumentDialog} maxWidth="lg" fullWidth onClose={() => setBidderToView(undefined)}>
          <DialogContent>
            <LoadingComponent label="Loading documents" />
          </DialogContent>
        </Dialog>
      );
    }

    if (documents && bidderToView && activeDocument) {
      return (
        <Dialog open={showDocumentDialog} fullScreen onClose={() => setBidderToView(undefined)}>
          <DialogContent>
            <Card>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Box>
                    <BidderDocument document={activeDocument!} />
                    <MobileStepper
                      className={classes.documentInfo}
                      variant="dots"
                      steps={documents.length}
                      position="static"
                      activeStep={bidderDocumentNumberToView}
                      nextButton={
                        <Button
                          size="small"
                          onClick={() => setBidderDocumentNumberToView(bidderDocumentNumberToView + 1)}
                          disabled={bidderDocumentNumberToView === documents.length - 1}
                        >
                          Next
                          <KeyboardArrowRight />
                        </Button>
                      }
                      backButton={
                        <Button
                          size="small"
                          onClick={() => setBidderDocumentNumberToView(bidderDocumentNumberToView - 1)}
                          disabled={bidderDocumentNumberToView === 0}
                        >
                          <KeyboardArrowLeft />
                          Back
                        </Button>
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <CardContent>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell variant="head">Name</TableCell>
                          <TableCell>
                            {bidderToView.firstName} {bidderToView.lastName}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell variant="head">Address</TableCell>
                          <TableCell>{Address.formatAddressAsSingleLine(bidderToView.address)}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell variant="head">Email</TableCell>
                          <TableCell>{bidderToView.email}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell variant="head">Mobile</TableCell>
                          <TableCell>{bidderToView.mobileNumber}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell variant="head">Date Received</TableCell>
                          <TableCell>{bidderToView.verification ? moment(bidderToView.verification?.lastUpdated).format("DD/MM/YYYY") : 'Unknown'}</TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell variant="head">Subscribed</TableCell>
                        <TableCell>{bidderToView.subscriptionPaymentMade ? 'Yes' : 'No'}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>
                            <TextField
                              fullWidth
                              required
                              error={!(activeDocument.feedbackIfInvalid && activeDocument.feedbackIfInvalid?.length > 0) && !activeDocument.fileValid}
                              helperText={
                                !(activeDocument.feedbackIfInvalid && activeDocument.feedbackIfInvalid?.length > 0) && !activeDocument.fileValid
                                  ? "Message is required before rejecting a document."
                                  : ""
                              }
                              label="Rejection message (caution: visible to bidder)"
                              value={activeDocument.feedbackIfInvalid ? activeDocument.feedbackIfInvalid : ""}
                              onChange={value => setActiveDocument({ ...activeDocument, feedbackIfInvalid: value.target.value })}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.bottomCell} colSpan={2}>
                            <Button
                              className={classes.buttonSpacing}
                              variant="contained"
                              color="primary"
                              onClick={() => markActiveDocumentAsInvalid(activeDocument.feedbackIfInvalid)}
                              disabled={!(activeDocument.feedbackIfInvalid && activeDocument.feedbackIfInvalid?.length > 0)}
                            >
                              Reject doc
                            </Button>
                            <Button className={classes.buttonSpacing} variant="contained" color="primary" onClick={() => markActiveDocumentAsValid()}>
                              Approve doc
                            </Button>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </CardContent>
                </Grid>
              </Grid>


            </Card>
          </DialogContent>
          <DialogActions>
            <ButtonGroup color="primary">
              <Button onClick={() => setBidderToView(undefined)}>Close without saving</Button>
              <Button
                disabled={!isReadyToSubmit}
                onClick={() => {
                  const doit = async () => {
                    await putDocuments();
                    refreshBidders();
                    setBidderToView(undefined);
                  };
                  doit();
                }}
                variant="contained"
              >
                Save Approvals
              </Button>
            </ButtonGroup>
          </DialogActions>
        </Dialog>
      );
    }
  };

  return (
    <>
      {loadingBidders && <LoadingComponent label="Refreshing bidders" />}
      {!loadingBidders && bidders && bidders.length === 0 && (
        <>
          <Typography variant="h5">No Bidders awaiting approval</Typography>
          <Divider className={classes.middleDivider} variant="middle" />
        </>
      )}
      {!loadingBidders && bidders && bidders.length > 0 && (
        <>
          {documentDialog()}
          <Typography variant="h5">Awaiting approval: {bidders.length}</Typography>
          <Table aria-label="Awaiting approval">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="left">Email</TableCell>
                <TableCell align="left">Address</TableCell>
                <TableCell align="left">Date Received</TableCell>
                <TableCell align="left">Subscribed</TableCell>
                <TableCell align="left"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bidders.map(bidder => (
                <TableRow key={bidder.id}>
                  <TableCell component="th" scope="row">
                    {bidder.firstName} {bidder.lastName}
                  </TableCell>
                  <TableCell align="left">{bidder.email}</TableCell>
                  <TableCell align="left">{Address.formatAddressAsSingleLine(bidder.address)}</TableCell>
                  <TableCell align="left">{bidder.verification ? moment(bidder.verification?.lastUpdated).format("DD/MM/yyyy") : 'Unknown'}</TableCell>
                  <TableCell align="left">{bidder.subscriptionPaymentMade ? 'Yes' : 'No'}</TableCell>
                  <TableCell align="left">
                    <Button variant="contained" color="primary" onClick={() => setBidderToView(bidder)}>
                      View documents
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      )}
    </>
  );
};
