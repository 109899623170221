import * as React from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  TextField,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Fab,
} from "@material-ui/core";
import { IBidder } from "../../interfaces/bidders/IBidder";
import { useState } from "react";
import moment from "moment";
import { useSnackbar } from "notistack";
import { addManualPayment, giveTrial } from "../../services/BidderService";
import { LoadingComponent } from "../loading/LoadingComponent";
import ReplayIcon from "@material-ui/icons/Replay";
import * as Styles from "./styles/BidderDetailsStyles";
import Alert from "@material-ui/lab/Alert/Alert";
import { DefaultAddManualPaymentDto } from "../../interfaces/bidders/IAddManualPaymentDto";
import { DefaultIssueRefundDto } from "../../interfaces/bidders/IIssueRefundDto";
import GetAppIcon from "@material-ui/icons/GetApp";
import { ReceiptData, useAllBidderReceiptsForMember } from "../../services/ReceiptService";
import { Link } from "react-router-dom";
import { issueDirectPaymentRefund, requestStripeRefund, rejectStripeRefund, postRequestRefund } from "../../services/RefundService";
import * as BidderService from "../../services/BidderService";
import { SecureArea } from "../authentication/SecureArea";
import { MemberPermission } from "../../services/MemberService";

interface IProps {
  bidder: IBidder;
  setBidder: React.Dispatch<React.SetStateAction<IBidder>>;
}

export const SubscriptionTab: React.FC<IProps> = ({ bidder, setBidder }) => {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [showFreeTrial, setShowFreeTrial] = useState(false);

  const [showAddPayment, setShowAddPayment] = useState(false);
  const [paymentDto, setPaymentDto] = useState(DefaultAddManualPaymentDto);
  const [showIssueRefund, setShowIssueRefund] = useState(false);
  const [issueRefundDto, setIssueRefundDto] = useState(DefaultIssueRefundDto);
  const [selectedReceiptData, setSelectedReceiptData] = React.useState<ReceiptData>();
  const [showRejectRefund, setShowRejectRefund] = useState(false);
  const [showRequestRefund, setShowRequestRefund] = useState(false);
  const [refundReasonValidation, setRefundReasonValidation] = useState("");

  const [performingAction, setPerformingAction] = useState(false);
  const [freeTrialWeeks, setFreeTrialWeeks] = useState(2);

  const requestRefund = (receipt: ReceiptData) => {
    setSelectedReceiptData(receipt);
    issueRefundDto.orderId = receipt.id;
    setIssueRefundDto(issueRefundDto);
    setShowRequestRefund(true);
  }


  const rejectRefund = (receipt: ReceiptData) => {
    setSelectedReceiptData(receipt);
    setShowRejectRefund(true);
  }

  const [receipts, loadingReceipts, , refreshReceipts] = useAllBidderReceiptsForMember(bidder.id, () => {
    enqueueSnackbar("There was a problem loading your subscription history, please try again.", { variant: "error" });
  });

  const classes = Styles.BidderDetailsStyles();

  const refetchBidder = async () => {
    var updatedBidder = await BidderService.GetBidder(bidder.id);
    if (updatedBidder.parsedBody) setBidder(updatedBidder.parsedBody);
  };

  const calculateExpiryTime = () => {
    return bidder.subscriptionEndDate === null || moment(bidder.subscriptionEndDate).isBefore(moment.now())
      ? moment().add(paymentDto.numberOfYears, "years")
      : moment(bidder.subscriptionEndDate).add(paymentDto.numberOfYears, "years");
  };

  const updateTrialTime = (weeks: string) => {
    var weeksAsInt = parseInt("0" + weeks);
    if (weeksAsInt < 1) {
      weeksAsInt = 1;
    }
    if (weeksAsInt > 104) {
      weeksAsInt = 104;
    }
    setFreeTrialWeeks(weeksAsInt);
  };

  const createTrial = async () => {
    setPerformingAction(true);
    try {
      await giveTrial(bidder.id, freeTrialWeeks);
      enqueueSnackbar("Successfully issued free trial.", { variant: "info" });
      await refetchBidder();
      setLoading(true);
      setShowFreeTrial(false);
    } catch {
      enqueueSnackbar("Something went wrong whilst trying to give a free trial. Please try again.", { variant: "error" });
    } finally {
      refreshReceipts();
      setPerformingAction(false);
      setLoading(false);
    }
  };

  const createTrialButton = () => {
    return (
      <Button variant="contained" color="secondary" onClick={() => setShowFreeTrial(true)} className={classes.margin}>
        Give Free Trial
      </Button>
    );
  };
  // REMOVED FOR COMMERCIAL CHANGES IN CR28
  // const createAddPaymentButton = () => {
  //   return (
  //     <Button variant="contained" color="secondary" onClick={() => setShowAddPayment(true)} className={classes.margin}>
  //       Add Payment
  //     </Button>
  //   );
  // };

  const createManualPayment = async () => {
    setPerformingAction(true);
    try {
      await addManualPayment(bidder.id, paymentDto);
      enqueueSnackbar("Successfully added manual payment.", { variant: "info" });
      await refetchBidder();
      setLoading(true);
      setShowAddPayment(false);
      setPaymentDto(DefaultAddManualPaymentDto);
    } catch {
      enqueueSnackbar("Something went wrong whilst trying to add a manual payment. Please try again.", { variant: "error" });
    } finally {
      refreshReceipts();
      setPerformingAction(false);
      setLoading(false);
    }
  };

  const createIssueRefund = async () => {
    if (issueRefundDto.reason.length < 1) {
      setRefundReasonValidation("You must enter a refund reason");
      return;
    }
    setRefundReasonValidation("");
    setPerformingAction(true);
    try {
      await issueDirectPaymentRefund(bidder.id, issueRefundDto);
      enqueueSnackbar("Successfully issued refund.", { variant: "info" });
      await refetchBidder();
      setLoading(true);
      setShowIssueRefund(false);
      setIssueRefundDto(DefaultIssueRefundDto);
    } catch {
      enqueueSnackbar("Something went wrong whilst trying to issue the refund. Please try again.", { variant: "error" });
    } finally {
      refreshReceipts();
      setPerformingAction(false);
      setLoading(false);
    }
  };

  const createRefundRequest = async () => {
    if (issueRefundDto.reason.length > 0) {
      setPerformingAction(true);
      try {
        await postRequestRefund(issueRefundDto);

        if (selectedReceiptData != undefined && selectedReceiptData != null && receipts != undefined && receipts != null) {
          selectedReceiptData.orderCurrentStatus = 'Refund Requested';
          let idx = receipts.findIndex(o => o.id == selectedReceiptData.id);

          if (idx > -1) {
            receipts[idx] = selectedReceiptData;
          }
        }

        enqueueSnackbar("Successfully requested refund.", { variant: "info" });
        setIssueRefundDto(DefaultIssueRefundDto);
      } catch {
        enqueueSnackbar("Something went wrong whilst trying to request the refund. Please try again.", { variant: "error" });
      } finally {
        setPerformingAction(false);
        setShowRequestRefund(false);
      }
    }
  };

  const rejectRefundRequest = async () => {
    if (selectedReceiptData?.id != undefined) {
      setPerformingAction(true);
      try {
        await rejectStripeRefund(bidder.id, selectedReceiptData.id);
        enqueueSnackbar("Successfully rejected refund.", { variant: "info" });
        await refetchBidder();
        setLoading(true);
        setShowRejectRefund(false);
      } catch {
        enqueueSnackbar("Something went wrong whilst trying to reject the refund. Please try again.", { variant: "error" });
      } finally {
        refreshReceipts();
        setPerformingAction(false);
        setLoading(false);
      }
    }
  };

  const friendlyStatusName = (status: string) => {
    switch (status) {
      case 'RefundRequested':
        return 'Refund Requested';
        break;
      case 'Refund Rejected':
      case 'RefundRejected':
        return 'Refund Rejected';
        break;
      default:
        return status;
        break;
    }
  }

  const currentSubscriptionStatus = () => {
    if (!bidder.subscriptionEndDate) {
      return (
        <>
          <Typography>This bidder hasn't yet paid for a subscription.</Typography>
          {/* {createTrialButton()} */}
        </>
      );
    }
    if (moment(bidder.subscriptionEndDate).isBefore(moment.now())) {
      return (
        <>
          <Alert severity="error">
            The bidders subscription expired {moment(bidder.subscriptionEndDate).fromNow()} on{" "}
            {moment(bidder.subscriptionEndDate).format("MMMM Do YYYY h:mm a")}{" "}
          </Alert>
          {/* {createTrialButton()} */}
        </>
      );
    }
    if (moment(bidder.subscriptionEndDate).isBefore(moment().add(14, "days"))) {
      return (
        <Alert severity="warning">
          Subscription expires {moment(bidder.subscriptionEndDate).fromNow()} - subscribed until{" "}
          {moment(bidder.subscriptionEndDate).format("MMMM Do YYYY h:mm a")}
        </Alert>
      );
    }
    return (
      <Alert severity="success">
        {moment(bidder.subscriptionEndDate).diff(moment().add(-25, "minutes"), "days")} days remaining - subscribed until{" "}
        {moment(bidder.subscriptionEndDate).format("MMMM Do YYYY h:mm a")}
      </Alert>
    );
  };

  const handleChange = (dto, setState: (value: any) => void) => prop => event => {
    setState({ ...dto, [prop]: event.target.value });
  };

  return (
    <>
      <Typography variant="h4">Subscription</Typography>

      {currentSubscriptionStatus()}

      {/* REMOVED FOR COMMERCIAL CHANGES IN CR28 */}
      {/* {createAddPaymentButton()} */}

      <Dialog open={showRejectRefund} onClose={() => setShowRejectRefund(false)}>
        <DialogTitle id="form-dialog-title">Reject Refund</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you wish to reject this refund?
          </DialogContentText>

        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowRejectRefund(false)} disabled={performingAction} variant="contained">
            BACK TO SUBSCRIPTIONS
          </Button>
          <Button onClick={rejectRefundRequest} color="primary" variant="contained" disabled={performingAction}>
            CONFIRM REJECT REFUND
          </Button>
        </DialogActions>
      </Dialog>




      <Dialog open={showFreeTrial} onClose={() => setShowFreeTrial(false)}>
        <DialogTitle id="form-dialog-title">Give Free Trial</DialogTitle>
        <DialogContent>
          <DialogContentText>Please enter the number of weeks you would like to give this bidder a free trial for.</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Number of weeks"
            type="number"
            value={freeTrialWeeks}
            inputProps={{ min: "1", max: "104" }}
            onChange={target => updateTrialTime(target.target.value)}
            fullWidth
          />
          <DialogContentText>
            The free trial will expire on{" "}
            {moment()
              .add(freeTrialWeeks, "weeks")
              .format("MMMM Do YYYY h:mm a")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowFreeTrial(false)} color="primary" disabled={performingAction}>
            Cancel
          </Button>
          <Button onClick={createTrial} color="primary" disabled={performingAction}>
            Create Trial
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showAddPayment} onClose={() => setShowAddPayment(false)}>
        <DialogTitle id="form-dialog-title">Add manual payment</DialogTitle>
        <DialogContent>
          <DialogContentText>Enter the details below to record a manual payment for this bidder.</DialogContentText>
          <FormControl fullWidth>
            <InputLabel id="subscription-length-select-helper-label">Subscription Length</InputLabel>
            <Select
              labelId="subscription-length-select-helper-label"
              value={paymentDto.numberOfYears}
              onChange={evt => setPaymentDto(bidder => ({ ...bidder, numberOfYears: parseInt(evt.target.value as string) }))}
            >
              <MenuItem value="1">1 Year</MenuItem>
              <MenuItem value="2">2 Years</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="payment-type-select-helper-label">Payment Type</InputLabel>
            <Select
              labelId="payment-type-select-helper-label"
              value={paymentDto.paymentType}
              onChange={handleChange(paymentDto, setPaymentDto)("paymentType")}
            >
              <MenuItem value="Cash">Cash</MenuItem>
              <MenuItem value="Card">Card</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <TextField
              label="Payment Reference"
              value={paymentDto.paymentReference}
              onChange={handleChange(paymentDto, setPaymentDto)("paymentReference")}
            />
          </FormControl>
          <DialogContentText>The subscription will expire on {calculateExpiryTime().format("MMMM Do YYYY h:mm a")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowAddPayment(false)} color="primary" disabled={performingAction}>
            Cancel
          </Button>
          <Button onClick={createManualPayment} color="primary" disabled={performingAction}>
            Add Payment
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showIssueRefund} onClose={() => setShowIssueRefund(false)}>
        <DialogTitle id="form-dialog-title">Issue Refund</DialogTitle>
        <DialogContent>
          <DialogContentText>Enter the details below to issue a refund for the last successful payment.</DialogContentText>
          <FormControl fullWidth>
            <TextField
              label="Refund Reason"
              value={issueRefundDto.reason}
              onChange={handleChange(issueRefundDto, setIssueRefundDto)("reason")}
              required
              error={refundReasonValidation.length > 0}
              helperText={refundReasonValidation}
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              label="Refund Payment Reference"
              value={issueRefundDto.referenceNumber}
              onChange={handleChange(issueRefundDto, setIssueRefundDto)("referenceNumber")}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowIssueRefund(false)} color="primary" disabled={performingAction}>
            Cancel
          </Button>
          <Button onClick={createIssueRefund} color="primary" disabled={performingAction}>
            Issue Refund
          </Button>
        </DialogActions>
      </Dialog>

      {/* <Dialog open={showRequestRefund} onClose={() => setShowRequestRefund(false)}>
        <DialogTitle id="form-dialog-title">Request Stripe Refund</DialogTitle>
        <DialogContent>
          <DialogContentText>Enter the details below to request a refund for the last successful payment.</DialogContentText>
          <FormControl fullWidth>
            <TextField
              label="Refund Reason"
              value={issueRefundDto.reason}
              onChange={handleChange(issueRefundDto, setIssueRefundDto)("reason")}
              required
              error={refundReasonValidation.length > 0}
              helperText={refundReasonValidation}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowRequestRefund(false)} color="primary" disabled={performingAction}>
            Cancel
          </Button>
          <Button onClick={createStripeRefundRequest} color="primary" disabled={performingAction}>
            Request Refund
          </Button>
        </DialogActions>
      </Dialog> */}


      <Dialog

        open={showRequestRefund}
        onClose={() => setShowRequestRefund(false)}
        maxWidth="md"
        PaperProps={{
          style: {
            maxWidth: "623px",
            width: "100%",
          },
        }}
      >
        <DialogTitle
          style={{
            border: "1px solid #CCCCCC",
          }}
          id="form-dialog-title">Refund Request</DialogTitle>

        <DialogContent
          style={{
            paddingTop: "15px",
            paddingBottom: "15px",
            paddingLeft: "23px",
            paddingRight: "23px",
          }}
        >
          <DialogContentText>
            Are you sure you wish to request a refund?
          </DialogContentText>
          <DialogContentText>
            We aim to respond to all refund requests within 2 working days.
          </DialogContentText>

          <FormControl fullWidth>
            <TextField
              label="Refund Reason"
              value={issueRefundDto.reason}
              onChange={handleChange(issueRefundDto, setIssueRefundDto)("reason")}
              required
              error={refundReasonValidation.length > 0}
              helperText={refundReasonValidation}
            />
          </FormControl>
        </DialogContent>
        <DialogActions
          style={{
            padding: "0 23px 40px 23px",
          }}
        >
          <Button
            style={{
              backgroundColor: "#FFFFFF",
              width: "50%",
            }}
            onClick={createRefundRequest} variant="contained" disabled={performingAction}>
            CONFIRM REFUND REQUEST
          </Button>
          <Button style={{ width: "50%", }} color="primary" onClick={() => setShowRequestRefund(false)} disabled={performingAction} variant="contained">
            BACK TO SUBSCRIPTIONS
          </Button>

        </DialogActions>
      </Dialog>



      <Typography variant="h4" className={classes.margin}>
        Subscription History
      </Typography>
      {loadingReceipts || loading ? (
        <LoadingComponent label="Loading Subscriptions"></LoadingComponent>
      ) : (
        <TableContainer component={Paper} className={classes.margin}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Order Description</TableCell>
                <TableCell align="right">Amount</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Receipt</TableCell>
                <TableCell>Refund Receipt</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {receipts?.map((receipt, index) => {
                const canRefundManualPayment =
                  receipt.isEligibleForRefund && (receipt.paymentType === "Card Terminal Payment" || receipt.paymentType === "Cash Payment");

                const canRequestPayzoneRefund = receipt.isEligibleForRefund && receipt.paymentType === "Online Card Payment";
                return (
                  <TableRow hover key={receipt.saleDate.toString()} className={classes.rows}>
                    <TableCell>{moment(receipt.saleDate).fromNow()}</TableCell>
                    <TableCell>{receipt.paymentType}</TableCell>
                    <TableCell>
                      {receipt.orderDescription}
                      {receipt.referenceNumber && receipt.referenceNumber.length > 0 && (
                        <>
                          <br />
                          <strong>Order Ref: </strong>
                          {receipt.transactionNumber}
                        </>
                      )}
                    </TableCell>
                    <TableCell align="right">
                      {/* {canRefundManualPayment && (
                        <IconButton aria-label="Issue Refund" title="Issue Refund" onClick={() => setShowIssueRefund(true)}>
                          <ReplayIcon />
                        </IconButton>
                      )}
                      {canRequestPayzoneRefund && (
                        <IconButton aria-label="Issue Refund" title="Issue Refund" onClick={() => setShowRequestRefund(true)}>
                          <ReplayIcon />
                        </IconButton>
                      )} */}
                      £{(receipt.totalIncVat / 100).toFixed(2)}
                    </TableCell>
                    <TableCell>{friendlyStatusName(receipt.orderCurrentStatus)}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={receipt.orderStatus === "Pending" || receipt.orderStatus === "Error" || receipt.orderStatus === "Failed"}
                        onClick={() => { window.open(`/Payments/Document/${receipt.id}`, '_blank', 'noopener,noreferrer'); }}>
                        DOWNLOAD
                      </Button>
                    </TableCell>
                    <TableCell>
                      {receipt.orderCurrentStatus === "Refunded" && (
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={receipt.orderStatus === "Pending" || receipt.orderStatus === "Error" || receipt.orderStatus === "Failed"}
                          onClick={() => { window.open(`/Payments/Document/${receipt.id}/status/${receipt.orderCurrentStatus}`, '_blank', 'noopener,noreferrer'); }}>
                          DOWNLOAD
                        </Button>
                      )}
                    </TableCell>


                    <TableCell align="right">
                      <SecureArea hideNotAuthorisedMessage={true} memberPermission={MemberPermission.SuperAdmin}>
                        {receipt.orderCurrentStatus == 'RefundRequested' &&
                          <Button variant="contained" onClick={() => { rejectRefund(receipt); }} disabled={receipt.orderCurrentStatus != 'RefundRequested'}>
                            REJECT REFUND
                          </Button>
                        }
                      </SecureArea>

                      <SecureArea hideNotAuthorisedMessage={true} memberPermission={MemberPermission.FullMember}>
                        {(receipt.orderCurrentStatus == 'Succeeded' || receipt.orderCurrentStatus == 'RefundRejected') && receipt.refundAllowed &&
                          <Button
                            style={{
                              backgroundColor: "#FFFFFF",
                            }}
                            variant="contained" onClick={() => { requestRefund(receipt); }}>
                            REQUEST REFUND
                          </Button>
                        }
                      </SecureArea>


                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};
