import { GetClientSettings } from "./SettingsService";
import { ApiService, HttpResponse } from "./ApiService";

import { ISalvageClientSettings } from "../interfaces/ISalvageClientSettings";
import { useReadonlyData } from "./useVersionedData";

const ClientSettings: ISalvageClientSettings = GetClientSettings();

export interface IFailedAuctionImport {
  referenceNumber: string;
  error: string;
}

export interface IAuctionImportPackProgress {
  batchId: string;
  isFinished: boolean;
  wasSuccessful: boolean;
  failedImports: IFailedAuctionImport[];
  processedCount: number;
  remainingCount: number;
}

export const PostPackUpload = async <T>(pack: FormData): Promise<HttpResponse<T>> => {
  return await ApiService().PostXML<T>(ClientSettings.LotPrivateApiUrl + `/pack/upload`, pack);
};

export const useAuctionImportPackProgress = (batchId: string, onError: (httpStatusCode: number, resultBody?: string) => void) =>
  useReadonlyData<IAuctionImportPackProgress>(`${ClientSettings.AuctionPrivateApiUrl}/integration-api/auction-packs/${batchId}/`, onError);
