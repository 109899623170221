import { Checkbox, FormControlLabel } from "@material-ui/core";
import React from "react";

interface TriStateCheckboxProps {
  title: string;
  appendNullTitle?: string;
  checked: boolean | null;
  callback: (state: boolean | null) => void;
}

export const TriStateCheckbox: React.FC<TriStateCheckboxProps> = ({ title, appendNullTitle = "(Unknown)", checked, callback }) => {
  const [currentState, setCurrentState] = React.useState<boolean | null>(checked);

  const onFieldChange = () => {
    if (currentState === null) {
      setCurrentState(true);
      callback(true);
    } else if (currentState === true) {
      setCurrentState(false);
      callback(false);
    } else if (currentState === false) {
      setCurrentState(null);
      callback(null);
    }
  };

  return (
    <FormControlLabel
      value="hasKeys"
      control={
        <Checkbox name="hasKeys" indeterminate={currentState === null} checked={currentState ?? false} onClick={onFieldChange} value={currentState} />
      }
      label={currentState === null ? `${title} ${appendNullTitle}` : title}
      labelPlacement="end"
    />
  );
};
