import * as React from "react";
import { Typography, Container, Box } from "@material-ui/core";
import { LotsComponent } from "../components/lots/LotsComponent";
import { SecureArea } from "../components/authentication/SecureArea";
import { MemberPermission } from "../services/MemberService";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../services/TelemetryService";

function Lots() {
  return (
    <Container maxWidth={"lg"} fixed>
      <SecureArea memberPermission={MemberPermission.BucketAdmin}>
        <Typography variant="h4" color="primary" gutterBottom>
          Lots
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          View all lots in the system.
        </Typography>
        <Box mt={4}>
          <LotsComponent />
        </Box>
      </SecureArea>
    </Container>
  );
}

export default withAITracking(reactPlugin, Lots, "Lots");
