import * as React from "react";
import { Typography, Container, Box } from "@material-ui/core";
import { SecureArea } from "../components/authentication/SecureArea";
import { MemberPermission } from "../services/MemberService";
import { MarginShareReportContainer } from "../components/reporting/MarginShareReportContainer";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../services/TelemetryService";

const MarginShareReporting = () => (
  <Container maxWidth={"lg"} fixed>
    <SecureArea memberPermission={MemberPermission.SuperAdmin}>
      <Typography variant="h4" color="primary" gutterBottom>
        Margin Share Report
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Create an export for Margin Share Report.
      </Typography>
      <Box mt={4}>
        <MarginShareReportContainer />
      </Box>
    </SecureArea>
  </Container>
);

export default withAITracking(reactPlugin, MarginShareReporting, "MarginShareReporting");
