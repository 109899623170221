import { useSnackbar } from "notistack";
import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Select, MenuItem, Grid } from "@material-ui/core";
import { IAuctionInfo } from "../../../services/AuctionService";
import { useConfiguration } from "../../../services/VehicleConfigurationService";
import { LoadingComponent } from "../../loading/LoadingComponent";
import * as LotService from "../../../services/LotService";
import * as AuctionService from "../../../services/AuctionService";
import { Vehicle } from "../../create-lot/components/LotFieldComponent";
import { useHistory } from "react-router-dom";

interface TypeChangeDialog {
  open: boolean;
  setOpen: (open: boolean) => void;
  auction: IAuctionInfo;
  refreshAuctions: () => void;
}

export const AuctionTypeChangeDialog: React.FC<TypeChangeDialog> = ({ open, setOpen, auction, refreshAuctions }) => {
  const [newType, setNewType] = React.useState<string>(auction.metadata.lotType);
  const [loading, setLoading] = React.useState<boolean>(false);

  const [lot, , lotIsLoading, , updateLot] = LotService.useLotDetails(auction.lotId, () =>
    enqueueSnackbar("There was a problem getting the lot details. Please try again.", { variant: "error" })
  );
  const [configuration, configurationLoading] = useConfiguration(() => {
    enqueueSnackbar("There was a problem getting the lot configuration options, please try again.", { variant: "error" });
  });

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const disableButton = () => {
    return newType === auction.metadata.lotType || loading || configurationLoading || typeMismatchProblem();
  };

  const typeMismatchProblem = () => {
    return !Vehicle.includes(auction.metadata.lotType) && Vehicle.includes(newType);
  };

  const changeType = async () => {
    if (typeMismatchProblem()) {
      enqueueSnackbar("There was a problem with the new type chosen. Please update the lot details", { variant: "error" });
    } else {
      if (!lotIsLoading) {
        try {
          if (lot) {
            setLoading(true);
            await updateLot({ ...lot, lotType: newType }); //Updates lot details with new type
            await AuctionService.updateAuctionMetadata(auction.id); //Re-synchronises lot details with auction
            enqueueSnackbar("Lot type updated", { variant: "success" });
          } else {
            enqueueSnackbar("There was a problem getting the lot details. Please try again.", { variant: "error" });
          }
        } catch {
          enqueueSnackbar("There was a problem updating the lot type. Please try again.", { variant: "error" });
        } finally {
          setLoading(false);
          refreshAuctions();
          setOpen(false);
        }
      }
    }
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
      <DialogTitle>Change Lot Type</DialogTitle>
      <DialogContent>
        {(configurationLoading || loading) && <LoadingComponent label="Loading" marginTop="0px" marginBottom="0px" />}

        {!loading && !configurationLoading && (
          <>
            <Typography variant="subtitle1" style={{ marginBottom: "16px" }}>
              <em>{auction.metadata.title}</em>
            </Typography>
            <Grid container alignItems="center">
              <Grid container style={{ marginBottom: "12px" }}>
                <Grid item xs={6}>
                  <Typography variant="subtitle1">Current lot type:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1">{auction.metadata.lotType}</Typography>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1">Select new lot type:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Select fullWidth id="vehicleType" name="vehicleType" onChange={event => setNewType(event.target.value as string)} value={newType}>
                  {configuration &&
                    configuration.item.lotTypes.map((lotType, index) => {
                      return (
                        <MenuItem key={index} value={lotType}>
                          {lotType}
                        </MenuItem>
                      );
                    })}
                </Select>
              </Grid>

              {typeMismatchProblem() && (
                <>
                  <Grid item xs={9} style={{ marginTop: "16px" }}>
                    <Typography variant="subtitle1" color="error">
                      Extra data is required when changing to this type. Click to update the lot details:
                    </Typography>
                  </Grid>
                  <Grid item xs={3} style={{ marginTop: "16px" }}>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => history.push(`/UpdateLot/${auction.lotId}/${auction.id}?lotType=${newType}`)}
                    >
                      Update Lot
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <Button color="primary" variant="contained" onClick={changeType} disabled={disableButton()}>
          Change Type
        </Button>
      </DialogActions>
    </Dialog>
  );
};
