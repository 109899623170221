import { ApiService, HttpResponse } from "./ApiService";
import { ISalvageClientSettings } from "../interfaces/ISalvageClientSettings";
import { GetClientSettings } from "./SettingsService";
import { IBidderSearch } from "../interfaces/bidders/IBidderSearch";
import { IBidderSearchResults } from "../interfaces/bidders/IBidderSearchResults";

const ClientSettings: ISalvageClientSettings = GetClientSettings();

export const SearchBidders = async (search: IBidderSearch): Promise<HttpResponse<IBidderSearchResults>> => {
  return await ApiService().Post<IBidderSearchResults>(`${ClientSettings.UsersPrivateApiUrl}/search/bidder/`, search);
};