import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import * as React from "react";
import { sentenceCaseTextFromCamelCase } from "./text-format/TextFormat";

export const Filter = ({ title, filterOptions, filterPropertyName, filterValue, changeHandler, enumCase = false, all = true }) => (
  <FormControl style={{ width: "100%" }} variant="outlined" margin="dense">
    <InputLabel variant="outlined">{title}</InputLabel>
    <Select label={title} value={filterValue} onChange={value => changeHandler(value, filterPropertyName)} variant="outlined">
      {all && <MenuItem value={"All"}>All</MenuItem>}
      {filterOptions?.map((type: string, index: number) => (
        <MenuItem value={type} key={index}>
          {enumCase ? sentenceCaseTextFromCamelCase(type, true) : type}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);
