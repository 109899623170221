import * as React from "react";
import { IBidder } from "../../interfaces/bidders/IBidder";
import { useSnackbar } from "notistack";
import { Typography, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Hidden, TablePagination } from "@material-ui/core";
import { LoadingComponent } from "../loading/LoadingComponent";
import { useAuctionsWatching } from "../../services/AuctionService";
import { WatchHistoryRow } from "./WatchHistoryRow";

interface BidderWatchingTabProps {
  bidder: IBidder;
}

export const BidderWatchingTab: React.FC<BidderWatchingTabProps> = ({ bidder }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [pageNumber, setPageNumber] = React.useState<number>(0);
  const [pageSize, setPageSize] = React.useState<number>(10);

  const [bidderWatchlistHistory, loadingBidderWatchlistHistory] = useAuctionsWatching(bidder.id, pageNumber, pageSize, () => {
    enqueueSnackbar("There was a problem loading the watch history for this bidder. Please try again.", { variant: "error" });
  });

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPageSize(parseInt(event.target.value, 10));
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPageNumber(newPage);
  };

  const paginationComponent = (
    <TablePagination
      component="div"
      count={bidderWatchlistHistory?.count ? bidderWatchlistHistory?.count : 0}
      page={pageNumber}
      rowsPerPage={pageSize}
      rowsPerPageOptions={[
        { value: 10, label: "10" },
        { value: 20, label: "20" },
        { value: 30, label: "30" },
      ]}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
  );

  return (
    <>
      <Typography variant="h4" component="h1" gutterBottom>
        Watchlist History
      </Typography>
      {loadingBidderWatchlistHistory && bidderWatchlistHistory === null && <LoadingComponent label="Loading watchlist" />}
      {!loadingBidderWatchlistHistory && bidderWatchlistHistory && (
        <>
          {paginationComponent}
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Title</TableCell>
                  <Hidden smDown>
                    <TableCell>Member</TableCell>
                    <TableCell>Auction End</TableCell>
                    <TableCell>Bid</TableCell>
                    <TableCell>Ref no</TableCell>
                    <TableCell>Registration</TableCell>
                  </Hidden>
                </TableRow>
              </TableHead>
              <TableBody>
                {bidderWatchlistHistory?.results.map((watchHistory, index) => (
                  <TableRow key={index}>
                    <WatchHistoryRow auctionListing={watchHistory} bidder={bidder} />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {paginationComponent}
        </>
      )}
    </>
  );
};
