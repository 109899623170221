import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { IBidder } from "../../../interfaces/bidders/IBidder";
import { IValidation } from "../../../interfaces/IValidation";
import { updateBidder } from "./UpdateBidder";
import { useSnackbar } from "notistack";
import * as Styles from "../styles/BidderDetailsStyles";
import { Box } from "@material-ui/core";
import { useHistory } from "react-router";
import * as BidderService from "../../../services/BidderService";

interface IProps {
  bidder: IBidder;
  setBidder: React.Dispatch<React.SetStateAction<IBidder>>;
  setValidation: React.Dispatch<React.SetStateAction<IValidation>>;
}

export const SaveChanges: React.FC<IProps> = ({ bidder, setBidder, setValidation }) => {
  const classes = Styles.BidderDetailsStyles();
  const history = useHistory();

  const [open, setOpen] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updateChanges = async () => {
    setIsSaving(true);
    const result = await updateBidder(bidder, setValidation);
    if (result) {
      const updatedBidder = await BidderService.GetBidder(bidder.id);
      if (updatedBidder.parsedBody) setBidder(updatedBidder.parsedBody);
      enqueueSnackbar("Bidder details changed.", { variant: "success" });
    } else {
      enqueueSnackbar("An error ocurred whilst trying to save this update. Please try again.", { variant: "error" });
    }
    setIsSaving(false);
    handleClose();
  };

  return (
    <div className={classes.buttons}>
      <Box mr={2}>
        <Button color="secondary" variant="contained" onClick={() => history.push(`/BidderSearch`)}>
          Cancel
        </Button>
      </Box>
      <Box>
        <Button color="primary" variant="contained" onClick={handleClickOpen}>
          Save Changes
        </Button>
      </Box>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{"Save Changes?"}</DialogTitle>
        <DialogContent>
          <p>Any changes made can't be undone once saved</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" disabled={isSaving}>
            Cancel
          </Button>
          <Button onClick={updateChanges} color="primary" variant="contained" disabled={isSaving} autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
