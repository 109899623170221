import * as React from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  ButtonGroup,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import { LoadingComponent } from "../loading/LoadingComponent";
import { currencyFormat, bidPlacedFormat } from "../../helpers/text-format/TextFormat";
import {
  useAuctionBidHistory,
  Bid,
  useBiddersOnAuction,
  sellDirectToBidAuction,
  useAuctionListing,
  auctionLotNotCollected,
  sellDirectWithNewBidAuction,
} from "../../services/AuctionService";
import * as Styles from "./styles/SellDirectToBidderStyles";
import { useHistory } from "react-router-dom";
import { AuctionStatus } from "../../interfaces/auctions/IAuction";

interface SellDirectToBidderComponent {
  auctionId: string;
}

export const SellDirectToBidderComponent: React.FC<SellDirectToBidderComponent> = ({ auctionId }) => {
  const history = useHistory();

  const [open, setOpen] = React.useState<boolean>(false);
  const [error, setError] = React.useState<boolean>(false);
  const [bid, setBid] = React.useState<Bid>();
  const [amount, setAmount] = React.useState<number>(0);
  const [loading, setLoading] = React.useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();
  const classes = Styles.SellDirectToBidderStyles();
  const [bidHistory, bidHistoryLoading] = useAuctionBidHistory(auctionId, () => {
    enqueueSnackbar("There was a problem with your auctions request please try again.", { variant: "error" });
  });
  const [bidders, biddersLoading] = useBiddersOnAuction(auctionId, () => {
    enqueueSnackbar("There was a problem with your bidder request please try again.", { variant: "error" });
  });

  const [auctionListing, , auctionListingLoading] = useAuctionListing(auctionId!, () => {
    enqueueSnackbar("There was a problem with your bidder request please try again.", { variant: "error" });
  });

  const handleSellDirectFromBid = async (auctionId: string, bidId: string) => {
    try {
      await sellDirectToBidAuction(auctionId, bidId);
      history.push(`/AuctionManagement/Auctions`);
    } catch {
      enqueueSnackbar("There was a problem with selling direct to the bidder, please try again.", { variant: "error" });
    }
  };

  const handleSellDirectWithAmount = async (auctionId: string, bidderId: string, amount: number) => {
    try {
      await sellDirectWithNewBidAuction(auctionId, bidderId, amount);
      history.push(`/AuctionManagement/Auctions`);
    } catch {
      enqueueSnackbar("There was a problem with selling direct to the bidder, please try again.", { variant: "error" });
    }
  };

  const handleLotNotCollected = async () => {
    try {
      await auctionLotNotCollected(auctionId);
    } catch {
      enqueueSnackbar("There was a problem with selling direct to the bidder, please try again.", { variant: "error" });
    }
  };

  const handleSubmit = async () => {
    try {
      if (bid) {
        setLoading(true);
        if (auctionListing?.status === AuctionStatus.AuctionEndedAndWon) {
          await handleLotNotCollected();
        }
        if (amount === bid.amount) {
          await handleSellDirectFromBid(auctionId, bid.bidId);
        } else await handleSellDirectWithAmount(auctionId, bid.bidderId, amount);
      } else {
        enqueueSnackbar("There was a problem with selling direct to the bidder, please try again.", { variant: "error" });
      }
    } catch {
      enqueueSnackbar("There was a problem with selling direct to the bidder, please try again.", { variant: "error" });
    } finally {
      setLoading(false);
      closeDialog();
    }
  };

  const openDialog = (newBid: Bid) => {
    setBid(newBid);
    setAmount(newBid.amount);
    setOpen(true);
  };

  const closeDialog = () => {
    setAmount(0);
    setOpen(false);
  };

  const handleAmountChange = e => {
    if (e.target.value % 1 !== 0) setError(true);
    else {
      setError(false);
    }
    setAmount(e.target.value === "" ? 0 : Math.floor(e.target.value));
  };

  const AmountDialog = () => (
    <Dialog open={open} fullWidth maxWidth="xs">
      <DialogTitle>Sell Direct</DialogTitle>
      <DialogContent>
        {loading ? (
          <LoadingComponent label="Loading" marginTop="0px" marginBottom="0px" />
        ) : (
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={6}>
              <Typography>Sell to Bidder: </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{bid?.bidderName ?? ""}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>Enter Amount: </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                type="number"
                error={error}
                helperText={error ? "Must be a whole number." : null}
                value={amount === 0 ? "" : amount}
                onChange={value => handleAmountChange(value)}
                InputProps={{
                  startAdornment: <InputAdornment position="start">£</InputAdornment>,
                }}
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Cancel</Button>
        <Button disabled={loading || amount <= 0} onClick={handleSubmit} variant="contained" color="primary">
          Sell Direct
        </Button>
      </DialogActions>
    </Dialog>
  );

  const bidRow = (bid: Bid) => {
    const bidder = bidders?.find(b => b.id === bid.bidderId);
    return (
      <TableRow>
        <TableCell>{bid.bidderName}</TableCell>
        <TableCell>
          <p className={classes.margin}>{bidder?.email}</p>
          <p className={classes.margin}>{bidder?.landlineNumber}</p>
          <p className={classes.margin}>{bidder?.mobileNumber}</p>
        </TableCell>
        <TableCell>
          <p className={classes.margin}>
            {bidder?.address.line1} {bidder?.address.line2} {bidder?.address.line3}
          </p>
          <p className={classes.margin}>
            {bidder?.address.city} {bidder?.address.county} {bidder?.address.country.name}
          </p>
          <p className={classes.margin}>{bidder?.address.postcode}</p>
        </TableCell>
        <TableCell>
          <p className={classes.margin}>{currencyFormat(bid.amount)}</p>
        </TableCell>
        <TableCell>{bidPlacedFormat(bid.bidDateTime.toString())}</TableCell>
        <TableCell>
          <ButtonGroup color="primary" fullWidth>
            <Button
              className={classes.buttonWidth}
              onClick={() => {
                openDialog(bid);
              }}
            >
              Sell To Bidder
            </Button>
          </ButtonGroup>
        </TableCell>
      </TableRow>
    );
  };

  return auctionListingLoading && bidHistoryLoading && biddersLoading ? (
    <LoadingComponent label="Loading" />
  ) : (
    <>
      <Grid container>
        <Grid xs={12} item>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Bidder name</TableCell>
                  <TableCell>Contact details</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>
                    <p className={classes.margin}>Amount</p>
                    <p className={classes.margin}>Proxy amount</p>{" "}
                  </TableCell>
                  <TableCell>Date/time</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bidHistory && bidHistory.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={3}>No bids available</TableCell>
                  </TableRow>
                )}
                {bidHistory && bidHistory.map((bid: Bid) => bidRow(bid))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      {AmountDialog()}
    </>
  );
};
