import moment from "moment";
import { OpeningTime, OpeningTimesStatus } from "../../services/MemberService";

export const ellipsisText = (input: string, maxLength: number) => {
  if (input.length > maxLength) return input.substring(0, maxLength) + "...";
  else return input;
};

export const currencyFormat = (amount: number) => {
  const localeString = amount.toLocaleString("GBP", { minimumFractionDigits: amount % 1 !== 0 ? 2 : 0 });
  if (localeString[0] === "-") return localeString.replace("-", "-£");
  else return `£${localeString}`;
};

export const openingTimes = (openingTime: OpeningTime) => {
  if (openingTime.openingTimeStatus === OpeningTimesStatus.Open) {
    const open = moment(openingTime.open).format("HH:mm");
    const close = moment(openingTime.close).format("HH:mm");
    return open + " - " + close;
  } else if (openingTime.openingTimeStatus === OpeningTimesStatus.Closed) {
    return "Closed";
  } else if (openingTime.openingTimeStatus === OpeningTimesStatus.PleaseCall) {
    return "Please call";
  }
};

export const mileFormat = (mileage: number) => {
  const string = String(mileage);
  return string + " miles";
};

export const bidPlacedFormatSeconds = (bidPlacedInput: string) => {
  return moment(bidPlacedInput).format("MMM Do YYYY HH:mm:ss");
};

export const bidPlacedFormat = (bidPlacedInput: string) => {
  return moment(bidPlacedInput).format("MMM Do YYYY HH:mm");
};

export const endDateFormat = (endDate: string, format: string = "DD/MM/YYYY[T]HH:mm:ss") => {
  return moment(endDate, format).format("MMM Do YYYY  HH:mm");
};

export const asDateTimeIfPossible = (potentialDate: string | undefined, outputFormat: string = "MMM Do YYYY HH:mm"): string | undefined => {
  if (potentialDate && potentialDate.length >= 10 && moment.utc(potentialDate, moment.ISO_8601, true).isValid()) {
    return potentialDate === "0001-01-01T00:00:00.0000000+00:00" ? "-" : moment.utc(potentialDate, moment.ISO_8601, true).format(outputFormat);
  } else {
    return potentialDate;
  }
};

export const dateToDateString = (date: Date) => {
  return moment(date).format("MMM Do YYYY HH:mm:ss");
};

export const dateToDateStringNoSeconds = (date: Date) => {
  return moment(date).format("MMM Do YYYY HH:mm");
};

export const days = (timeLeftDuration: moment.Duration) => {
  return timeLeftDuration.days() - 7 * timeLeftDuration.weeks();
};

export const timeLeftYearsMonthsWeeks = (timeLeftDuration: moment.Duration) => {
  return `${timeLeftDuration.years()}y ${timeLeftDuration.months()}m ${timeLeftDuration.weeks()}w `;
};

export const timeLeftMonthsWeeksDays = (timeLeftDuration: moment.Duration) => {
  return `${timeLeftDuration.months()}m ${timeLeftDuration.weeks()}w ${days(timeLeftDuration)}d `;
};

export const timeLeftWeeksDays = (timeLeftDuration: moment.Duration) => {
  return `${timeLeftDuration.weeks()}w ${days(timeLeftDuration)}d `;
};

export const timeLeftDaysHours = (timeLeftDuration: moment.Duration) => {
  return `${timeLeftDuration.days()}d ${timeLeftDuration.hours()}h `;
};

export const timeLeftHoursMinutes = (timeLeftDuration: moment.Duration) => {
  return `${timeLeftDuration.hours()}h ${timeLeftDuration.minutes()}m `;
};

export const timeLeftMinutes = (timeLeftDuration: moment.Duration) => {
  return ` ${timeLeftDuration.minutes()}m  left `;
};

export const timeLeftMinutesSeconds = (timeLeftDuration: moment.Duration) => {
  return `${timeLeftDuration.minutes()}m ${timeLeftDuration.seconds()}s `;
};

export const timeLeftFormat = (timeLeftDuration: moment.Duration | undefined) => {
  if (timeLeftDuration === undefined) {
    return "";
  } else if (timeLeftDuration.years() > 0) {
    return timeLeftMonthsWeeksDays(timeLeftDuration);
  } else if (timeLeftDuration.months() > 0) {
    return timeLeftMonthsWeeksDays(timeLeftDuration);
  } else if (timeLeftDuration.weeks() > 0) {
    return timeLeftWeeksDays(timeLeftDuration);
  } else if (timeLeftDuration.days() > 0) {
    return timeLeftDaysHours(timeLeftDuration);
  } else if (timeLeftDuration.hours() > 0) {
    return timeLeftHoursMinutes(timeLeftDuration);
  } else if (timeLeftDuration.minutes() > 10) {
    return timeLeftMinutes(timeLeftDuration);
  } else if (timeLeftDuration.minutes() <= 10) {
    return timeLeftMinutesSeconds(timeLeftDuration);
  } else {
    return "";
  }
};

export const sentenceCaseTextFromCamelCase = (camelCase: string | null | undefined, preserveCapitalisation = false): string => {
  if (!camelCase) return "";

  var result = camelCase.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + (preserveCapitalisation ? result : result.toLowerCase()).slice(1);
};

export const booleanToYesNo = (bool: boolean | null) => {
  if (bool === null) {
    return "Unknown";
  } else if (!bool) {
    return "No";
  } else {
    return "Yes";
  }
};

export const feeFormat = (fee: number) => {
  if (fee === 0) {
    return "No Fee";
  } else {
    return `${fee * 100}%`;
  }
};

export const salesDocumentDateFormat = (bidPlacedInput: string) => {
  const bidPlacedOutput = moment(bidPlacedInput).format("ddd Do MMM YYYY HH:mm");
  return bidPlacedOutput;
};

export const paymentReceiptSaleDateFormat = (date: Date) => {
  return moment(date).format("Do MMMM YYYY");
};

export const paymentReceiptExpiryDateFormat = (date: Date) => {
  return moment(date).format("dddd Do of MMMM YYYY");
};

export const lotCollectedDateFormat = (date: string) => {
  if(date == null){
return "N/A";
  } else {
   return moment(date).format("MMM Do YYYY  HH:mm");  
  }
};
