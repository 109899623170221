import * as React from "react";
import Skeleton from "@material-ui/lab/Skeleton";

export const BulkProcessSkeleton = () => (
  <div style={{ margin: "10px 40px" }}>
    <Skeleton width="100%" height={60} />
    <Skeleton width="100%" height={30} />
    <Skeleton width="100%" height={30} />
    <Skeleton width="100%" height={30} />
    <Skeleton width="100%" height={30} />
    <Skeleton width="100%" height={30} />
    <Skeleton width="100%" height={30} />
    <Skeleton width="100%" height={30} />
    <Skeleton width="100%" height={30} />
    <Skeleton width="100%" height={30} />
    <Skeleton width="100%" height={60} />
    <Skeleton width="100%" height={60} />
    <Skeleton width="100%" height={60} />
    <Skeleton width="100%" height={60} />
    <Skeleton width="100%" height={60} />
    <Skeleton width="100%" height={60} />
    <Skeleton width="100%" height={60} />
  </div>
);
