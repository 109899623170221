import { makeStyles } from "@material-ui/styles";
import { Theme, createStyles } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

export const BulkProcessDialogStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableRow: {
      "&:nth-of-type(odd)": {
        backgroundColor: grey[50],
      },
    },
    searchButton: { margin: "8px 8px 8px 8px", padding: "7px 20px 7px 20px" },
    tableCell: {
      padding: "4px",
    },
    title: {
      paddingBottom: "8px",
    },
    p: {
      fontSize: "12px",
    },
    bulkProcessIcon: {
      marginLeft: "8px",
      color: theme.palette.common.white,
    },
    dialogContent: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    outcomeIcons: {
      fontSize: "100px",
      margin: "40px",
    },
    brokenImageIcon: {
      padding: "20%",
    },
    dialogText: { margin: "12px 0px" },
    warningCell: {
      color: theme.palette.error.main,
    },
  })
);
