import { makeStyles } from "@material-ui/core/styles";

export const LiveBidItemStyles = makeStyles(theme => ({
  cover: {
    height: "45px",
    width: "100%",
  },
  auctionLotDetails: {
    fontSize: "10px",
    fontWeight: "bold",
  },
  tableCellText: {
    fontSize: "10px",
    paddingRight: "2px",
  },
  tableCellTextMetrics: {
    fontSize: "10px",
    textAlign: "center",
  },
  highestProxy: { display: "flex", flexDirection: "row-reverse" },
  tableCell: {
    fontSize: "10px",
    borderLeft: "1px dashed rgba(224, 224, 224, 1)",
    borderRight: "1px dashed rgba(224, 224, 224, 1)",
    paddingLeft: "4px !important",
    paddingRight: "4px !important",
  },
  defaultChip: {
    fontSize: "10px",
    marginLeft: "2px",
    borderRadius: "4px",
  },
  greenChip: {
    fontSize: "10px",
    backgroundColor: "rgba(0, 128, 0, 0.6)",
    color: "white",
    marginLeft: "2px",
    borderRadius: "4px",
  },
  redChip: {
    fontSize: "10px",
    backgroundColor: "rgba(255, 0, 0, 0.6)",
    color: "white",
    marginLeft: "2px",
    borderRadius: "4px",
  },
  yellowChip: {
    fontSize: "10px",
    backgroundColor: "rgb(255, 255, 0, 0.6)",
    color: "black",
    marginLeft: "2px",
    borderRadius: "4px",
  },
  purpleChip: {
    fontSize: "10px",
    backgroundColor: "rgb(128, 0, 128, 0.6)",
    color: "white",
    marginLeft: "2px",
    borderRadius: "4px",
  }
}));
