export const AuctionSeparations = [
  { int: 1, desc: "1 second" },
  { int: 5, desc: "5 seconds" },
  { int: 10, desc: "10 seconds" },
  { int: 15, desc: "15 seconds" },
  { int: 20, desc: "20 seconds" },
  { int: 25, desc: "25 seconds" },
  { int: 30, desc: "30 seconds" },
  { int: 60, desc: "1 minute" },
  { int: 300, desc: "5 minutes" },
  { int: 900, desc: "15 minutes" },
  { int: 3600, desc: "1 hour" },
];
