import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableCell,
  Paper,
  TableContainer,
  TableRow,
  TableHead,
  Container,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { useSnackbar } from "notistack";
import { useAuctionAuditHistory, AuctionAudit, AuctionAuditTypes, AuctionAuditChange } from "../../../services/AuctionLotMgmtService";
import { Skeleton } from "@material-ui/lab";
import { AuctionHistoryDialogStyles } from "../styles/AuctionHistoryDialogStyles";
import { AuctionAuditChangeDialog } from "./AuctionAuditChangeDialog";
import { dateToDateStringNoSeconds } from "../../../helpers/text-format/TextFormat";

interface HistoryDialog {
  open: boolean;
  setOpen: (open: boolean) => void;
  auctionId: string;
}

export const AuctionAuditDialog: React.FC<HistoryDialog> = ({ open, setOpen, auctionId }) => {
  const classes = AuctionHistoryDialogStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [openChanges, setOpenChanges] = React.useState<boolean>(false);
  const [changes, setChanges] = React.useState<AuctionAuditChange[]>();

  React.useEffect(() => {
    setOpen(!openChanges);
  }, [setOpen, openChanges]);

  const [auctionAuditHistory, isAuctionHistoryLoading, ,] = useAuctionAuditHistory(auctionId, () =>
    enqueueSnackbar("Could not retrieve auction audit history", { variant: "error" })
  );

  const auctionTypeName = (auctionAuditType: AuctionAuditTypes) => {
    switch (auctionAuditType) {
      case AuctionAuditTypes.Unknown: {
        return "Unknown audit type";
      }
      case AuctionAuditTypes.Create: {
        return "Auction created";
      }
      case AuctionAuditTypes.Update: {
        return "Auction updated";
      }
      case AuctionAuditTypes.UpdateState: {
        return "Auction state updated";
      }
      case AuctionAuditTypes.AddToBucket: {
        return "Auction added to bucket";
      }
      case AuctionAuditTypes.RemoveFromBucket: {
        return "Auction removed from bucket";
      }
      case AuctionAuditTypes.BucketLive: {
        return "Auction live";
      }
      case AuctionAuditTypes.Relist: {
        return "Auction relisted";
      }
      case AuctionAuditTypes.UpdateReserve: {
        return "Auction reserve updated";
      }
      case AuctionAuditTypes.RemoveBid: {
        return "Bid removed from auction";
      }
      case AuctionAuditTypes.BuyBack: {
        return "Buyback placed";
      }
      case AuctionAuditTypes.Ended: {
        return "Auction ended";
      }
      case AuctionAuditTypes.Collected: {
        return "Auction collected";
      }
      case AuctionAuditTypes.Completed: {
        return "Auction completed";
      }
      case AuctionAuditTypes.UpdateLotCollectedDate: {
        return "Update lot collected date";
      }
      case AuctionAuditTypes.UpdateLotPaymentDate: {
        return "Update lot payment date";
      }
      default: {
        return "Unknown audit type";
      }
    }
  };

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
        <DialogTitle>Auction Audit History</DialogTitle>
        <DialogContent>
          {isAuctionHistoryLoading && auctionAuditHistory === null && (
            <Container component={Paper} className={classes.skeleton}>
              <Skeleton width="100%" height={40} />
              <Skeleton width="100%" height={40} />
              <Skeleton width="100%" height={40} />
              <Skeleton width="100%" height={40} />
              <Skeleton width="100%" height={40} />
            </Container>
          )}
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={1} className={classes.tableHeader}>
                    Name
                  </TableCell>
                  <TableCell colSpan={1} className={classes.tableHeader}>
                    Date
                  </TableCell>
                  <TableCell colSpan={1} className={classes.tableHeader}>
                    Change Type
                  </TableCell>
                  <TableCell colSpan={1} className={classes.tableHeader}>
                    View Changes
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {auctionAuditHistory &&
                  auctionAuditHistory.map((audit: AuctionAudit, index) => (
                    <TableRow key={index} className={classes.tableRow}>
                      <TableCell>{audit?.createdByName}</TableCell>
                      <TableCell>{dateToDateStringNoSeconds(audit?.createdDate)}</TableCell>
                      <TableCell>{auctionTypeName(audit?.auditType)}</TableCell>
                      <TableCell>
                        <Tooltip title="View audit log">
                          <IconButton
                            aria-label="View Audit log"
                            onClick={() => {
                              setChanges(audit.changes);
                              setOpenChanges(true);
                            }}
                          >
                            <AssignmentIcon color="primary" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
      {changes && <AuctionAuditChangeDialog open={openChanges} setOpen={setOpenChanges} auctionAuditChanges={changes} />}
    </>
  );
};
