import * as React from "react";
import { Typography, Container, Box } from "@material-ui/core";
import { IdVerificationContainer } from "../components/id-verification/IdVerificationContainer";
import { SecureArea } from "../components/authentication/SecureArea";
import { MemberPermission } from "../services/MemberService";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../services/TelemetryService";

function IdVerification() {
  return (
    <Container maxWidth={"lg"} fixed>
      <SecureArea memberPermission={MemberPermission.AccountAdmin}>
        <Typography variant="h4" color="primary" gutterBottom>
          ID Verification
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Use this page to verify a bidders identification.
        </Typography>
        <Box mt={4}>
          <IdVerificationContainer />
        </Box>
      </SecureArea>
    </Container>
  );
}

export default withAITracking(reactPlugin, IdVerification, "IdVerification");
