import { makeStyles, Theme } from "@material-ui/core/styles";

export const BucketAuctionDragItemItemStyles = makeStyles(theme => ({
  paneBackground: { backgroundColor: theme.palette.secondary.light },
  leftPane: {
    backgroundColor: theme.palette.secondary.light,
    borderRight: "2px solid " + theme.palette.primary.main,
    padding: "8px",
    overflow: "none",
    display: "flex",
    flexDirection: "column",
  },
  rightPane: { backgroundColor: theme.palette.secondary.light, padding: "8px", display: "flex", flexDirection: "column" },
  paneHeading: { padding: "16px" },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  auctionDragItemWrapper: {
    border: "1px solid lightgrey",
    borderRadius: "4px",
    margin: ".5rem",
    backgroundColor: theme.palette.common.white,
    cursor: "move",
  },
  auctionImage: {
    height: "64px",
    width: "auto",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
  },
  auctionCard: { margin: "16px 4px", cursor: "move" },
  auctionDraftCard: { margin: "16px 4px" },
  auctionAvatar: { backgroundColor: theme.palette.primary.main },
  auctionTitle: { display: "flex", flexDirection: "column", justifyContent: "center" },
  auctionEmptyTitle: { display: "flex", flexDirection: "column", justifyContent: "center", border: "8px" },
  auctionDragHandleWrapper: { display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "right" },
  auctionDragItem: { display: "flex", justifyContent: "right" },
  controlButton: { margin: "8px" },
  buttonContainer: { display: "flex", justifyContent: "flex-end" },
  dropArea: {
    flex: 1,
  },
  dropContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    flexBasis: "auto",
  },
  dateWarningText: {
    marginTop: "20px",
    color: "red",
  },
}));

export const dropTypographyStyle = (theme: Theme, canDrop: boolean) => ({
  backgroundColor: canDrop ? theme.palette.primary.main : theme.palette.secondary.light,
  color: canDrop ? theme.palette.secondary.light : theme.palette.text.primary,
  marginTop: "10px",
});

export const dropDivStyle = (theme: Theme, canDrop: boolean) => ({
  backgroundColor: canDrop ? theme.palette.primary.main : theme.palette.secondary.light,
  color: canDrop ? theme.palette.secondary.light : theme.palette.text.primary,
  flex: 1,
  flexBasis: "auto",
  paddingBotom: "60px",
  paddingTop: "10px",
  marginTop: "10px",
});
