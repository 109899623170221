import { makeStyles } from "@material-ui/core/styles";

export const BidderDetailsStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: "rgba(240, 240, 240, 1)",
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  rows: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.grey[50],
    },
  },
  margin: {
    marginTop: "15px",
  },
  suspensionButtons: {
    marginRight: "8px",
    marginTop: "8px",
  },
  lotSwitch: {
    marginTop: "16px",
  },
  auctionTableCell: {
    padding: "6px 8px",
  },
  disabled: {
    color: "grey",
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
}));
