import { CountryDefault } from "../components/bidder-details/ICountry";

export interface ICountry {
  name: string;
  alpha2: string;
  countryCode: string;
}

export interface IAddress {
  line1: string;
  line2: string;
  line3: string;
  city: string;
  postcode: string;
  county: string;
  country: ICountry;
  latitude: number;
  longitude: number;
}

export const AddressDefault: IAddress = {
  line1: "",
  line2: "",
  line3: "",
  city: "",
  postcode: "",
  county: "",
  country: CountryDefault,
  latitude: 0,
  longitude: 0,
};

export const formatAddressAsSingleLine = (address: IAddress) => {
  let formattedAddress: string = address.line1 + ", ";
  if (address.line2 && address.line2 !== "") {
    formattedAddress = formattedAddress + address.line2 + ", ";
  }
  if (address.line3 && address.line3 !== "") {
    formattedAddress = formattedAddress + address.line3 + ", ";
  }

  formattedAddress = formattedAddress + address.city + ". ";
  formattedAddress = formattedAddress + address.postcode;
  return formattedAddress;
};
