import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import React from "react";
import { IAuctionInfo } from "../../../services/AuctionService";

interface SortAuctionProps {
  sortBy: string;
  setSortBy: (sortBy: string) => void;
}

export const SortAuction: React.FC<SortAuctionProps> = ({ sortBy, setSortBy }) => {
  return (
    <FormControl style={{ width: "100%" }}>
      <InputLabel>Sort</InputLabel>
      <Select value={sortBy} onChange={event => setSortBy(event.target.value as string)}>
        <MenuItem value={"NewestToOldest"}>Newest to Oldest</MenuItem>
        <MenuItem value={"OldestToNewest"}>Oldest to Newest</MenuItem>
        <MenuItem value={"StartPriceLowToHigh"}>Start Price (low to high)</MenuItem>
        <MenuItem value={"StartPriceHighToLow"}>Start Price (high to low)</MenuItem>
        <MenuItem value={"ReservePriceLowToHigh"}>Reserve Price (low to high)</MenuItem>
        <MenuItem value={"ReservePriceHighToLow"}>Reserve Price (high to low)</MenuItem>
        <MenuItem value={"PAVLowToHigh"}>PAV (low to high)</MenuItem>
        <MenuItem value={"PAVHighToLow"}>PAV (high to low)</MenuItem>
        <MenuItem value={"LotType"}>Lot Type (A - Z)</MenuItem>
        <MenuItem value={"Make"}>Make (A - Z)</MenuItem>
        <MenuItem value={"RegNumber"}>Reg number (A - Z)</MenuItem>
        <MenuItem value={"Random"}>Random</MenuItem>
      </Select>
    </FormControl>
  );
};

export const sort = (allAuctionsInBucket: IAuctionInfo[] | undefined, sortBy: string): IAuctionInfo[] | undefined => {
  switch (sortBy) {
    case "OldestToNewest": {
      return allAuctionsInBucket?.sort((a, b) => a.metadata.year - b.metadata.year);
    }
    case "NewestToOldest": {
      return allAuctionsInBucket?.sort((a, b) => b.metadata.year - a.metadata.year);
    }
    case "StartPriceLowToHigh": {
      return allAuctionsInBucket?.sort((a, b) => a.startPrice - b.startPrice);
    }
    case "StartPriceHighToLow": {
      return allAuctionsInBucket?.sort((a, b) => b.startPrice - a.startPrice);
    }
    case "ReservePriceLowToHigh": {
      return allAuctionsInBucket?.sort((a, b) => a.reservePrice - b.reservePrice);
    }
    case "ReservePriceHighToLow": {
      return allAuctionsInBucket?.sort((a, b) => b.reservePrice - a.reservePrice);
    }
    case "PAVLowToHigh": {
      return allAuctionsInBucket?.sort((a, b) => a.metadata.preAccidentValue - b.metadata.preAccidentValue);
    }
    case "PAVHighToLow": {
      return allAuctionsInBucket?.sort((a, b) => b.metadata.preAccidentValue - a.metadata.preAccidentValue);
    }
    case "LotType": {
      return allAuctionsInBucket?.sort((a, b) => a.metadata.lotType.localeCompare(b.metadata.lotType));
    }
    case "Make": {
      return allAuctionsInBucket?.sort((a, b) => (a.metadata.make ?? "").localeCompare(b.metadata.make ?? ""));
    }
    case "RegNumber": {
      return allAuctionsInBucket?.sort((a, b) => (a.metadata.registrationNumber ?? "").localeCompare(b.metadata.registrationNumber ?? ""));
    }
    case "Random": {
      return allAuctionsInBucket?.sort((a, b) => a.lotId.localeCompare(b.lotId));
    }
    default: {
      return allAuctionsInBucket;
    }
  }
};
