import * as signalR from "@microsoft/signalr";
import moment from "moment";
import React, { FunctionComponent, useState, useContext, ReactNode, useEffect } from "react";
import { useMsal } from "../authentication/MsalProvider";
import { ILatestBid } from "../interfaces/auctions/ILatestBid";
import { ISalvageClientSettings } from "../interfaces/ISalvageClientSettings";
import { GetClientSettings } from "../services/SettingsService";
import { HubContext, IHubContext, ISignalRUserMessage } from "./HubContext";
import { useHubConnection } from "./useHubConnection";

const clientSettings: ISalvageClientSettings = GetClientSettings();

export type HubProviderProps = {
  children?: ReactNode;
};

const retryPolicy: signalR.IRetryPolicy = {
  nextRetryDelayInMilliseconds: retryContext => retryContext.previousRetryCount * 4096,
};

export interface IData<T> {
  data: T;
  timestamp: moment.Moment;
}

export const HubProvider: FunctionComponent<HubProviderProps> = ({ children }: HubProviderProps) => {
  const latestHubUrl = `${clientSettings.SignalRPrivateApiUrl}/signalr/hub/`;
  const msal = useMsal();

  useEffect(() => {
    if (msal.accounts.length > 0) {
      if (!latestHub) {
        setLatestHub(
          new signalR.HubConnectionBuilder()
            .withAutomaticReconnect(retryPolicy)
            .withUrl(latestHubUrl, {
              accessTokenFactory: async () => {
                if (msal.accounts.length > 0) {
                  const token = await msal.instance.acquireTokenSilent({
                    scopes: [clientSettings.B2CSettings.TokenScope],
                    account: msal.accounts[0],
                    forceRefresh: false,
                  });
                  if (token) {
                    return token.accessToken;
                  }
                }
                return "";
              },
            })
            .build()
        );
        console.log("Created hub");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [msal.accounts]);

  const [latestHub, setLatestHub] = useState<signalR.HubConnection | null>(null);

  const [bidData, setBidData] = React.useState<ILatestBid | null>(null);
  const [bidErrorData, setBidErrorData] = React.useState<ISignalRUserMessage | null>(null);
  const [privateUserData, setPrivateUserData] = React.useState<ISignalRUserMessage | null>(null);
  const [connectedOnce, setConnectedOnce] = React.useState(false);

  const hubState = latestHub?.state ?? signalR.HubConnectionState.Disconnected;

  React.useEffect(() => {
    if (latestHub && hubState === signalR.HubConnectionState.Connected) {
      if (connectedOnce) {
        window.location.reload();
      }
      setConnectedOnce(true);
      latestHub.onclose(error => {
        console.error("Error with the signalr connection - trying to reconnect:", error);
        latestHub.start();
      });
      latestHub.on("WebsiteLatestBid", args => {
        try {
          const newData: ILatestBid = JSON.parse(args);
          setBidData(newData);
        } catch {
          throw Error("Couldn't convert args to type T");
        }
      });
      latestHub.on("WebsiteLatestBidErrors", args => {
        try {
          const newData: ISignalRUserMessage = JSON.parse(args);
          setBidErrorData(newData);
        } catch {
          throw Error("Couldn't convert args to type T");
        }
      });
      latestHub.on("WebsiteNotificationPrivateUser", args => {
        try {
          const newData: ISignalRUserMessage = JSON.parse(args);
          setPrivateUserData(newData);
        } catch {
          throw Error("Couldn't convert args to type T");
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latestHub, hubState]);

  useHubConnection(latestHub);

  const contextValue: IHubContext = {
    hub: latestHub,
    bidData: bidData,
    bidErrorData: bidErrorData,
    privateUserData: privateUserData,
  };

  return <HubContext.Provider value={contextValue}>{children}</HubContext.Provider>;
};

export const useHub = () => useContext(HubContext);
