import { ISalvageClientSettings } from "../interfaces/ISalvageClientSettings";
import { useReadonlyData } from "./useVersionedData";
import { GetClientSettings } from "./SettingsService";
import { IAddress } from "../interfaces/IAddress";
const ClientSettings: ISalvageClientSettings = GetClientSettings();

export interface IMemberSite {
  id: string;
  isMainSite: string;
  siteName: string;
  location: IAddress;
}

export const useMemberSites = (onError: (httpStatusCode: number, resultBody?: string) => void) =>
  useReadonlyData<IMemberSite[]>(`${ClientSettings.UsersPrivateApiUrl}/membersites/`, onError);
