import { makeStyles } from "@material-ui/core/styles";

export const RefundContainerStyles = makeStyles(theme => ({
  buttonContainer: { display: "flex", flexDirection: "row", alignItems: "center" },
  searchButton: {
    maxHeight: "40px",
    marginLeft: "4px",
  },
  tableTitle: {
    paddingLeft: "4px",
    paddingRight: "4px",
  },
  tableCell: { padding: "8px 4px 8px 4px" },
  firstTableCell: { padding: "8px 4px 8px 8px" },
  lastTableCell: { padding: "8px 8px 8px 4px" },
}));
