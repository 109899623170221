import * as React from "react";
import { Typography, Container, Box } from "@material-ui/core";
import { SecureArea } from "../components/authentication/SecureArea";
import { MemberPermission } from "../services/MemberService";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../services/TelemetryService";
import { MaliciousBidderReportContainer } from "../components/reporting/MaliciousBidderReportContainer";

const MaliciousBidderReport = () => (
  <Container maxWidth={"lg"} fixed>
    <SecureArea memberPermission={MemberPermission.SuperAdmin}>
      <Typography variant="h4" color="primary" gutterBottom>
        Malicious Bidder Report
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Create an export for Malicious Bidder Report.
      </Typography>
      <Box mt={4}>
        <MaliciousBidderReportContainer />
      </Box>
    </SecureArea>
  </Container>
);

export default withAITracking(reactPlugin, MaliciousBidderReport, "MaliciousBidderReport");
