import React, { useState } from "react";
import { Paper, Table, TableHead, TableRow, TableCell, TableBody, Typography, Tooltip } from "@material-ui/core";
import * as Styles from "./styles/LiveBidListStyles";
import { LiveBidItem } from "../../services/AuctionService";
import { LiveBid } from "./LiveBid";
import { LiveBidDialog } from "./LiveBidDialog";

export const LiveBidList: React.FC<{ liveBidItems: LiveBidItem[] | null; showProxyBids: boolean; refreshLiveBids: () => void }> = ({ liveBidItems, showProxyBids, refreshLiveBids }) => {
  const classes = Styles.LiveBidListStyles();

  const [dialogProps, setDialogProps] = useState<{ auctionId: string; previousAuctionId: string; title: string } | null>(null);

  const openDialog = (auctionId: string, previousAuctionId: string, title: string) => {
    setDialogProps({ auctionId: auctionId, previousAuctionId: previousAuctionId, title: title });
  };

  const closeDialog = () => {
    setDialogProps(null);
    refreshLiveBids();
  };

  const handleBiddingClosed = () => {
    if (dialogProps === null) {
      refreshLiveBids();
    }
  };

  return (
    <div>
      <Paper className={classes.root}>
        <Table size="small">
          <TableHead  className={classes.MuiTableCellHead}>
            <TableRow>
              <TableCell padding={"none"} variant={"head"} className={classes.tableImageCell}></TableCell>
              <TableCell padding={"none"} variant={"head"} className={classes.tableCell}>
                <Typography component="p" className={classes.tableTitle}>
                  Ref
                </Typography>
              </TableCell>
              <TableCell padding={"none"} variant={"head"} className={classes.tableCell}>
                <Typography component="p" className={classes.tableTitle}>
                  Make, Model, Trim,
                </Typography>
                <Typography component="p" className={classes.tableTitle}>
                  Category, Reg No, Mileage, location,
                </Typography>
                <Typography component="p" className={classes.tableTitle}>
                  Client
                </Typography>
              </TableCell>
              <TableCell align={"right"} padding={"none"} variant={"head"} className={classes.tableCell}>
                <Typography component="p" className={classes.tableTitle}>
                  Ending Time Left
                </Typography>
              </TableCell>
              <TableCell align={"right"} padding={"none"} variant={"head"} className={classes.tableCell}>
                <Tooltip title={"This is the initial lot cost."}>
                  <Typography component="p" className={classes.tableTitle}>
                    Cost
                  </Typography>
                </Tooltip>
                <Tooltip title={"This is the pre accident value."}>
                  <Typography component="p" className={classes.tableTitle}>
                    PAV
                  </Typography>
                </Tooltip>
              </TableCell>
              <TableCell align={"right"} padding={"none"} variant={"head"} className={classes.tableCell}>
                <Typography component="p" className={classes.tableTitle}>
                  Reserve
                </Typography>
                <Typography component="p" className={classes.tableTitle}>
                  Start
                </Typography>
              </TableCell>
              <TableCell align={"right"} padding={"none"} variant={"head"} className={classes.tableCell}>
                <Typography component="p" className={classes.tableTitle}>
                  Highest Bid
                </Typography>
              </TableCell>
              <TableCell align={"right"} padding={"none"} variant={"head"} className={classes.tableCell}>
                <Tooltip title={"The admin/release fee without VAT."}>
                  <Typography component="p" className={classes.tableTitle}>
                    Admin Fee
                  </Typography>
                </Tooltip>
                <Tooltip title={"The auction fee is based on the auction selling price."}>
                  <Typography component="p" className={classes.tableTitle}>
                    Auction Fee
                  </Typography>
                </Tooltip>
              </TableCell>
              <TableCell align={"right"} padding={"none"} variant={"head"} className={classes.tableCell}>
                <Tooltip title={"The web fee is calculated using the Auction Fee + Admin Fee + Member Fee."}>
                  <Typography component="p" className={classes.tableTitle}>
                    Web Fees
                  </Typography>
                </Tooltip>
                <Typography component="p" className={classes.tableTitle}>
                  Breakdown
                </Typography>
              </TableCell>
              <TableCell align={"right"} padding={"none"} variant={"head"} className={classes.tableCell}>
                <Tooltip title={"The net profit/loss is calculated using the Web Fee - Proxy."}>
                  <Typography component="p" className={classes.tableTitle}>
                    Net Profit/Loss
                  </Typography>
                </Tooltip>
              </TableCell>
              <TableCell padding={"none"} variant={"head"} className={classes.tableCell}>
                <Typography component="p" className={classes.tableTitle}>
                  Highest Bidder
                </Typography>
                <Typography component="p" className={classes.tableTitle}>
                  Recent Purchase Count
                </Typography>
              </TableCell>
              <TableCell padding={"none"} variant={"head"} className={`${classes.tableCell} ${classes.rotateTableCell}`}>
                <Typography component="p" className={classes.tableTitle}>
                  Watch
                </Typography>
              </TableCell>
              <TableCell padding={"none"} variant={"head"} align={"right"} className={`${classes.tableCell} ${classes.rotateTableCell}`}>
                <Typography component="p" className={classes.tableTitle}>
                  View
                </Typography>
              </TableCell>
              <TableCell padding={"none"} variant={"head"} align={"right"} className={`${classes.tableCell} ${classes.rotateTableCell}`}>
                <Typography component="p" className={classes.tableTitle}>
                  Bids
                </Typography>
              </TableCell>

              {showProxyBids && (
                <TableCell padding={"none"} variant={"head"} align={"right"} className={`${classes.tableCell} ${classes.rotateTableCell}`}>
                  <Typography component="p" className={classes.tableTitle}>
                    Proxy Bid
                  </Typography>
                </TableCell>
              )}


              <TableCell padding={"checkbox"} variant={"head"} align={"right"} className={classes.tableCell}>
                <Typography component="p" className={classes.tableTitle}>
                  Options
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {liveBidItems &&
              liveBidItems.map(liveBidItem => (
                <TableRow key={liveBidItem.id} className={classes.tableRow}>
                  <LiveBid
                    liveBidItemProp={liveBidItem}
                    handleBiddingClosed={() => handleBiddingClosed()}
                    showProxyBids={showProxyBids}
                    openDialog={(auctionId, previousAuctionId, title) => openDialog(auctionId, previousAuctionId, title)}
                  />
                </TableRow>
              ))}
          </TableBody>
        </Table>

        {dialogProps && (
          <LiveBidDialog
            handleClose={closeDialog}
            auctionId={dialogProps.auctionId}
            previousAuctionId={dialogProps.previousAuctionId}
            title={dialogProps.title}
            showProxyBids={showProxyBids}
          />
        )}
      </Paper>
    </div>
  );
};
