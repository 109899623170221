import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableCell,
  Paper,
  TableContainer,
  TableRow,
  TableHead,
  Container,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import { Skeleton } from "@material-ui/lab";
import { PermissionAudit, usePermissionAudit } from "../../services/PermissionService";
import { dateToDateString } from "../../helpers/text-format/TextFormat";
import { PermissionAuditDialogStyles } from "./styles/PermissionAuditDialogStyles";

interface PermissionAuditDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  adminId: string;
}

export const PermissionAuditDialog: React.FC<PermissionAuditDialogProps> = ({ open, setOpen, adminId }) => {
  const classes = PermissionAuditDialogStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [auditHistory, isAuditHistoryLoading, , , , refreshAuditHistory] = usePermissionAudit(adminId, () =>
    enqueueSnackbar("Could not retrieve auction audit history", { variant: "error" })
  );

  React.useEffect(() => {
    if (open) {
      refreshAuditHistory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
        <DialogTitle>Auction Audit History</DialogTitle>
        <DialogContent>
          {isAuditHistoryLoading && auditHistory === null && (
            <Container component={Paper} className={classes.skeleton}>
              <Skeleton width="100%" height={40} />
              <Skeleton width="100%" height={40} />
              <Skeleton width="100%" height={40} />
              <Skeleton width="100%" height={40} />
              <Skeleton width="100%" height={40} />
            </Container>
          )}
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={1} className={classes.tableHeader}>
                    Changed by
                  </TableCell>
                  <TableCell colSpan={1} className={classes.tableHeader}>
                    Message
                  </TableCell>
                  <TableCell colSpan={1} className={classes.tableHeader}>
                    Date
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {auditHistory &&
                  auditHistory.map((audit: PermissionAudit, index) => (
                    <TableRow key={index} className={classes.tableRow}>
                      <TableCell>{audit?.enteredByName}</TableCell>
                      <TableCell>{audit?.message}</TableCell>
                      <TableCell>{dateToDateString(audit?.dateAdded)}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
