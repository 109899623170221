import React, { useEffect } from "react";
import { CircularProgress, Box, TextField, IconButton, Tooltip } from "@material-ui/core";
import { useSnackbar, OptionsObject } from "notistack";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { useAuctionReserve } from "../../services/AuctionService";
import * as Styles from "./styles/LiveBidItemDialogStyles";
import { IValidation, ValidationDefault } from "../../interfaces/IValidation";

const variantError: OptionsObject = { variant: "error" };
const variantSuccess: OptionsObject = { variant: "success" };

interface DialogReserveUpdateProps {
  auctionId: string;
  updated: () => void;
}

export const DialogReserveUpdate: React.FC<DialogReserveUpdateProps> = ({ auctionId, updated }) => {
  const classes = Styles.LiveBidItemDialogStyles();

  var validationError: boolean = false;

  const { enqueueSnackbar } = useSnackbar();
  const [validation, setValidation] = React.useState<IValidation>(ValidationDefault);

  const [auctionReserve, setAuctionReserve, auctionReserveLoading, , putAuctionReserve, , auctionReserveValidation] = useAuctionReserve(
    auctionId,
    httpStatusCode => {
      if (httpStatusCode === 400) {
        validationError = true;
      } else {
        enqueueSnackbar("There was a problem with your auction reserve request, please try again.", variantError);
      }
    }
  );

  useEffect(() => {
    setValidation(auctionReserveValidation === null ? ValidationDefault : auctionReserveValidation);
  }, [auctionReserveValidation]);

  const isError = (field: string): boolean => {
    return validation[field] !== undefined;
  };

  const ErrorMessage = (field: string): string => {
    return validation[field] !== undefined ? validation[field].error : "";
  };

  const onFieldChange = (e: any, errorName: string) => {
    setValidation({
      ...validation,
      [errorName]: { field: "", error: "" },
    });

    var value: any;

    if (e.target.type === "checkbox") {
      value = e.target.checked;
    } else if (e.target.type === "number") {
      value = e.target.value === "" ? 0 : e.target.value;
    } else {
      value = e.target.value;
    }

    if (auctionReserve) {
      setAuctionReserve({
        ...auctionReserve,
        [e.target.name]: value,
      });
    }
  };

  const updateReservePrice = () => {
    validationError = false;
    putAuctionReserve().then(() => {
      if (!validationError) {
        updated();
        enqueueSnackbar("Auction reserve price updated.", variantSuccess);
      }
    });
  };

  const disableUpdate = () => {
    return auctionReserve
      ? auctionReserve.currentBidAmount + auctionReserve.currentReserve === 0
        ? false
        : auctionReserve.currentBidAmount >= auctionReserve.currentReserve
      : false;
  };

  const tooltipText = () => {
    if (disableUpdate()) {
      return "Reserve disabled, bid exceeds current reserve price";
    } else {
      return "Save reserve price";
    }
  };

  return (
    <Box className={classes.inputRoot}>
      <TextField
        required
        fullWidth
        type="number"
        error={isError("ReservePrice") || isError("CurrentReserve")}
        helperText={ErrorMessage("ReservePrice") || ErrorMessage("CurrentReserve")}
        name={"reservePrice"}
        label="Update reserve price"
        value={auctionReserve?.reservePrice && auctionReserve?.reservePrice > 0 ? auctionReserve?.reservePrice : ""}
        onChange={value => onFieldChange(value, "ReservePrice")}
        disabled={auctionReserveLoading ? false : disableUpdate()}
        inputProps={{
          step: "10",
        }}
      />
      <Tooltip title={tooltipText()}>
        <div>
          <IconButton
            color={"primary"}
            type="submit"
            className={classes.iconButton}
            aria-label="Save"
            onClick={updateReservePrice}
            disabled={auctionReserveLoading ? false : disableUpdate()}
          >
            <ArrowUpwardIcon />
          </IconButton>
        </div>
      </Tooltip>
      {auctionReserveLoading && <CircularProgress color="primary" size={16} thickness={8} />}
    </Box>
  );
};
