import React from "react";
import * as Styles from "../styles/LotFormStyles";
import { StepType } from "../types/StepTypes";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { useHistory } from "react-router";

interface LotFormButtonProps {
  activeStep: StepType;
  mediaAllValid: boolean | undefined;
  hideCancel: boolean;
  handleCancel: () => void;
  handleSave: () => void;
  handleNext: () => void;
  handleBack: () => void;
}

export const LotFormButtons: React.FC<LotFormButtonProps> = ({
  activeStep,
  mediaAllValid,
  hideCancel,
  handleCancel,
  handleSave,
  handleNext,
  handleBack,
}) => {
  const history = useHistory();
  const classes = Styles.LotFormStyles();

  const [open, setOpen] = React.useState<boolean>(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.buttons}>
      {activeStep !== StepType.Completed && !hideCancel && (
        <Button variant="contained" onClick={handleOpen} className={classes.button}>
          Cancel
        </Button>
      )}
      {activeStep !== StepType.Details && activeStep !== StepType.Completed && (
        <>
          <Button variant="contained" onClick={handleSave} className={classes.button}>
            {activeStep !== StepType.Options ? "Save" : "Save and close"}
          </Button>
          <Button variant="contained" onClick={handleBack} className={classes.button}>
            Back
          </Button>
        </>
      )}
      {activeStep === StepType.Options && (
        <Button variant="contained" color="primary" onClick={handleNext} className={classes.button} disabled={!mediaAllValid}>
          Save
        </Button>
      )}
      {activeStep !== StepType.Options && activeStep !== StepType.Completed && (
        <Button variant="contained" color="primary" onClick={handleNext} className={classes.button}>
          Next
        </Button>
      )}

      {activeStep === StepType.Completed && (
        <>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              history.push("/AuctionManagement/Auctions");
            }}
            className={classes.button}
          >
            Lot Management
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              history.push("/Buckets");
            }}
            className={classes.button}
          >
            Bucket Management
          </Button>
        </>
      )}

      <Dialog open={open} fullWidth>
        <DialogTitle>Cancel</DialogTitle>
        <DialogContent>Are you sure you want to cancel this lot?</DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()}>Cancel</Button>
          <Button onClick={() => handleCancel()} variant="contained" color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
