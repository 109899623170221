import { makeStyles, Theme } from "@material-ui/core/styles";
import { lightGrey } from "../../../theme";

export const StandardNavigationStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: lightGrey,
    marginBottom: "32px",
  },
  navigationContainer: {
    display: "flex",
    alignItems: "center",
    height: "100%",
  },
  logo: {
    height: "48px",
  },
  headerBarContainer: {
    padding: "20px",
    backgroundColor: "#474747",
    width: "100%",
  },
}));
