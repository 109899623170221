import * as React from "react";
import { CardMedia, Grid, TableCell, Typography } from "@material-ui/core";
import { AuctionRowStyles } from "../styles/AuctionRowStyles";
import { Lot, lotStatusGroupingString } from "../../../services/LotService";
import BrokenImageIcon from "@material-ui/icons/BrokenImage";

interface LotRowProps {
  lot: Lot;
}

export const LotRow: React.FC<LotRowProps> = ({ lot }) => {
  const classes = AuctionRowStyles();
  const imageContainer = () => {
    if (lot.thumbnailImageUrl !== undefined) {
      return (
        <CardMedia className={classes.image} component="img" alt={lot.lotDetails.title} image={lot.thumbnailImageUrl} title={lot.lotDetails.title} />
      );
    }
  };

  return (
    <>
      <TableCell className={classes.tableCell}>
        {!lot.auctionImage ? (
          <div style={{ padding: "20%" }}>
            <BrokenImageIcon color={"secondary"} />
          </div>
        ) : (
          imageContainer()
        )}
      </TableCell>
      <TableCell className={classes.tableCell}>
        <Typography variant="subtitle2" className={classes.title}>
          {lot.lotDetails.title ? lot.lotDetails.title : "No title"}
        </Typography>
        <Typography variant="body2">{lot.lotDetails.registrationNumber}</Typography>
      </TableCell>
      <TableCell className={`${classes.tableCell} ${classes.infoCell}`}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={2}>
            <Typography component="p" className={classes.p}>
              Ref
            </Typography>
          </Grid>
          <Grid item xs={12} md={10}>
            <Typography component="p" className={classes.p}>
              {lot.lotDetails.referenceNumber}
            </Typography>
          </Grid>
          <Grid item xs={12} md={2}>
            <Typography component="p" className={classes.p}>
              Location
            </Typography>
          </Grid>
          <Grid item xs={12} md={10}>
            <Typography component="p" className={classes.p}>
              {lot.lotProperties.siteName ? lot.lotProperties.siteName : null}
            </Typography>
          </Grid>
          <Grid item xs={12} md={2}>
            <Typography component="p" className={classes.p}>
              Status
            </Typography>
          </Grid>
          <Grid item xs={12} md={10}>
            <Typography component="p" className={classes.p}>
              {lotStatusGroupingString(lot.status)}
            </Typography>
          </Grid>
        </Grid>
      </TableCell>
    </>
  );
};
