import React from "react";
import { useSnackbar } from "notistack";
import { MyMemberDetailsDto, useMyMember } from "../services/MemberService";
import { useMsal } from "../authentication/MsalProvider";
import { useHistory } from "react-router-dom";

const AppContext = React.createContext<MyMemberDetailsDto | null>(null);

const AppContextProvider: React.FC = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const msal = useMsal();

  const [memberDetails, memberDetailsLoading] = useMyMember(msal.accounts.length > 0, errorCode => {
    if (errorCode === 401) {
      history.push(`/401`);
    } else {
      enqueueSnackbar("There was a problem with your member request, please try again.", { variant: "error" });
    }
  });

  return (
    <>
      <AppContext.Provider value={memberDetails}>{!memberDetailsLoading && children}</AppContext.Provider>
    </>
  );
};

export { AppContextProvider, AppContext };
