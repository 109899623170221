import * as React from "react";
import { Container, Typography } from "@material-ui/core";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { LoadingComponent } from "../components/loading/LoadingComponent";
import { useMsal } from "../authentication/MsalProvider";
import { IMsalContext } from "../authentication/MsalContext";
import { GetClientSettings } from "../services/SettingsService";
import { ISalvageClientSettings } from "../interfaces/ISalvageClientSettings";
import { reactPlugin } from "../services/TelemetryService";

function ConfirmAccount() {
  const clientSettings: ISalvageClientSettings = GetClientSettings();
  const msal: IMsalContext = useMsal();

  React.useEffect(() => {
    msal.instance.loginRedirect({
      scopes: [clientSettings.B2CSettings.TokenScope],
      authority: clientSettings.B2CSettings.TenantForgotPassword,
      redirectStartPage: window.origin,
    });
  }, [msal.instance, clientSettings.B2CSettings.TenantForgotPassword, clientSettings.B2CSettings.TokenScope]);

  return (
    <Container maxWidth={"lg"} fixed style={{ minHeight: "350px" }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Confirm Account
      </Typography>
      <Typography
        variant="subtitle1"
        component="h2"
        gutterBottom
        style={{
          paddingBottom: "32px",
        }}
      >
        Please confirm your email address to enable your account.
      </Typography>

      <LoadingComponent label="Please wait" />
    </Container>
  );
}

export default withAITracking(reactPlugin, ConfirmAccount, "ConfirmAccount");
