import * as React from "react";
import { ILatestBid } from "../interfaces/auctions/ILatestBid";
import { useHub } from "./HubProvider";

enum SignalRHub {
  AuctionInfo = 0,
  PrivateUser,
  LatestBidErrors,
}

const useSignalrLatestBid = (auctionId: string): [ILatestBid | null, string | undefined | null] => {
  const [data, setData] = React.useState<ILatestBid | null>(null);
  const hub = useHub();
  const increment = hub.bidData?.increment ?? 0;
  React.useEffect(() => {
    if (hub.bidData && hub.bidData?.auctionId === auctionId) {
      setData(hub.bidData);
    }
  }, [hub.bidData, increment, auctionId]);

  return [data, hub.hub?.connectionId];
};

const useSignalrPrivateUser = () => {
  const hub = useHub();
  return hub.privateUserData;
};

const useSignalrLatestBidErrors = () => {
  const hub = useHub();
  return hub.bidErrorData;
};

const useSignalR = (hub: SignalRHub) => {
  switch (hub) {
    case SignalRHub.PrivateUser:
      return useSignalrPrivateUser;
    case SignalRHub.LatestBidErrors:
      return useSignalrLatestBidErrors;
    default:
      throw new Error("Unknown SignalRHub enum");
  }
};

export { useSignalR, SignalRHub, useSignalrLatestBid };
