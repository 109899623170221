import { makeStyles } from "@material-ui/styles";

import { Theme, createStyles } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

export const ContainerStyles = makeStyles((theme: Theme) =>
  createStyles({
    width100: { width: "100%" },
    searchTextField: { top: "0", marginTop: "0px", marginBottom: "0px" },
    searchButton: { margin: "8px 8px 8px 8px", padding: "7px 20px 7px 20px" },
    bulkProcessButton: { margin: "8px 8px 8px 8px", padding: "7px 12px 7px 12px" },
    tableHeadCell: {
      padding: "16px 4px 16px 4px",
    },
    tableRow: {
      "&:nth-of-type(odd)": {
        backgroundColor: grey[50],
      },
    },
    bulkProcessIcon: {
      marginLeft: "8px",
    },
  })
);
