import * as React from "react";
import { Typography, Container, Box } from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import { SecureArea } from "../components/authentication/SecureArea";
import { MemberPermission } from "../services/MemberService";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../services/TelemetryService";
import { AlertFormComponent } from "../components/alert-form/AlertFormComponent";

type CreateAlertParams = { id: string };

function CreateAlert({ match }: RouteComponentProps<CreateAlertParams>) {
  return (
    <Container maxWidth={"lg"} fixed>
      <SecureArea memberPermission={MemberPermission.SuperAdmin}>
        <Typography variant="h4" color="primary" gutterBottom>
        Create an Alert
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
        Fill out the form to create an alert
        </Typography>
        <Box mt={4}>
          <AlertFormComponent id={match.params.id === undefined ? null : match.params.id} />
        </Box>
      </SecureArea>
    </Container>
  );
}

export default withAITracking(reactPlugin, CreateAlert, "Createalert");
