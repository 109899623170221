import * as React from "react";
import { Typography, Container, Box } from "@material-ui/core";
import { SecureArea } from "../components/authentication/SecureArea";
import { MemberPermission } from "../services/MemberService";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../services/TelemetryService";
import { PaymentReconcilliationReportContainer } from "../components/reporting/PaymentReconcilliationReportContainer";

const PaymentReconcilliationReporting = () => (
  <Container maxWidth={"lg"} fixed>
    <SecureArea memberPermission={MemberPermission.SuperAdmin}>
      <Typography variant="h4" color="primary" gutterBottom>
        Payment Reconcilliation Report
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Create an export for Payment Reconcilliation Report.
      </Typography>
      <Box mt={4}>
        <PaymentReconcilliationReportContainer />
      </Box>
    </SecureArea>
  </Container>
);

export default withAITracking(reactPlugin, PaymentReconcilliationReporting, "PaymentReconcilliationReporting");
