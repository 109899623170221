import React from "react";
import { Select, MenuItem, Typography, Grid } from "@material-ui/core";
import { useSnackbar, OptionsObject } from "notistack";
import { createLot } from "../../../services/LotService";
import { LoadingComponent } from "../../loading/LoadingComponent";
import { useConfiguration } from "../../../services/VehicleConfigurationService";
import { useHistory } from "react-router";

const variantError: OptionsObject = { variant: "error" };

export const TypeSelection: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [lotType, setLotType] = React.useState<string>();

  const [configuration, configurationLoading] = useConfiguration(() => {
    enqueueSnackbar("There was a problem getting the lot configuration options, please try again.", variantError);
  });

  const onFieldChange = (event: any) => {
    if (event.target.value) {
      setLoading(true);
      setLotType(event.target.value);
      createLot(event.target.value)
        .then(data => {
          if (data.parsedBody) {
            history.push(`/NewLot/${data.parsedBody.id}`);
          } else {
            enqueueSnackbar("Could not create lot, please try again.", variantError);
          }
        })
        .catch(() => {
          enqueueSnackbar("There was a problem with your request, please try again.", variantError);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      {configurationLoading && <LoadingComponent label="Loading" />}
      {loading && <LoadingComponent label="Loading" />}

      <Grid container spacing={3}>
        {!loading && !configurationLoading && (
          <>
            <Grid item xs={12} md={2}>
              <Typography variant="subtitle1">Select lot type:</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Select
                fullWidth
                id="vehicleType"
                name="vehicleType"
                onChange={value => onFieldChange(value)}
                value={lotType === undefined ? "" : lotType}
              >
                {configuration &&
                  configuration.item.lotTypes.map((lotType, index) => {
                    return (
                      <MenuItem key={index} value={lotType}>
                        {lotType}
                      </MenuItem>
                    );
                  })}
              </Select>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};
