import * as React from "react";

import { Grid } from "@material-ui/core";

import Skeleton from "@material-ui/lab/Skeleton";

export const SearchSkeleton = () => (
  <Grid container spacing={1}>
    <Grid item xs={2}>
      <Skeleton width="100%" height={40} />
    </Grid>
    <Grid item xs={2}>
      <Skeleton width="100%" height={40} />
    </Grid>
    <Grid item xs={2}>
      <Skeleton width="100%" height={40} />
    </Grid>
    <Grid item xs={2}>
      <Skeleton width="100%" height={40} />
    </Grid>
    <Grid item xs={2}>
      <Skeleton width="100%" height={40} />
    </Grid>
    <Grid item xs={2}>
      <Skeleton width="100%" height={40} />
    </Grid>
    <Grid item xs={2}>
      <Skeleton width="100%" height={40} />
    </Grid>
    <Grid item xs={6}>
      <Skeleton width="100%" height={40} />
    </Grid>
    <Grid item xs={2}>
      <Skeleton width="100%" height={40} />
    </Grid>
  </Grid>
);
