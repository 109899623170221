import { makeStyles } from "@material-ui/styles";

import { Theme, createStyles } from "@material-ui/core";
import { lightGrey } from "../../../theme";
import { grey } from "@material-ui/core/colors";

export const PermissionAuditDialogStyles = makeStyles((theme: Theme) =>
  createStyles({
    skeleton: {
      margin: "12px 0px 12px 0px",
      padding: "8px 8px",
    },
    tableContainer: {
      margin: "12px 0px 12px 0px",
    },
    tableHeader: {
      backgroundColor: lightGrey,
    },
    tableRow: {
      "&:nth-of-type(odd)": {
        backgroundColor: grey[50],
      },
    },
  })
);
