import React from "react";
import { Grid, Typography, Box } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { usePreviousAuctionReadonly } from "../../services/AuctionService";
import { currencyFormat, endDateFormat } from "../../helpers/text-format/TextFormat";
import * as Styles from "./styles/LiveBidItemDialogStyles";

interface LiveBidDialogProps {
  auctionId: string;
  isCurrentAuction: boolean;
}

export const DialogAuctionHistory: React.FC<LiveBidDialogProps> = ({ auctionId, isCurrentAuction }) => {
  const classes = Styles.LiveBidItemDialogStyles();

  const [previousAuction, previousAuctionLoading] = usePreviousAuctionReadonly(auctionId);

  return (
    <>
      <Box component="div" className={classes.list}>
        {previousAuctionLoading && (
          <>
            <Typography variant="subtitle2" component="h1" gutterBottom>
              {isCurrentAuction ? "Current Auction Details" : "Last Auction Details"}
            </Typography>
            <Grid container>
              <Grid item xs={2}>
                <Typography>
                  <Skeleton variant="text" width={"90%"} />
                </Typography>
                <Typography>
                  <Skeleton variant="text" width={"90%"} />
                </Typography>
                <Typography>
                  <Skeleton variant="text" width={"90%"} />
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography>
                  <Skeleton variant="text" width={"90%"} />
                </Typography>
                <Typography>
                  <Skeleton variant="text" width={"90%"} />
                </Typography>
                <Typography>
                  <Skeleton variant="text" width={"90%"} />
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>
                  <Skeleton variant="text" width={"90%"} />
                </Typography>
                <Typography>
                  <Skeleton variant="text" width={"90%"} />
                </Typography>
                <Typography>
                  <Skeleton variant="text" width={"90%"} />
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>
                  <Skeleton variant="text" width={"90%"} />
                </Typography>
                <Typography>
                  <Skeleton variant="text" width={"90%"} />
                </Typography>
                <Typography>
                  <Skeleton variant="text" width={"90%"} />
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
        {!previousAuctionLoading && previousAuction && (
          <>
            <Typography variant="subtitle2" component="h1" gutterBottom>
              {isCurrentAuction ? "Current Auction Details" : "Last Auction Details"}
            </Typography>
            <Grid container className={classes.padding8}>
              <Grid item xs={3}>
                <Typography variant="body2">Start price:</Typography>
                <Typography variant="body2">Final bid:</Typography>
                <Typography variant="body2">Total bids:</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2">{currencyFormat(previousAuction.startPrice)}</Typography>
                <Typography variant="body2">{currencyFormat(previousAuction.finalBidAmount)}</Typography>
                <Typography variant="body2">{previousAuction.totalBids}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2">Reserve price:</Typography>
                <Typography variant="body2">Ended:</Typography>
                <Typography variant="body2">Times listed:</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2">{currencyFormat(previousAuction.reservePrice)}</Typography>
                <Typography variant="body2">{endDateFormat(previousAuction.auctionEnd, "")}</Typography>
                <Typography variant="body2">{previousAuction.numberOfListings}</Typography>
              </Grid>

              {/* <Grid item xs={3}>
                <Typography variant="body2">Increment:</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2">{currencyFormat(previousAuction.bidIncrement)}</Typography>
              </Grid> */}
            </Grid>
          </>
        )}
        {!previousAuctionLoading && !previousAuction && (
          <>
            <Typography variant="subtitle2" component="h1" gutterBottom>
              Last Auction Details
            </Typography>
            <Grid container className={classes.padding8}>
              <Grid item xs={12}>
                <Typography variant="body2">No previous listings found for this auction.</Typography>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </>
  );
};
