declare global {
  interface Window {
    __e2e_environment: {
      SOURCE_VERSION: string;
      INSTRUMENTATION_KEY: string;
      B2C_USER_SCOPE: string;
      TOKEN_SCOPE: string;
      CLIENT_ID: string;
      TENANT_SIGN_IN: string;
      TENANT_SIGN_UP: string;
      TENANT_FORGOT_PASSWORD: string;
      PUBLIC_LOT_API: string;
      PRIVATE_LOT_API: string;
      PUBLIC_AUCTION_API: string;
      PRIVATE_AUCTION_API: string;
      PRIVATE_USER_API: string;
      PRIVATE_SIGNALR_API: string;
      PRIVATE_PAYMENTS_API: string;
      PUBLIC_TIME_SYNC_API: string;
      AUTH_REDIRECT_URL: string;
      AUCTION_SITE_URL: string;
    };
  }
}

const SOURCE_VERSION = window.__e2e_environment.SOURCE_VERSION;
const INSTRUMENTATION_KEY = window.__e2e_environment.INSTRUMENTATION_KEY;
const B2C_USER_SCOPE = window.__e2e_environment.B2C_USER_SCOPE;
const TOKEN_SCOPE = window.__e2e_environment.TOKEN_SCOPE;
const CLIENT_ID = window.__e2e_environment.CLIENT_ID;
const TENANT_SIGN_IN = window.__e2e_environment.TENANT_SIGN_IN;
const TENANT_SIGN_UP = window.__e2e_environment.TENANT_SIGN_UP;
const TENANT_FORGOT_PASSWORD = window.__e2e_environment.TENANT_FORGOT_PASSWORD;
const PUBLIC_LOT_API = window.__e2e_environment.PUBLIC_LOT_API;
const PRIVATE_LOT_API = window.__e2e_environment.PRIVATE_LOT_API;
const PUBLIC_AUCTION_API = window.__e2e_environment.PUBLIC_AUCTION_API;
const PRIVATE_AUCTION_API = window.__e2e_environment.PRIVATE_AUCTION_API;
const PRIVATE_USER_API = window.__e2e_environment.PRIVATE_USER_API;
const PRIVATE_SIGNALR_API = window.__e2e_environment.PRIVATE_SIGNALR_API;
const PRIVATE_PAYMENTS_API = window.__e2e_environment.PRIVATE_PAYMENTS_API;
const PUBLIC_TIME_SYNC_API = window.__e2e_environment.PUBLIC_TIME_SYNC_API;
const AUTH_REDIRECT_URL = window.__e2e_environment.AUTH_REDIRECT_URL;
const AUCTION_SITE_URL = window.__e2e_environment.AUCTION_SITE_URL;

export {
  SOURCE_VERSION,
  INSTRUMENTATION_KEY,
  B2C_USER_SCOPE,
  TOKEN_SCOPE,
  CLIENT_ID,
  TENANT_SIGN_IN,
  TENANT_SIGN_UP,
  TENANT_FORGOT_PASSWORD,
  PUBLIC_LOT_API,
  PRIVATE_LOT_API,
  PUBLIC_AUCTION_API,
  PRIVATE_AUCTION_API,
  PRIVATE_USER_API,
  PRIVATE_SIGNALR_API,
  PRIVATE_PAYMENTS_API,
  PUBLIC_TIME_SYNC_API,
  AUTH_REDIRECT_URL,
  AUCTION_SITE_URL,
};
