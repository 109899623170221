import * as React from "react";
import { IBidder } from "../../../interfaces/bidders/IBidder";
import { IValidation } from "../../../interfaces/IValidation";
import * as BidderService from "../../../services/BidderService";

export const updateBidder = (bidder: IBidder, setValidation: React.Dispatch<React.SetStateAction<IValidation>>): Promise<boolean> => {
  return BidderService.UpdateBidder(bidder)
    .then(result => {
      setValidation({} as IValidation);
      return true;
    })
    .catch(async response => {
      if (response.status === 400) {
        setValidation(response.validation);
      }
      return false;
    });
};
