import * as React from "react";
import { Typography, Container } from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import { SecureArea } from "../components/authentication/SecureArea";
import { MemberPermission } from "../services/MemberService";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../services/TelemetryService";
import { SalesDocumentContainer } from "../components/sales-document/SalesDocumentContainer";

type TParams = { auctionId: string };

function SalesDocument({ match }: RouteComponentProps<TParams>) {
  return (
    <SecureArea memberPermission={MemberPermission.BucketAdmin}>
      <Container maxWidth={"lg"} fixed style={{ paddingBottom: "12px" }}>
        <Typography variant="h4" color="primary" gutterBottom>
          Sales Document
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          This page shows the sales document, you can download or print the pdf.
        </Typography>
      </Container>
      <SalesDocumentContainer auctionId={match.params.auctionId} />
    </SecureArea>
  );
}

export default withAITracking(reactPlugin, SalesDocument, "SalesDocument");
