import { ApiService, HttpResponse } from "./ApiService";
import { GetClientSettings } from "./SettingsService";
import { ISalvageClientSettings } from "../interfaces/ISalvageClientSettings";
import { IStripeNetProduct } from "../interfaces/stripe-products/IStripeNetProduct";

const ClientSettings: ISalvageClientSettings = GetClientSettings();

export const GetProducts = async (): Promise<HttpResponse<IStripeNetProduct[]>> => {
  return await ApiService().Get<IStripeNetProduct[]>(`${ClientSettings.PaymentsPrivateApiUrl}/stripeadmin/products`);
};

// export async function GetBidder(bidderId: string) {
//   const bidder = ApiService().Get<IBidder>(`${ClientSettings.UsersPrivateApiUrl}/bidder/${bidderId}`);
//   return bidder;
// }

// export const UpdateBidder = async (bidder: IBidder): Promise<HttpResponse<IBidder>> => {
//   return await ApiService().Put<IBidder>(ClientSettings.UsersPrivateApiUrl + "/bidder/", bidder);
// };
