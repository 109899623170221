import * as React from "react";
import { Grid, TablePagination, Typography, Fab } from "@material-ui/core";
import * as Styles from "./styles/StripeProductsSearchStyles";
import { IStripeProduct } from "../../interfaces/stripe-products/IStripeProduct";
import { useState } from "react";
import { LoadingComponent } from "../loading/LoadingComponent";
import * as StripeProductService from "../../services/StripeProductService";
import useDebounce from "../../hooks/useDebounce";
import AddIcon from "@material-ui/icons/Add";
import { StripeProductList } from "./StripeProductList";
import { useHistory } from "react-router-dom";

const results: Array<IStripeProduct> = [];

export const StripeProductsListContainer: React.FC = () => {
    const classes = Styles.StripeProductsSearchStyles();
    const [searchResults, setSearchResults] = useState(results);
    const [searchText, setSearchText] = useState<string>("");
    const [isLoading, setIsLoading] = React.useState(true);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [searchResultCount, setSearchResultCount] = React.useState(0);

    const debouncedSearch = useDebounce(searchText, 800);
    const history = useHistory();

    React.useEffect(() => {
        if (debouncedSearch !== undefined) {
            search();
        }
    }, [debouncedSearch, rowsPerPage, page]);


    const search = () => {
        StripeProductService.SearchBidders({
            search: debouncedSearch,
            pageSize: rowsPerPage,
            pageNumber: page,
        })
            .then(result => {
                if (result.parsedBody !== undefined) {
                    setSearchResults(result.parsedBody.results);
                    setSearchResultCount(result.parsedBody.resultCount);
                    setIsLoading(false);
                }
            })
            .catch(async response => {
                console.log("error searching:" + response);
            });
    };


    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        setIsLoading(true);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        setIsLoading(true);
    };

    // const handleReset = () => {
    //     if (searchText !== "" || page !== 0) {
    //         setSearchText("");
    //         setPage(0);
    //         setIsLoading(true);
    //     }
    // };

    return (
        <Grid>
            <Grid item xs={12} style={{ textAlign: "end" }}>
                <Fab
                    color="primary"
                    aria-label="add"
                    size="medium"
                    variant="extended"
                    onClick={() => history.push(`/StripeProducts/Create`)}
                    style={{ marginLeft: "8px" }}
                >
                    <AddIcon />
                    <Typography variant="button" display="inline">
                        Create Subscription
                    </Typography>
                </Fab>
            </Grid>



            {isLoading ? (
                <LoadingComponent label="Loading plans" />
            ) : (
                <>
                    <Grid item xs={12} className={classes.results}>
                        <StripeProductList results={searchResults} refresh={search} />
                    </Grid>
                    <Grid item xs={12}>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={searchResultCount}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </Grid>
                </>
            )}
        </Grid>
    );
};
