import * as React from "react";
import { useHistory } from "react-router-dom";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  ButtonGroup,
  Grid,
  Fab,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import BrokenImageIcon from "@material-ui/icons/BrokenImage";
import AddIcon from "@material-ui/icons/Add";
import { useSnackbar } from "notistack";
import {
  useLotsAwaitingAuctionCreation,
  createAuctionFromLotId,
  lotStatusString,
  useLotsInDraft,
  deleteLot,
  Lot,
  LotStatus,
} from "../../services/LotService";
import { LoadingComponent } from "../loading/LoadingComponent";
import { LotsFilter } from "./types/LotsFilter";

export const LotsComponent: React.FC = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [lots, setLots] = React.useState<Lot[] | null>();
  const [deleteLotId, setDeleteLotId] = React.useState<string>();

  const [pendingLots, pendingLotsLoading, , refreshPendingLots] = useLotsAwaitingAuctionCreation(() => {
    enqueueSnackbar("There was a problem with your lot details request, please try again.", { variant: "error" });
  });

  const [draftLots, draftLotsLoading, , refreshDraftLots] = useLotsInDraft(() => {
    enqueueSnackbar("There was a problem with your lot details request, please try again.", { variant: "error" });
  });

  const [lotTypeDropdown, setLotTypeDropdown] = React.useState<LotsFilter>(LotsFilter.All);

  React.useEffect(() => {
    if (lotTypeDropdown === LotsFilter.All) {
      setLoading(draftLotsLoading && pendingLotsLoading);
      if (draftLots && pendingLots) {
        setLots(draftLots.concat(pendingLots));
      } else if (draftLotsLoading) {
        setLots(draftLots);
      } else if (pendingLotsLoading) {
        setLots(pendingLots);
      }
    } else if (lotTypeDropdown === LotsFilter.Draft) {
      setLoading(draftLotsLoading);
      setLots(draftLots);
    } else {
      setLoading(pendingLotsLoading);
      setLots(pendingLots);
    }
  }, [lotTypeDropdown, draftLotsLoading, pendingLotsLoading, draftLots, pendingLots]);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setLotTypeDropdown(event.target.value as LotsFilter);
  };

  const handleDialogOpen = (lotId: string) => {
    setDeleteLotId(lotId);
    setOpen(true);
  };

  const handleDialogClose = () => {
    setDeleteLotId(undefined);
    setOpen(false);
  };

  const handleDialogRemove = () => {
    setOpen(false);
    if (deleteLotId) {
      setLoading(true);
      deleteLot(deleteLotId)
        .then(() => {
          refreshDraftLots();
          refreshPendingLots();
        })
        .catch(() => {
          enqueueSnackbar("There was a problem with removing the lot, please try again.", { variant: "error" });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const DeleteDialog = () => {
    return (
      <Dialog open={open} onClose={handleDialogClose}>
        <DialogTitle>{"Confirm Remove Lot?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to remove this lot?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={() => handleDialogRemove()} variant="contained" color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      {loading && <LoadingComponent label="Loading" />}
      {!loading && (
        <Grid container>
          <Grid xs={12} item style={{ textAlign: "end" }}>
            <Fab color="primary" aria-label="add" size="medium" variant="extended" onClick={() => history.push(`/CreateLot`)}>
              <AddIcon />
              <Typography variant="button" display="inline">
                Create lot
              </Typography>
            </Fab>
          </Grid>
          <Grid xs={12} item style={{ textAlign: "end", margin: "24px 0" }}>
            <FormControl>
              <InputLabel>Lot Status</InputLabel>
              <Select value={lotTypeDropdown} onChange={handleChange} style={{ minWidth: "200px" }}>
                <MenuItem value={LotsFilter.All}>All</MenuItem>
                <MenuItem value={LotsFilter.Draft}>Draft Lots</MenuItem>
                <MenuItem value={LotsFilter.Pending}>Awaiting to be assigned to auction</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={12} item>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Image</TableCell>
                    <TableCell>Reference</TableCell>
                    <TableCell style={{ width: "100%" }}>Title</TableCell>
                    <TableCell>Location</TableCell>
                    <TableCell>Registration</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell align={"right"} width={"350px"}>
                      Options
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {lots && lots.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={3}>No lots available</TableCell>
                    </TableRow>
                  )}

                  {lots &&
                    lots.map(lot => (
                      <TableRow key={lot.id}>
                        <TableCell style={{ padding: "4px" }}>
                          <div
                            onClick={() => {
                              history.push(`/UpdateLot/${lot.id}`);
                            }}
                            style={{
                              cursor: "pointer",
                              height: "75px",
                              width: "100px",
                              backgroundRepeat: "no-repeat",
                              backgroundImage: `url("${lot.auctionImage}")`,
                              backgroundSize: "cover",
                            }}
                          >
                            {!lot.auctionImage && (
                              <div style={{ padding: "20%" }}>
                                <BrokenImageIcon color={"secondary"} />
                              </div>
                            )}
                          </div>
                        </TableCell>
                        <TableCell>{lot.lotDetails.referenceNumber === null ? "" : lot.lotDetails.referenceNumber}</TableCell>
                        <TableCell>{lot.lotDetails.title === null ? "No title" : lot.lotDetails.title}</TableCell>
                        <TableCell>{lot.lotProperties?.location === null ? "" : lot.lotProperties.location.city}</TableCell>
                        <TableCell>{lot.lotDetails.registrationNumber === null ? "" : lot.lotDetails.registrationNumber}</TableCell>
                        <TableCell>{lotStatusString(lot.status)}</TableCell>
                        <TableCell style={{ textAlign: "end" }}>
                          <ButtonGroup color="primary">
                            <Button
                              onClick={() => {
                                handleDialogOpen(lot.id);
                              }}
                            >
                              Remove
                            </Button>
                            <Button
                              variant={lot.status !== LotStatus.AwaitingAuctionCreation ? "contained" : undefined}
                              onClick={() => {
                                history.push(`/UpdateLot/${lot.id}`);
                              }}
                            >
                              Edit
                            </Button>
                            {lot.status === LotStatus.AwaitingAuctionCreation && (
                              <Button
                                variant="contained"
                                onClick={() => {
                                  setLoading(true);
                                  createAuctionFromLotId(lot.id)
                                    .then(data => {
                                      history.push(`/UpdateLot/${lot.id}/${data.parsedBody!.id}`);
                                    })
                                    .catch(() => {
                                      enqueueSnackbar("There was a problem with creating the auction, please try again.", { variant: "error" });
                                    })
                                    .finally(() => {
                                      setLoading(false);
                                    });
                                }}
                              >
                                Create Auction
                              </Button>
                            )}
                          </ButtonGroup>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            {DeleteDialog()}
          </Grid>
        </Grid>
      )}
    </>
  );
};
