import * as React from "react";
import { Select, MenuItem, InputLabel, FormControl } from "@material-ui/core";
import * as Styles from "./styles/BidderStatusSelectStyles";

export const BidderStatusSearch: React.FC<{
  setSearchStatus: React.Dispatch<React.SetStateAction<string>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  searchStatus: string;
  setGetSearchResults: React.Dispatch<React.SetStateAction<boolean>>;
  getSearchResults: boolean;
}> = ({ setSearchStatus, searchStatus, setGetSearchResults, setPage, getSearchResults }) => {
  const classes = Styles.BidderStatusSelectStyles();

  const handleChange = (event: any) => {
    setSearchStatus(event.target.value);
    setPage(0);
    setGetSearchResults(true);
  };

  return (
    <div>
      <FormControl variant="outlined" margin="dense" className={classes.formControl}>
        <InputLabel>Bidder Filters</InputLabel>
        <Select
          disabled={getSearchResults}
          labelWidth={100}
          onChange={e => {
            handleChange(e);
          }}
          variant="outlined"
          margin="dense"
          fullWidth
          value={searchStatus}
        >
          <MenuItem value={"0"}>All Bidders</MenuItem>
          <MenuItem value={"1"}>Recently Logged in (last 48 hours)</MenuItem>
          <MenuItem value={"2"}>Recently bid (last 48 hours)</MenuItem>
          <MenuItem value={"3"}>Recently Won (last 48 hours)</MenuItem>
          <MenuItem value={"4"}>Expired Bidders (withing the last 2 months)</MenuItem>
          <MenuItem value={"5"}>Bidders near expiry (within the next month)</MenuItem>
          <MenuItem value={"6"}>Suspended Bidders</MenuItem>
          <MenuItem value={"7"}>Bidders awaiting verification</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};
