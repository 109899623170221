import { ApiService, HttpResponse } from "./ApiService";
import { IConfiguration } from "../interfaces/vehicleConfigurations/IConfiguration";
import { GetClientSettings } from "./SettingsService";
import { ISalvageClientSettings } from "../interfaces/ISalvageClientSettings";
import { useReadonlyData } from "./useVersionedData";
const ClientSettings: ISalvageClientSettings = GetClientSettings();

export const useConfiguration = (onError: (httpStatusCode: number, resultBody?: string) => void) =>
  useReadonlyData<IConfiguration>(`${ClientSettings.LotPublicApiUrl}/lot/configuration/vehicle/`, onError);

export const GetConfiguration = async (): Promise<HttpResponse<IConfiguration>> => {
  const url: string = ClientSettings.LotPublicApiUrl + "/lot/configuration/vehicle/";
  return await ApiService().Get<IConfiguration>(url);
};
