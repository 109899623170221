import React, { useState } from "react";
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@material-ui/core";

import { AuctionStatus } from "../../../interfaces/auctions/IAuction";
import { IAuctionInfo } from "../../../services/AuctionService";
import { useSnackbar } from "notistack";
import { useActiveBuckets, useDraftBuckets } from "../../../services/BucketService";
import { bucketStatusString, IBucket, is24HoursInFuture } from "../../../interfaces/buckets/IBucket";
import { DialogComponent } from "../DialogComponent";
import moment from "moment";

interface BucketDialogProps {
  open: boolean;
  setOpen: (value: React.SetStateAction<boolean>) => void;
  auction: IAuctionInfo;
  handleAddToBucket: (auctionId: string) => Promise<void>;
  setBucketId: (value: React.SetStateAction<string>) => void;
  loading: boolean;
}

export const BucketDialog: React.FC<BucketDialogProps> = ({ open, setOpen, auction, handleAddToBucket, setBucketId, loading }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [draftBuckets, isDraftBucketsLoading, , refreshDraft] = useDraftBuckets(() => {
    enqueueSnackbar("There was a problem loading draft buckets, please try again.", { variant: "error" });
  });
  const [activeBuckets, isActiveBucketsLoading, , refreshActive] = useActiveBuckets(() => {
    enqueueSnackbar("There was a problem loading active buckets, please try again.", { variant: "error" });
  });

  const [allBuckets, setAllBuckets] = useState<IBucket[]>([]);
  const [chosenBucket, setChosenBucket] = useState<IBucket>();
  const [isValid, setIsValid] = useState(true);

  React.useEffect(() => {
    const buckets: IBucket[] = [];
    if (draftBuckets) buckets.push(...draftBuckets);
    if (activeBuckets) buckets.push(...activeBuckets);
    setAllBuckets(buckets);
  }, [draftBuckets, activeBuckets]);

  React.useEffect(() => {
    if (open) {
      refreshDraft();
      refreshActive();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const BucketChangeHandler = (event: any) => {
    const bucketId = event.target.value;
    const bucket = allBuckets.find(x => x.id === bucketId);
    setChosenBucket(bucket);
    setBucketId(event.target.value);
    setIsValid(is24HoursInFuture(moment(bucket?.scheduledDate)) ?? false);
  };

  const addToBucket = () => {
    const valid = is24HoursInFuture(moment(chosenBucket?.scheduledDate)) ?? false;
    setIsValid(valid);
    if (valid) {
      handleAddToBucket(auction.id);
    }
  };

  const closeDialog = () => {
    setChosenBucket(undefined);
    setOpen(false);
  };

  return (
    <DialogComponent
      open={open}
      onClose={closeDialog}
      title={"Add to bucket"}
      changeHandler={addToBucket}
      buttonText="Add to bucket"
      contentElement={
        !isDraftBucketsLoading &&
        !isActiveBucketsLoading && (
          <FormControl style={{ width: "100%" }} error={!isValid}>
            <InputLabel>Bucket</InputLabel>
            <Select
              fullWidth
              required
              onChange={event => BucketChangeHandler(event)}
              disabled={auction.status !== AuctionStatus.CreatedAwaitingBucketing}
            >
              {allBuckets !== null &&
                allBuckets.map((bucket, index) => (
                  <MenuItem key={index} value={bucket.id}>
                    {bucket.title} - {bucketStatusString(bucket.status)}
                  </MenuItem>
                ))}
            </Select>
            <FormHelperText>
              {!isValid
                ? "The schedule date for this bucket is less than 24 hours in the future. Please change the schedule date or choose a different bucket."
                : ""}
            </FormHelperText>
          </FormControl>
        )
      }
      id={auction.id}
      buttonDisabled={loading || !isValid}
    />
  );
};
