import { makeStyles } from "@material-ui/core/styles";

export const FeaturedDragContainerStyles = makeStyles(theme => ({
  buttonWrapper: { display: "flex", justifyContent: "flex-end" },
  OkButton: {
    marginTop: "16px",
    marginLeft: "4px",
  },
  CancelButton: {
    marginTop: "16px",
    marginLeft: "4px",
  },
}));
