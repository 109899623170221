import { Grid } from "@material-ui/core";
import { IMedia } from "../../../interfaces/lots/IMedia";
import * as React from "react";
import { DropTargetMonitor, useDrop, useDrag } from "react-dnd";
import { FeaturedDragItemTypes } from "../../featured-ordering/FeaturedDragItemTypes";
import { DisplayImageContainer } from "./DisplayImageContainer";

interface IProps {
  lotId: string;
  media: IMedia;
  index: number;
  mediaUpdated: (updatedMedia: IMedia) => Promise<void>;
  moveMedia: (dragIndex: number, hoverIndex: number) => void;
}

interface DragItem {
  index: number;
  item: IMedia;
  type: string;
}

export const DraggableMediaContainer: React.FC<IProps> = ({ lotId, media, index, mediaUpdated, moveMedia }) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [, drop] = useDrop({
    accept: FeaturedDragItemTypes.MEDIA,
    drop(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      moveMedia(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [, drag] = useDrag({
    item: { type: FeaturedDragItemTypes.MEDIA, media, index },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <Grid ref={ref} item xs={3}>
      <DisplayImageContainer lotId={lotId} media={media} mediaUpdated={mediaUpdated}></DisplayImageContainer>
    </Grid>
  );
};
