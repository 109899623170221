import * as React from "react";
import { TextField, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";
import { IBidder } from "../../interfaces/bidders/IBidder";
import * as BidderService from "../../services/BidderService";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import MessageIcon from "@material-ui/icons/Message";

interface IProps {
  bidder: IBidder;
  noteAdded: () => void;
}

export const AddNote: React.FC<IProps> = ({ bidder, noteAdded }) => {
  const [addNoteOpen, setAddNoteOpen] = React.useState(false);
  const [isPublic, setIsPublic] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [isAddingNote, setIsAddingNote] = React.useState(false);

  const handleClose = () => {
    setAddNoteOpen(false);
    setMessage("");
  };

  const openAddNote = (isPublic: boolean) => {
    setIsPublic(isPublic);
    setAddNoteOpen(true);
  };

  const addNote = async () => {
    setIsAddingNote(true);
    await BidderService.addBidderNote(bidder.id, isPublic, message);
    setAddNoteOpen(false);
    setIsAddingNote(false);
    setMessage("");
    noteAdded();
  };

  const isTooLongWordError = () => {
    const wordLongerthanTwentyNineChars = new RegExp(/[^\W]{29,}/);
    const matchArr = message?.match(wordLongerthanTwentyNineChars);
    return matchArr !== null && matchArr.length > 0;
  };

  return (
    <>
      <h1>Add Note</h1>
      <p>
        Click the button below to add either a private note against the bidder. This information could be sent to the bidder if they request all data
        we hold about them.
      </p>
      <Button variant="contained" onClick={() => openAddNote(false)} startIcon={<NoteAddIcon />}>
        Add Private Note
      </Button>
      <p>Click the button below to send a message to the bidder.</p>
      <Button variant="contained" onClick={() => openAddNote(true)} startIcon={<MessageIcon />}>
        Send a message to the bidder
      </Button>

      <Dialog open={addNoteOpen} onClose={handleClose} maxWidth="lg" fullWidth>
        <DialogTitle>{isPublic ? "Send message to bidder" : "Add private note"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {isPublic
              ? "Please enter the message you would like send to the bidder"
              : "Please enter the note you would like stored privately against the bidder"}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="message"
            value={message}
            onChange={event => setMessage(event.target.value)}
            label="Message"
            helperText={isTooLongWordError() ? "Invalid text detected, a single word is greater than 29 characters." : ""}
            error={isTooLongWordError()}
            fullWidth
            multiline
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" disabled={isAddingNote}>
            Cancel
          </Button>
          <Button onClick={addNote} color="primary" disabled={isAddingNote}>
            {isPublic ? "Send" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
