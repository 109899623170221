import React from "react";
import { Typography } from "@material-ui/core";

export default function Completed() {
  return (
    <>
      <Typography variant="h5" gutterBottom>
        Completed
      </Typography>
      <Typography variant="subtitle1">The lot has been created.</Typography>
      <Typography variant="subtitle1">Select 'Lot Management' or 'Bucket Management' to continue.</Typography>
    </>
  );
}
