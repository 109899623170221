import { makeStyles } from "@material-ui/core/styles";

export const BidderTextFieldStyles = makeStyles(theme => ({
  textField: {
    top: "0",
    marginTop: "0px",
    marginBottom: "0px",
  },
  select: {
    top: "50%",
  },
}));
