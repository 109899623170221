import React from "react";

import { MediaManager } from "../../media-manager/MediaManager";

interface MediaDetailsProps {
  lotId: string;
  isValid: (mediaUploaded: boolean) => void;
  uploadMedia: boolean;
  mediaUploaded: (mediaUploaded: boolean) => void;
}

export const MediaDetails: React.FC<MediaDetailsProps> = ({ lotId, isValid, uploadMedia, mediaUploaded }) => {
  const uploadAreaComponent = (
    <section className="container">
      <MediaManager lotId={lotId} isValid={isValid} uploadMedia={uploadMedia} mediaUploaded={mediaUploaded} />
    </section>
  );

  return uploadAreaComponent;
};
