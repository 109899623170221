import React from "react";
import { useMsal } from "../authentication/MsalProvider";
import { ISalvageClientSettings } from "../interfaces/ISalvageClientSettings";
import { IValidation, IValidationError } from "../interfaces/IValidation";
import { GetClientSettings } from "./SettingsService";

const clientSettings: ISalvageClientSettings = GetClientSettings();

const isResponseJson = (response: Response) => {
  return response !== null;
  // const contentType = response.headers.get("content-type");
  // return contentType && contentType.indexOf("application/json") !== -1;
};

/**
 * @param uri API endpoint to use for GET and PUT
 * @param onError Callback to execute if the HTTP status code is an error. Alternative to using the errorHttpStatusCode status.
 * @param shouldGetImmediately Flag to determine if the fetch call should be executed immediately or not.
 * @returns data: in-memory object equivalent to React.useState
 * @returns setData: in-memory object equivalent to React.useState
 * @returns isloading: true if any API operation is in progress
 * @returns errorHttpStatusCode: if the last API operation resulted in an error then this is the code
 * @returns put: a method which can be called to put the current in-memory data back to the API. Alternatively, supply a data structure, and this will be sent immediately (and re-stored in the data hook)
 * @returns refresh: a method which can be called to re-fetch from the API and update the in-memory data property accordingly
 * @returns validationMessages: (optional) standardised validation errors where appropriate
 */
const useVersionedData = <T>(
  uri: string | null,
  onError?: (httpStatusCode: number, resultBody?: string) => void,
  shouldGetImmediately: boolean = true
): [
  T | null,
  React.Dispatch<React.SetStateAction<T | null>>,
  boolean,
  number | null,
  (toPut?: T | undefined) => Promise<void>,
  () => Promise<void>,
  IValidation | null
] => {
  const msal = useMsal();

  const [data, setData] = React.useState<T | null>(null);
  const [etag, setEtag] = React.useState<string | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorCode, setErrorCode] = React.useState<number | null>(null);
  const [validationMessages, setValidationMessages] = React.useState<IValidation | null>(null);

  const constructHeaders = async (otherHeaders?: {}) => {
    if (msal.accounts.length > 0) {
      const token = await msal.instance.acquireTokenSilent({
        scopes: [clientSettings.B2CSettings.TokenScope],
        account: msal.accounts[0],
        forceRefresh: false,
      });
      if (token !== undefined) {
        return new Headers({
          authorization: `Bearer ${token.accessToken}`,
          ...otherHeaders,
        });
      }
    }
    return new Headers({ ...otherHeaders });
  };

  const responseAsData = async (response: Response) => {
    const fetchedEtag = response.headers.get("ETag");
    setEtag(fetchedEtag);

    if (response.status === 204) {
      return null;
    }

    if (isResponseJson(response)) {
      return await response.json();
    }

    return response;
  };

  const responseAsIValidation = async (response: Response) => {
    const validationErrors = (await response.json()) as IValidationError[];

    const validation: IValidation = {} as IValidation;
    for (let i = 0; i < validationErrors.length; i++) {
      const key = validationErrors[i]["field"];
      validation[key] = validationErrors[i];
    }

    return validation;
  };

  const addEtagHeaderIfKnown = (existingHeaders: Headers): Headers => {
    if (etag) {
      return new Headers({
        "If-Match": etag,
        ...existingHeaders,
      });
    } else {
      return existingHeaders;
    }
  };

  const apiOperationAsync = async (requestInit: RequestInit): Promise<void> => {
    if (uri === null) {
      return;
    }

    setIsLoading(true);
    try {
      const response = await fetch(uri, requestInit);
      if (response.ok) {
        setData(await responseAsData(response));
        setErrorCode(null);
        setValidationMessages(null);
      } else if (response.status === 400) {
        setErrorCode(response.status);
        var val = await responseAsIValidation(response);
        setValidationMessages(val);
        if (onError) onError(response.status, "Validation error");
      } else {
        setData(null);
        setErrorCode(response.status);
        setValidationMessages(null);
        if (onError) onError(response.status, await response.text());
      }
    } finally {
      setIsLoading(false);
    }
  };

  const apiGetAsync = async (): Promise<void> => {
    const headers = await constructHeaders();
    const requestInit: RequestInit = {
      headers,
      method: "GET",
    };

    await apiOperationAsync(requestInit);
  };

  const apiPutAsync = async (toPut?: T | undefined): Promise<void> => {
    const headers = addEtagHeaderIfKnown(await constructHeaders());
    const requestInit: RequestInit = {
      headers,
      method: "PUT",
      body: JSON.stringify(toPut ? toPut : data),
    };

    await apiOperationAsync(requestInit);
  };

  React.useEffect(() => {
    if (shouldGetImmediately) {
      const doit = async () => apiGetAsync();
      doit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uri]);

  return [data, setData, isLoading, errorCode, apiPutAsync, apiGetAsync, validationMessages];
};

/**
 * @param uri API endpoint to use for POST
 * @param onError Callback to execute if the HTTP status code is an error. Alternative to using the errorHttpStatusCode status.
 * @returns data: in-memory object equivalent to React.useState
 * @returns setData: in-memory object equivalent to React.useState
 * @returns isloading: true if any API operation is in progress
 * @returns errorHttpStatusCode: if the last API operation resulted in an error then this is the code
 * @returns post: a method which can be called to put the current in-memory data back to the API. Alternatively, supply a data structure, and this will be sent immediately (and re-stored in the data hook)
 * @returns validationMessages: (optional) standardised validation errors where appropriate
 */
const usePostedData = <TResponse, TPost>(
  uri: string | null,
  onError?: (httpStatusCode: number, resultBody?: string) => void,
  disableJson?: boolean
): [
  TPost | null,
  React.Dispatch<React.SetStateAction<TPost | null>>,
  (toPost?: TPost | undefined) => Promise<void>,
  TResponse | null,
  boolean,
  number | null,
  IValidation | null
] => {
  const msal = useMsal();

  const [response, setResponse] = React.useState<TResponse | null>(null);
  const [data, setData] = React.useState<TPost | null>(null);
  const [etag, setEtag] = React.useState<string | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorCode, setErrorCode] = React.useState<number | null>(null);
  const [validationMessages, setValidationMessages] = React.useState<IValidation | null>(null);

  const constructHeaders = async (otherHeaders?: {}) => {
    if (msal.accounts.length > 0) {
      const token = await msal.instance.acquireTokenSilent({
        scopes: [clientSettings.B2CSettings.TokenScope],
        account: msal.accounts[0],
        forceRefresh: false,
      });
      if (token !== undefined) {
        return new Headers({
          authorization: `Bearer ${token.accessToken}`,
          ...otherHeaders,
        });
      }
    }
    return new Headers({ ...otherHeaders });
  };

  const responseAsData = async (response: Response) => {
    const fetchedEtag = response.headers.get("ETag");
    setEtag(fetchedEtag);

    if (response.status === 204) {
      return null;
    }

    if (!(disableJson ?? false) && isResponseJson(response)) {
      return await response.json();
    }

    return await response.text();
  };

  const responseAsIValidation = async (response: Response) => {
    const validationErrors = (await response.json()) as IValidationError[];

    const validation: IValidation = {} as IValidation;
    for (let i = 0; i < validationErrors.length; i++) {
      const key = validationErrors[i]["field"];
      validation[key] = validationErrors[i];
    }

    return validation;
  };

  const addEtagHeaderIfKnown = (existingHeaders: Headers): Headers => {
    if (etag) {
      return new Headers({
        "If-Match": etag,
        ...existingHeaders,
      });
    } else {
      return existingHeaders;
    }
  };

  const apiOperationAsync = async (requestInit: RequestInit): Promise<void> => {
    if (uri === null) {
      return;
    }

    setIsLoading(true);
    try {
      const response = await fetch(uri, requestInit);
      if (response.ok) {
        setResponse(await responseAsData(response));
        setErrorCode(null);
        setValidationMessages(null);
      } else if (response.status === 400) {
        setResponse(null);
        setErrorCode(response.status);
        var val = await responseAsIValidation(response);
        setValidationMessages(val);
        if (onError) onError(response.status, "Validation error");
      } else {
        setResponse(null);
        setErrorCode(response.status);
        setValidationMessages(null);
        if (onError) onError(response.status, await response.text());
      }
    } finally {
      setIsLoading(false);
    }
  };

  const apiPostAsync = async (toPost?: TPost | undefined): Promise<void> => {
    const headers = addEtagHeaderIfKnown(await constructHeaders());
    const requestInit: RequestInit = {
      headers,
      method: "POST",
      body: JSON.stringify(toPost ? toPost : data),
    };
    await apiOperationAsync(requestInit);
  };

  return [data, setData, apiPostAsync, response, isLoading, errorCode, validationMessages];
};

/**
 * @param uri API endpoint to use for GET
 * @param onError Callback to execute if the HTTP status code is an error. Alternative to using the errorHttpStatusCode status.
 * @returns [data, isLoading, errorHttpStatusCode, refresh()]
 */
const useReadonlyData = <T>(
  uri: string | null,
  onError?: (httpStatusCode: number) => void,
  shouldGetImmediately: boolean = true
): [T | null, boolean, number | null, () => Promise<void>] => {
  const [data, , isLoading, errorHttpStatusCode, , refresh] = useVersionedData<T>(uri, onError, shouldGetImmediately);

  return [data, isLoading, errorHttpStatusCode, refresh];
};

export { useVersionedData, useReadonlyData, usePostedData };
