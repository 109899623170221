import React from "react";
import { Typography, Paper, Container, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import FolderIcon from "@material-ui/icons/Folder";
import { useSnackbar } from "notistack";
import { IAuctionImportPackProgress, PostPackUpload } from "../../services/PackUploadService";
import { PackUploadStyles } from "./styles/PackUploadStyles";
import { PackUploadStatus } from "./PackUploadStatus";
import { LoadingComponent } from "../loading/LoadingComponent";
import { IValidation } from "../../interfaces/IValidation";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

export const PackUpload: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = PackUploadStyles();
  const [isUploading, setIsUploading] = React.useState<boolean>(false);
  const [isProcessing, setIsProcessing] = React.useState<boolean>(false);
  const [batchId, setBatchId] = React.useState<string>();
  const [validation, setValidation] = React.useState<IValidation | null>();

  const PackUpload = async (file: File) => {
    const formData = new FormData();
    formData.append(file.name, file);

    const errorFeedback = () => enqueueSnackbar(`Pack Upload failed for ${file.name}`, { variant: "error" });

    try {
      const response = await PostPackUpload<IAuctionImportPackProgress>(formData);
      const result = response?.parsedBody as IAuctionImportPackProgress | undefined;
      if (result) {
        setBatchId(result.batchId);
      } else {
        errorFeedback();
      }
    } catch (err) {
      if (err.status === 400 && err.validation) {
        setValidation(err.validation);
      } else {
        errorFeedback();
      }
    }
  };

  const onDrop = async (uploadedFiles: Array<File>) => {
    const acceptedFiles: Array<File> = [];
    for (let x = 0; x < uploadedFiles.length; x++) {
      const uploadedfile = uploadedFiles[x];
      if (isXml(uploadedfile)) {
        acceptedFiles.push(uploadedfile);
      } else {
        enqueueSnackbar("Incorrect file type", { variant: "error" });
      }
    }

    if (acceptedFiles.length > 1) {
      enqueueSnackbar("Please import one XML pack at a time", { variant: "error" });
    } else if (acceptedFiles.length > 0) {
      setIsUploading(true);
      try {
        //Resets fields from previous upload attempt
        setValidation(null);
        setBatchId(undefined);

        await Promise.all(acceptedFiles.map(f => PackUpload(f)));
      } finally {
        setIsUploading(false);
      }
    }
  };

  const { isDragActive, getRootProps, getInputProps } = useDropzone({
    onDrop,
    noDragEventsBubbling: true,
  });

  const isXml = (file: File): boolean => {
    const fileType = file.type;
    return fileType.includes("xml");
  };

  const failedXML = () => {
    const validationArr =
      validation &&
      Object.keys(validation).map((field, i) => (
        <TableRow className={classes.tableRow} key={i}>
          <TableCell>{field}</TableCell>
          <TableCell>{validation && validation[field]?.error}</TableCell>
        </TableRow>
      ));

    if (validationArr && validationArr.length > 0) {
      return (
        <TableContainer className={classes.statusContainer} component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" colSpan={2}>
                  <HighlightOffIcon className={classes.outcomeIcons} color="primary" />
                  <Typography variant="h5">Invalid data in xml</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableHead>
              <TableRow>
                <TableCell>Property</TableCell>
                <TableCell>Reason</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{validationArr}</TableBody>
          </Table>
        </TableContainer>
      );
    }
  };

  return (
    <Container>
      {!isUploading && !isProcessing && (
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <Paper className={classes.paper} elevation={3}>
            <FolderIcon color="primary" className={classes.folderIcon} />
            <Typography variant="h5" component="h3">
              {isDragActive ? "Drop File" : "Click here or drop a file to start pack import"}
            </Typography>
          </Paper>
        </div>
      )}
      {isUploading && (
        <Paper className={classes.processingPaper} elevation={3}>
          <LoadingComponent label="Uploading file, please wait" marginBottom="0px" marginTop="0px" />
        </Paper>
      )}
      {batchId && <PackUploadStatus batchId={batchId} setIsProcessing={setIsProcessing} />}
      {validation && failedXML()}
    </Container>
  );
};
