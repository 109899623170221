import { IAuctionInfo } from "../../../services/AuctionService";
import * as React from "react";
import { Grid, TextField, Select, MenuItem, InputLabel, FormControl, Button } from "@material-ui/core";

import { useSnackbar } from "notistack";
import { useInsuranceCompanies } from "../../../services/MemberService";
import { useConfiguration } from "../../../services/VehicleConfigurationService";
import { IMemberSite, useMemberSites } from "../../../services/MemberSiteService";
import { Skeleton } from "@material-ui/lab";
import { SearchStyles } from "../styles/SearchStyles";

interface SearchComponentProps {
  insuranceCompanyFilter: string;
  sitePostcodeFilter: string;
  makeFilter: string;
  searchText: string;
  lotTypeFilter: string;
  setInsuranceCompanyFilter: (insuranceCompanyFilter: string) => void;
  setSitePostcodeFilter: (sitePostcodeFilter: string) => void;
  setMakeFilter: (makeFilter: string) => void;
  setSearchText: (searchText: string) => void;
  setLotTypeFilter: (lotTypeFilter: string) => void;
}

export const SearchComponent: React.FC<SearchComponentProps> = ({
  insuranceCompanyFilter,
  sitePostcodeFilter,
  searchText,
  makeFilter,
  lotTypeFilter,
  setInsuranceCompanyFilter,
  setSitePostcodeFilter,
  setMakeFilter,
  setSearchText,
  setLotTypeFilter,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = SearchStyles();

  const [configuration, configurationLoading] = useConfiguration(() => {
    enqueueSnackbar("There was a problem getting the lot configuration options, please try again.", { variant: "error" });
  });

  const [memberSites, memberSitesLoading, ,] = useMemberSites(() => {
    enqueueSnackbar("There was a problem with your request, please try again.", { variant: "error" });
  });

  const [insuranceCompanies, insuranceCompaniesLoading] = useInsuranceCompanies(() => {
    enqueueSnackbar("There was a problem getting the list of insurance companies, please try again.", { variant: "error" });
  });

  const makes = configuration?.item.vehicleMake.map(vehicleMake => vehicleMake.make) ?? [""];
  const lotTypes = configuration?.item.lotTypes ?? [""];

  const reset = () => {
    setMakeFilter("");
    setSearchText("");
    setSitePostcodeFilter("");
    setLotTypeFilter("");
    setInsuranceCompanyFilter("");
  };

  return (
    <>
      {(configurationLoading || memberSitesLoading || insuranceCompaniesLoading) && (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Skeleton variant="text" height="16px" animation="wave" width="100%" />
          </Grid>
          <Grid item xs={6}>
            <Skeleton variant="text" height="16px" animation="wave" width="100%" />
          </Grid>
          <Grid item xs={6}>
            <Skeleton variant="text" height="16px" animation="wave" width="100%" />
          </Grid>
          <Grid item xs={6}>
            <Skeleton variant="text" height="16px" animation="wave" width="100%" />
          </Grid>
        </Grid>
      )}
      {!configurationLoading && !memberSitesLoading && !insuranceCompaniesLoading && (
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <FormControl className={classes.searchTextField}>
              <TextField
                margin="normal"
                id="textSearch"
                placeholder={"Search"}
                onChange={e => setSearchText(e.target.value)}
                value={searchText ? searchText : ""}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl className={classes.width100}>
              <InputLabel>Makes</InputLabel>
              <Select value={makeFilter} onChange={event => setMakeFilter(event.target.value as string)}>
                <MenuItem value={""}>All</MenuItem>
                {makes?.map((make: string, index) => {
                  return (
                    <MenuItem value={make} key={index}>
                      {make}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl className={classes.width100}>
              <InputLabel>Client</InputLabel>
              <Select value={insuranceCompanyFilter} onChange={event => setInsuranceCompanyFilter(event.target.value as string)}>
                <MenuItem value={""}>All</MenuItem>
                {insuranceCompanies?.map((insurer: string, index) => {
                  return (
                    <MenuItem value={insurer} key={index}>
                      {insurer}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl className={classes.width100}>
              <InputLabel>Site</InputLabel>
              <Select value={sitePostcodeFilter} onChange={event => setSitePostcodeFilter(event.target.value as string)}>
                <MenuItem value={""}>All</MenuItem>
                {memberSites?.map((site: IMemberSite, index) => {
                  return (
                    <MenuItem value={site.location.postcode} key={index}>
                      {site.siteName}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl className={classes.width100}>
              <InputLabel>Lot Type</InputLabel>
              <Select value={lotTypeFilter} onChange={event => setLotTypeFilter(event.target.value as string)}>
                <MenuItem value={""}>All</MenuItem>
                {lotTypes?.map((type: string, index) => {
                  return (
                    <MenuItem value={type} key={index}>
                      {type}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <Button color="primary" className={classes.resetButton} onClick={reset}>
              Reset
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export const searchAuctionsAwaitingBucketing = (
  allAuctions: IAuctionInfo[],
  search: string,
  insuranceCompany: string,
  make: string,
  sitePostcode: string,
  lotType: string
): IAuctionInfo[] => {
  const searchText = search.toLowerCase();
  const searchAuctions = allAuctions?.filter(f => {
    if (searchText !== "" && searchText !== null) {
      return (
        f?.title?.includes(searchText) ||
        f?.metadata?.referenceNumber?.toLowerCase().includes(searchText) ||
        f.metadata?.bodyShape?.toLowerCase().includes(searchText) ||
        f.metadata?.branchCode?.toLowerCase().includes(searchText) ||
        f.metadata?.colour?.toLowerCase().includes(searchText) ||
        f.metadata?.damageCategory?.toLowerCase().includes(searchText) ||
        f.metadata?.description?.toLowerCase().includes(searchText) ||
        f.metadata?.fuelType?.toLowerCase().includes(searchText) ||
        f.metadata?.insuranceCompany?.toLowerCase().includes(searchText) ||
        f.metadata?.location?.toLowerCase().includes(searchText) ||
        f.metadata?.title?.toLowerCase().includes(searchText) ||
        f.metadata?.make?.toLowerCase().includes(searchText) ||
        f.metadata?.model?.toLowerCase().includes(searchText) ||
        f.metadata?.postcode?.toLowerCase().includes(searchText) ||
        f.metadata?.lotType?.toLowerCase().includes(searchText) ||
        f.metadata?.transmissionType?.toLowerCase().includes(searchText)
      );
    }

    return allAuctions;
  });

  const insuranceCompanyAuctions = searchAuctions?.filter(f =>
    insuranceCompany !== "" ? f.metadata?.insuranceCompany === insuranceCompany : searchAuctions
  );

  const makeAuctions = insuranceCompanyAuctions?.filter(f => (make !== "" ? f.metadata?.make === make : insuranceCompanyAuctions));

  const siteAuctions = makeAuctions?.filter(f => (sitePostcode !== "" ? f.metadata?.postcode === sitePostcode : makeAuctions));

  const typeAuctions = siteAuctions?.filter(f => (lotType !== "" ? f.metadata.lotType === lotType : siteAuctions));

  return typeAuctions;
};
