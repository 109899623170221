import React from "react";
import moment from "moment";
import { TableRow, TableCell, Table, TableHead, TableBody, Paper, Typography } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { useLiveBidsViews, View } from "../../services/AuctionService";
import * as Styles from "./styles/LiveBidItemDialogStyles";
import { endDateFormat } from "../../helpers/text-format/TextFormat";
import { Link } from "react-router-dom";

interface DialogViewsProps {
  auctionId: string;
}
export const DialogViews: React.FC<DialogViewsProps> = ({ auctionId }) => {
  const classes = Styles.LiveBidItemDialogStyles();

  const [views, viewsLoading] = useLiveBidsViews(auctionId);

  const viewList = (): any => {
    if (views && views.length > 0) {
      return views.map((viewed: View, index: number) => (
        <TableRow key={index} className={classes.tableRow}>
          <TableCell align="left" size="small">
            <Link style={{ textDecoration: "none", color: "black" }} to={`/BidderDetailsChange/${viewed.bidderId}`}>
              {viewed.bidderName}
            </Link>
          </TableCell>
          <TableCell align="left" size="small">
            {endDateFormat(moment(viewed.lastUpdated).toString(), "")}
          </TableCell>
          <TableCell align="left" size="small">
            {viewed.metric}
          </TableCell>
        </TableRow>
      ));
    } else {
      return (
        <TableRow>
          <TableCell colSpan={3} align="left" size="small">
            No one is has viewed this auction
          </TableCell>
        </TableRow>
      );
    }
  };

  const headerCount = () => {
    if (viewsLoading) {
      return (
        <Typography variant="subtitle2" component="h1" gutterBottom>
          Views: <Skeleton style={{ display: "inline-block" }} variant="text" width={25} />
        </Typography>
      );
    } else {
      return (
        <Typography variant="subtitle2" component="h1" gutterBottom>
          Views: {views ? views.length : 0}
        </Typography>
      );
    }
  };

  return (
    <Paper className={classes.list}>
      {headerCount()}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Bidder name</TableCell>
            <TableCell>Last Viewed</TableCell>
            <TableCell>Views</TableCell>
          </TableRow>
        </TableHead>
        {viewsLoading && (
          <TableBody>
            <TableRow>
              <TableCell>
                <Skeleton variant="text" height={20} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" height={20} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" height={20} />
              </TableCell>
            </TableRow>
          </TableBody>
        )}
        {!viewsLoading && <TableBody>{viewList()}</TableBody>}
      </Table>
    </Paper>
  );
};
