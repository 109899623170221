import * as React from "react";
import { Typography, Container } from "@material-ui/core";
import { InsuranceCompaniesComponent } from "../components/maintenance/InsuranceCompaniesComponent";
import { SecureArea } from "../components/authentication/SecureArea";
import { MemberPermission } from "../services/MemberService";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../services/TelemetryService";

function InsuranceCompanies(RouteComponentProps) {
  return (
    <Container maxWidth={"lg"} fixed>
      <SecureArea memberPermission={MemberPermission.LiveAuctionAdmin}>
        <Typography variant="h4" color="primary" gutterBottom>
          Clients
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          This function allows you to manage the list of clients available.
        </Typography>
        <InsuranceCompaniesComponent />
      </SecureArea>
    </Container>
  );
}

export default withAITracking(reactPlugin, InsuranceCompanies, "InsuranceCompanies");
