import { HubConnection } from "@microsoft/signalr";
import * as React from "react";
import { ILatestBid } from "../interfaces/auctions/ILatestBid";


export interface ISignalRUserMessage {
    message: string; // Plain text message for the user
    type: string; // Type, being either "info" or "error" or "warning"
    operationNonce: string | undefined; // If this relates to a specific operation, then this is the ID  
    timeStamp: string; // If this relates to a specific operation, then this is the ID
}


export interface IHubContext {
    hub: HubConnection | null;
    bidData: ILatestBid | null;
    bidErrorData: ISignalRUserMessage | null;
    privateUserData: ISignalRUserMessage | null;
}

const defaultHubContext: IHubContext = {
    hub: null,
    bidData: null,
    bidErrorData: null,
    privateUserData: null
};

export const HubContext = React.createContext<IHubContext>(defaultHubContext);
export const HubConsumer = HubContext.Consumer;
