import { IBid } from "./IBid";
import { IEntityBase } from "../IEntityBase";
import moment from "moment";
import { IAuctionMetadata } from "./IAuctionMetadata";

export enum AuctionStatus {
  Undefined = 0,
  CreatedPartially = 100,
  CreatedAwaitingBucketing = 110,
  CreatedAwaitingPublishing = 120,
  Active = 200,
  ActiveButSuspended = 210,
  AuctionEndedAndWon = 300,
  AuctionEndedButNotWon = 310,
  AuctionEndedButSuspended = 320,
  AuctionCollectedRecently = 350,
  CompletedAsLotCollected = 400,
  CompletedAsArchived = 410,
  CompletedAsReListed = 420,
  DeletedBeforeBucketing = 500,
}

export const isEndedOrCompleted = (status: AuctionStatus): boolean =>
  status >= AuctionStatus.AuctionEndedAndWon && status <= AuctionStatus.CompletedAsReListed;

export interface IAuction extends IEntityBase {
  lotId: string;
  status: AuctionStatus;
  bucketId: string;
  memberId: string;
  metadata: IAuctionMetadata;
  auctionEnd: string;
  startPrice: number;
  reservePrice: number;
  currentHighestBid: IBid;
  bids: IBid[];
  lotCollectedDate:string;
  lotPaymentDate:string;
}

export const auctionStatusGroupingString = (auctionStatus: AuctionStatus) => {
  switch (auctionStatus) {
    case AuctionStatus.CreatedPartially: {
      return "Incomplete";
    }
    case AuctionStatus.CreatedAwaitingBucketing:
    case AuctionStatus.CreatedAwaitingPublishing: {
      return "Holding";
    }
    case AuctionStatus.Active:
    case AuctionStatus.ActiveButSuspended: {
      return "Live";
    }
    case AuctionStatus.AuctionEndedAndWon:
    case AuctionStatus.AuctionCollectedRecently: {
      return "Closed - Sold";
    }
    case AuctionStatus.AuctionEndedButNotWon:
    case AuctionStatus.AuctionEndedButSuspended: {
      return "Closed - Not Sold";
    }
    case AuctionStatus.CompletedAsLotCollected:
    case AuctionStatus.CompletedAsReListed:
    case AuctionStatus.CompletedAsArchived:
    case AuctionStatus.DeletedBeforeBucketing: {
      return "Archived";
    }
    default: {
      return "Unknown";
    }
  }
};

export const auctionStatusString = (auctionStatus: AuctionStatus) => {
  switch (auctionStatus) {
    case AuctionStatus.CreatedPartially: {
      return "Auction partially created";
    }
    case AuctionStatus.CreatedAwaitingBucketing: {
      return "Awaiting bucket assignment";
    }
    case AuctionStatus.CreatedAwaitingPublishing: {
      return "Bucket assigned and awaiting publishing";
    }
    case AuctionStatus.Active: {
      return "Live";
    }
    case AuctionStatus.ActiveButSuspended: {
      return "Suspended";
    }
    case AuctionStatus.AuctionEndedAndWon: {
      return "Auction ended and won";
    }
    case AuctionStatus.AuctionEndedButNotWon: {
      return "Auction ended but not won";
    }
    case AuctionStatus.AuctionEndedButSuspended: {
      return "Auction ended but suspended";
    }
    case AuctionStatus.AuctionCollectedRecently: {
      return "Collected";
    }
    case AuctionStatus.CompletedAsLotCollected: {
      return "Completed";
    }
    case AuctionStatus.CompletedAsArchived: {
      return "Deleted / Archived";
    }
    case AuctionStatus.CompletedAsReListed: {
      return "Relisted";
    }
    case AuctionStatus.DeletedBeforeBucketing: {
      return "Deleted";
    }
    default: {
      return "Unknown";
    }
  }
};

export const AuctionDefault: IAuction = {
  id: "",
  lotId: "",
  status: AuctionStatus.Undefined,
  memberId: "00000000-0000-0000-0000-000000000000",
  bucketId: "",
  auctionEnd: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
  startPrice: 0,
  reservePrice: 0,
  currentHighestBid: {} as IBid,
  bids: [] as IBid[],
  metadata: {} as IAuctionMetadata,
  _etag: "00000000-0000-0000-0000-000000000000",
  lotCollectedDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
  lotPaymentDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
};
