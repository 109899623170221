import * as React from "react";
import { NotLoggedIn } from "./NotLoggedIn";
import { AuthenticationInProgress } from "./AuthenticationInProgress";
import { MemberPermission } from "../../services/MemberService";
import { AppContext } from "../../contexts/AppContext";
import { MsalConsumer } from "../../authentication/MsalContext";
import Unauthorised from "../../pages/Unauthorised";

interface SecureAreaProps {
  hideNotAuthorisedMessage?: boolean;
  memberPermission: MemberPermission;
}

export const SecureArea: React.FC<SecureAreaProps> = ({ hideNotAuthorisedMessage, memberPermission, children }) => {
  const context = React.useContext(AppContext);

  const hasPermission = () => {
    if (!context) {
      return false;
    }

    return context.memberPermissions >= memberPermission;
  };

  return (
    <MsalConsumer>
      {msal => (
        <>
          {msal.loginInProgress && <AuthenticationInProgress />}
          {!msal.loginInProgress && msal.accounts.length > 0 && (
            <>
              {context && hasPermission() && children}
              {context && !hideNotAuthorisedMessage && !hasPermission() && <Unauthorised />}
            </>
          )}
          {!msal.loginInProgress && msal.accounts.length === 0 && <NotLoggedIn />}
        </>
      )}
    </MsalConsumer>
  );
};
