import { BlobTokenModel } from "../models/BlobTokenModel";
import { StorageURL, AnonymousCredential, ContainerURL, BlockBlobURL, Aborter, uploadBrowserDataToBlockBlob } from "@azure/storage-blob";

const pipeline = StorageURL.newPipeline(new AnonymousCredential());

export async function UploadFileToBlobStorage(token: BlobTokenModel, blobName: string, fileToUpload: File | Blob, metaData?: any) {
  const containerURL = new ContainerURL(token.url, pipeline);
  const blockBlobURL = BlockBlobURL.fromContainerURL(containerURL, blobName);

  const response = await uploadBrowserDataToBlockBlob(Aborter.none, fileToUpload, blockBlobURL, {
    metadata: metaData,
  });
  return response;
}
