import * as React from "react";

import { Grid, TablePagination, Box, InputAdornment, TextField, InputLabel, Select, MenuItem } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { LiveBidList } from "./LiveBidList";
import { LoadingComponent } from "../loading/LoadingComponent";
import { useLiveBidsSearch } from "../../services/AuctionService";
import useDebounce from "../../hooks/useDebounce";
import { useHub } from "../../hooks/HubProvider";
import * as SettingsService from "../../services/SettingsService";
import { GetMembersInsuranceCompanies } from "../../services/MemberService";
import { useMember } from "../../services/MemberService";

export const LiveBidSearch: React.FC = () => {
  const [pageNumber, setPageNumber] = React.useState<number>(0);
  const [pageSize, setPageSize] = React.useState<number>(25);
  const [searchText, setSearchText] = React.useState<string>();
  const [insuranceCompany, setInsuranceCompany] = React.useState<string>("all");
  const [siteId, setSiteId] = React.useState<string>("all");

  const [showProxyBids, setShowProxyBids] = React.useState<boolean>(false);
  const [insuranceCompanies, setInsuranceCompanies] = React.useState<Array<string>>([]);


  const debouncedSearch = useDebounce(searchText, 800);
  const hub = useHub();

  const [liveAuctionResults, liveAuctionResultsLoading, , refreshLiveAuctionResults] = useLiveBidsSearch(
    hub.hub?.connectionId ?? "",
    pageNumber,
    pageSize,
    debouncedSearch ?? "",
    siteId,
    insuranceCompany,
    undefined,
    hub.hub?.connectionId !== null && hub.hub?.connectionId !== undefined
  );

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPageNumber(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(+event.target.value);
    setPageNumber(0);
  };

  React.useEffect(() => {

    SettingsService.GetCurrentMemberAdminSettings()
      .then(result => {
        if (result.parsedBody !== undefined) {
           setShowProxyBids(result.parsedBody.showProxyBidIndicatorOnLiveBids);
        }
      })
      .catch(async response => {
        console.log("error searching:" + response);
      });
  }, []);

  React.useEffect(() => {
    async function fetchData() {
      try {
        const existingCompanies = await GetMembersInsuranceCompanies();
        if (existingCompanies.parsedBody) {
          setInsuranceCompanies(existingCompanies.parsedBody);
        }
      } catch (error) {
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [member, , memberLoading] = useMember(() => {
  });




  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={3}>
          <Select
            fullWidth
            id="siteId"
            name="siteId"
            value={siteId === null ? "all" : siteId}
            onChange={e => {
              setSiteId(e.target.value !== undefined && e.target.value != null ? e.target.value as string : "");
              setPageNumber(0);
            }
            }
          >
            <MenuItem key={-1} value={"all"}>
              All Locations
            </MenuItem>

            {member?.sites.map((item, index) => {
              return (
                !item.isMainSite && (
                  <MenuItem key={index} value={item.id}>
                    {item.siteName}
                  </MenuItem>
                )
              );
            })}
          </Select>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Select
            fullWidth
            id="insuranceCompany"
            name="insuranceCompany"
            value={insuranceCompany === null ? "all" : insuranceCompany}
            onChange={e => {
              setInsuranceCompany(e.target.value !== undefined && e.target.value != null ? e.target.value as string : "");
              setPageNumber(0);
            }
            }
          >
            <MenuItem key={-1} value={"all"}>
              All Clients
            </MenuItem>

            {insuranceCompanies.map((item, index) => {
              return (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>

        <Grid item xs={12} sm={6}>

          <Box display="flex" flexDirection="row-reverse" paddingBottom={4}>
            <TextField
              size="small"
              variant="outlined"
              label="Search"
              type={"text"}
              disabled={liveAuctionResultsLoading}
              onChange={e => {
                setSearchText(e.target?.value ?? "");
                setPageNumber(0);
              }}
              value={searchText ?? ""}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

        </Grid>
      </Grid>



      {liveAuctionResultsLoading && <LoadingComponent label="Loading" />}
      {!liveAuctionResultsLoading && liveAuctionResults && (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <LiveBidList
              liveBidItems={liveAuctionResults?.results ?? null}
              refreshLiveBids={() => refreshLiveAuctionResults()}
              showProxyBids={showProxyBids} />
          </Grid>
          <Grid item xs={12}>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={liveAuctionResults?.count ?? 0}
              rowsPerPage={liveAuctionResults?.pageSize ?? 0}
              page={liveAuctionResults?.pageNumber ?? 0}
              onChangePage={(_event: unknown, newPage: number) => handleChangePage(_event, newPage)}
              onChangeRowsPerPage={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeRowsPerPage(event)}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};
