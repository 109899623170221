import { makeStyles } from "@material-ui/core/styles";

export const EndedAuctionsStyles = makeStyles(theme => ({
  filterGrid: {
    paddingTop: "16px",
    textAlign: "end",
    paddingBottom: "4px",
  },
  filterWidth: { minWidth: "280px" },
  buttonWidth: {
    minWidth: "180px",
  },
  liveBidsArea: { marginTop: "64px", marginLeft: "8px" },
  liveBidsButton: { margin: "8px" },
}));
