import React from "react";
import { useSnackbar } from "notistack";
import { TableCell, Hidden, CircularProgress, Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Table, TableHead, TableRow, TableBody, TableFooter, TablePagination } from "@material-ui/core";
import { Bid, IAuctionInfoWithAdminOnlyData, useAuctionBidHistory } from "../../services/AuctionService";
import * as BidderService from "../../services/BidderService";
import { bidPlacedFormatSeconds, currencyFormat } from "../../helpers/text-format/TextFormat";
import { IBidder } from "../../interfaces/bidders/IBidder";
import { AuctionStatus } from "../../interfaces/auctions/IAuction";
import { ISalvageClientSettings } from "../../interfaces/ISalvageClientSettings";
import { GetClientSettings } from "../../services/SettingsService";
import { IBid } from "../../interfaces/auctions/IBid";
import CloseIcon from '@material-ui/icons/Close';
import moment from "moment";

interface BidHistoryRowProps {
  auctionListing: IAuctionInfoWithAdminOnlyData;
  bidder: IBidder;
}

export const BidHistoryRow: React.FC<BidHistoryRowProps> = ({ auctionListing, bidder }) => {
  const { enqueueSnackbar } = useSnackbar();
  const clientSettings: ISalvageClientSettings = GetClientSettings();

  const [bidHistory, bidHistoryLoading] = useAuctionBidHistory(auctionListing.id, () => {
    enqueueSnackbar("There was a problem loading the latest bid information, please try again.", { variant: "error" });
  });
  const [latestBidForBidder, setLatestBidForBidder] = React.useState<Bid>();

  React.useEffect(() => {
    if (bidHistory) {
      const sorted = [...bidHistory].filter(a => a.bidderId === bidder.id).sort((a, b) => Number(b.bidDateTime) - Number(a.bidDateTime));
      setLatestBidForBidder(sorted.length > 0 ? sorted[0] : undefined);
    }
  }, [bidHistory, bidder.id]);

  const message = () => {
    switch (auctionListing.status) {
      case AuctionStatus.Active: {
        return latestBidForBidder?.isWinningBid ? "Highest bidder" : "Outbid";
      }
      case AuctionStatus.ActiveButSuspended || AuctionStatus.AuctionEndedButSuspended: {
        return "Auction suspended";
      }
      case AuctionStatus.CompletedAsArchived: {
        return "Auction deleted / archived";
      }
      default: {
        return "Auction ended";
      }
    }
  };


  //let selectedAuction: IAuctionInfoWithAdminOnlyData;;
  const [selectedAuction, setSelectedAuction] = React.useState<IAuctionInfoWithAdminOnlyData>();
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(30);
  const [bids, setBids] = React.useState<IBid[]>([]);
  const [bidsCount, setBidsCount] = React.useState(0);
  const [loadingBids, setLoadingBids] = React.useState(true);
  const [showBids, setShowBids] = React.useState(false);

  const fetchBidsData = async (id: string, pagePassed: number, pageSizePassed: number) => {
    setBids([]);
    setBidsCount(0);

    if (id !== undefined && id != null) {
      setLoadingBids(true);
      try {
        const results = await BidderService.getBiddersBids(bidder.id, id, pagePassed, pageSizePassed);
        if (results.parsedBody) {
          setBids(results.parsedBody!.results);
          setBidsCount(results.parsedBody!.resultCount);
        }
      } catch (error) { }
      setLoadingBids(false);
    }
  };


  const viewBids = (auction: IAuctionInfoWithAdminOnlyData) => {
    setSelectedAuction(auction);

    setTimeout(() => {
      fetchBidsData(auction.id, page, pageSize);
    }, 500);

    setLoadingBids(true);
    setShowBids(true);
  };

  return (
    <>
      <Dialog open={showBids} onClose={() => setShowBids(false)}
        PaperProps={{
          style: {
            maxWidth: 1200,
            width: '100%',
          }
        }}
      >
        <DialogTitle>Vehicle Bid History</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setShowBids(false)}
          style={{
            position: 'absolute',
            right: '8px',
            top: '8px',
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent style={{ paddingBottom: '30px' }}>
          {loadingBids && (
            <>
              <TableCell colSpan={9} align="center">
                <CircularProgress color="primary" size={16} thickness={8} style={{ marginRight: "12px" }} />
                Loading...
              </TableCell>
            </>
          )}
          {!loadingBids && (

            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Type</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>IP Address</TableCell>
                  <TableCell>Entered By</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bids?.map((item, bidIndex) => (
                  <TableRow>
                    <TableCell>Bid Placed</TableCell>
                    <TableCell style={{ whiteSpace: "nowrap" }}>{moment(item.bidDateTime).format('DD/MM/YYYY h:mm a')}</TableCell>
                    <TableCell>{currencyFormat(item.amount)}</TableCell>
                    <TableCell>{item.bidderIp}</TableCell>
                    <TableCell>{item.bidderName}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 30, 50, 100]}
                    colSpan={6}
                    count={bidsCount}
                    rowsPerPage={pageSize}
                    page={page}
                    onChangePage={(evt, number) => {
                      setPage(number)
                      if (selectedAuction != null) {
                        fetchBidsData(selectedAuction.id, number, pageSize);
                      }
                    }}
                    onChangeRowsPerPage={event => {
                      setPageSize(parseInt(event.target.value));
                      if (selectedAuction != null) {
                        fetchBidsData(selectedAuction.id, page, parseInt(event.target.value));
                      }

                      setTimeout(() => {
                        //   fetchBidsData();
                        console.log('page change: ', pageSize);
                      }, 500);
                    }}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          )}
        </DialogContent>
      </Dialog>








      {bidHistoryLoading && (
        <>
          <TableCell colSpan={9} align="center">
            <CircularProgress color="primary" size={16} thickness={8} style={{ marginRight: "12px" }} />
            Loading...
          </TableCell>
        </>
      )}
      {!bidHistoryLoading && (
        <>
          <TableCell padding="checkbox">
            <a href={`${clientSettings.AuctionSiteUrl}Lot/${auctionListing.id}/`} target="_blank" rel="noopener noreferrer">
              <img alt="logo" src={auctionListing.thumbnailImageUrl} width="95px" height="auto"></img>
            </a>
          </TableCell>
          <TableCell>{auctionListing.title}</TableCell>
          <Hidden smDown>
            <TableCell>{auctionListing.memberName}</TableCell>
            <TableCell style={{ whiteSpace: "nowrap" }}>{moment(auctionListing.auctionEnd).format('DD/MM/YYYY h:mm a')}</TableCell>
            <TableCell>{currencyFormat(latestBidForBidder?.amount ? latestBidForBidder?.amount : 0)}</TableCell>
            <TableCell>{auctionListing.adminOnly.bidderIp ?? "N/A"}</TableCell>
            <TableCell>{message()}</TableCell>
            <TableCell>{auctionListing.metadata.referenceNumber}</TableCell>
            <TableCell>{auctionListing.adminOnly.registrationNumber}</TableCell>
            <TableCell>
              <Button
                variant="contained"
                color="primary"
                onClick={() => { viewBids(auctionListing); }}>
                VIEW BIDS
              </Button>
            </TableCell>
          </Hidden>
        </>
      )}
    </>
  );
};
