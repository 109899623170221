import * as React from "react";
import { Typography, Container, Box } from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import { LotFormComponent } from "../components/create-lot/LotFormComponent";
import { SecureArea } from "../components/authentication/SecureArea";
import { MemberPermission } from "../services/MemberService";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../services/TelemetryService";

type CreateLotParams = { id: string };

function CreateLot({ match }: RouteComponentProps<CreateLotParams>) {
  return (
    <Container maxWidth={"lg"} fixed>
      <SecureArea memberPermission={MemberPermission.BucketAdmin}>
        <Typography variant="h4" color="primary" gutterBottom>
          Create Lot
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Fill out the form to create a new lot.
        </Typography>
        <Box mt={4}>
          <LotFormComponent id={match.params.id === undefined ? null : match.params.id} auctionId={null} />
        </Box>
      </SecureArea>
    </Container>
  );
}

export default withAITracking(reactPlugin, CreateLot, "CreateLot");
