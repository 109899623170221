import * as React from "react";
import { Typography, Container, Box } from "@material-ui/core";
import { SecureArea } from "../components/authentication/SecureArea";
import { MemberPermission } from "../services/MemberService";
import { AuctionManagementContainer } from "../components/auction-management/AuctionManagementContainer";
import { RouteComponentProps } from "react-router";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../services/TelemetryService";

type TParams = { type: string };

function AuctionManagement({ match }: RouteComponentProps<TParams>) {
  return (
    <Container maxWidth={"lg"} fixed>
      <SecureArea memberPermission={MemberPermission.BucketAdmin}>
        <Typography variant="h4" color="primary" gutterBottom>
          Lot Management
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          View and manage all auctions
        </Typography>
        <Box mt={4}>
          <AuctionManagementContainer tab={match.params.type === "Draft" ? 1 : 0} />
        </Box>
      </SecureArea>
    </Container>
  );
}

export default withAITracking(reactPlugin, AuctionManagement, "AuctionManagement");
