import * as React from "react";
import { Typography, Container, Box } from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import { SecureArea } from "../components/authentication/SecureArea";
import { MemberPermission } from "../services/MemberService";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../services/TelemetryService";
import { StripeProductFormComponent } from "../components/create-stripe-product/StripeProductFormComponent";

type EditStripeProductParams = { id: string };

function EditStripeProduct({ match }: RouteComponentProps<EditStripeProductParams>) {
  return (
    <Container maxWidth={"lg"} fixed>
      <SecureArea memberPermission={MemberPermission.SuperAdmin}>
        <Typography variant="h4" color="primary" gutterBottom>
        Edit Bidder Subscription Plan
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
        Fill out the form to update a bidder subscription plan
        </Typography>
        <Box mt={4}>
          <StripeProductFormComponent id={match.params.id === undefined ? null : match.params.id} />
        </Box>
      </SecureArea>
    </Container>
  );
}

export default withAITracking(reactPlugin, EditStripeProduct, "EditStripeProduct");
