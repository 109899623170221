import * as React from "react";
import { EndedAuctionsComponent } from "../components/ended-auctions/EndedAuctionsComponent";
import { Container } from "@material-ui/core";
import { SecureArea } from "../components/authentication/SecureArea";
import { MemberPermission } from "../services/MemberService";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../services/TelemetryService";

function EndedAuctions() {
  return (
    <Container maxWidth={"lg"} fixed>
      <SecureArea memberPermission={MemberPermission.BucketAdmin}>
        <EndedAuctionsComponent />
      </SecureArea>
    </Container>
  );
}

export default withAITracking(reactPlugin, EndedAuctions, "EndedAuctions");
