import React, { useEffect } from "react";
import { CardMedia, Typography, TableCell, Chip, Box, Tooltip, Button } from "@material-ui/core";
import Flag from "react-world-flags";
import { useSignalrLatestBid } from "../../hooks/useSignalR";
import * as MediaService from "../../services/MediaService";
import { LiveBidItem, useLiveBid } from "../../services/AuctionService";
import { currencyFormat } from "../../helpers/text-format/TextFormat";
import { IMedia } from "../../interfaces/lots/IMedia";
import TimerContainer from "./TimerContainer";
import * as Styles from "./styles/LiveBidItemStyles";
import { EditDialog } from "./EditDialog";
import { Link } from "react-router-dom";
import { ISalvageClientSettings } from "../../interfaces/ISalvageClientSettings";
import { GetClientSettings } from "../../services/SettingsService";
import { AuctionStatus } from "../../interfaces/auctions/IAuction";

interface IProps {
  liveBidItemProp: LiveBidItem;
  handleBiddingClosed: () => void;
  showProxyBids: boolean;
  openDialog: (auctionId: string, previousAuctionId: string, title: string) => void;
}

export const LiveBid: React.FC<IProps> = ({ liveBidItemProp, handleBiddingClosed, showProxyBids, openDialog }) => {
  const classes = Styles.LiveBidItemStyles();
  const clientSettings: ISalvageClientSettings = GetClientSettings();

  const [liveBidItem, setLiveBidItem] = React.useState<LiveBidItem>(liveBidItemProp);
  const [signalrLatestBid] = useSignalrLatestBid(liveBidItem.id);
  const [mediaUrl, setMediaUrl] = React.useState<string>();

  const [liveBid, , , refreshLiveBid] = useLiveBid(liveBidItem.id, undefined, signalrLatestBid !== null && signalrLatestBid !== undefined);

  // If a new bid comes in, then overwrite our current position one with that.
  React.useEffect(() => {
    if (signalrLatestBid !== null && signalrLatestBid !== undefined) {
      refreshLiveBid();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signalrLatestBid]);

  React.useEffect(() => {
    if (liveBid) {
      setLiveBidItem(liveBid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liveBid]);

  var title = [
    liveBidItem.make,
    liveBidItem.model,
    liveBidItem.trimLevel,
    liveBidItem.damageCategory,
    liveBidItem.registrationNumber,
    liveBidItem.mileage !== 0 ? liveBidItem.mileage : "Unknown",
    liveBidItem.location ?? "Location unavailable",
  ]
    .filter(e => e && e !== "")
    .join(" ");

  const getTitle = () => {
    var titleRow1: string = [liveBidItem.make, liveBidItem.model, liveBidItem.trimLevel].filter(e => e && e !== "").join(" ");

    var titleRow2: string = [
      liveBidItem.damageCategory,
      liveBidItem.registrationNumber,
      liveBidItem.mileage !== 0 ? liveBidItem.mileage : "Unknown",
      liveBidItemProp.memberName,
      liveBidItem.location ?? "Location unavailable",
    ]
      .filter(e => e && e !== "")
      .join(" ");

    if (titleRow1 === "") {
      titleRow1 = liveBidItem.title;
    }

    return (
      <>
        <Typography component="p" className={classes.auctionLotDetails}>
          {titleRow1} <EditDialog lotId={liveBidItem.lotId} auctionId={liveBidItem.id} />
        </Typography>
        <Typography component="p" className={classes.tableCellText}>
          {titleRow2}
        </Typography>
        <Typography component="p" className={classes.auctionLotDetails}>
          {liveBidItem.insuranceCompany}
        </Typography>
      </>
    );
  };

  useEffect(() => {
    MediaService.GetLotMedia(liveBidItem.lotId).then((response: any) => {
      const media: IMedia[] | undefined = response.parsedBody as IMedia[];
      if (media !== undefined && media.length > 0) {
        const primaryMedia: IMedia[] = media.filter(item => {
          return item.order === 0;
        });

        if (primaryMedia.length > 0) {
          setMediaUrl(primaryMedia[0].url);
        } else if (primaryMedia.length > 1) {
          setMediaUrl(primaryMedia[0].url);
        } else {
          setMediaUrl(media[0].url);
        }
      }
    });
  }, [liveBidItem.lotId]);

  const imageContainer = () => {
    if (mediaUrl !== undefined) {
      return (
        <a
          style={{ textDecoration: "none", color: "black" }}
          href={`${clientSettings.AuctionSiteUrl}Lot/${liveBidItem.id}/`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <CardMedia className={classes.cover} component="img" alt={liveBidItem.make} image={mediaUrl} title={liveBidItem.make} />
        </a>
      );
    }
  };

  const highestBidGreaterThanReserveChip = () => {
    if ((liveBidItem?.amount ?? 0) === 0) {
      return (
        <Tooltip title="No bids have been placed">
          <Chip className={classes.defaultChip} size="small" color="default" label={currencyFormat(liveBidItem?.amount ?? 0)} />
        </Tooltip>
      );
    } else if (liveBidItem.reservePrice === 0) {
      return <Chip className={classes.greenChip} size="small" color="default" label={currencyFormat(liveBidItem?.amount ?? 0)} />;
    } else if (liveBidItem.amount >= liveBidItem.reservePrice) {
      return (
        <Tooltip title="Bid is higher than reserve">
          <Chip className={classes.greenChip} size="small" color="default" label={currencyFormat(liveBidItem?.amount ?? 0)} />
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title="Bid is not higher than reserve">
          <Chip className={classes.redChip} size="small" color="default" label={currencyFormat(liveBidItem?.amount ?? 0)} />
        </Tooltip>
      );
    }
  };

  const isBuyBackChip = () => {
    if (liveBidItem.isBuyBack) {
      return (
        <Tooltip title="Buyback has been placed">
          <Chip className={classes.yellowChip} size="small" color="default" label={"BB"} />
        </Tooltip>
      );
    } else {
      return "";
    }
  };

  const profitChip = () => {
    const current = liveBidItem.currentProfit;

    if (current > 0) {
      return (
        <Tooltip title={`This auction is making a ${currencyFormat(current)} profit`}>
          <Chip className={classes.greenChip} size="small" color="default" label={currencyFormat(current)} />
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title={`This auction is making a ${currencyFormat(current)} loss`}>
          <Chip className={classes.redChip} size="small" color="default" label={currencyFormat(current)} />
        </Tooltip>
      );
    }
  };

  const getBidderName = () => {
    if (liveBidItem.bidderName) {
      if (liveBidItem.bidderSuspended) {
        return (
          <Tooltip title={"This bidder has been suspended"}>
            <Link style={{ textDecoration: "none", color: "black" }} to={`/BidderDetailsChange/${liveBidItem.bidderId}`}>
              <Chip className={classes.redChip} style={{ cursor: "pointer" }} size="small" color="default" label={liveBidItem.bidderName} />
            </Link>
          </Tooltip>
        );
      } else {
        return (
          <Link style={{ textDecoration: "none", color: "black" }} to={`/BidderDetailsChange/${liveBidItem.bidderId}`}>
            {liveBidItem.bidderName}
          </Link>
        );
      }
    } else {
      return (
        <Tooltip title={"Bidder name"}>
          <>{liveBidItem.bidderName ?? "No bidder"}</>
        </Tooltip>
      );
    }
  };

  return (
    <>
      <TableCell align="left" size="small" padding={"none"}>
        {imageContainer()}
      </TableCell>
      <TableCell align="left" size="small" padding={"none"} className={classes.tableCell}>
        <Typography variant="body1" className={classes.tableCellText}>
          {liveBidItem.referenceNumber}
        </Typography>
      </TableCell>
      <TableCell align="left" size="small" padding={"none"} className={classes.tableCell}>
        {getTitle()}
      </TableCell>
      <TableCell align="right" size="small" padding={"none"} className={classes.tableCell}>
        <TimerContainer
          endDateInput={liveBidItem.auctionEnd}
          status={signalrLatestBid?.auctionInfo.auctionStatusCode ?? AuctionStatus.Active}
          handleBiddingClosed={() => handleBiddingClosed()}
        />
      </TableCell>
      <TableCell align="right" padding={"none"} className={classes.tableCell}>
        <Typography variant="body1" className={classes.tableCellText}>
          {currencyFormat(liveBidItem.cost)}
        </Typography>
        <Typography variant="body1" className={classes.tableCellText}>
          {currencyFormat(liveBidItem.preAccidentValue)}
        </Typography>
      </TableCell>
      <TableCell align="right" size="small" padding={"none"} className={classes.tableCell}>
        <Typography variant="body1" className={classes.tableCellText}>
          {currencyFormat(liveBidItem.reservePrice)}
        </Typography>
        <Typography variant="body1" className={classes.tableCellText}>
          {currencyFormat(liveBidItem.startPrice)}
        </Typography>
      </TableCell>
      <TableCell align="right" size="small" padding={"none"} className={classes.tableCell}>
        <Box className={classes.highestProxy}>
          {highestBidGreaterThanReserveChip()}
          {isBuyBackChip()}
        </Box>
      </TableCell>
      <TableCell align="right" size="small" padding={"none"} className={classes.tableCell}>
        <Typography variant="body1" className={classes.tableCellText}>
          {currencyFormat(liveBidItem.adminFee)}
        </Typography>
        <Typography variant="body1" className={classes.tableCellText}>
          {currencyFormat(liveBidItem.auctionFee)}
        </Typography>
      </TableCell>
      <TableCell align="right" size="small" padding={"none"} className={classes.tableCell}>
        <Typography variant="body1" className={classes.tableCellText}>
          {`£${liveBidItem.webFee}`}
        </Typography>
        <Typography style={{ fontSize: "9px" }} variant="body1" className={classes.tableCellText}>
          {`£${liveBidItem.auctionFee} + £${liveBidItem.adminFee} + £${liveBidItem.additionalFee}`}
        </Typography>
      </TableCell>
      <TableCell align="right" size="small" padding={"none"} className={classes.tableCell}>
        {profitChip()}
      </TableCell>
      <TableCell align="left" size="small" padding={"none"} className={classes.tableCell}>
        {liveBidItem.bidderCountryCode && (
          <Typography component="span" className={classes.tableCellText}>
            <Flag code={liveBidItem.bidderCountryCode} height="9" width="auto" />
          </Typography>
        )}
        <Typography component="span" className={classes.tableCellText}>
          {getBidderName()}
        </Typography>
        <Typography component="span" className={classes.tableCellText} color={"primary"}>
          {liveBidItem.bidderPurchaseCount}
        </Typography>
      </TableCell>
      <TableCell align="right" size="small" padding={"none"} className={classes.tableCell}>
        <Typography variant="body1" className={classes.tableCellTextMetrics}>
          {liveBidItem.watchCount}
        </Typography>
      </TableCell>
      <TableCell align="right" size="small" padding={"none"} className={classes.tableCell}>
        <Typography variant="body1" className={classes.tableCellTextMetrics}>
          {liveBidItem.viewCount}
        </Typography>
      </TableCell>
      <TableCell align="right" size="small" padding={"none"} className={classes.tableCell}>
        <Typography variant="body1" className={classes.tableCellTextMetrics}>
          {liveBidItem.bidCount}
        </Typography>
      </TableCell>

      {showProxyBids && (
        <TableCell align="right" size="small" padding={"none"} className={classes.tableCell}>
          <Typography variant="body1" className={classes.tableCellTextMetrics}>

            {liveBidItem.isProxy && liveBidItem.proxyAmount > liveBidItem.amount && (
              <>P</>
            )}


          </Typography>
        </TableCell>
      )}

      <TableCell align="left" size="small" padding={"checkbox"}>
        <Button
          color={"primary"}
          style={{ fontSize: "11px" }}
          size={"small"}
          onClick={() => openDialog(liveBidItem.id, liveBidItem.previousAuctionId, title ?? "")}
        >
          Manage
        </Button>
      </TableCell>
    </>
  );
};
