export interface IStripeProduct {
    id?: string;
    name: string;
    description: string;
    stripeId: string;
    priceId: string;
    durationInMonths: number;
    active: boolean;
    amount?: number;
    vatInclusive: boolean;
    stripeProductName: string;
}


export const StripeProductDefault: IStripeProduct = {
    id: undefined,
    name: "",
    description: "",
    stripeId: "",
    priceId: "",
    durationInMonths: 12,
    active: true,
    amount: undefined,
    vatInclusive: false,
    stripeProductName: ''
};