import { makeStyles } from "@material-ui/styles";

import { Theme, createStyles } from "@material-ui/core";
import { grey } from "../../../theme";

export const PackUploadStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: "30px",
      paddingTop: "50px",
      textAlign: "center",
      minHeight: "200px",
    },
    processingPaper: {
      padding: "30px",
      paddingTop: "35px",
      textAlign: "center",
      minHeight: "200px",
    },
    folderIcon: { fontSize: "40px" },
    errorText: {
      textAlign: "left",
    },
    tableRow: {
      "&:nth-of-type(odd)": {
        backgroundColor: grey[50],
      },
    },
    buttons: {
      display: "flex",
      justifyContent: "flex-end",
    },
    button: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(1),
    },
    outcomeIcons: {
      fontSize: "100px",
      margin: "40px",
    },
    statusContainer: {
      marginTop: "15px",
    },
  })
);
