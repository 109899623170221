import React, { useState } from "react";
import { IStripeProduct } from "../../interfaces/stripe-products/IStripeProduct";
import { Paper, Table, TableHead, TableRow, TableCell, TableBody, Tooltip, IconButton, DialogContentText, Fab } from "@material-ui/core";
import * as Styles from "./styles/StripeProductItemStyles";
import DeleteIcon from "@material-ui/icons/Delete";
import { useHistory } from "react-router";
import EditIcon from "@material-ui/icons/Edit";
import { DialogComponent } from "../auction-management/DialogComponent";
import * as StripeProductService from "../../services/StripeProductService";
import { useSnackbar, OptionsObject } from "notistack";

const variantError: OptionsObject = { variant: "error" };
const variantSuccess: OptionsObject = { variant: "success" };

interface StripeProductListProps {
  results: IStripeProduct[];
  refresh: (toPost?: undefined) => void;
}


export const StripeProductList: React.FC<StripeProductListProps> = ({ results, refresh }) => {
  const classes = Styles.StripeProductItemStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [selectedPlanId, setSelectedPlanId] = useState<string>("");

  const handleDeletion = (id) => {
    setOpen(false);
    setLoading(true);
    StripeProductService.Delete(id).then(result => {      
      enqueueSnackbar("Successfully Deleted.", variantSuccess);
      refresh();
      setLoading(false);
    }).catch(async response => {
      enqueueSnackbar("Could not delete plan, please try again.", variantError);
    })
  }

  const DeleteDialog = (id) => {
    setSelectedPlanId(id);
    setOpen(true);
  };

  if (results.length >= 1) {
    return (

      <div>
        <DialogComponent
          open={open}
          onClose={setOpen}
          title="Confirm deletion of plan"
          changeHandler={handleDeletion}
          contentElement={<DialogContentText>Are you sure you want to remove this plan?</DialogContentText>}
          id={selectedPlanId}
          buttonDisabled={loading}
        />


        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell}>
                  <h3 className={classes.margin}>Name</h3>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <h3 className={classes.margin}>Description</h3>
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                  <h3>Stripe Product</h3>
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                  <h3 className={classes.margin}>Duration</h3>
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                  <h3>Stripe Product Price</h3>
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                  <h3>&nbsp;</h3>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {results.map((item: IStripeProduct, index: number) => (
                <TableRow
                  hover
                  key={item.id}
                  role="checkbox"
                  className={classes.tableRow}
                >

                  <TableCell className={classes.tableCell} component="th" scope="row">
                    {item.name}
                  </TableCell>
                  <TableCell style={{ maxWidth: "420px",}}className={classes.tableCell} component="th" scope="row">
                    {item.description}
                  </TableCell>
                  <TableCell className={classes.tableCell} component="th" scope="row">
                    {item.stripeProductName}
                  </TableCell>
                  <TableCell className={classes.tableCell} component="th" scope="row">
                    {item.durationInMonths} Months
                  </TableCell>
                  <TableCell className={classes.tableCell} component="th" scope="row">
                    {item.amount !== undefined && item.amount != null ? `£${(item.amount/100).toFixed(2)}` : `Unknown`}
                  </TableCell>
                  <TableCell className={classes.tableCell} component="th" scope="row">

                    <Tooltip title="Edit plan">
                    <Fab
                        color="primary"
                        aria-label="add"
                        size="small"
                        onClick={() => history.push(`/StripeProducts/Edit/${item.id}`)}
                    >
                      <EditIcon/>
                    </Fab>

                    </Tooltip>

                    <Tooltip title="Delete plan">

                    <Fab
                        color="secondary"
                        aria-label="add"
                        size="small"
                        style={{ marginLeft: "8px", marginTop: "15px", marginBottom: "15px" }}
                        onClick={() => {
                          DeleteDialog(item.id);
                          setOpen(true);
                        }}
                        >
                          <DeleteIcon/>
                        </Fab>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
    );
  } else {
    return <h2>No plans found</h2>;
  }
};


