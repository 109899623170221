export enum AlertType {
    Alert = 0,
    Notification = 1
  }
  
  export const allAlertType = Object.values(AlertType)
    .filter(val => typeof val !== "string")
    .map(f => f as AlertType);
  
  export const alertTypeDescription = (filterType: AlertType) => {
    switch (filterType) {
      case AlertType.Alert:
        return "Alert";
      case AlertType.Notification:
        return "Notification";
      default:
        return "";
    }
  };
  