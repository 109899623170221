import { makeStyles } from "@material-ui/styles";

import { Theme, createStyles } from "@material-ui/core";

export const CreateBucketStyles = makeStyles((theme: Theme) =>
  createStyles({
    addNewBucketLabel: {
      marginLeft: "15px;",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    loading: { width: "500px" },
  })
);
