import { makeStyles } from "@material-ui/core";

export const UpdateNotificationStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: "16px",
  },
  section: {
    paddingBottom: "24px",
  },
}));
