import { IAddress, AddressDefault } from "../IAddress";
import { IBusiness } from "./IBusiness";
import { VerificationStatus } from "./IVerification";
import { ISuspension } from "./ISuspension";
import { BidderStatus } from "../../enums/BidderStatus";
import { IVerification } from "./IVerification";

export interface IBidder {
  id: string;
  title: string;
  firstName: string;
  lastName: string;
  email: string;
  landlineNumber: string;
  mobileNumber: string;
  address: IAddress;
  bidderStatus: BidderStatus;
  isPotentialDuplicate: boolean;
  business: IBusiness | null;
  watchlist: Array<string>;
  verificationStatus: VerificationStatus;
  identityProvider: string;
  lastLogin: Date;
  bidderCreation: Date;
  lastBidDate: Date | null;
  lastAuctionWonDate: Date | null;
  mailingListOptIn: boolean;
  subscriptionEndDate: Date | null;
  suspensionDetails: ISuspension[];
  memberId: string;
  memberName: string;
  registrationIP: string;
  accountOrigin: string;
  accountType: number;
  accountTypeFriendly: string;
  verification: IVerification | null;
  termsAndConditionsAgreementDate: Date | null;
  termsAndConditionsAgreementVersion: number | null;


  subscriptionPaymentMade: boolean;
}

export const BidderDefault: IBidder = {
  id: "",
  email: "",
  landlineNumber: "",
  mobileNumber: "",
  address: AddressDefault,
  bidderStatus: BidderStatus.Unknown,
  isPotentialDuplicate: false,
  business: null,
  watchlist: [],
  verificationStatus: VerificationStatus.Unknown,
  identityProvider: "Unknown",
  lastLogin: new Date(),
  bidderCreation: new Date(),
  lastBidDate: null,
  lastAuctionWonDate: null,
  mailingListOptIn: false,
  subscriptionEndDate: null,
  suspensionDetails: [],
  memberId: "",
  memberName: "",
  firstName: "",
  lastName: "",
  title: "",
  registrationIP: "",
  accountOrigin: "",
  accountType: 0,
  accountTypeFriendly: "",
  verification: null,
  termsAndConditionsAgreementDate: null,
  termsAndConditionsAgreementVersion: null,

  subscriptionPaymentMade: false
};
