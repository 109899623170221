import { makeStyles } from "@material-ui/styles";

import { Theme, createStyles } from "@material-ui/core";

export const SearchStyles = makeStyles((theme: Theme) =>
  createStyles({
    resetButton: {
      margin: "16px 8px 0px 8px",
      padding: "8px 20px 0px 20px",
    },
    width100: {
      width: "100%",
    },
    searchTextField: {
      width: "100%",
      display: "flex",
    },
  })
);
