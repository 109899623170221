import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableCell,
  Paper,
  TableContainer,
  TableRow,
  TableHead,
} from "@material-ui/core";
import { AuctionAuditChange } from "../../../services/AuctionLotMgmtService";
import { AuctionHistoryDialogStyles } from "../styles/AuctionHistoryDialogStyles";
import { asDateTimeIfPossible, currencyFormat } from "../../../helpers/text-format/TextFormat";
import { AuctionStatus, auctionStatusString } from "../../../interfaces/auctions/IAuction";

interface HistoryDialog {
  open: boolean;
  setOpen: (open: boolean) => void;
  auctionAuditChanges: AuctionAuditChange[];
}

export const AuctionAuditChangeDialog: React.FC<HistoryDialog> = ({ open, setOpen, auctionAuditChanges }) => {
  const classes = AuctionHistoryDialogStyles();

  const getPropertyName = (propertyName: string) => {
    return propertyName.replace(/([A-Z])/g, " $1").trim();
  };

  const parseValue = (propertyName: string, value: string | undefined) => {
    switch (propertyName) {
      case "Status":
        return auctionStatusString(AuctionStatus[value ?? "Undefined"]);

      case "StartPrice":
      case "ReservePrice":
      case "BidIncrement":
      case "PreAccidentValue":
      case "Cost":
      case "AdditionalFee":
      case "ProxyAmount":
      case "Amount":
        return value ? currencyFormat(Number(value)) : "";

      case "IsFeatured":
      case "IsFreeSubscription":
      case "IsE2eLot":
      case "CanItemBeViewed":
      case "CanItemBeDelivered":
      case "IsItemOffSite":
      case "HasServiceHistory":
      case "HasKeys":
      case "HasStereo":
      case "HasLogBook":
      case "HasVIN":
      case "VatIncluded":
      case "DoesStart":
      case "DoesDrive":
      case "IsBuyBack":
      case "IsDeleted":
      case "AuctionEnd":
      case "BidDateTime":
      default:
        return asDateTimeIfPossible(value) ?? "-";
    }
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
      <DialogTitle>Auction Audit Changes</DialogTitle>
      <DialogContent>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={1} className={classes.tableHeader} style={{ width: "20%" }}>
                  Property Name
                </TableCell>
                <TableCell colSpan={1} className={classes.tableHeader} style={{ width: "40%" }}>
                  Original
                </TableCell>
                <TableCell colSpan={1} className={classes.tableHeader} style={{ width: "40%" }}>
                  Updated
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {auctionAuditChanges &&
                auctionAuditChanges.map((audit: AuctionAuditChange, index) => (
                  <TableRow key={index} className={classes.tableRow}>
                    <TableCell>{getPropertyName(audit?.propertyName)}</TableCell>
                    <TableCell>{parseValue(audit?.propertyName, audit?.originalValue) ?? ""}</TableCell>
                    <TableCell>{parseValue(audit?.propertyName, audit?.updatedValue) ?? ""}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};
