import { makeStyles } from "@material-ui/styles";

import { Theme, createStyles } from "@material-ui/core";
import { lightGrey } from "../../../theme";
import { grey } from "@material-ui/core/colors";

export const AuctionHistoryDialogStyles = makeStyles((theme: Theme) =>
  createStyles({
    skeleton: {
      margin: "12px 0px 12px 0px",
      padding: "8px 8px",
    },
    tableContainer: {
      margin: "12px 0px 12px 0px",
    },
    tableHeader: {
      backgroundColor: lightGrey,
      fontSize: "20px",
    },
    tableRow: {
      "&:nth-of-type(odd)": {
        backgroundColor: grey[50],
      },
    },
    tableCell: { verticalAlign: "center" },
    salesDocumentLink: {
      display: "flex",
      cursor: "pointer",
    },
    attachmentIcon: {
      paddingLeft: "4px",
    },
    proxyBidContainer: { display: "inline-flex" },
    winning: { color: "green", paddingRight: "8px" },
    losing: { color: "red", paddingRight: "8px" },
    proxyAmount: { display: "inline-block" }
  })
);
